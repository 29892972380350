import React, { useState, useMemo, ReactElement } from "react";
import { Button, Dropdown, Popup, SemanticICONS } from "semantic-ui-react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import util from "utils/utils";
import { Other } from "simplydo/interfaces";

type RawAction<ItemType> = {
  checkPermission?: string;
  checkEnabledFeatures?: string[];
  superadminsBypassChecks?: boolean;
  checkSuperAdminPermission?: string;
  appear?: "single" | "multi" | "both";
} & (
  | {
      type?: "Item";
      name: string;
      icon?: SemanticICONS;
      onClick?: (items: ItemType[]) => void;
      onHover?: (items: ItemType[]) => React.ReactNode;
      link?: (items: ItemType[]) => string;
      disabled?: boolean;
      actions?: (RawAction<ItemType> | ((items: ItemType[]) => RawAction<ItemType>))[];
    }
  | {
      type: "Divider";
    }
);

export type Action<ItemType> = RawAction<ItemType> | ((items: ItemType[]) => RawAction<ItemType>);

export type ActionDropdownProps<ItemType> = {
  actions?: Action<ItemType>[] | ((currentItems: ItemType[]) => Action<ItemType>[]);
  items: ItemType[];
  type: "single" | "multi";
  user: Other.IUserMe | null;
  subMenu?: {
    name: string;
    icon?: SemanticICONS;
    onClick: () => void;
    open: boolean;
  };
};

const SubMenuItem = styled(Dropdown.Item)`
  &&& {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1em;
    color: rgba(0, 0, 0, 0.87);
    padding: 0.78571429rem 0.64285714rem !important;
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
    box-shadow: none;
    -webkit-touch-callout: none;
    &:hover {
      background: rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.95);
    }
  }
`;

const StyledDropdownMenu = styled(Dropdown.Menu)<{ $subMenu: boolean }>`
  &&&& {
    ${({ $subMenu }) =>
      $subMenu
        ? `
        right: calc(100% + 10px) !important;
        left: auto !important;
      `
        : ""}
  }
`;

const ActionDropdown = <ItemType extends Record<string, any>>(props: ActionDropdownProps<ItemType>) => {
  const { user, actions: propActions } = props;

  // Maintain open state just for submenus
  const [subMenuOpen, setSubMenuOpen] = useState("");

  const multiProps =
    !props.subMenu && props.type === "multi"
      ? {
          icon: "caret down",
          trigger: <div style={{ whiteSpace: "nowrap" }}>{util.pluralise(props.items.length, "Item", "Items")}</div>,
          style: {
            flexWrap: "nowrap",
            display: "flex",
          },
        }
      : {};
  const actions = useMemo(() => {
    if (typeof propActions === "function") {
      return propActions(props.items);
    }
    return propActions;
  }, [props.items, propActions]);

  if (!actions.length) {
    return <></>;
  }
  return (
    <Dropdown
      icon={null}
      open={props.subMenu?.open} // Only controlled if subMenu
      onClose={() => setSubMenuOpen("")}
      trigger={
        props.subMenu ? (
          <SubMenuItem
            icon={props.subMenu.icon ?? "caret left"}
            content={props.subMenu.name}
            onClick={() => props.subMenu.onClick()} // set this submenu as active in parent
          />
        ) : (
          <Button compact icon="chevron down" />
        )
      }
      pointing={props.subMenu ? "right" : undefined}
      direction="left"
      style={
        props.subMenu
          ? {
              zIndex: 2,
              width: "100%",
            }
          : {
              zIndex: 2,
            }
      }
      disabled={props.items?.length === 0}
      {...multiProps}
    >
      {props.items?.length === 0 ? null : (
        <StyledDropdownMenu $subMenu={!!props.subMenu}>
          {actions ? (
            actions
              .map((rawAction) => {
                if (typeof rawAction === "function") {
                  return rawAction(props.items);
                }
                return rawAction;
              })
              .filter((action) => {
                if (action.appear && action.appear !== props.type && action.appear !== "both") {
                  return false;
                }
                if (action.superadminsBypassChecks && util.hasPermission(user, "super.viewDashboard")) {
                  return true;
                }
                if (action.checkSuperAdminPermission && util.hasPermission(user, action.checkSuperAdminPermission)) {
                  return true;
                }
                if (action.checkPermission) {
                  if (!user) {
                    return false;
                  }
                  if (!util.hasPermission(user, action.checkPermission, user.organisation._id)) {
                    return false;
                  }
                }
                if (action.checkEnabledFeatures) {
                  if (!user) {
                    return false;
                  }
                  if (!util.organisationFeaturesEnabled(user, action.checkEnabledFeatures)) {
                    return false;
                  }
                }
                return true;
              })
              .map((action, idx) => {
                if (action.type === "Divider") {
                  return <Dropdown.Divider key={idx} />;
                }
                let HoverWrapper: React.FC<{ children?: ReactElement<any, any> }> = ({ children }) => children;
                if (action.onHover) {
                  const UpdatedWrapper = ({ children }) => (
                    <Popup
                      content={<div>{action.onHover(props.items)}</div>}
                      trigger={children}
                      position="top center"
                    />
                  );
                  HoverWrapper = UpdatedWrapper;
                }

                if (action.actions) {
                  return (
                    <HoverWrapper key={action.name}>
                      <div>
                        <ActionDropdown
                          key={action.name}
                          subMenu={{
                            name: action.name,
                            icon: action.icon,
                            open: subMenuOpen === action.name,
                            onClick: () => setSubMenuOpen(action.name),
                          }}
                          {...props}
                          actions={action.actions}
                        />
                      </div>
                    </HoverWrapper>
                  );
                }
                return (
                  <HoverWrapper key={action.name}>
                    <Dropdown.Item
                      key={action.name}
                      icon={action.icon}
                      content={action.name}
                      onClick={() => action.onClick && action.onClick(props.items)}
                      {...(action.link ? { as: Link, to: action.link(props.items) } : {})}
                    />
                  </HoverWrapper>
                );
              })
          ) : (
            <Dropdown.Item>No actions configured</Dropdown.Item>
          )}
        </StyledDropdownMenu>
      )}
    </Dropdown>
  );
};

export default ActionDropdown;
