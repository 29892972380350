import { Other } from "simplydo/interfaces";
import ConfigurableTable from "../ConfigurableTable";
import { Link } from "react-router-dom";
import util from "utils/utils";
import { Image, Segment } from "semantic-ui-react";
import moment from "moment";

type UsersProps = {
  businessProfile: Other.IIdeaBusinessProfile;
};

export const Users = ({ businessProfile }: UsersProps) => {
  return (
    <Segment>
      <ConfigurableTable
        tableKey="businessProfileUsers"
        data={businessProfile.ownerUsers ?? []}
        keyExtractor={(user) => user._id}
        columns={[
          {
            key: "image",
            name: "",
            settingName: "Image",
            width: 40,
            center: true,
            render: ({ item }) => (
              <Link to={`/users/${item._id}`}>
                <Image style={{ objectFit: "cover" }} avatar src={util.avatarUrl(item)} />
              </Link>
            ),
          },
          {
            key: "firstName",
            name: "First name",
            render: ({ item }) => <Link to={`/users/${item._id}`}>{item.profile.firstName}</Link>,
            sortable: true,
          },
          {
            key: "lastName",
            name: "Last name",
            render: ({ item }) => <Link to={`/users/${item._id}`}>{item.profile.lastName}</Link>,
            sortable: true,
          },
          {
            key: "department",
            name: "Department",
            render: ({ item }) => item.profile.department,
            sortable: false,
          },
          {
            key: "createdAt",
            name: "Registered on",
            render: ({ cell }) => moment(cell).format("DD/MM/YYYY"),
            sortable: false,
          },
          {
            key: "lastSeenAt",
            name: "Last seen",
            render: ({ cell }) => moment(cell).format("DD/MM/YYYY"),
            sortable: false,
          },
        ]}
      />
    </Segment>
  );
};
