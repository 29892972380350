import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import moment from "moment";
import util from "utils/utils";

import RowComponent from "components/lib/RowComponent";

function HomeChallenges({ t, challenges }) {
  if (!challenges || !challenges.length) return null;
  return (
    <RowComponent>
      <RowComponent.Fill>
        <RowComponent.Title $linked to="/challenges">
          {t("generic.challenges")}
        </RowComponent.Title>
        <Button as={Link} to="/challenges/discover" primary size="mini">
          {t("challenges.yours.sidebar.viewAll")}
        </Button>
      </RowComponent.Fill>
      {challenges.slice(0, 4).map((challenge, index) => (
        <RowComponent.Cell index={index} key={challenge._id} as={Link} to={`/challenges/${challenge._id}`}>
          <div
            style={{
              width: 50,
              height: 40,
              borderRadius: 3,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundImage: `url(${util.mixinCssUrlFallback(util.getImageUrl(challenge, ["coverImage", "bannerImage"], ["200x200"], util.challengeImage()))})`,
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 5,
              marginRight: 5,
              position: "relative",
              top: -1,
            }}
          >
            <RowComponent.Title style={{ color: "#5d5d5d" }} block>
              {challenge.name}
            </RowComponent.Title>
            <RowComponent.StatusArea>
              {challenge.stage === "closed" ? (
                <>
                  <RowComponent.StatusLabel color="red" /> Closed
                </>
              ) : (
                challenge.stage === "published" &&
                (challenge.deadline ? (
                  <>
                    <RowComponent.StatusLabel color="blue" /> Closes {moment(challenge.deadline).fromNow()}
                  </>
                ) : (
                  <>
                    <RowComponent.StatusLabel color="green" /> Open
                  </>
                ))
              )}
            </RowComponent.StatusArea>
          </div>
        </RowComponent.Cell>
      ))}
    </RowComponent>
  );
}

const HomeChallengesContainer = withTranslation()(HomeChallenges);

export default HomeChallengesContainer;
