import React, { useState, useCallback, useEffect } from "react";
import { Loader, Modal, Icon, Button, Input, Checkbox } from "semantic-ui-react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import actions from "actions";
import styled from "styled-components";

import api from "api";
import util from "utils/utils";

import ProjectBoard from "components/lib/ProjectBoard";
import NotFound from "components/NotFound";

const BoardContainer = styled.div`
  background-color: #fff;
  padding: 1rem 10px;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 20px;
`;

const CustomProjectBoard = ({ user, onUpdateProjectBoard, onRemoveProjectBoard }) => {
  const [board, setBoard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editorModalOpen, setEditorModalOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [isPrivate, setIsPrivate] = useState(false);
  const [saving, setSaving] = useState(false);

  const { t } = useTranslation();

  const params = useParams();
  const matchId = params.id;
  const navigate = useNavigate();

  const getBoard = useCallback((idea) => idea?.projectManagement?.boards?.find((b) => b.forId === matchId), [matchId]);

  const getProjectBoard = useCallback(() => {
    setLoading(true);
    api.boards.get(
      matchId,
      (data) => {
        setBoard(data.board);
        setLoading(false);
      },
      (err) => {
        toast.error(err.message);
        setLoading(false);
      },
    );
  }, [matchId]);

  const deleteBoard = useCallback(() => {
    util
      .confirm(
        "Delete board",
        `Are you sure you want to delete this board and all related lanes, comments and ${t("generic.idea")} references? This cannot be undone.`,
      )
      .then(() => {
        api.boards.delete(
          matchId,
          () => {
            toast.success("Deleted board");
            onRemoveProjectBoard(matchId);
            navigate("/admin/boards");
          },
          (err) => toast.error(err.message),
        );
      })
      .catch(() => {});
  }, [matchId, navigate, onRemoveProjectBoard, t]);

  useEffect(() => {
    if (board) {
      setNewName(board.name);
      setNewDescription(board.description);
      setIsPrivate(board.isPrivate);
    }
  }, [board]);

  const saveProjectBoard = useCallback(() => {
    const updateData = { name: newName, description: newDescription, isPrivate };
    setSaving(true);
    api.boards.edit(
      matchId,
      updateData,
      () => {
        toast.success("Board updated");
        setBoard((prevBoard) => Object.assign(prevBoard, updateData));
        onUpdateProjectBoard(matchId, updateData);
        setSaving(false);
      },
      (err) => {
        toast.error(err.message);
        setSaving(false);
      },
    );
  }, [matchId, onUpdateProjectBoard, newName, newDescription, isPrivate, setBoard]);

  useEffect(() => {
    getProjectBoard();
  }, [getProjectBoard]);

  const canManage =
    util.hasPermission(user, "super.viewDashboard") ||
    (!board?.isPrivate && util.hasPermission(user, "org.manageProjectBoards", user.organisation._id));

  if (!board && loading) return <Loader active />;
  if (!board && !loading) return <NotFound />;
  return (
    <BoardContainer>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={editorModalOpen}
        onClose={() => setEditorModalOpen(false)}
      >
        <Modal.Header>Board settings</Modal.Header>
        <Modal.Content>
          <h4>Board name</h4>
          <Input value={newName} onChange={(e, { value }) => setNewName(value)} />
          <h4>Board description</h4>
          <Input value={newDescription} onChange={(e, { value }) => setNewDescription(value)} />
          {board.user === user._id ? (
            <>
              <h4>Private Board</h4>
              <Checkbox
                checked={isPrivate}
                onChange={() => setIsPrivate((prev) => !prev)}
                label="If checked, this board will only be visible/editable by you."
              />
            </>
          ) : null}
          <h4>Delete board</h4>
          <span style={{ display: "block" }}>
            This will delete the board, all the lanes, any project comments, and update {t("generic.ideas")} to remove
            references to this board. This action cannot be reversed.
          </span>
          <Button color="red" content="Delete board" onClick={deleteBoard} style={{ marginTop: 10 }} />
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" onClick={() => setEditorModalOpen(false)} />
          <Button
            loading={saving}
            disabled={!newName}
            content="Save changes"
            labelPosition="right"
            icon="checkmark"
            onClick={saveProjectBoard}
            positive
          />
        </Modal.Actions>
      </Modal>
      <ProjectBoard
        forId={board._id}
        getBoard={getBoard}
        canProjectManage={canManage}
        canManage={canManage}
        loading={loading}
        defaultLane={board.defaultLane || {}}
        heading={
          <HeadingContainer>
            <div>
              <h3 style={{ margin: 0 }}>{board.name}</h3>
              {board.description && <span>{board.description}</span>}
            </div>
            {canManage ? (
              <Icon
                name="edit"
                style={{ marginLeft: 15, cursor: "pointer" }}
                size="large"
                onClick={() => setEditorModalOpen(true)}
              />
            ) : null}
          </HeadingContainer>
        }
      />
    </BoardContainer>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => ({
  onUpdateProjectBoard: (boardId, data) => dispatch(actions.user.onUpdateProjectBoard(boardId, data)),
  onRemoveProjectBoard: (boardId) => dispatch(actions.user.onRemoveProjectBoard(boardId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomProjectBoard);
