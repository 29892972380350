import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { Divider, Button, Label } from "semantic-ui-react";
import styled from "styled-components";
import moment from "moment";

import api from "api";
import RowComponent from "components/lib/RowComponent";
import InviteCard from "./InviteCard";
import { HomeInvitation } from "./InviteCard/types";
import { useAppSelector } from "store";

const InvitationContainer = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  padding: 1rem;
  box-shadow: ${({ theme }) => theme.boxShadow};
  h4 {
    margin-bottom: 0;
    display: block;
  }
  .ui {
    display: flex;
  }
`;

type IHomeInvitations = {
  invitations: HomeInvitation[];
  updateInvitations?: (invitations: HomeInvitation[]) => void;
  removeInvitation?: (invitation: HomeInvitation) => void;
  hideTitle?: boolean;
  showCreatedBySelf?: boolean;
  limit?: number;
};

const HomeInvitations = ({
  invitations,
  updateInvitations,
  removeInvitation,
  hideTitle,
  showCreatedBySelf = false,
  limit,
}: IHomeInvitations) => {
  const user = useAppSelector((state) => state.user);

  const clearInvite = useCallback(
    (invitation: HomeInvitation) => {
      const updatedInvitations = invitations.filter((i) => i._id !== invitation._id);
      updateInvitations && updateInvitations(updatedInvitations);
      removeInvitation && removeInvitation(invitation);
    },
    [invitations, updateInvitations, removeInvitation],
  );

  const acceptInvite = useCallback(
    (invitation: HomeInvitation, success: (i: HomeInvitation) => any, fail: (i: HomeInvitation) => any) => {
      api.invitations.accept(
        invitation._id,
        () => {
          const acceptedInvitation = {
            ...invitation,
            acceptedAt: new Date().toISOString(),
            acceptedBy: user._id,
          };
          success(acceptedInvitation);
          clearInvite(acceptedInvitation);
        },
        () => fail(invitation),
      );
    },
    [clearInvite, user],
  );

  const rejectInvite = useCallback(
    (invitation: HomeInvitation, success: (i: HomeInvitation) => any, fail: (i: HomeInvitation) => any) => {
      api.invitations.reject(
        invitation._id,
        () => {
          success(invitation);
          clearInvite(invitation);
        },
        () => fail(invitation),
      );
    },
    [clearInvite],
  );

  const removeInvite = useCallback(
    (invitation: HomeInvitation, success: (i: HomeInvitation) => any, fail: (i: HomeInvitation) => any) => {
      api.invitations.remove(
        invitation._id,
        () => {
          success(invitation);
          clearInvite(invitation);
        },
        () => fail(invitation),
      );
    },
    [clearInvite],
  );

  // You can't actually ever see idea invites because they are all external invites send by you
  // The only invites you can see are either group invites sent to you or requestJoinGroup invites from or to you
  const visibleInvitations = useMemo(() => {
    const filtered = invitations.filter((i) => showCreatedBySelf === i.createdByCurrentUser);
    if (limit) {
      return filtered.splice(0, limit);
    }
    return filtered;
  }, [invitations, showCreatedBySelf, limit]);

  if (visibleInvitations?.length === 0) return null;
  return (
    <RowComponent $noBorder>
      {hideTitle ? null : (
        <RowComponent.Fill>
          <RowComponent.Title block={undefined} linked={undefined} containerStyle={undefined}>
            Invitations & requests
          </RowComponent.Title>
          <Button as={Link} to="/invitations" primary size="mini">
            View all invitations
          </Button>
        </RowComponent.Fill>
      )}
      {visibleInvitations?.length > 0 ? (
        <InvitationContainer>
          {visibleInvitations.map((invitation, index) => (
            <>
              <div style={{ position: "relative" }}>
                <Label ribbon style={{ display: "inline-block", position: "absolute", top: -23 }} size="mini">
                  {invitation.acceptedAt ? (
                    <>Accepted {moment(invitation.acceptedAt).fromNow()}</>
                  ) : (
                    <>{moment(invitation.createdAt).fromNow()}</>
                  )}
                </Label>
                <InviteCard
                  key={index}
                  invitation={invitation}
                  acceptInvite={acceptInvite}
                  rejectInvite={rejectInvite}
                  removeInvite={removeInvite}
                />
              </div>
              {index !== visibleInvitations.length - 1 && <Divider fitted />}
            </>
          ))}
          {limit && invitations.length > limit ? (
            <>
              <Divider fitted />
              <RowComponent.Fill>
                <Divider hidden fitted />
                <Button basic as={Link} to="/invitations" size="mini">
                  + {invitations.length - limit} more
                </Button>
              </RowComponent.Fill>
            </>
          ) : null}
        </InvitationContainer>
      ) : null}
    </RowComponent>
  );
};

export default HomeInvitations;
