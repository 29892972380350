import React, { useState, useCallback, useEffect, useMemo, Dispatch, SetStateAction } from "react";
import { Table, Button, Icon, Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import api from "api";
import { useAppSelector } from "store";
import { ChallengeChooser } from "components/lib/Choosers";
import { OpenAPI, Other } from "simplydo/interfaces";

import AutomaticAnnouncementModal from "./AutomaticAnnouncementModal";

type AutomaticConditions = {
  value: string;
  text: string;
  subConditions?: string;
  getOptions?: (challengeId: string, callback: (options: any) => void) => void;
};

type AutomaticAnnouncement = OpenAPI.GET<"/{entity}/{id}/announcements">["response"]["automaticAnnouncements"][0];

type AutomaticAnnouncementsManagerProps = {
  forType: string;
  forId: string;
  automaticAnnouncements: AutomaticAnnouncement[];
  setAutomaticAnnouncements: Dispatch<SetStateAction<AutomaticAnnouncement[]>>;
  automaticConditions: AutomaticConditions[];
  templatesAvailable?: boolean;
  setTemplateModalOpen: Dispatch<SetStateAction<boolean>>;
  handleCreatedAnnouncement: (announcement: AutomaticAnnouncement, openEditor?: boolean) => void;
  deleteAnnouncement: (announcementId: string, cb?: () => void) => void;
  externalOpenEditor?: AutomaticAnnouncement;
  setExternalOpenEditor: Dispatch<SetStateAction<AutomaticAnnouncement>>;
};

const AutomaticAnnouncementsManager = ({
  forType,
  forId,
  automaticAnnouncements,
  setAutomaticAnnouncements,
  automaticConditions,
  templatesAvailable,
  setTemplateModalOpen,
  handleCreatedAnnouncement,
  deleteAnnouncement,
  externalOpenEditor,
  setExternalOpenEditor,
}: AutomaticAnnouncementsManagerProps) => {
  const user = useAppSelector((state) => state.user);
  const [subconditionOptions, setSubconditionOptions] = useState<{ ideaFields?: any }>({});
  const { t } = useTranslation();

  const [editingAutomaticAnnouncement, setEditingAutomaticAnnouncement] = useState<Partial<AutomaticAnnouncement>>({});
  const [selectedFromUser, setSelectedFromUser] = useState(null);

  const [userHasChallenges, setUserHasChallenges] = useState(false);
  const [challengeChooserOpen, setChallengeChooserOpen] = useState(false);
  const [copyingFromChallenge, setCopyingFromChallenge] = useState(false);

  const userId = user?._id;

  const getSendWhenText = useCallback(
    (announcementSendWhen) => {
      const condition = automaticConditions.find((autoCondition) => autoCondition.value === announcementSendWhen);
      return condition?.text;
    },
    [automaticConditions],
  );

  const copyAnnouncementsFromChallenge = useCallback(
    (challenge: Other.IChallenge) => {
      setCopyingFromChallenge(true);
      api.announcements.copy(
        {
          fromEntity: "challenge",
          fromEntityId: challenge._id,
          fromEntityType: "automatic",
          toEntity: forType,
          toEntityId: forId,
          toEntityType: "automatic",
        },
        (data) => {
          setAutomaticAnnouncements((prevState) => [...data.announcements, ...prevState]);
          toast.success(`Copied announcements from "${challenge.name}"`);
          setCopyingFromChallenge(false);
          setChallengeChooserOpen(false);
        },
        (err) => {
          toast.error(err.message);
          setCopyingFromChallenge(false);
        },
      );
    },
    [forId, forType, setAutomaticAnnouncements],
  );

  useEffect(() => {
    if (externalOpenEditor) {
      setEditingAutomaticAnnouncement(externalOpenEditor);
    }
  }, [externalOpenEditor]);

  useEffect(() => {
    api.users.getChallenges(
      userId,
      { page: 1, limit: 1, query: "" },
      (data) => {
        setUserHasChallenges(data.challenges.length > 0);
      },
      () => {},
    );
  }, [userId]);

  // We set this if we have any data from Sendgrid about automatic announcements. This comes in the form of an object "events"
  // With possible event counts: openCount, deliveredCount, bounceCount
  const autoAnnouncementsHaveEventData = useMemo(
    () => !!automaticAnnouncements.find((a) => !!a.events),
    [automaticAnnouncements],
  );

  useEffect(() => {
    const cond = automaticConditions.find((sc) => sc.value === editingAutomaticAnnouncement.sendWhen);
    if (cond && cond.subConditions && cond.getOptions && !subconditionOptions[cond.subConditions]) {
      cond.getOptions(forId, (options) =>
        setSubconditionOptions((prev) => ({
          ...prev,
          [cond.subConditions]: options,
        })),
      );
    }
  }, [forId, subconditionOptions, automaticConditions, editingAutomaticAnnouncement.sendWhen]);

  return (
    <div>
      <ChallengeChooser
        controlled
        isOpen={challengeChooserOpen}
        onClose={() => setChallengeChooserOpen(false)}
        onComplete={(challenge) => copyAnnouncementsFromChallenge(challenge)}
        ignores={[forId]}
        instructions={`Choose ${t("generic.challengeWithArticle")} to copy announcements from. You can view, edit and delete them after copying.`}
        buttonProps={{
          loading: copyingFromChallenge,
          content: `Copy from this ${t("generic.challenge")}`,
        }}
        trigger={undefined}
        createNewAction={undefined}
      />
      <AutomaticAnnouncementModal
        deleteAutomaticAnnouncement={deleteAnnouncement}
        editingAutomaticAnnouncement={editingAutomaticAnnouncement}
        setEditingAutomaticAnnouncement={setEditingAutomaticAnnouncement}
        handleCreatedAnnouncement={handleCreatedAnnouncement}
        selectedFromUser={selectedFromUser}
        setExternalOpenEditor={setExternalOpenEditor}
        automaticConditions={automaticConditions}
        setSelectedFromUser={setSelectedFromUser}
        automaticAnnouncements={automaticAnnouncements}
        setAutomaticAnnouncements={setAutomaticAnnouncements}
        subconditionOptions={subconditionOptions}
        forType={forType}
        forId={forId}
      />

      <h3>
        {!automaticAnnouncements.length && <Icon name={"exclamation circle"} color={"red"} />}
        {t("announcements.automatic.title")}
      </h3>
      <p>{t("announcements.automatic.info")}</p>

      <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0" }}>
        {userHasChallenges && forType === "challenge" ? (
          <Button
            secondary
            icon="copy"
            content="Copy announcements from another challenge"
            size="small"
            onClick={() => setChallengeChooserOpen(true)}
          />
        ) : null}
        {templatesAvailable ? (
          <Button
            secondary
            icon="copy"
            content="View announcement templates"
            size="small"
            onClick={() => setTemplateModalOpen(true)}
          />
        ) : null}
        <Button
          size="small"
          secondary
          icon="paper plane"
          content="New auto announcement"
          onClick={() => setEditingAutomaticAnnouncement({ _id: null })}
        />
      </div>

      {automaticAnnouncements.length === 0 ? null : (
        <div>
          <h5 style={{ clear: "both" }}>Current auto announcements</h5>
          <Table unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Send when</Table.HeaderCell>
                <Table.HeaderCell>Subject</Table.HeaderCell>
                {autoAnnouncementsHaveEventData ? (
                  [
                    <Table.HeaderCell key="sent-header" collapsing>
                      Sent
                    </Table.HeaderCell>,
                    <Table.HeaderCell key="delivered-header" collapsing>
                      Delivered
                    </Table.HeaderCell>,
                    <Table.HeaderCell key="bounced-header" collapsing>
                      Bounced{" "}
                      <Popup
                        content="Bounced emails were rejected by the recipient's mail server. This is commonly caused by a misspelled or nonexistent email address."
                        trigger={<Icon name="question circle" />}
                      />
                    </Table.HeaderCell>,
                    <Table.HeaderCell key="opened-header" collapsing>
                      Opened
                    </Table.HeaderCell>,
                  ]
                ) : (
                  <Table.HeaderCell collapsing>Times sent</Table.HeaderCell>
                )}
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {automaticAnnouncements.map((a) => (
                <Table.Row key={a._id}>
                  <Table.Cell collapsing>{t(getSendWhenText(a.sendWhen))}</Table.Cell>
                  <Table.Cell>{a.subject}</Table.Cell>
                  {autoAnnouncementsHaveEventData ? (
                    [
                      <Table.Cell key={`${a._id}-sent`} collapsing>
                        {a.events?.processedCount || a.countSent || 0}
                      </Table.Cell>,
                      <Table.Cell key={`${a._id}-delivered`} collapsing>
                        {a.events?.deliveredCount || 0}
                      </Table.Cell>,
                      <Table.Cell key={`${a._id}-bounced`} collapsing>
                        {a.events?.bounceCount || 0}
                      </Table.Cell>,
                      <Table.Cell key={`${a._id}-opened`} collapsing>
                        {a.events?.openCount || 0}
                      </Table.Cell>,
                    ]
                  ) : (
                    <Table.Cell collapsing>{a.countSent || "Never"}</Table.Cell>
                  )}
                  <Table.Cell collapsing>
                    <Button
                      basic
                      size="tiny"
                      icon="edit"
                      content="Edit"
                      onClick={() => {
                        setEditingAutomaticAnnouncement(a);
                        setSelectedFromUser(a.ownerFromUser);
                      }}
                    />
                    <Button basic size="tiny" icon="trash" onClick={() => deleteAnnouncement(a._id)} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  );
};

export default AutomaticAnnouncementsManager;
