import React from "react";
import { Image, Form, Card, Button, Label, Icon, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import RichText from "components/lib/Editors/RichText";
import RichTextViewer from "components/lib/Editors/RichTextViewer";
import ImageChooser from "components/lib/Choosers/ImageChooser";
import FieldSeparator from "../FieldSeparator";
import FieldHeader from "../FieldHeader";

class Customers extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.defaultCustomersPeople = [{}, {}, {}];
  }

  updateProfile = (profileIndex, field, value) => {
    const people = Object.assign(this.defaultCustomersPeople, this.props.customersPeople);
    people[profileIndex][field] = value;
    this.props.updateIdea(people, ["customersPeople"]);
  };

  addPreference = (profileIndex) => {
    const people = Object.assign(this.defaultCustomersPeople, this.props.customersPeople);
    const prefs = people[profileIndex].preferences || [];
    prefs.push(this.state[`pref${profileIndex}`]);
    this.updateProfile(profileIndex, "preferences", prefs);
    this.setState({ [`pref${profileIndex}`]: "" });
  };

  deletePreference = (profileIndex, prefIndex) => {
    const people = Object.assign(this.defaultCustomersPeople, this.props.customersPeople);
    const prefs = people[profileIndex].preferences || [];
    prefs.splice(prefIndex, 1);
    this.updateProfile(profileIndex, "preferences", prefs);
  };

  render() {
    const {
      isEditing,
      updateIdea,
      includeSection,
      t,
      externalChanges,
      customersMarket,
      customersStrategy,
      customersPeople,
      ideaId,
    } = this.props;

    return (
      <div>
        {isEditing ? (
          <div>
            <FieldHeader
              externalChange={externalChanges.customersMarket}
              title={t("ideas.6Cs.sections.customers.title")}
              isEditing={isEditing}
              description={t("ideas.6Cs.sections.customers.info")}
            />
            <RichText
              value={customersMarket}
              onChange={(val) => updateIdea(val, ["customersMarket"])}
              forType="idea"
              forId={ideaId}
            />
          </div>
        ) : (
          <Grid stackable centered>
            <Grid.Column computer={10}>
              <RichTextViewer content={customersMarket} />
            </Grid.Column>
          </Grid>
        )}

        <FieldSeparator includeSection={includeSection} />

        <FieldHeader
          externalChange={externalChanges.customersPeople}
          style={{ textAlign: "center" }}
          title={t("ideas.6Cs.sections.customers.profiles.title")}
        />
        <Card.Group itemsPerRow={3} stackable centered style={{ marginTop: 30 }}>
          {(customersPeople || this.defaultCustomersPeople).map((customer, i) =>
            isEditing ? (
              <>
                <Card key={i}>
                  {customer.portrait && <Image src={customer.portrait} />}
                  <Card.Content>
                    <ImageChooser
                      forType="idea"
                      forId={ideaId}
                      fluid
                      trigger={
                        <Button
                          size="small"
                          fluid
                          icon="image"
                          color="yellow"
                          content={t("ideas.6Cs.sections.customers.profiles.form.portrait")}
                        />
                      }
                      onComplete={(p, n, src) => this.updateProfile(i, "portrait", src)}
                    />
                  </Card.Content>
                  <Card.Content>
                    <Form>
                      <Form.Input
                        fluid
                        label={t("ideas.6Cs.sections.customers.profiles.form.nickname")}
                        placeholder={t("ideas.6Cs.sections.customers.profiles.form.nickname")}
                        value={customer.nickname || ""}
                        icon="user"
                        onChange={(e) => this.updateProfile(i, "nickname", e.target.value)}
                      />
                      <Form.Group widths="equal">
                        <Form.Input
                          fluid
                          label={t("ideas.6Cs.sections.customers.profiles.form.job")}
                          placeholder={t("ideas.6Cs.sections.customers.profiles.form.job")}
                          value={customer.job || ""}
                          onChange={(e) => this.updateProfile(i, "job", e.target.value)}
                        />
                        <Form.Input
                          fluid
                          label={t("ideas.6Cs.sections.customers.profiles.form.age")}
                          placeholder={t("ideas.6Cs.sections.customers.profiles.form.age")}
                          value={customer.age || ""}
                          onChange={(e) => this.updateProfile(i, "age", e.target.value)}
                        />
                      </Form.Group>

                      <Form.Input
                        fluid
                        label={t("ideas.6Cs.sections.customers.profiles.form.interests")}
                        size="mini"
                        value={this.state[`pref${i}`] || ""}
                        onChange={(e) => this.setState({ [`pref${i}`]: e.target.value })}
                        action={<Button color="yellow" icon="plus" onClick={() => this.addPreference(i)} />}
                      />
                    </Form>
                    {customer.preferences &&
                      customer.preferences.map((pref, j) => (
                        <Label key={j} size="tiny" style={{ margin: "10px 5px 0px 0px" }}>
                          <Icon name="heart" />
                          {pref} <Icon name="delete" onClick={() => this.deletePreference(i, j)} />
                        </Label>
                      ))}
                  </Card.Content>
                </Card>
              </>
            ) : (
              <>
                {customer.nickname ? (
                  <Card key={i}>
                    {customer.portrait && <Image src={customer.portrait} />}
                    <Card.Content>
                      <Card.Header>{customer.nickname}</Card.Header>
                      <Card.Meta>
                        {customer.age}, {customer.job}
                      </Card.Meta>
                    </Card.Content>

                    {customer.preferences && customer.preferences.length > 0 && (
                      <Card.Content extra textAlign="right">
                        <Icon name="heart" /> {customer.preferences.join(", ")}
                      </Card.Content>
                    )}
                  </Card>
                ) : null}
              </>
            ),
          )}
        </Card.Group>

        <FieldSeparator includeSection={includeSection} />

        {isEditing ? (
          <div>
            <FieldHeader
              externalChange={externalChanges.customersStrategy}
              title={t("ideas.6Cs.sections.customers.market.title")}
              isEditing={isEditing}
              description={t("ideas.6Cs.sections.customers.market.info")}
            />
            <RichText
              value={customersStrategy || ""}
              onChange={(val) => updateIdea(val, ["customersStrategy"])}
              forType="idea"
              forId={ideaId}
            />
          </div>
        ) : (
          customersStrategy && (
            <Grid stackable centered>
              <Grid.Column computer={10}>
                <FieldHeader title={t("ideas.6Cs.sections.customers.market.name")} />
                <RichTextViewer content={customersStrategy} />
              </Grid.Column>
            </Grid>
          )
        )}
      </div>
    );
  }
}

export default withTranslation()(Customers);
