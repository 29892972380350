import React, { useState, useCallback } from "react";
import { Modal, Button, Input } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import actions from "actions";
import api from "api";
import styled from "styled-components";
import useTheme from "theme/useTheme";
import { useTranslation } from "react-i18next";

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  span {
    margin-bottom: 5px;
    display: block;
    font-weight: 700;
  }
`;

const BoardInput = ({ label, value, onChange }) => {
  const theme = useTheme();
  return (
    <InputContainer>
      <span>{label}</span>
      <Input
        placeholder="Enter a value..."
        value={value}
        onChange={(e, { value: newValue }) => onChange(newValue)}
        style={
          theme.sizes?.isComputer
            ? {
                width: 500,
              }
            : {}
        }
      />
    </InputContainer>
  );
};

const BoardCreator = ({ isOpen, onClose, onBoardCreated = () => {}, onAddProjectBoard }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [creating, setCreating] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const createBoard = useCallback(() => {
    setCreating(true);
    api.boards.create(
      { name, description },
      ({ board }) => {
        onAddProjectBoard(board);
        toast.success("Board created");
        setCreating(false);
        onBoardCreated(board);
        onClose();
        navigate(`/boards/${board._id}`);
      },
      (err) => {
        toast.error(err.message);
        setCreating(false);
      },
    );
  }, [name, description, navigate, onBoardCreated, onClose, onAddProjectBoard]);

  return (
    <Modal mountNode={document.getElementById("semantic-modal-mount-node")} onClose={onClose} open={isOpen}>
      <Modal.Header>Create a new project board</Modal.Header>
      <Modal.Content>
        <p>You will be the manager of this board, and you can add and remove any {t("generic.ideas")} to it.</p>
        <BoardInput label="Board name" onChange={setName} value={name} />
        <BoardInput label="Description" onChange={setDescription} value={description} />
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" content="Cancel" onClick={onClose} />
        <Button
          loading={creating}
          disabled={!name}
          content="Create board"
          labelPosition="right"
          icon="checkmark"
          onClick={createBoard}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onAddProjectBoard: (board) => dispatch(actions.user.onAddProjectBoard(board)),
});

export default connect(null, mapDispatchToProps)(BoardCreator);
