import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import util from "utils/utils";

import { Popup, Icon } from "semantic-ui-react";
import { MultiLineParagraph } from "components/lib/UI";
import { ImageWithFallback } from "components/lib/ImageWithFallback";

const Warn = styled.div`
  background-color: #ff1234;
  padding: 2px;
  border-radius: 50%;

  &&& img {
    margin-right: 0;
  }
`;

export const StyledHeader = styled.header`
  span {
    margin: 0;
    display: block;
    font-weight: 350;
  }
  h2 {
    font-size: 15px;
    font-weight: 600;
    color: rgb(40, 40, 40);
  }
  p {
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 13px;
    color: #2a2828;
    padding: 3px;
    padding-left: 6px;
    border-left: 4px solid #ffe4b5;
    background-color: #fffad1;
    display: block;
  }
`;

function FieldHeader({ externalChange, title, description = "", isEditing, isAssessing, style, required }) {
  const { t } = useTranslation();

  return (
    <StyledHeader style={style}>
      <div style={{ display: "flex", flexDirection: "row", marginBottom: 5 }}>
        {title && (
          <div>
            <h2 style={{ display: "inline" }}>{title}</h2>{" "}
            {required && isEditing && (
              <Popup
                size="small"
                trigger={
                  <Icon
                    name="asterisk"
                    style={{ position: "relative", top: -5, marginLeft: 3 }}
                    color="red"
                    size="tiny"
                  />
                }
                content={`This field must be filled in to submit your ${t("generic.idea")}`}
              />
            )}
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            marginTop: -6,
            marginLeft: 12,
            height: 24,
          }}
        >
          {externalChange
            ? Object.values(externalChange).map((u) => (
                <Popup
                  header="Warning"
                  content={`${u.profile?.fullName} is currently editing this field, to ensure that no work is lost please wait until they are done.`}
                  key={u._id}
                  trigger={
                    <Warn>
                      <ImageWithFallback
                        avatar
                        src={util.avatarUrl(u)}
                        fallbackSrc={util.avatarUrl()}
                        data-tooltip={u.profile?.fullName}
                      />
                    </Warn>
                  }
                />
              ))
            : null}
        </div>
      </div>
      {(isAssessing || isEditing) && description.trim() && (
        <MultiLineParagraph>
          <Icon name="info circle" />
          {description}
        </MultiLineParagraph>
      )}
    </StyledHeader>
  );
}

export default FieldHeader;
