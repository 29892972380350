import React from "react";
import { Label, Checkbox, Header } from "semantic-ui-react";

type CheckboxHeaderProps = {
  as?: string;
  header: string;
  description?: string;
  label?: string;
  checked?: boolean;
  onChange: (checked: boolean) => void;
  children: React.ReactNode;
  plain?: boolean;
  willHideChildren?: boolean;
  disabled?: boolean;
};

const CheckboxHeader = ({
  as,
  header,
  description,
  label,
  checked,
  onChange,
  children,
  plain,
  willHideChildren,
  disabled,
}: CheckboxHeaderProps) => {
  return (
    <div style={{ marginBottom: 20, display: "flex", flex: "auto" }}>
      <div style={{ textAlign: "center", paddingTop: 10 }}>
        <Checkbox disabled={disabled} toggle={!plain} checked={checked} onChange={(e, c) => onChange(c.checked)} />
      </div>
      <div style={{ paddingLeft: 15 }}>
        <Header as={as} style={{ marginBottom: 5, cursor: "pointer" }} onClick={() => onChange(!checked)}>
          {header} {label && <Label>{label}</Label>}
        </Header>
        {description ? <p>{description}</p> : null}
        {willHideChildren && !checked ? null : <div>{children}</div>}
      </div>

      <div style={{ clear: "both" }} />
    </div>
  );
};

export default CheckboxHeader;
