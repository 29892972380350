import React from "react";
import styled from "styled-components";

const StyledDiv = styled.div`
  margin-top: 30px;
  text-align: right;
`;

function SettingActions({ children }) {
  return <StyledDiv>{children}</StyledDiv>;
}

export default SettingActions;
