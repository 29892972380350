import React, { useMemo } from "react";
import { Button, Message } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";
import styled from "styled-components";
import util from "utils/utils";
import constants from "utils/constants";
import { useSelector } from "react-redux";

type IFullBusinessProfile = {
  ideaBusinessProfile: Other.IIdeaBusinessProfile;
  style?: object;
  viewReportForCompany: () => void;
  generatingCreditReport: boolean;
  idea?: Other.IIdea;
};

const BusinessProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  .company-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  span,
  h5 {
    margin: 0;
    display: block;
  }
`;

const BusinessProfileBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  .company-detail-items {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    .company-item {
      flex: 0.333;
      display: flex;
      flex-direction: column;
      .item-title {
        font-weight: 600;
      }
      > span {
        &:not(.item-title) {
          opacity: 0.8;
        }
      }
    }
  }
  .company-detail-items-half {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    .company-item {
      flex: 0.333;
      display: flex;
      min-width: 50%;
      flex-direction: column;
      .item-title {
        font-weight: 600;
      }
      > span {
        &:not(.item-title) {
          opacity: 0.8;
        }
      }
    }
  }
  .credit-report-area {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

const BusinessProfileHeader = styled.div`
  display: flex;
  flex-direction: row;
  .company-logo {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    margin-right: 10px;
    object-fit: contain;
  }
`;

const businessTypeMap = {
  public: "Public Sector",
  private: "Private Sector",
  notForProfit: "Not For-Profit",
  academic: "Academic",
};

const businessSizeMap = {
  micro: "Micro <10 Employees",
  small: "Small <50 Employees",
  medium: "Medium <250 Employees",
  large: "Large >250 Employees",
};

const businessStatusMap = {
  preStartup: "Pre-start-up",
  startup: "Start up < 1 year",
  established_small: "Established 1 - 5 years",
  established_medium: "Established 5 - 10 years",
  established_large: "Established > 10 years",
};

const CompanyItem = ({
  companyItem,
  itemTitle,
  href,
}: {
  companyItem?: string;
  itemTitle: string;
  href?: "link" | "email";
}) =>
  companyItem ? (
    <div className="company-item">
      <span className="item-title">
        {itemTitle}
        {":"}
      </span>
      {href ? (
        <a href={`${href === "email" ? "mailto:" : ""}${companyItem}`}>{companyItem}</a>
      ) : (
        <span>{companyItem}</span>
      )}
    </div>
  ) : null;

const FullBusinessProfile = ({
  ideaBusinessProfile,
  style,
  generatingCreditReport,
  viewReportForCompany,
  idea,
}: IFullBusinessProfile) => {
  const user = useSelector((state: { user: Other.IUserMe | null }) => state?.user);
  const { profile, creditsafeReportUrl } = ideaBusinessProfile;
  const {
    name,
    imageUrl,
    address = {},
    creditsafeRegNo,
    vatNo,
    phoneNumber,
    creditsafeCompanyId,
    primaryContact,
    websiteUrl,
    type,
    status,
    size,
    description,
    accreditations,
    vatNoSkipped,
    creditsafeRegNoSkipped,
  } = profile;
  const { country, city, streetAddress, postcode } = address;
  const {
    fullName = "",
    phoneNumber: primaryContactPhoneNumber = "",
    email = "",
    jobTitle = "",
  } = primaryContact || {};
  const fullAddress = useMemo(
    () => [streetAddress, city, country].filter((addressSegment) => !!addressSegment),
    [country, city, streetAddress],
  );
  const hasReportPermissions = util.canManageChallenge(user, idea.ownerChallenge) || idea.isAssessor;

  const isMissingRequiredInfo =
    !name ||
    !status ||
    !type ||
    !size ||
    !streetAddress ||
    !country ||
    !city ||
    !postcode ||
    !websiteUrl ||
    !phoneNumber ||
    !jobTitle ||
    (!vatNo && !vatNoSkipped) ||
    (!creditsafeRegNo && !creditsafeRegNoSkipped);
  return (
    <BusinessProfileContainer style={style}>
      {isMissingRequiredInfo && (
        <Message
          icon="exclamation triangle"
          warning
          size="small"
          content="Your organisation profile is missing required information"
        />
      )}
      <BusinessProfileHeader>
        {imageUrl ? (
          <img
            className="company-logo"
            src={imageUrl}
            alt="Company logo"
            style={{ borderRadius: "50%", objectFit: "cover" }}
          />
        ) : null}
        <div className="company-details">
          <h5>{name}</h5>
          {fullAddress.length ? <span>{fullAddress.join(", ")}</span> : null}
        </div>
      </BusinessProfileHeader>
      {description ? <p>{description}</p> : null}
      <BusinessProfileBody>
        <div className="company-detail-items">
          <CompanyItem itemTitle="Phone number" companyItem={phoneNumber} />
          <CompanyItem itemTitle="Company registration number" companyItem={creditsafeRegNo} />
          <CompanyItem itemTitle="VAT registration number" companyItem={vatNo} />
        </div>
        <div className="company-detail-items">
          <CompanyItem itemTitle="Status" companyItem={businessStatusMap[status]} />
          <CompanyItem itemTitle="Type" companyItem={businessTypeMap[type]} />
          <CompanyItem itemTitle="Size" companyItem={businessSizeMap[size]} />
        </div>
        <h5 style={{ marginBottom: 5 }}>Primary contact details</h5>
        <div className="company-detail-items-half">
          <CompanyItem itemTitle="Name" companyItem={fullName} />
          <CompanyItem itemTitle="Phone number" companyItem={primaryContactPhoneNumber} />
        </div>
        <div className="company-detail-items-half">
          <CompanyItem itemTitle="Email" companyItem={email} href="email" />
          <CompanyItem itemTitle="Job title" companyItem={jobTitle} />
        </div>
        {accreditations?.length ? (
          <div className="company-detail-items">
            <CompanyItem
              itemTitle="Accreditations"
              companyItem={constants.accreditations
                .filter((accreditation) => accreditations.includes(accreditation.key))
                .map((accreditation) => accreditation.text)
                .join(", ")}
            />
          </div>
        ) : null}
        {creditsafeCompanyId && hasReportPermissions ? (
          <div className="credit-report-area">
            {creditsafeReportUrl ? (
              <Button
                content="View credit report"
                icon="file pdf"
                as="a"
                href={creditsafeReportUrl}
                target="_blank"
                rel="noopener noreferrer"
              />
            ) : (
              <Button
                content="View credit report"
                icon="file pdf"
                onClick={() => viewReportForCompany()}
                loading={generatingCreditReport}
              />
            )}
          </div>
        ) : null}
      </BusinessProfileBody>
    </BusinessProfileContainer>
  );
};

export default FullBusinessProfile;
