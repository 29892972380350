import React, { useState, useEffect, useCallback, useMemo, lazy } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams, Routes, Route, useLocation } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Message, Label } from "semantic-ui-react";
import { useSelector, useDispatch } from "react-redux";
import api from "api";
import util from "utils/utils";
import actions from "actions";
import styled from "styled-components";
import toast from "react-hot-toast";
import useTheme from "theme/useTheme";
import { setInterpolationDictionary } from "src/i18n";

import constants from "utils/constants";
import ChallengeStatusBar from "components/lib/ChallengeStatusBar";
import { SideNav } from "components/lib/SideNav";
import PageLoadError from "components/lib/PageLoadError";
import { PlaceholderStack, PlaceholderItem } from "components/lib/UI";
import ChallengeIPStatementAgreement from "components/lib/ChallengeIPStatementAgreement";
import ChallengeOverview from "./Overview";
import ChallengePlanner from "./Planner/PlannerMenu";
import ChallengeIdeas from "./Ideas";
import ChallengeAssessments from "./Assessments";
import NewIdea from "./NewIdea";

import ChallengeHeader from "./Header";

const ChallengeActivity = lazy(() => import("./Activity"));

const StyledLabel = styled(Label)`
  line-height: 1.1em !important;
`;

// Wrapper func component for when visiting the project ideas page
// Ensures that if you visit challenges/<challenge_id>/board, the view type is set to board
const ChallengeIdeasProjectWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const updateIdeaViewType = useCallback(
    (newIdeaViewType) => dispatch(actions.challenges.setIdeaViewType(newIdeaViewType)),
    [dispatch],
  );

  useEffect(() => {
    updateIdeaViewType("board");
  }, [updateIdeaViewType]);

  return children;
};

function Challenge({ t, isPreview, previewId }) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const params = useParams();
  const challengeId = isPreview ? previewId : params.id?.replace("assessments", "");

  const challenge = useSelector(({ challenges }) => challenges.challenges.filter((c) => c._id === challengeId)[0]);
  const ideaViewType = useSelector(({ challenges }) => challenges.ideaViewType);
  const user = useSelector((state) => state.user);
  const urlOrganisation = useSelector((state) => state.organisations?.urlOrganisation);

  const dispatch = useDispatch();
  const receive = useCallback((newChallenge) => dispatch(actions.challenges.receive(newChallenge)), [dispatch]);
  const addEngagement = useCallback((engagement) => dispatch(actions.user.addEngagement(engagement)), [dispatch]);
  const updateFilters = useCallback((data) => dispatch(actions.challenges.updateIdeaFilters(data)), [dispatch]);
  const onFollow = useCallback((context, id) => dispatch(actions.user.onFollow(context, id)), [dispatch]);
  const updateIdeaViewType = useCallback(
    (newIdeaViewType) => dispatch(actions.challenges.setIdeaViewType(newIdeaViewType)),
    [dispatch],
  );

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [myIdeas, setMyIdeas] = useState([]);
  const [myAssignedAssessments, setMyAssignedAssessments] = useState([]);
  const [popularTags, setPopularTags] = useState([]);
  const [allStamps, setAllStamps] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [availableOrganisations, setAvailableOrganisations] = useState([]);
  const [firstView, setFirstView] = useState(true);
  const [hasSignedStatement, setHasSignedStatement] = useState(false);
  const [hasClickedNewIdea, setHasClickedNewIdea] = useState(false);
  const [creatingNewIdea, setCreatingNewIdea] = useState(false);
  const [ipManagementInfo, setIpManagementInfo] = useState({
    userHasSigned: false,
    hasSensitiveInfo: false,
    ipStatementEnabled: false,
    userSignature: null,
  });
  const [ipStatement, setIpStatement] = useState(null);
  const [ipStatementAgreementOpen, setIpStatementAgreementOpen] = useState(false);
  const [fluid, setFluid] = useState(false);
  const userId = user?._id;
  const hasAcceptedInvitation = user?.hasAcceptedInvitation;
  const orgIpManagementEnabled = util.organisationFeaturesEnabled(user, ["ipManagement"]);
  const challengePlannerEnabled = util.organisationFeaturesEnabled(user, ["challengePlanner"]);
  const canViewEntireChallenge = useMemo(() => util.canViewEntireChallenge(user, challenge), [user, challenge]);

  /*
    Auto collapse the sidebar on some pages
  */
  const [isCollapsed, setIsCollapsed] = useState(undefined);
  useEffect(() => {
    setIsCollapsed((prev) => {
      if (location.pathname.includes("/ideas")) {
        if (!theme.sizes.isLargeComputer) {
          return true;
        }
      }
      return prev;
    });
  }, [ideaViewType, location, theme]);

  /*
    If the user has a preferred idea view type of board, but is not allowed to view the project board on this challenge, we adjust it back to card
  */
  useEffect(() => {
    if (ideaViewType === "board" && challenge) {
      if (challenge?.projectManagementVisibility !== "users" && !canViewEntireChallenge) {
        updateIdeaViewType("card");
      }
    }
  }, [canViewEntireChallenge, challenge, ideaViewType, updateIdeaViewType, user]);

  const getAssignedAssessments = useCallback(() => {
    api.challenges.getAssignedAssessments(challengeId, ({ ideas }) => {
      setMyAssignedAssessments(ideas);
    });
  }, [setMyAssignedAssessments, challengeId]);

  useEffect(() => {
    if (hasAcceptedInvitation) {
      getAssignedAssessments();
    }
  }, [hasAcceptedInvitation, getAssignedAssessments]);

  useEffect(() => {
    if (firstView) {
      window.scrollTo(0, 0);
      setFirstView(false);
    }
  }, [firstView]);

  const getChallenge = useCallback(() => {
    if (isPreview && !previewId) {
      return;
    }
    setLoading(true);
    setErrorMessage("");
    api.challenges.get(
      isPreview ? previewId : challengeId,
      (newChallenge) => {
        if (userId) {
          addEngagement("viewedChallenge");
        }
        // Avoid refetching challenge so we don't overwrite
        if (!isPreview) {
          receive(newChallenge);
          if (!userId) {
            setInterpolationDictionary(newChallenge?.ownerOrganisation?.terminology);
          }
        }
        setLoading(false);
        updateFilters({
          prevViewed: newChallenge._id,
          ideaPage: 1,
          ideaOrder: newChallenge.defaultIdeaOrder || "default",
          tagFilter: [],
          groupFilter: [],
          challengeFilter: [],
          ideaFieldChoices: [],
          ideaFilter: "",
        });
      },
      (err) => {
        setLoading(false);
        setErrorMessage(err.message || `Unable to load at this time`);
      },
    );

    if (userId) {
      api.challenges.getIdeas(challengeId, { scope: "me" }, ({ ideas }) => {
        setMyIdeas(ideas);
      });
      getAssignedAssessments();
    }
  }, [challengeId, addEngagement, getAssignedAssessments, receive, updateFilters, userId, isPreview, previewId]);

  const getLatestIpStatementVersion = useCallback(() => {
    if (isPreview && !previewId) {
      return;
    }
    api.challenges.getLatestIpStatement(
      isPreview ? previewId : challengeId,
      ({ userHasSigned, hasSensitiveInfo, ipStatementEnabled, activeStatement, userSignature }) => {
        if (ipStatementEnabled) {
          setIpManagementInfo({
            userHasSigned,
            hasSensitiveInfo,
            ipStatementEnabled,
            userSignature,
          });
          setHasSignedStatement(userHasSigned);
          setIpStatement(activeStatement);
        }
      },
    );
  }, [previewId, challengeId, isPreview]);

  const { ipManagement = {} } = challenge || {};
  useEffect(() => {
    if (orgIpManagementEnabled && ipManagement?.isEnabled) {
      getLatestIpStatementVersion();
    }
  }, [getLatestIpStatementVersion, ipManagement, orgIpManagementEnabled]);

  useEffect(() => {
    getChallenge();
  }, [getChallenge]);

  const assignIdea = useCallback(
    (idea) => {
      if (!userId) return;

      let authToken = null;
      if (util.localStorageIsSupported()) {
        authToken = localStorage.getItem(`ideaAuthToken:${idea?._id}`);
      }
      if (userId && !idea?.user && authToken && idea?.authToken === authToken) {
        const data = { authToken };
        api.ideas.assign(
          idea?._id,
          data,
          () => {
            localStorage.removeItem(`ideaAuthToken:${idea?._id}`);
            localStorage.removeItem(`challengeIdeas:${challengeId}`);
          },
          () => {},
        );
      }
    },
    [userId, challengeId],
  );

  useEffect(() => {
    if (challengeId) {
      if (!util.localStorageIsSupported()) {
        return;
      }
      const challengeIdeas = localStorage.getItem(`challengeIdeas:${challengeId}`);
      if (!challengeIdeas) {
        return;
      }

      const ideaIds = challengeIdeas.split("::");
      if (ideaIds.length === 1 && !location.search.includes("noRedirect")) {
        const authToken = localStorage.getItem(`ideaAuthToken:${ideaIds[0]}`);
        api.ideas.get(
          ideaIds[0],
          (idea) => {
            if (!userId) {
              if (!idea.isSubmitted) {
                navigate(`/ideas/${ideaIds[0]}`);
              }
            } else {
              setMyIdeas((prev) => ({ ...prev.filter((i) => i._id !== idea._id), idea }));
              if (!idea?.user) {
                assignIdea(idea);
              }
            }
          },
          () => {},
          authToken,
        );
      } else {
        ideaIds.forEach((i) => {
          const authToken = localStorage.getItem(`ideaAuthToken:${i}`);
          api.ideas.get(
            i,
            (idea) => {
              setMyIdeas((currMyIdeas) => {
                const filteredCurrentMyIdeas = currMyIdeas.filter((m) => m._id !== idea._id);
                return [...filteredCurrentMyIdeas, idea];
              });
              if (!idea?.user) {
                assignIdea(idea);
              }
            },
            () => {},
            authToken,
          );
        });
      }
    }
  }, [challengeId, userId, navigate, assignIdea, location.search]);

  const newIdea = useCallback(() => {
    window.scrollTo(0, 0);
    setHasClickedNewIdea(true);
    if (ipStatement && ipManagementInfo.ipStatementEnabled && !hasSignedStatement) {
      setIpStatementAgreementOpen(true);
      return;
    }
    setCreatingNewIdea(true);
    util.newIdea(challenge, user, (idea) => {
      navigate(`/ideas/${idea._id}`);
      if (user) {
        onFollow("challenge", idea.challenge);
      }
      setHasClickedNewIdea(false);
      setCreatingNewIdea(false);
    });
  }, [user, challenge, navigate, onFollow, hasSignedStatement, ipStatement, ipManagementInfo]);

  useEffect(() => {
    if (
      hasClickedNewIdea &&
      !creatingNewIdea &&
      ipStatement &&
      ipManagementInfo.ipStatementEnabled &&
      hasSignedStatement
    ) {
      newIdea();
    }
  }, [hasSignedStatement, newIdea, ipStatement, ipManagementInfo, hasClickedNewIdea, creatingNewIdea]);

  const redirectToLogin = useCallback(
    (evt, orgCode) => {
      const currOrgCode = orgCode || challenge?.ownerOrganisation?.code;
      if (user && currOrgCode) {
        window.location.href = `https://${currOrgCode}.simplydo.co.uk/login?code=${currOrgCode}&utm-challenge=${challengeId}&then=/challenges/${challengeId}/ideas/new`;
      } else {
        navigate(`/login?utm-challenge=${challengeId}&then=/challenges/${challengeId}/ideas/new`);
      }
    },
    [challengeId, challenge, navigate, user],
  );

  const toggleStamp = useCallback(
    (stamp) => {
      const newAllStamps = Object.assign([], allStamps);
      if (stamp in constants.legacyStampMapper) {
        if (!allStamps.includes(stamp) && !allStamps.includes(constants.legacyStampMapper[stamp])) {
          newAllStamps.push(stamp);
          setAllStamps(newAllStamps);
        }
      } else if (!allStamps.includes(stamp)) {
        newAllStamps.push(stamp);
        setAllStamps(newAllStamps);
      }
    },
    [setAllStamps, allStamps],
  );

  const onClickProjectBoardLink = useCallback(() => {
    const localStorageIsSupported = util.localStorageIsSupported();
    const hasPreviouslySeenProjectBoardInfo = localStorageIsSupported
      ? !!localStorage.getItem("hasSeenProjectBoardInfo")
      : true;
    if (ideaViewType === "board" && location.pathname === `/challenges/${challengeId}/ideas`) {
      if (!hasPreviouslySeenProjectBoardInfo) {
        toast(
          `The ${t("generic.challenge")} project board has been moved into the "${t("common:capitalise", { key: "generic.ideas" })}" page. You are already in the right place.`,
          {
            duration: 100000,
            icon: "ℹ️",
          },
        );
        if (localStorageIsSupported) {
          localStorage.setItem("hasSeenProjectBoardInfo", true);
        }
      }
    } else if (localStorageIsSupported && !hasPreviouslySeenProjectBoardInfo) {
      toast(
        `The ${t("generic.challenge")} project board has been moved into the "${t("common:capitalise", { key: "generic.ideas" })}" page. You can still use the sidebar link to access it, or you can change your ${t("generic.idea")} view directly from the "${t("common:capitalise", { key: "generic.ideas" })}" page.`,
        { duration: 100000, icon: "ℹ️" },
      );
      localStorage.setItem("hasSeenProjectBoardInfo", true);
    }
    updateIdeaViewType("board");
    navigate(`/challenges/${challengeId}/ideas?queryView=board`);
  }, [challengeId, navigate, updateIdeaViewType, ideaViewType, location, t]);

  const onClickIdeasLink = useCallback(() => {
    const localStorageIsSupported = util.localStorageIsSupported();
    if (localStorageIsSupported) {
      const ideaViewPreference = localStorage.getItem("ideaViewTypePreference");
      updateIdeaViewType(["card", "list"].includes(ideaViewPreference) ? ideaViewPreference : "card");
    } else {
      updateIdeaViewType("card");
    }
    navigate(`/challenges/${params.id}/ideas`);
  }, [navigate, params.id, updateIdeaViewType]);

  const ipStatementHandled =
    !util.organisationFeaturesEnabled(user, ["ipManagement"]) ||
    !ipManagementInfo.ipStatementEnabled ||
    (ipManagementInfo.ipStatementEnabled && ipManagementInfo.userHasSigned);

  const PageLoadErrorMessage = useMemo(() => {
    let errorMessageTitle = t(user ? "challenge.errorUser" : "challenge.errorNoUser");
    let errorMessageContent = errorMessage;
    const usingOrg = user ? user.organisation : urlOrganisation;
    if (window.location.pathname === `/challenges/${challengeId}/ideas/new`) {
      errorMessageTitle = t("challenge.errorNewIdea");
      errorMessageContent = `Please contact an administrator${usingOrg ? ` for ${usingOrg?.name} ` : " "}to request permission if you think this is incorrect.`;
    }
    return (
      <>
        <Message header={errorMessageTitle} content={errorMessageContent} />
      </>
    );
  }, [challengeId, errorMessage, t, user, urlOrganisation]);

  const unsubmittedAssessments = useMemo(
    () =>
      myAssignedAssessments?.filter(
        (item) => !item?.assessments?.some((assessment) => assessment?.isSubmitted === true),
      ),
    [myAssignedAssessments],
  );

  const challengeIdeasProps = {
    challenge,
    myIdeas,
    newIdea,
    popularTags,
    updatePopularTags: setPopularTags,
    allStamps,
    availableGroups,
    availableOrganisations,
    updateAllStamps: setAllStamps,
    toggleStamp,
    setAvailableGroups,
    setAvailableOrganisations,
  };

  if (errorMessage) return <PageLoadError title={PageLoadErrorMessage} />;
  return (
    <div style={{ pointerEvents: isPreview && "none" }}>
      {challenge && (
        <Helmet>
          <title>{challenge ? challenge.name : t("common:capitalise", { key: "generic.challenge" })}</title>
          <meta
            property="og:title"
            content={challenge ? challenge.name : t("common:capitalise", { key: "generic.challenge" })}
          />
          <meta property="og:description" content={`by ${challenge.owner?.profile.fullName}`} />
          <meta property="og:image" content={challenge.bannerImageUrl} />
          <meta property="og:url" content={`${util.host(user)}/challenges/${challenge._id}`} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@_simplydo"></meta>
          <meta name="twitter:url" content={`${util.host(user)}/challenges/${challenge._id}`} />
          <meta name="twitter:title" content={challenge.name} />
          <meta name="twitter:description" content={`by ${challenge.owner?.profile.fullName}`} />
          <meta name="twitter:image" content={challenge.bannerImageUrl} />
        </Helmet>
      )}
      {errorMessage && !loading && !challenge && (
        <PageLoadError title={t(user ? "challenge.errorUser" : "challenge.errorNoUser")} message={errorMessage} />
      )}
      {util.organisationFeaturesEnabled(user, ["ipManagement"]) ? (
        <ChallengeIPStatementAgreement
          user={user}
          challenge={challenge}
          isOpen={ipStatementAgreementOpen}
          setHasSignedStatement={setHasSignedStatement}
          onClose={() => setIpStatementAgreementOpen(false)}
          ipManagementInfo={ipManagementInfo}
          setIpManagementInfo={setIpManagementInfo}
          ipStatement={ipStatement}
          setIpStatement={setIpStatement}
        />
      ) : null}
      {/* <ProductTour
        isReady={(!loading && challenge?._id)}
        tour='challenge'
      /> */}
      {user && (
        <ChallengeStatusBar
          challengeId={challengeId}
          loading={loading}
          user={user}
          isPreview={isPreview}
          challenge={challenge}
          newIdea={newIdea}
          myOwnIdeas={myIdeas}
          overviewPage={true}
          redirectToLogin={redirectToLogin}
        />
      )}
      <div>
        <ChallengeHeader
          loading={loading}
          user={user}
          isPreview={isPreview}
          challenge={challenge}
          myIdeas={myIdeas}
          newIdea={newIdea}
          isIdeasPage={true}
          redirectToLogin={redirectToLogin}
        />
        <SideNav
          fluid
          collapsible
          isCollapsed={!!isCollapsed}
          onCollapse={() => setIsCollapsed(true)}
          onExpand={() => setIsCollapsed(false)}
          loading={loading}
          top={120}
          navItems={
            loading
              ? [
                  {
                    type: "custom",
                    key: "divider",
                    content: <div style={{ height: 15 }} />,
                  },
                  loading
                    ? {
                        key: "loading",
                        type: "custom",
                        content: (
                          <PlaceholderStack gap={15}>
                            <PlaceholderItem height={40} />
                            <PlaceholderItem height={40} />
                          </PlaceholderStack>
                        ),
                      }
                    : null,
                ]
              : [
                  {
                    type: "custom",
                    key: "divider",
                    content: <div style={{ height: 15 }} />,
                  },
                  {
                    key: "overview",
                    to: ".",
                    content: t("challenge.details.title"),
                    iconLeft: "info circle",
                    active: isPreview ? true : window.location.pathname === `/challenges/${params.id}`,
                  },

                  ((challenge?.ideaVisibility === "users" || canViewEntireChallenge) && !isPreview) || loading
                    ? {
                        key: "ideas",
                        content: t("generic.ideas"),
                        iconLeft: "lightbulb",
                        active:
                          window.location.pathname === `/challenges/${params.id}/ideas` && ideaViewType !== "board",
                        onClick: onClickIdeasLink,
                        rightIcon:
                          user && challenge?.ideaCount > 0 ? (
                            <StyledLabel size="tiny" circular>
                              {challenge?.ideaCount || 0}
                            </StyledLabel>
                          ) : null,
                      }
                    : null,

                  user && canViewEntireChallenge
                    ? {
                        key: "activity",
                        to: `activity`,
                        content: "Activity",
                        iconLeft: "chart line",
                        active: window.location.pathname === `/challenges/${params.id}/activity`,
                      }
                    : null,

                  user &&
                  (myAssignedAssessments?.length > 0 ||
                    (canViewEntireChallenge && challenge?.ideaTemplateData?.assessmentEnabled))
                    ? {
                        key: "assessments",
                        to: `assessments`,
                        content: !canViewEntireChallenge ? "Your assessments" : "Assessment",
                        iconLeft: "percent",
                        rightIcon:
                          unsubmittedAssessments?.length > 0 ? (
                            <StyledLabel size="tiny" color="red" circular>
                              {unsubmittedAssessments?.length}
                            </StyledLabel>
                          ) : null,
                        active: window.location.pathname.indexOf(`/challenges/${params.id}/assessments`) > -1,
                      }
                    : null,

                  user && canViewEntireChallenge && challengePlannerEnabled
                    ? {
                        key: "planner",
                        to: `planner`,
                        content: "Planner",
                        iconLeft: "calendar",
                        active: window.location.pathname.indexOf(`/challenges/${params.id}/planner`) > -1,
                      }
                    : null,

                  user && (canViewEntireChallenge || challenge?.projectManagementVisibility === "users")
                    ? {
                        key: "projectManagement",
                        content: `${t("common:capitalise", { key: "generic.idea" })} project board`,
                        iconLeft: "compass",
                        onClick: onClickProjectBoardLink,
                        active:
                          (window.location.pathname === `/challenges/${params.id}/ideas` && ideaViewType === "board") ||
                          window.location.pathname === `/challenges/${params.id}/board`,
                      }
                    : null,
                ].filter((item) => Boolean(item))
          }
          main={
            <>
              {loading ? (
                <>
                  <PlaceholderStack gap={20} style={{}}>
                    <PlaceholderItem height={40} style={{ minWidth: "100%" }} />
                    <PlaceholderItem height={250} style={{ minWidth: "100%" }} />
                    {window.location.pathname === `/challenges/${params.id}` && (
                      <>
                        <PlaceholderItem height={40} style={{ minWidth: "100%", marginTop: 20 }} />
                        <PlaceholderItem height={150} style={{ minWidth: "100%" }} />
                      </>
                    )}{" "}
                  </PlaceholderStack>
                </>
              ) : (
                <Routes>
                  <Route
                    path={isPreview ? "" : "/"}
                    element={
                      <ChallengeOverview
                        challenge={challenge}
                        user={user}
                        isPreview={isPreview}
                        newIdea={newIdea}
                        myIdeas={myIdeas}
                        loading={loading}
                        ipStatement={ipStatement}
                        ipManagementInfo={ipManagementInfo}
                        ipStatementHandled={ipStatementHandled}
                        setIpStatementAgreementOpen={setIpStatementAgreementOpen}
                        redirectToLogin={redirectToLogin}
                      />
                    }
                  />
                  <Route path="ideas/new" element={<NewIdea redirectToLogin={redirectToLogin} />} />
                  <Route path="ideas" element={<ChallengeIdeas {...challengeIdeasProps} />} />
                  {user && challenge ? (
                    <Route
                      path="board"
                      element={
                        <ChallengeIdeasProjectWrapper>
                          <ChallengeIdeas {...challengeIdeasProps} />
                        </ChallengeIdeasProjectWrapper>
                      }
                    />
                  ) : null}
                  {user && canViewEntireChallenge && challengePlannerEnabled && (
                    <Route path="planner/*" element={<ChallengePlanner challenge={challenge} />} />
                  )}
                  {challenge && (
                    <Route
                      path="assessments/*"
                      element={
                        <ChallengeAssessments
                          challenge={challenge}
                          getAssignedAssessments={getAssignedAssessments}
                          myAssignedAssessments={myAssignedAssessments}
                          setMyAssignedAssessments={setMyAssignedAssessments}
                          setFluid={setFluid}
                          fluid={fluid}
                        />
                      }
                    />
                  )}
                  {user && <Route path="activity" element={<ChallengeActivity challenge={challenge} />} />}
                </Routes>
              )}
            </>
          }
        />
      </div>
    </div>
  );
}

const ChallengeContainer = withTranslation()(Challenge);

export default ChallengeContainer;
