import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Segment, Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";

const ErrorDiv = styled.div`
  background-color: #184258fc;
  width: 100vw;
  height: 100vh;
  .container {
    position: absolute;
    left: ${({ theme }) => (theme.sizes.isMobile ? "2%" : "25%")};
    top: 15%;
    width: 50%;
    min-height: 50%;
  }
  .centerDiv {
    margin-top: 20px;
    text-align: center;
    .buttonClass {
      min-width: 55%;
      margin: 10px;
    }
  }
`;

function ErrorScreen({ setError, theme }) {
  const navigate = useNavigate();
  return (
    <ErrorDiv theme={theme}>
      <Segment className="container">
        <div className="centerDiv">
          <Icon name="exclamation triangle" size="huge" color="red" />
          <h2>Simply Do has encountered a problem.</h2>
          <p> An automated report of your issue has been submitted.</p>
          <h5>Please continue to the previous page or contact our support for guidance if the issue persists.</h5>
          <div style={{ display: "inline-block" }}>
            <Button
              as="a"
              className="buttonClass"
              size="large"
              href="mailto:support@simplydo.co.uk?subject=Platform%20Problem"
              color="grey"
              icon="mail"
              content="Email support"
            />
            <Button
              className="buttonClass"
              size="large"
              onClick={() => {
                setError(false);
                navigate("..");
              }}
              icon="arrow left"
              color="grey"
              content="Return to previous page"
            />
          </div>
        </div>
      </Segment>
    </ErrorDiv>
  );
}

export default connect()(ErrorScreen);
