import React, { useState, useCallback, ReactElement, useMemo } from "react";
import {
  Grid,
  Container,
  Header,
  Button,
  Table,
  Image,
  Icon,
  Input,
  Loader,
  SemanticICONS,
  Dropdown,
  Popup,
} from "semantic-ui-react";
import { Other, OpenAPI } from "simplydo/interfaces";
import { unstable_usePrompt } from "react-router-dom";
import util from "utils/utils";
import api from "api";
import { UserChooser } from "components/lib/Choosers";
import { SelectIdeaChip } from "components/lib/Cards/IdeaCard/ListView/styles";
import styled from "styled-components";
import toast from "react-hot-toast";
import { FakeLink } from "components/lib/UI";

import AssessorModal from "./AssessorModal";
import PendingInvitationModal from "./PendingInvitationModal";
import IdeaModal from "./IdeaModal";

import { useIdeas, useAssessors } from "./hooks";
import { useTranslation } from "react-i18next";
import { ImageWithFallback } from "components/lib/ImageWithFallback";

export const TableHeaderItem = styled(Header)`
  img {
    height: 26px !important;
    width: 26px !important;
    margin-right: 8px !important;
  }
  div.image {
    margin-right: 5px !important;
  }
`;

const GridHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  > .header {
    margin: 0;
  }
`;

export type IExistingAssessor = OpenAPI.Schemas["users"] & {
  isEmailInvitee: false;
  type: "existingAssessor";
};

export type IPotentialAssessor =
  | {
      _id: string;
      profile: { fullName: string };
      isEmailInvitee: true;
      type: "potentialAssessor";
    }
  | (OpenAPI.Schemas["users"] & {
      type: "potentialAssessor";
      isEmailInvitee?: false;
    });

export type IPendingInvitationAssessor = Other.IInvitation & {
  isEmailInvitee: true;
  type: "pendingInvitation";
};

/*
  The possibility of being an "assessor" in the list is somewhat complicated, but it boils down to 3 types:
  - Existing assessor: This is a USER, who is assigned to 1 or more ideas in the challenge
  - Pending invitation: This is an invitation pertaining to an email address, which has been invited to assess 1 or more ideas in this challenge
  - Potential assessor: This is a user OR an email address that only exists locally, i.e. the user has added them to the list ready to assign them to some ideas, but this has not been saved yet. A potential assessor can be isEmailInvitee true or false
*/
export type IAssessor = IExistingAssessor | IPendingInvitationAssessor | IPotentialAssessor;

type IAssignment = {
  challenge: Other.IChallenge;
};

export type IModalAction = {
  icon?: SemanticICONS;
  content: string;
  onClick?: () => void;
  loading?: boolean;
};

type IOptions<ItemType> = {
  name: string;
  icon: SemanticICONS;
  onClick?: (item: ItemType) => void;
};

type IAssignmentTable<ItemType, T extends keyof ItemType = keyof ItemType> = {
  items: ItemType[];
  keyExtractor: (item: ItemType) => ItemType[T];
  onSelect: (itemKey: ItemType[T]) => void;
  selectedItems: string[];
  renderCellContent: (item: ItemType) => ReactElement;
  loading?: boolean;
  options: (item: ItemType) => IOptions<ItemType[T]>[];
};

const AssignmentTable = <ItemType extends Record<string, any>>({
  items,
  keyExtractor,
  onSelect,
  renderCellContent,
  selectedItems,
  loading,
  options,
}: IAssignmentTable<ItemType>) => {
  if (loading) return <Loader inline="centered" />;
  return (
    <Table basic="very">
      <Table.Body>
        {items.map((item) => {
          const key = keyExtractor(item);
          const itemOptions = options(item);
          return (
            <Table.Row key={key}>
              <Table.Cell collapsing>
                <SelectIdeaChip
                  // @ts-ignore
                  $compact
                  $isSelected={selectedItems.indexOf(key) > -1}
                  onClick={() => {
                    onSelect(key);
                  }}
                >
                  <Icon name="check" />
                </SelectIdeaChip>
              </Table.Cell>
              <Table.Cell>{renderCellContent(item)}</Table.Cell>
              <Table.Cell collapsing>
                {itemOptions.length ? (
                  <Dropdown icon={null} trigger={<Icon name="chevron down" />}>
                    <Dropdown.Menu>
                      {options(item).map((option) => (
                        <Dropdown.Item
                          key={option.name}
                          text={option.name}
                          onClick={option.onClick}
                          icon={option.icon}
                        />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : null}
              </Table.Cell>
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

const assignmentMapIsEmpty = (assignmentMap) => {
  // The assignment map has keys user: {}, invitation: {}, email: {}; if all are empty, the map is empty
  // Therefore, we need to check if every value in the assignment map is an empty object
  return Object.values(assignmentMap).every((v) => Object.keys(v).length === 0);
};

const Assignment = ({ challenge }: IAssignment) => {
  const challengeId = challenge._id;

  const { t } = useTranslation();

  const {
    potentialIdeas,
    setPotentialIdeas,
    allIdeas,
    setAllIdeas,
    getAllIdeas,
    loadingPotentialIdeas,
    potentialIdeaSearchSettings,
    setPotentialIdeaSearchSettings,
    nextPageAvailable,
    prevPageAvailable,
  } = useIdeas(challengeId);

  const {
    potentialAssessors,
    setPotentialAssessors,
    loadingPotentialAssessors,
    existingAssessors,
    setExistingAssessors,
    existingAssessorsLoading,
    pendingInvitations,
    setPendingInvitations,
  } = useAssessors(challengeId);
  const existingAssessorIds = useMemo(() => existingAssessors.map((ea) => ea._id), [existingAssessors]);
  const pendingInvitationIds = useMemo(() => pendingInvitations.map((pi) => pi._id), [pendingInvitations]);
  const [removingInvitation, setRemovingInvitation] = useState<string>("");

  const potentialUserAssessorIds = useMemo(
    () => potentialAssessors.filter((pa) => !pa.isEmailInvitee).map((pa) => pa._id),
    [potentialAssessors],
  );

  const [selectedPotentialAssessors, setSelectedPotentialAssessors] = useState<string[]>([]);

  // Track new, local assignments, by their type
  // Users: Existing assessor or new assessor, by SimplyDo user id
  // Invitations: Outstanding email invitations without a SimplyDo account
  // Emails: Emails unassociated with an account or an invitation
  const [assignmentMap, setAssignmentMap] = useState<{
    user: { [userId: string]: string[] };
    invitation: { [invitationId: string]: string[] };
    email: { [email: string]: string[] };
  }>({ user: {}, invitation: {}, email: {} });

  unstable_usePrompt({
    when: !assignmentMapIsEmpty(assignmentMap),
    message: "You have unsaved assignments. Are you sure you want to leave?",
  });

  // We need to maintain a steady list of *essentially* every idea in the challenge to match assessors to any existing assignments on N>1 page of the challenge ideas. As you can only assign assessors to ideas we've seen on this page, we effectively just create an ongoing list of every idea that has been loaded to this page via filtering/paginatin
  const [selectedPotentialIdeas, setSelectedPotentialIdeas] = useState<string[]>([]);

  const [previewingItem, setPreviewingItem] = useState<{
    type: "existingAssessor" | "idea" | "pendingInvitation" | "potentialAssessor" | "";
    _id: string;
  }>({ type: "", _id: "" });

  const [unassigningAssessor, setUnassigningAssessor] = useState<{ user: string; idea: string }>({
    user: "",
    idea: "",
  });
  const [_savingAssignments, setSavingAssignments] = useState<boolean>(false);

  const assessorItems: IAssessor[] = useMemo(
    () => [...existingAssessors, ...potentialAssessors, ...pendingInvitations],
    [existingAssessors, potentialAssessors, pendingInvitations],
  );

  // Helper function to get the context of an id (user, invitation, email) from our local states
  const getAssignmentMapType = useCallback(
    (itemId) => {
      if ([...potentialUserAssessorIds, ...existingAssessorIds].indexOf(itemId) > -1) return "user";
      if (pendingInvitationIds.indexOf(itemId) > -1) return "invitation";
      return "email";
    },
    [existingAssessorIds, potentialUserAssessorIds, pendingInvitationIds],
  );

  // Create a map of each potential assessor and all their existing assignments and any unsaved local ones
  const assessorIdToIdeaMap = useMemo<{
    [userId: string]: { existing: Other.IIdea[]; potential: Other.IIdea[] };
  }>(() => {
    const userIds = [...existingAssessors.map((u) => u._id), ...potentialAssessors.map((pa) => pa._id)];
    const aIdMap = {};
    userIds.forEach((userId) => {
      const localAssignments = assignmentMap.user[userId] || assignmentMap.email[userId] || [];
      // Seperate ideas into local ones not yet saved, and previously assigned. Existing take priority
      const existingAssignments = allIdeas.filter(
        (idea) => (idea.assessments ?? []).map((a) => a.user).indexOf(userId) > -1,
      );
      const newLocalAssignments = allIdeas.filter(
        (idea) =>
          localAssignments.indexOf(idea._id) > -1 && existingAssignments.map((i) => i._id).indexOf(idea._id) === -1,
      );
      aIdMap[userId] = {
        existing: existingAssignments ?? [],
        potential: newLocalAssignments ?? [],
      };
    });
    // Also map any email invitees
    pendingInvitations.forEach((pendingInvitation) => {
      const localAssignments = assignmentMap.invitation[pendingInvitation._id] ?? [];
      const existingAssignments = allIdeas.filter((idea) => pendingInvitation?.forIdeas.indexOf(idea._id) > -1);
      const newLocalAssignments = allIdeas.filter(
        (idea) =>
          localAssignments.indexOf(idea._id) > -1 && existingAssignments.map((i) => i._id).indexOf(idea._id) === -1,
      );
      aIdMap[pendingInvitation._id] = {
        existing: existingAssignments ?? [],
        potential: newLocalAssignments ?? [],
      };
    });
    return aIdMap;
  }, [potentialAssessors, assignmentMap, allIdeas, existingAssessors, pendingInvitations]);

  // For each idea, get any pending invitations, locally selected potential assessors, and existing assessors
  const ideaIdToAssessorMap = useMemo<{
    [ideaId: string]: {
      existing: IExistingAssessor[];
      pendingExisting: IPendingInvitationAssessor[];
      potential: IPotentialAssessor[];
    };
  }>(() => {
    const ideaIdMap = {};
    potentialIdeas.forEach((idea) => {
      // For each idea, get the related idea pending invitations, existing assessors, and any entries in the assignment map that match this idea
      const ideaAssessors = (idea.assessments ?? []).map((a) => a.user);
      const ideaPendingInvitations = pendingInvitations.filter((pi) => pi.forIdeas?.indexOf(idea._id) > -1);
      const ideaExistingAssessors = existingAssessors.filter((ea) => ideaAssessors.indexOf(ea._id) > -1);

      // A list of every possible currently selected idea, using the assignment map to create an array of [[userId, ideaIds[]]]
      const assignmentMapCopy = { ...assignmentMap };
      const assignmentMapIds = Object.entries(assignmentMapCopy.user)
        .concat(Object.entries(assignmentMapCopy.invitation))
        .concat(Object.entries(assignmentMapCopy.email));
      // Turn the above into an array of assessor IDs that have this idea selected
      const assignmentMapUserIds = Object.values(
        assignmentMapIds.filter(([, ideaIds]) => ideaIds.indexOf(idea._id) > -1).map(([userId]) => userId),
      );
      // Finally, filter all our currently loaded users (potential/existing assessors) by whether they're locally associated with this idea
      const assignmentMapPotentialAssessors = assessorItems.filter((pa) => assignmentMapUserIds.indexOf(pa._id) > -1);
      ideaIdMap[idea._id] = {
        existing: ideaExistingAssessors,
        pendingExisting: ideaPendingInvitations,
        potential: assignmentMapPotentialAssessors,
      };
    });
    return { ...ideaIdMap };
  }, [potentialIdeas, pendingInvitations, existingAssessors, assignmentMap, assessorItems]);

  const searchAssessors = useCallback(
    (queryTerm: string, onComplete: (users: OpenAPI.Schemas["User"][]) => void, fail) => {
      api.search.challengeAssessors(
        challengeId,
        queryTerm,
        ({ users }) => {
          onComplete(users);
        },
        fail,
      );
    },
    [challengeId],
  );

  // Update the local assignment map for any type (user/invitation/email) with ideas to be either added or removed
  const localAssignAssessors = useCallback(
    (ids: string[], ideaIds: string[]) => {
      setAssignmentMap((prevAssignmentMap) => {
        const updatedAssignmentMap = { ...prevAssignmentMap };
        ids.forEach((id) => {
          const itemType = getAssignmentMapType(id);
          let existingLocalAssignments = [...(updatedAssignmentMap[itemType][id] ?? [])];
          ideaIds.forEach((ideaId) => {
            if (existingLocalAssignments.indexOf(ideaId) > -1) {
              existingLocalAssignments = existingLocalAssignments.filter((i) => i !== ideaId);
            } else {
              existingLocalAssignments = [...existingLocalAssignments, ideaId];
            }
          });
          updatedAssignmentMap[itemType][id] = existingLocalAssignments;
        });
        return updatedAssignmentMap;
      });
    },
    [getAssignmentMapType],
  );

  const localAssignSelectedItems = useCallback(() => {
    localAssignAssessors(selectedPotentialAssessors, selectedPotentialIdeas);
    setSelectedPotentialAssessors([]);
    setSelectedPotentialIdeas([]);
  }, [localAssignAssessors, selectedPotentialAssessors, selectedPotentialIdeas]);

  // When adding/removing potential assessor users/emails, update the challenge in the database to allow coming back to this page later. These are cleared upon saving an assignment
  const updatePotentialAssessors = useCallback(
    (updatedPotentialAssessors) => {
      const potentialEmailAssessors = updatedPotentialAssessors.filter((a) => !!a.isEmailInvitee);
      const potentialUserAssessors = updatedPotentialAssessors.filter((a) => !a.isEmailInvitee);
      api.challenges.updatePotentialAssessors(
        challengeId,
        potentialUserAssessors.map((u) => u._id),
        potentialEmailAssessors.map((e) => e._id),
        () => {},
        () => {},
      );
    },
    [challengeId],
  );

  const removeLocalAssignments = useCallback(
    (id) => {
      const itemType = getAssignmentMapType(id);
      setAssignmentMap((prevMap) => ({
        ...prevMap,
        [itemType]: {
          ...prevMap[itemType],
          [id]: [],
        },
      }));
    },
    [getAssignmentMapType],
  );

  // Remove an assessor that has been added as a potential assessor, update the API to remove them from the challenge and remove any local assignments
  const removePotentialAssessor = useCallback(
    (potentialAssessor: IPotentialAssessor) => {
      removeLocalAssignments(potentialAssessor._id);
      const updatedPotentialAssessors = potentialAssessors.filter((pa) => pa._id !== potentialAssessor._id);
      setPotentialAssessors(updatedPotentialAssessors);
      updatePotentialAssessors(updatedPotentialAssessors);
    },
    [removeLocalAssignments, potentialAssessors, setPotentialAssessors, updatePotentialAssessors],
  );

  // Right now we have a semi-not-ideal way of tracking ideas in two states, because we both need to know _every_ idea to know the existing assessors for said ideas as well as track assignments when filtering potential ideas
  // Therefore this helper function will update local state for both idea lists at once
  const updateLocalIdeaAssessors = useCallback(
    (ideaIds: string[], userIds: string[], action: "adding" | "removing") => {
      const ideaUpdater = (ideas) =>
        ideas.map((idea) => {
          if (ideaIds.indexOf(idea._id) === -1) return idea;
          const ideaAssessments = idea.assessments ?? [];
          return {
            ...idea,
            assessments:
              action === "removing"
                ? ideaAssessments.filter((assessment) => userIds.indexOf(assessment.user) === -1)
                : [...ideaAssessments, ...userIds.map((id) => ({ user: id }))],
          };
        });
      setAllIdeas((prevIdeas) => ideaUpdater(prevIdeas));
      setPotentialIdeas((prevIdeas) => ideaUpdater(prevIdeas));
    },
    [setAllIdeas, setPotentialIdeas],
  );

  // After saving assignments, we reset the local state
  const saveAssignments = useCallback(() => {
    setSavingAssignments(true);
    const toastId = toast.loading("Assigning assessors...");
    // Assign assessors using a matrix object of userId|invitationId|email -> ideaIds[]
    api.challenges.assignAssessorsMatrix(
      challengeId,
      assignmentMap,
      () => {
        toast.loading("Loading updated assignments...", { id: toastId });
        setPotentialAssessors([]);
        setSelectedPotentialAssessors([]);
        setSelectedPotentialIdeas([]);
        setAssignmentMap({ user: {}, email: {}, invitation: {} });
        // After a successful assignment, update our local states with new API data
        api.challenges.getAssessmentDashboard(
          challengeId,
          "",
          ({
            assessors: newAssessors,
            pendingInvitations: newPendingInvitations,
          }: {
            assessors: any;
            pendingInvitations: Other.IInvitation[];
          }) => {
            setExistingAssessors(
              newAssessors.map((assessor) => ({ ...assessor.ownerAssessor, type: "existingAssessor" })),
            );
            setPendingInvitations(
              newPendingInvitations.map((pi) => ({ ...pi, type: "pendingInvitation", isEmailInvitee: true })),
            );
            setSavingAssignments(false);
            toast.success("Got new assignments", { id: toastId, duration: 500 });
            getAllIdeas();
          },
          (err) => {
            toast.error(err.message, { id: toastId, duration: 500 });
            setSavingAssignments(false);
          },
        );
      },
      (err) => {
        toast.error(err.message, { id: toastId, duration: 500 });
        toast.dismiss(toastId);
      },
    );
  }, [challengeId, assignmentMap, setPotentialAssessors, setExistingAssessors, setPendingInvitations, getAllIdeas]);

  // Unassign assessor from a given idea, then update the local idea states
  const unassignAssessor = useCallback(
    (ideaId: string, userId: string) => {
      util.confirm("Unassign assessor", "Are you sure you want to unassign this assessor?").then(() => {
        setUnassigningAssessor({ user: userId, idea: ideaId });
        api.challenges.unassignAssessors(
          challengeId,
          [ideaId],
          [userId],
          () => {
            updateLocalIdeaAssessors([ideaId], [userId], "removing");
            setUnassigningAssessor({ user: "", idea: "" });
          },
          (err) => {
            setUnassigningAssessor({ user: "", idea: "" });
            toast.error(err.message);
          },
        );
      });
    },
    [challengeId, updateLocalIdeaAssessors],
  );

  const AssessorTableItem = useCallback(
    (item: IAssessor) => {
      const onClick = () => setPreviewingItem({ type: item.type, _id: item._id });
      const itemType = getAssignmentMapType(item._id);
      const existingAssignments = assignmentMap[itemType][item._id];
      const emailHasMultipleInvitations =
        item.type === "pendingInvitation"
          ? pendingInvitations.filter((pi) => pi.invitee === item?.invitee).map((pi) => pi._id)
          : [];
      return (
        <div>
          <TableHeaderItem as="h4" style={{ fontWeight: "400" }}>
            {!item.isEmailInvitee ? (
              <ImageWithFallback avatar src={util.avatarUrl(item)} fallbackSrc={util.avatarUrl()} />
            ) : (
              <Image>
                <Icon name="mail" />
              </Image>
            )}
            {item.type === "pendingInvitation"
              ? `${item.invitee}${emailHasMultipleInvitations.length > 1 ? `(Invitation ${emailHasMultipleInvitations.indexOf(item._id) + 1})` : ""}`
              : item.profile.fullName}
            <Header.Subheader>
              {/* eslint-disable-next-line no-nested-ternary */}
              {item.type === "existingAssessor" || item.type === "pendingInvitation" ? (
                <FakeLink onClick={onClick}>
                  Assigned to{" "}
                  {util.pluralise(
                    assessorIdToIdeaMap[item._id]?.existing.length || 0,
                    t("generic.idea"),
                    t("generic.ideas"),
                  )}
                  {existingAssignments?.length
                    ? `, selected to assess ${util.pluralise(assessorIdToIdeaMap[item._id]?.potential.length || 0, t("generic.idea"), t("generic.ideas"))}`
                    : ""}
                </FakeLink>
              ) : existingAssignments?.length ? (
                <FakeLink onClick={onClick}>
                  Selected to assess {util.pluralise(existingAssignments.length, t("generic.idea"), t("generic.ideas"))}
                </FakeLink>
              ) : null}
            </Header.Subheader>
          </TableHeaderItem>
        </div>
      );
    },
    [assignmentMap, assessorIdToIdeaMap, getAssignmentMapType, pendingInvitations, t],
  );

  const IdeaTableItem = useCallback(
    (item: Other.IIdea) => {
      const onClick = () => setPreviewingItem({ type: "idea", _id: item._id });
      const ideaMapIdea = ideaIdToAssessorMap[item._id];

      return (
        <TableHeaderItem as="h4" style={{ fontWeight: "400" }}>
          <ImageWithFallback
            avatar
            style={{ borderRadius: 4, objectFit: "cover" }}
            src={util.ideaCoverImage(item)}
            fallbackSrc={util.ideaCoverImage()}
          />
          {item.name}
          <Header.Subheader>
            <FakeLink onClick={onClick}>
              {[
                ideaMapIdea?.existing.length
                  ? `${util.pluralise(ideaMapIdea.existing.length, "existing assessor", "existing assessors")}`
                  : false,
                ideaMapIdea?.pendingExisting.length
                  ? `${util.pluralise(ideaMapIdea.pendingExisting.length, "pending invitation", "pending invitations")}`
                  : false,
                ideaMapIdea?.potential.length
                  ? `${util.pluralise(ideaMapIdea.potential.length, "selected assessor", "selected assessors")}`
                  : false,
              ]
                .filter((i) => Boolean(i))
                .join(", ")}
            </FakeLink>
          </Header.Subheader>
        </TableHeaderItem>
      );
    },
    [ideaIdToAssessorMap],
  );

  const getAssessorActions = useCallback(
    (assessor: IAssessor, idea: Other.IIdea) => {
      const isExistingAssessor = (idea.assessments ?? []).map((a) => a.user).indexOf(assessor._id) > -1;
      return [
        {
          content: `Unassign from ${t("generic.idea")}`,
          icon: "trash" as SemanticICONS,
          loading: unassigningAssessor.user === assessor._id && unassigningAssessor.idea === idea._id,
          onClick: isExistingAssessor
            ? () => unassignAssessor(idea._id, assessor._id)
            : () => removeLocalAssignments(assessor._id),
        },
      ];
    },
    [unassigningAssessor, unassignAssessor, removeLocalAssignments, t],
  );

  const removePendingInvitation = useCallback(
    (pendingInvitation: Other.IInvitation) => {
      const pendingInvitationIdeas = assessorIdToIdeaMap[pendingInvitation._id].existing;
      util
        .confirm(
          "Remove pending invitation",
          `This invitation is for ${util.pluralise(pendingInvitationIdeas.length, t("generic.idea"), t("generic.ideas"))}. Removing this invitation will remove all existing invitations for all associated ${t("generic.ideas")}. Are you sure you want to continue?`,
        )
        .then(() => {
          setRemovingInvitation(pendingInvitation._id);
          api.invitations.remove(
            pendingInvitation._id,
            () => {
              setPendingInvitations((prevInvitations) => [
                ...prevInvitations.filter((i) => i._id !== pendingInvitation._id),
              ]);
              setRemovingInvitation("");
            },
            (err) => {
              toast.error(err.message);
              setRemovingInvitation("");
            },
          );
        })
        .catch(() => {});
    },
    [assessorIdToIdeaMap, setPendingInvitations, t],
  );

  const getInvitationActions = useCallback(
    (pendingInvitation: Other.IInvitation, idea: Other.IIdea) => {
      const isInvitedIdea = (pendingInvitation.forIdeas ?? []).indexOf(idea._id) > -1;
      return [
        {
          content: `Unassign from ${t("generic.idea")}`,
          icon: "trash" as SemanticICONS,
          loading: removingInvitation === pendingInvitation._id,
          onClick: isInvitedIdea
            ? () => removePendingInvitation(pendingInvitation)
            : () => removeLocalAssignments(pendingInvitation._id),
        },
      ];
    },
    [removingInvitation, removePendingInvitation, removeLocalAssignments, t],
  );

  const getIdeaActions = useCallback(
    (idea: Other.IIdea, assessor: IExistingAssessor | IPotentialAssessor | IPendingInvitationAssessor, isLocal) =>
      [
        assessor.type === "existingAssessor" && !isLocal
          ? {
              content: "Unassign assessor",
              icon: "trash" as SemanticICONS,
              loading: unassigningAssessor.user === assessor._id && unassigningAssessor.idea === idea._id,
              onClick: () => unassignAssessor(idea._id, assessor._id),
            }
          : null,
        assessor.type === "pendingInvitation" && !isLocal
          ? {
              content: "Remove invitation",
              icon: "trash" as SemanticICONS,
              loading: removingInvitation === assessor._id,
              onClick: () => removePendingInvitation(assessor),
            }
          : null,
        assessor.type === "potentialAssessor" || isLocal
          ? {
              content: "Remove selected assessor",
              icon: "trash" as SemanticICONS,
              onClick: () => removeLocalAssignments(assessor._id),
            }
          : null,
      ].filter((a) => Boolean(a)),
    [
      removeLocalAssignments,
      removePendingInvitation,
      removingInvitation,
      unassignAssessor,
      unassigningAssessor.idea,
      unassigningAssessor.user,
    ],
  );

  const handleSelectingPotentialAssessors = useCallback(
    (newPotentialAssessors) => {
      // Add the new potential assessors to the list of potential assessors if they're not already there
      const updatedPotentialAssessors = [...potentialAssessors];
      newPotentialAssessors.forEach((newAssessor) => {
        if (updatedPotentialAssessors.map((a) => a._id).indexOf(newAssessor._id) === -1) {
          updatedPotentialAssessors.push(newAssessor);
        }
      });
      setPotentialAssessors(updatedPotentialAssessors);
      updatePotentialAssessors(updatedPotentialAssessors);

      // Also auto select the recently added assessors if they are not already selected
      setSelectedPotentialAssessors((prev) =>
        newPotentialAssessors.reduce((acc, newAssessor) => {
          if (acc.indexOf(newAssessor._id) === -1) {
            return [...acc, newAssessor._id];
          }
          return acc;
        }, prev),
      );
    },
    [setPotentialAssessors, updatePotentialAssessors, potentialAssessors],
  );

  return (
    <Container fluid>
      {/* Modal for reviewing assessor's existing/selected assignments */}
      <AssessorModal
        isOpen={previewingItem.type === "existingAssessor"}
        onClose={() => setPreviewingItem({ type: "", _id: "" })}
        assessor={[...existingAssessors, ...potentialAssessors].find((a) => a._id === previewingItem._id)}
        existingIdeas={assessorIdToIdeaMap[previewingItem._id]?.existing}
        potentialIdeas={assessorIdToIdeaMap[previewingItem._id]?.potential}
        getAssessorActions={getAssessorActions}
      />

      {/* Modal for reviewing pending invitations existing/selected assignments */}
      <PendingInvitationModal
        isOpen={previewingItem.type === "pendingInvitation"}
        onClose={() => setPreviewingItem({ type: "", _id: "" })}
        pendingInvitation={pendingInvitations.find((pi) => pi._id === previewingItem._id)}
        existingIdeas={assessorIdToIdeaMap[previewingItem._id]?.existing}
        potentialIdeas={assessorIdToIdeaMap[previewingItem._id]?.potential}
        getInvitationActions={getInvitationActions}
      />

      {/* Modal for reviewing ideas and their potential/existing assessors  */}
      <IdeaModal
        isOpen={previewingItem.type === "idea"}
        onClose={() => setPreviewingItem({ type: "", _id: "" })}
        idea={potentialIdeas.find((i) => i._id === previewingItem._id)}
        existingAssessors={ideaIdToAssessorMap[previewingItem._id]?.existing}
        potentialAssessors={ideaIdToAssessorMap[previewingItem._id]?.potential}
        pendingInvitations={ideaIdToAssessorMap[previewingItem._id]?.pendingExisting}
        getIdeaActions={getIdeaActions}
      />

      {/* Double columned grid for assigning users <-> ideas */}
      <Grid columns={2} style={{ marginBottom: 10 }}>
        <Grid.Row divided>
          <Grid.Column>
            <GridHeader>
              <Header>Assessors</Header>
              <Button.Group compact>
                <Button
                  content={selectedPotentialAssessors.length ? "Deselect all" : "Select all"}
                  onClick={
                    selectedPotentialAssessors.length
                      ? () => setSelectedPotentialAssessors([])
                      : () => setSelectedPotentialAssessors(assessorItems.map((item) => item._id))
                  }
                />
              </Button.Group>
            </GridHeader>
            <AssignmentTable
              items={assessorItems}
              keyExtractor={(item) => item._id}
              selectedItems={selectedPotentialAssessors}
              loading={loadingPotentialAssessors || existingAssessorsLoading}
              onSelect={(itemKey) => {
                setSelectedPotentialAssessors((prev) =>
                  prev.indexOf(itemKey as string) > -1
                    ? prev.filter((i) => i !== itemKey)
                    : [...prev, itemKey as string],
                );
              }}
              renderCellContent={(item) => AssessorTableItem(item)}
              options={(item) =>
                [
                  item.type === "potentialAssessor"
                    ? {
                        name: "Remove potential assessor",
                        icon: "close" as SemanticICONS,
                        onClick: () => removePotentialAssessor(item),
                      }
                    : null,
                  assignmentMap[item._id]?.length
                    ? {
                        name: "Remove selected assignments",
                        icon: "trash" as SemanticICONS,
                        onClick: () => removeLocalAssignments(item._id),
                      }
                    : null,
                ].filter((i) => Boolean(i))
              }
            />
            {/* @ts-ignore  */}
            <UserChooser
              clearOnComplete
              trigger={<Button fluid content={`Add${potentialAssessors.length ? " more" : ""} assessors`} />}
              // @ts-ignore
              onComplete={(newPotentialAssessors: IPotentialAssessor[]) => {
                handleSelectingPotentialAssessors(newPotentialAssessors);
              }}
              searchFunction={searchAssessors}
              externalInvitesInAudience={challenge?.visibility?.organisations?.length > 0}
              forType="assessor"
              forId={challengeId}
              audienceWarningText={
                <span>
                  The users below are currently outside of the {t("generic.challenge")} audience. Users not in the{" "}
                  {t("generic.challenge")} audience will be unable to view the {t("generic.challenge")} and their
                  assessments. To ensure assessors can access their assessments, add them to the{" "}
                  <a target="_blank" rel="noreferrer" href={`/challenges/${challengeId}/settings/audience`}>
                    {t("generic.challenge")} audience
                  </a>{" "}
                  after making this assignment.
                </span>
              }
            />
          </Grid.Column>
          <Grid.Column>
            <GridHeader>
              <Header>{t("common:capitalise", { key: "generic.ideas" })}</Header>
              <Button.Group compact>
                <Button
                  content={
                    potentialIdeaSearchSettings.showDraftIdeas
                      ? `Hide draft ${t("generic.ideas")}`
                      : `Show draft ${t("generic.ideas")}`
                  }
                  onClick={() =>
                    setPotentialIdeaSearchSettings((prev) => ({ ...prev, showDraftIdeas: !prev.showDraftIdeas }))
                  }
                />
                <Button
                  content={selectedPotentialIdeas.length ? "Deselect all" : "Select all"}
                  onClick={
                    selectedPotentialIdeas.length
                      ? () => setSelectedPotentialIdeas([])
                      : () => setSelectedPotentialIdeas(potentialIdeas.map((item) => item._id))
                  }
                />
              </Button.Group>
            </GridHeader>
            <Input
              icon="search"
              value={potentialIdeaSearchSettings.query}
              onChange={(e) => setPotentialIdeaSearchSettings((prev) => ({ ...prev, query: e.target.value }))}
              fluid
              loading={loadingPotentialIdeas}
              placeholder={`Filter ${t("generic.ideas")}...`}
            />
            <AssignmentTable
              items={potentialIdeas}
              keyExtractor={(item) => item._id}
              selectedItems={selectedPotentialIdeas}
              loading={loadingPotentialIdeas && !potentialIdeas.length}
              onSelect={(itemKey) => {
                setSelectedPotentialIdeas((prev) =>
                  prev.indexOf(itemKey as string) > -1
                    ? prev.filter((i) => i !== itemKey)
                    : [...prev, itemKey as string],
                );
              }}
              renderCellContent={(item) => IdeaTableItem(item)}
              options={() => []}
            />
            <Button.Group floated="right" size="tiny">
              {prevPageAvailable ? (
                <Button
                  content="Previous page"
                  loading={loadingPotentialIdeas}
                  onClick={() => setPotentialIdeaSearchSettings((prev) => ({ ...prev, page: prev.page - 1 }))}
                />
              ) : null}
              {nextPageAvailable ? (
                <Button
                  content="Next page"
                  loading={loadingPotentialIdeas}
                  onClick={() => setPotentialIdeaSearchSettings((prev) => ({ ...prev, page: prev.page + 1 }))}
                />
              ) : null}
            </Button.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ justifyContent: "flex-end" }}>
          {!assignmentMapIsEmpty(assignmentMap) ? (
            <Button
              content="Clear all unsaved assignments"
              icon="trash"
              onClick={() => {
                setAssignmentMap({ user: {}, invitation: {}, email: {} });
                setSelectedPotentialAssessors([]);
                setSelectedPotentialIdeas([]);
              }}
            />
          ) : null}

          <Popup
            trigger={
              <div>
                <Button
                  primary
                  content={`Assign ${util.pluralise(selectedPotentialAssessors.length, "assessor", "assessors")} to ${util.pluralise(selectedPotentialIdeas.length, t("generic.idea"), t("generic.ideas"))}`}
                  disabled={!selectedPotentialAssessors.length || !selectedPotentialIdeas.length}
                  onClick={() => localAssignSelectedItems()}
                />
              </div>
            }
            content={`Locally assign assessors to ${t("generic.ideas")}. This will not be saved until you click "Save new assignments". Local assignments will show as "selected" in the table, and will be saved when you click "Save new assignments".`}
          />
          <Popup
            content={`Confirm all local assignments. Existing users and new email addresses will be sent an invitation to assess the ${t("generic.idea")}.`}
            trigger={
              <Button
                primary
                content="Save new assignments"
                icon="checkmark"
                onClick={saveAssignments}
                disabled={assignmentMapIsEmpty(assignmentMap)}
              />
            }
          />
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default Assignment;
