import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Divider, Icon, Container, Message, Button, Input, Table, Modal, Popup } from "semantic-ui-react";
import toast from "react-hot-toast";
import api from "api";
import actions from "actions";
import util from "utils/utils";
import QRCodeStyling from "qr-code-styling";
import useTheme from "theme/useTheme";
import AppIcon from "src/images/appIcon.png";
import { useAppDispatch, useAppSelector } from "store";
import { SearchParams } from "simplydo/core";

const ApiToken = ({ token, tokenIndex, deleteToken, onViewToken }) => (
  <Table.Row>
    <Table.Cell collapsing>{token.serviceName || "Unknown"}</Table.Cell>
    <Table.Cell>
      <Popup
        content={
          token.userHasViewedToken
            ? "You have already viewed this token. It is no longer visible for security reasons. If you have the lost the token information you will need to delete it and recreate a token for the relevant service"
            : "You are able to view this token once. Press the 'View token' button to view the token. It will no longer be visible after this."
        }
        trigger={<Input fluid value={"x".repeat(20)} size="small" readOnly type="password" />}
      />
    </Table.Cell>
    <Table.Cell collapsing>
      {!token.userHasViewedToken ? (
        <Button size="mini" primary onClick={() => onViewToken(token.id, tokenIndex)} icon="eye" content="View token" />
      ) : null}
      <Button size="mini" icon="trash" basic onClick={() => deleteToken(token.token)} />
    </Table.Cell>
  </Table.Row>
);

function ApiSettings() {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const onServicesUpdated = useCallback(
    (enabledServices) => dispatch(actions.user.onServicesUpdated(enabledServices)),
    [dispatch],
  );
  const { t } = useTranslation();
  const location = useLocation();
  const { search } = location;
  const navigate = useNavigate();

  const [newApiTokenService, setNewApiTokenService] = useState("");
  const [creatingToken, setCreatingToken] = useState(false);
  const [externalTokens, setExternalTokens] = useState([]);
  const [viewingExternalToken, setViewingExternalToken] = useState(null);
  const inputRef = useRef<HTMLInputElement>();
  const copyToken = () => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.select();
    document.execCommand("Copy");
    toast("Token copied");
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [generatingQRCode, setGeneratingQRCode] = useState(false);
  const [QRCodeValue, setQRCodeValue] = useState("");
  const [localIp, setLocalIp] = useState("");
  const isDevMode = import.meta.env.VITE_STAGE === "Dev";
  const theme = useTheme();
  const otpQrRef = useRef(null);

  useEffect(() => {
    api.auth.getApiTokens(
      (newTokens) => {
        setExternalTokens(newTokens.externalTokens);
      },
      () => {},
    );

    const searchParams = new SearchParams(window.location.search);
    if (searchParams.has("error")) setErrorMessage(searchParams.get("error"));
    if (searchParams.has("success")) setSuccessMessage(searchParams.get("success"));
  }, [location]);

  const createToken = () => {
    setCreatingToken(true);
    api.auth.createExternalApiToken(
      { serviceName: newApiTokenService },
      (token) => {
        setCreatingToken(false);
        setExternalTokens([...externalTokens, token]);
        setViewingExternalToken(token);
        setNewApiTokenService("");
        toast("Your new token is ready to be used");
      },
      (err) => {
        toast.error(err.message);
        setCreatingToken(false);
      },
    );
  };

  const viewToken = useCallback((tokenId, tokenIndex) => {
    util
      .confirm(
        "View API token",
        "This will mark the token as viewed, and it will no longer be visible to you. Please note down the token to continue using it. Are you sure you want to continue?",
      )
      .then(() => {
        api.auth.viewExternalApiToken(
          tokenId,
          tokenIndex,
          ({ success, token: viewingToken }) => {
            setExternalTokens((prevTokens) =>
              prevTokens.map((token, index) => {
                if (index === tokenIndex) {
                  return {
                    ...token,
                    userHasViewedToken: true,
                  };
                }
                return token;
              }),
            );
            if (!success) {
              toast.error("You have already viewed this token");
            } else {
              setViewingExternalToken(viewingToken);
            }
          },
          (err) => {
            toast.error(err.message);
          },
        );
      })
      .catch(() => {});
  }, []);

  const deleteToken = (tokenId, tokenIndex) => {
    util
      .confirm(
        "Delete this token?",
        "Any services using this token will no longer be able to access your Simply Do account.",
      )
      .then(
        () => {
          setCreatingToken(true);
          api.auth.deleteExternalApiToken(
            tokenId,
            tokenIndex,
            () => {
              setCreatingToken(false);
              if (tokenId) {
                setExternalTokens(Object.assign([], externalTokens).filter((token) => tokenId !== token.id));
              } else {
                setExternalTokens(Object.assign([], externalTokens).filter((_, index) => index !== tokenIndex));
              }
            },
            (err) => {
              setCreatingToken(false);
              toast.error(err.message);
            },
          );
        },
        () => {},
      )
      .catch(() => {});
  };

  const removeService = (service) => {
    util
      .confirm("Remove this service?", "Simply Do will no longer be connected to the chosen service.")
      .then(() => {
        api.users.removeService(
          user._id,
          service,
          ({ enabledServices }) => {
            onServicesUpdated(enabledServices);
          },
          (err) => toast.error(err.message),
        );
      })
      .catch(() => {});
  };

  const generateQRCode = useCallback(() => {
    setGeneratingQRCode(true);
    api.auth.generateExchangeToken(
      ({ exchangeToken }) => {
        setQRCodeValue(
          `${isDevMode ? `exp://${localIp}:19000/--/` : "simplydo://"}?org=${user.organisation.code}&token=${exchangeToken}`,
        );
        setGeneratingQRCode(false);
      },
      (err) => {
        toast.error(err.message);
        setGeneratingQRCode(false);
      },
    );
  }, [user, isDevMode, localIp]);

  const qrCode = useMemo(
    () =>
      new QRCodeStyling({
        width: 200,
        height: 200,
        type: "svg",
        data: QRCodeValue,
        image: AppIcon,
        dotsOptions: {
          color: "#14435B",
        },
        backgroundOptions: {
          color: "#ffffff",
        },
        imageOptions: {
          margin: 4,
        },
      }),
    [QRCodeValue],
  );

  useEffect(() => {
    if (QRCodeValue && !qrCode._container) {
      qrCode.append(otpQrRef.current);
    }
  }, [QRCodeValue, qrCode]);

  useEffect(() => {
    const params = new SearchParams(search);
    const connectionSuccessString = params.get("connectionSuccess");
    if (connectionSuccessString) {
      if (connectionSuccessString === "true") {
        if (params.get("connectionType") === "user" && params.get("connectionService") === "office365") {
          if (user.enabledServices.indexOf("office365") === -1) {
            onServicesUpdated([...user.enabledServices, "office365"]);
          }
        }
      }
      params.delete("connectionSuccess");
      params.delete("connectionType");
      params.delete("connectionService");
      navigate({ search: params.toString() }, { replace: true });
    }
  }, [search, navigate, onServicesUpdated, user.enabledServices]);

  return (
    <Container>
      <h3>Mobile App Login</h3>
      <p>
        Scan this QR code to automatically open the mobile app and authenticate using this account (
        {user.profile.fullName}). For security reasons the code is single-use only, it is required to generate a new
        code for every login attempt.
      </p>
      {QRCodeValue ? (
        <div>
          <div ref={otpQrRef} />
          <Input
            fluid
            size="small"
            readOnly
            value={QRCodeValue}
            style={{ margin: "10px 0" }}
            action={
              !theme.sizes.isComputer ? (
                <Button
                  onClick={() => {
                    window.location.href = QRCodeValue;
                  }}
                >
                  Open QR link
                </Button>
              ) : null
            }
          />
        </div>
      ) : (
        <Message
          warning
          icon="warning sign"
          header="Before you proceed"
          content="This QR code is used to identify you directly with our service. It should be considered a private and secure credential. Just like a password, never share this code with anyone else."
        />
      )}
      {isDevMode ? (
        <Input
          size="small"
          style={{ margin: "10px 0", maxWidth: 300 }}
          value={localIp}
          onChange={(e, { value }) => setLocalIp(value)}
          placeholder="Enter local IP to open Expo"
        />
      ) : null}
      <Button
        primary
        onClick={generateQRCode}
        style={{ margin: "10px 0", maxWidth: "fit-content" }}
        loading={generatingQRCode}
        disabled={isDevMode && !localIp}
      >
        I understand, generate a login code
      </Button>

      <Divider section />

      <h3>Third-party services</h3>
      <p>{util.appName()} can interact directly with the services below.</p>

      {errorMessage && <Message error>{errorMessage}</Message>}
      {successMessage && <Message success>{successMessage}</Message>}

      <h4>
        <Icon name="microsoft" style={{ fontSize: 15 }} /> Microsoft Office365
      </h4>
      <p>Connect your Microsoft account to add and upload content to and from your Office365 OneDrive.</p>
      {(user.enabledServices || []).indexOf("office365") > -1 ? (
        <Message info>
          <Icon name="check" /> You've connected this service
          <Button
            style={{ marginLeft: 20, maxWidth: "fit-content" }}
            size="tiny"
            basic
            content="Disconnect"
            onClick={() => removeService("office365")}
          />
        </Message>
      ) : (
        <Button
          onClick={() => util.connectOAuth("microsoft", user, "user", user._id, "userIntegration", "office365")}
          icon="microsoft"
          style={{ maxWidth: "fit-content" }}
          content="Connect to Microsoft Office365"
          primary
        />
      )}

      <Divider section />
      <h3>{t("users.account.options.apiTokens.title")}</h3>
      <p>{t("users.account.options.apiTokens.info", { appName: util.appName() })}</p>

      <h5>{t("users.account.options.apiTokens.new")}</h5>
      <p>{t("users.account.options.apiTokens.newInfo")}</p>
      <Input
        size="small"
        fluid
        value={newApiTokenService}
        action={<Button primary content="Create" onClick={createToken} />}
        loading={creatingToken}
        placeholder='Service name (e.g. "Zapier")'
        onChange={(e) => setNewApiTokenService(e.target.value)}
      />

      <h5>Tokens you've already created</h5>
      <p>
        To authenticate using these tokens, call the appropriate endpoint over <code>HTTPS</code>, placing the token in
        the <code>Authorization</code> header. For more information about using the {util.appName()} API please get in
        touch.
      </p>
      {externalTokens.length > 0 ? (
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Service</Table.HeaderCell>
              <Table.HeaderCell>Token</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {externalTokens.map((token, tokenIndex) => (
              <ApiToken
                key={tokenIndex}
                token={typeof token === "string" ? { token } : token}
                deleteToken={() => deleteToken(token.id, tokenIndex)}
                tokenIndex={tokenIndex}
                onViewToken={viewToken}
              />
            ))}
          </Table.Body>
        </Table>
      ) : (
        <Message>No tokens have been created yet.</Message>
      )}

      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={!!viewingExternalToken}
        onClose={() => setViewingExternalToken(null)}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Viewing external API token</Modal.Header>
        <Modal.Content>
          <Message warning>
            <Message.Header>Important!</Message.Header>
            <p>
              This token is only shown once. Please copy it now and keep it safe. Once you close this modal, you cannot
              view this token again.
            </p>
          </Message>
          <Input
            fluid
            value={viewingExternalToken?.token}
            size="small"
            readOnly
            ref={inputRef}
            action={<Button size="mini" onClick={copyToken} icon="copy" />}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => setViewingExternalToken(null)}>
            Done
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
}

export default ApiSettings;
