import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dropdown, Divider, Icon } from "semantic-ui-react";

import RowComponent from "components/lib/RowComponent";

import util from "utils/utils";
import actions from "actions";
import api from "api";

const IconImage = ({ url, size = 30 }) => (
  <div
    style={{
      width: size,
      height: size,
      borderRadius: "50%",
      overflow: "hidden",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      backgroundImage: `url(${url})`,
      marginRight: 8,
    }}
  />
);

function HomeUser({ user, onSwitchOrganisation }) {
  const switchOrganisation = (orgId) => {
    if (orgId === user.organisation._id) {
      return;
    }
    api.users.switchOrganisation(user._id, orgId, (data) => {
      onSwitchOrganisation(data.organisation);
    });
  };

  return (
    <RowComponent>
      <div style={{ width: "100%" }}>
        <Dropdown
          style={{
            flexDirection: "row",
            display: "flex",
            fontWeight: "bold",
            alignItems: "center",
          }}
          size="tiny"
          basic
          direction="right"
          trigger={
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <IconImage url={util.mixinCssUrlFallback(util.avatarUrl(user), util.avatarUrl())} />
              {`${user?.profile?.firstName} ${user?.profile?.lastName}`}
            </div>
          }
        >
          <Dropdown.Menu style={{ minWidth: "20vw" }}>
            {user.availableOrganisations && user.availableOrganisations.length > 1 ? (
              <>
                <Dropdown.Header content="Switch organisation context" style={{ margin: "9px 0 8px" }} />
                {user.availableOrganisations.map((o) => (
                  <React.Fragment key={o._id}>
                    <Divider style={{ margin: 0, borderBottomWidth: 0 }} />
                    <Dropdown.Item style={{ padding: "5px !important" }} onClick={() => switchOrganisation(o._id)}>
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Icon name={o._id === user.organisation._id ? "check" : null} style={{ marginRight: 8 }} />
                        <IconImage size={20} url={o.darkLogoUrl} />
                        {o.name}
                      </div>
                    </Dropdown.Item>
                  </React.Fragment>
                ))}
                <Divider style={{ margin: 0, borderBottomWidth: 0 }} />
                <Dropdown.Item as={Link} to="/preferences">
                  <Icon name="building" style={{ marginRight: 8 }} />
                  Manage organisations
                </Dropdown.Item>
                <Divider style={{ margin: 0, borderBottomWidth: 0 }} />
              </>
            ) : null}
            <Dropdown.Item as={Link} to={`/users/${user._id}`}>
              <Icon name="user" style={{ marginRight: 8 }} />
              View profile
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </RowComponent>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  onSwitchOrganisation: (org) => dispatch(actions.user.switchOrganisation(org)),
});

const HomeUserContainer = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(HomeUser));

export default HomeUserContainer;
