import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { OpenAPI, Other } from "simplydo/interfaces";
import toast from "react-hot-toast";
import { useAppSelector } from "store";
import util from "utils/utils";
import api from "api";
import BaseChip, { ChipConfiguration } from "./BaseChip";

type IUserChipBase = {
  user: Partial<OpenAPI.Schemas["User"]>;
};

type IUserChip = IUserChipBase & ChipConfiguration;

function UserChip({ user, actionIcon, actionClick, actionTooltip, ...props }: IUserChip) {
  const navigate = useNavigate();
  const currentUser = useAppSelector<Other.IUserMe>((state) => state.user);

  const userId = user?._id;

  const ghost = useCallback(() => {
    util
      .confirm(
        "Really ghost as this user?",
        "Ghosting allows you to view the platform and carry out actions as though you are the user. You can un-ghost at any time by tapping the avatar at the top-right of the screen.",
      )
      .then(() =>
        api.users.ghost(
          userId,
          () => {
            navigate("/");
          },
          (err) => toast.error(err.message),
        ),
      )
      .catch(() => {});
  }, [navigate, userId]);

  if (!user || !user.profile) {
    user = {
      profile: {
        fullName: "Unknown User",
      },
    };
  }

  const canGhost =
    user?._id &&
    !(user?._id === currentUser?._id) &&
    util.hasPermission(currentUser, "super.ghostUser", user.organisation);

  const { jobTitle = "", department = "" } = user.profile;
  let description = jobTitle;
  if (department) {
    if (jobTitle) {
      description += " - ";
    }
    description += department;
  }

  return (
    <BaseChip
      {...props}
      title={user.profile.fullName}
      linkTo={user._id ? `/users/${user._id}` : null}
      description={description}
      imageUrl={util.avatarUrl(user)}
      labels={user.iconRoles}
      actionIcon={actionIcon ? actionIcon : canGhost ? "snapchat ghost" : undefined}
      actionClick={actionClick ? actionClick : canGhost ? ghost : undefined}
      actionTooltip={actionClick ? actionTooltip : canGhost ? "Ghost as this user" : undefined}
      details={user?.profile?.tagline ? user?.profile?.tagline : null}
    />
  );
}

export default UserChip;
