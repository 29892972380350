import React, { useState, useEffect, useCallback, useContext, useMemo } from "react";
import { useDidMount } from "utils/useDidMount";
import { Link } from "react-router-dom";
import { Menu, Popup, Input, Header, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";
import actions from "actions";
import api from "api";
import websocketApi from "api/websocket";
import { ThemeContext } from "styled-components";

import CircularButtonWithNumber from "../CircularButtonWithNumber";
import TraySearch from "./TraySearch";
import TrayMessages from "./TrayMessages";

const mapStateToProps = (state) => ({
  user: state.user,
  selectedMessageThreads: state.messages.selectedMessageThreads,
  messageThreads: state.messages.messageThreads,
  newMessageCount: state.messages.newMessageCount,
});
const mapDispatchToProps = (dispatch) => ({
  onMessageThreadSelected: (thread, isPhone = false) => dispatch(actions.messages.selectThread(thread, isPhone)),
  onMessageThreadsReceived: (threads, newMessageCount) =>
    dispatch(actions.messages.receiveThreads(threads, newMessageCount)),
});

const MessageTray = withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    ({
      messageThreads,
      user,
      t,
      onMessageThreadSelected,
      onMessageThreadsReceived,
      className,
      isMobile,
      newMessageCount,
      style,
    }) => {
      const theme = useContext(ThemeContext);
      const [searchState, setSearchState] = useState({
        results: [],
        searching: false,
        searchValue: "",
      });
      const [popupOpen, setPopupOpen] = useState(false);
      const { results, searching, searchValue } = searchState;
      const [recipients, setRecipients] = useState([]);
      const userId = user?._id;

      const addRecipient = useCallback(
        (recipient) => {
          const isRecipient = recipients.find((r) => r._id === recipient._id);
          if (!isRecipient) setRecipients((prevRecipients) => [recipient, ...prevRecipients]);
        },
        [recipients, setRecipients],
      );

      const removeRecipient = useCallback(
        (recipient) => setRecipients((prevRecipients) => prevRecipients.filter((r) => r._id !== recipient._id)),
        [setRecipients],
      );

      const getThreads = useCallback(
        (data = {}) => {
          api.messages.getThreads(
            { page: 1, query: "" },
            ({ messageThreads: fetchedThreads, newMessageCount: fetchedCount }) => {
              onMessageThreadsReceived(fetchedThreads, fetchedCount);
              if (data?.thread) {
                const notifiedThread = fetchedThreads.find((thread) => thread._id === data?.thread);
                const { participantsMuted = [] } = notifiedThread;
                if (notifiedThread && participantsMuted.indexOf(userId) === -1)
                  onMessageThreadSelected(notifiedThread, isMobile);
              }
            },
          );
        },
        [userId, theme.sizes.isMobile],
      ); // eslint-disable-line react-hooks/exhaustive-deps

      useDidMount(() => {
        getThreads();
        const messageSubscription = websocketApi.subscribe(`newMessage-${userId}`, (message) => {
          if (message.thread) {
            getThreads();
          }
        });
        return () => messageSubscription.unsubscribe();
      }, [getThreads, userId]);

      useEffect(() => {
        if (popupOpen) {
          setSearchState((prevState) => ({ ...prevState, results: [], searchValue: "" }));
          setRecipients([]);
          api.messages.getThreads(
            { page: 1, query: "" },
            ({ messageThreads: fetchedThreads, newMessageCount: fetchedCount }) => {
              onMessageThreadsReceived(fetchedThreads, fetchedCount);
            },
          );
        }
      }, [popupOpen, onMessageThreadsReceived]);

      const searchMessageRecipient = useCallback(
        (e) => {
          setSearchState((prevState) => ({ ...prevState, searching: true, searchValue: e?.target?.value }));
          api.search.messageRecipient(
            e?.target?.value || "",
            ({ users }) => {
              setSearchState((prevState) => ({ ...prevState, searching: false, results: users }));
            },
            (err) => {
              toast.error(err.message);
              setSearchState((prevState) => ({ ...prevState, searching: false }));
            },
          );
        },
        [setSearchState],
      );

      const handleSearchChange = useCallback(
        (e) => {
          e.persist();
          searchMessageRecipient(e);
        },
        [searchMessageRecipient],
      );

      const selectRecipients = useCallback(
        (newUser) => {
          setPopupOpen(false);
          api.messages.findThreadByUser(newUser._id, (data) => {
            if (data?.thread) {
              onMessageThreadSelected(data.thread);
            } else {
              onMessageThreadSelected(
                {
                  _id: `newThread:${newUser._id}`,
                  participantUsers: [user, newUser],
                },
                theme.sizes.isMobile,
              );
            }
          });
        },
        [onMessageThreadSelected, setPopupOpen, user, theme.sizes.isMobile],
      );

      const selectMessageThread = useCallback(
        (thread) => {
          api.journey.record("users", userId, "openMessageMole");
          setPopupOpen(false);
          onMessageThreadSelected(thread, theme.sizes.isMobile);
        },
        [setPopupOpen, onMessageThreadSelected, theme.sizes.isMobile, userId],
      );

      const createChat = useCallback(
        (users) => {
          api.messages.createThread({ users }, (thread) => {
            onMessageThreadSelected(thread);
            setPopupOpen(false);
          });
        },
        [setPopupOpen, onMessageThreadSelected],
      );

      const renderMessageContent = useMemo(
        () => (
          <>
            <Menu borderless secondary vertical style={{ minWidth: theme.sizes.isMobile ? "100%" : 350 }}>
              <div style={{ padding: 5 }}>
                <div style={{ padding: 5 }}>
                  <Header style={{ marginBottom: 0 }}>Direct messages</Header>
                  <Divider style={{ marginTop: 3, marginBottom: 0 }} />
                </div>
              </div>
              {searchValue ? (
                <TraySearch
                  results={results}
                  searching={searching}
                  recipients={recipients}
                  selectRecipients={selectRecipients}
                  addRecipient={addRecipient}
                  removeRecipient={removeRecipient}
                  createChat={createChat}
                />
              ) : (
                <TrayMessages messageThreads={messageThreads} selectMessageThread={selectMessageThread} user={user} />
              )}
            </Menu>
            <div>
              <Input
                size="small"
                fluid
                onChange={(e) => handleSearchChange(e)}
                placeholder={t("messages.findRecipient")}
              />
              <div style={{ display: "flex", justifyContent: "center", marginTop: 5 }}>
                <Link className="with-border" to="/messages">
                  {t("messages.view")}
                </Link>
              </div>
            </div>
          </>
        ),
        [
          theme,
          results,
          searching,
          recipients,
          selectRecipients,
          addRecipient,
          removeRecipient,
          createChat,
          messageThreads,
          selectMessageThread,
          user,
          handleSearchChange,
          searchValue,
          t,
        ],
      );

      if (isMobile) return renderMessageContent;
      return (
        <Popup
          on="click"
          style={{ width: "fit-content" }}
          positionFixed
          wide="very"
          position="bottom right"
          offset={[5, 0]}
          className="message-tray"
          open={popupOpen}
          onClose={() => setPopupOpen(false)}
          trigger={
            <div style={style}>
              <CircularButtonWithNumber
                className={className}
                onClick={() => setPopupOpen(!popupOpen)}
                icon="envelope"
                count={newMessageCount}
              />
            </div>
          }
          content={
            <div
              style={{
                minWidth: "350px",
                minHeight: "500px",
                maxHeight: "90vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              className="tray messages"
            >
              {renderMessageContent}
            </div>
          }
        />
      );
    },
  ),
);

const MessageTrayContainer = withTranslation()(connect(mapStateToProps)(MessageTray));

export default MessageTrayContainer;
