import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Divider,
  Form,
  Icon,
  Segment,
  Message,
  Button,
  Table,
  Input,
  Accordion,
  AccordionTitle,
  AccordionContent,
  Label,
} from "semantic-ui-react";
import { sdiClickDomain } from "utils/domains";
import RichText from "components/lib/Editors/RichText";
import { FileChooserWithDropzone } from "components/lib/Choosers";
import { DateTimeInput } from "semantic-ui-calendar-react";
import { useSelector } from "react-redux";
import moment from "moment";
import uuid from "uuid";
import toast from "react-hot-toast";
import util from "utils/utils";

const linkRegex = /<a[^>]+href="([^"]+)"[^>]+>([^<]+)<\/a>/gi;

type IAnnouncement = {
  subject?: string;
  replyTo?: string;
  callToActionText?: string;
  callToActionLink?: string;
  body?: string;
  text?: string;
  attachments?: Array<{
    _id: string;
    name: string;
    value: string;
    url?: string;
  }>;
  calendarEvent?: {
    startDate?: string;
    endDate?: string;
    name?: string;
    location?: string;
    description?: string;
  };
  customRecipients?: string[];
  customRecipientOwners?: any[];
};

type AnnouncementBaseProps = {
  forType: string;
  forId: string;
  settings?: React.ReactNode;
  accordionPanels?: React.ReactNode;
  announcement: IAnnouncement;
  setAnnouncement: (announcement: any) => void;
  activeAccordionIndexes: number[];
  toggleActiveAccordion: (index: number) => void;
  selectedFromUser?: any;
};

const AnnouncementBase = ({
  forType,
  forId,
  settings,
  announcement,
  setAnnouncement,
  activeAccordionIndexes,
  toggleActiveAccordion,
  accordionPanels,
  selectedFromUser,
}: AnnouncementBaseProps) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const [renamingAttachmentId, setRenamingAttachmentId] = useState("");
  const [renamingAttachmentName, setRenamingAttachmentName] = useState("");

  const links = (announcement.body ?? "").matchAll(linkRegex);
  const problematicLinks = Array.from(links).filter((link) => {
    const [_, href, text] = link;
    return href.trim() !== text.trim();
  });
  const { calendarEvent } = announcement;

  const addAttachments = useCallback(
    (
      files: {
        savedName: string;
        fileName: string;
      }[],
    ) => {
      toast.success("Upload successful");
      setAnnouncement((prevState) => ({
        ...prevState,
        attachments: [
          ...(prevState.attachments ?? []),
          ...files.map((file) => ({
            value: file.savedName,
            name: file.fileName,
            _id: uuid.v4(),
          })),
        ],
      }));
    },
    [setAnnouncement],
  );

  const onChange = useCallback(
    (value, text) => {
      setAnnouncement((prevState) => ({ ...prevState, body: value, text }));
    },
    [setAnnouncement],
  );

  return (
    <Form>
      <Segment>
        <h3>Content</h3>
        Customise the content of the announcement to be sent to users.
        <Message info size="small">
          <h4>
            <Icon name="lightbulb outline" />
            {t("announcements.form.placeholders.title")}
          </h4>
          <p>{t("announcements.form.placeholders.info")}</p>
          <ul>
            <li>
              <code>
                <mark>{"{firstName}"}</mark>
              </code>{" "}
              {t("announcements.form.placeholders.firstName")}
            </li>
            <li>
              <code>
                <mark>{"{lastName}"}</mark>
              </code>{" "}
              {t("announcements.form.placeholders.lastName")}
            </li>
          </ul>
        </Message>
        <Divider hidden />
        <Form.Input
          label={t("announcements.form.subject")}
          placeholder={`${t("announcements.form.subject")}...`}
          required
          value={announcement.subject}
          onChange={(e, { value }) => setAnnouncement((prevState) => ({ ...prevState, subject: value }))}
        />
        <Divider hidden />
        <Form.Field required label={t("announcements.form.body")} style={{ margin: 0 }} />
        <RichText
          preset="simple"
          fullMentionLinks
          autoHideMenu={false}
          placeholder={t("announcements.form.message")}
          value={announcement.body}
          onChange={onChange}
        />
        {problematicLinks.length !== 0 ? (
          <Message error style={{ display: "block" }}>
            Your message includes a labelled hyperlink. This will not be "clickable" in direct messages. For
            announcements we recommend that the hyperlink text is the same as the URL that it points to.
            <br />
            <br />
            We suggest making the following changes:
            <ul>
              {problematicLinks.map((link, index) => (
                <li key={index}>
                  "{link[2]}" {" => "} {link[1]}
                </li>
              ))}
            </ul>
          </Message>
        ) : null}
      </Segment>
      <Accordion styled fluid>
        <AccordionTitle index={0} active={activeAccordionIndexes.includes(0)} onClick={() => toggleActiveAccordion(0)}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <h3 style={{ marginBottom: 0 }}>Settings</h3>
            {announcement?.replyTo && (
              <Label
                style={{ marginLeft: 10 }}
                color="blue"
                size="small"
                content={`Reply-to: ${announcement?.replyTo}`}
              />
            )}
            {announcement?.callToActionText && (
              <Label
                style={{ marginLeft: 10 }}
                color="blue"
                size="small"
                content={`Call-to action: ${announcement.callToActionText}`}
              />
            )}
            {selectedFromUser && (
              <Label
                style={{ marginLeft: 10 }}
                color="blue"
                size="small"
                content={`From user: ${selectedFromUser?.profile?.fullName}`}
              />
            )}
            {announcement?.customRecipients && (
              <Label
                style={{ marginLeft: 10 }}
                color="blue"
                size="small"
                content={
                  announcement?.customRecipients?.length === 1
                    ? `To user: ${announcement?.customRecipientOwners[0]?.profile?.fullName}`
                    : `To users: ${announcement?.customRecipientOwners[0]?.profile?.fullName} and ${util.pluralise(announcement?.customRecipientOwners?.length - 1, "other", "others", true)}`
                }
              />
            )}
          </div>
        </AccordionTitle>
        <AccordionContent active={activeAccordionIndexes.includes(0)}>
          <h5 style={{ marginBottom: 5 }}>Reply-to address</h5>
          <p style={{ marginBottom: 5 }}>
            A reply-to address is the designated email where responses to your email announcement will be sent.
          </p>
          <Form.Input
            placeholder="example@address.com"
            width={5}
            value={announcement.replyTo}
            onChange={(e, { value }) => setAnnouncement((prevState) => ({ ...prevState, replyTo: value }))}
          />
          <Divider hidden />
          <h5 style={{ marginBottom: 5 }}>Call-to-action</h5>
          <p>
            A call to action is a customizable button displayed at the end of your email that directs users to your
            desired destination.
          </p>
          <Form.Group>
            <Form.Input
              fluid
              label="Text"
              placeholder="View on SimplyDo"
              width={5}
              value={announcement.callToActionText}
              onChange={(e, { value }) => setAnnouncement((prevState) => ({ ...prevState, callToActionText: value }))}
            />
            <Form.Input
              fluid
              label="URL"
              placeholder={`${sdiClickDomain}/linkTo${t("common:capitalise", { key: "generic.challenge" })}`}
              width={7}
              value={announcement.callToActionLink}
              onChange={(e, { value }) => setAnnouncement((prevState) => ({ ...prevState, callToActionLink: value }))}
            />
            <Form.Button
              label="Preview"
              style={{
                background: "#14435b",
                color: "white",
                fontWeight: 500,
                minWidth: 170,
              }}
            >
              {announcement.callToActionText || "View on SimplyDo"}
            </Form.Button>
          </Form.Group>
          {settings}
        </AccordionContent>
        <AccordionTitle index={1} active={activeAccordionIndexes.includes(1)} onClick={() => toggleActiveAccordion(1)}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <h3 style={{ marginBottom: 0 }}>Attachments</h3>
            {announcement.attachments?.length > 0 && (
              <Label
                style={{ marginLeft: 10 }}
                color="blue"
                size="small"
                content={`${util.pluralise(announcement.attachments?.length, "attachment", "attachments", true)}`}
              />
            )}
          </div>
        </AccordionTitle>
        <AccordionContent active={activeAccordionIndexes.includes(1)}>
          <p>
            Attach additional files to the announcement. Recipients will be able to download and access the attached
            files from the announcement.
          </p>
          {announcement.attachments?.length > 0 ? (
            <Table basic="very">
              {announcement.attachments?.map((file) => (
                <Table.Row key={file._id}>
                  <Table.Cell>
                    {renamingAttachmentId === file._id ? (
                      <Input
                        size="mini"
                        fluid
                        defaultValue={renamingAttachmentName}
                        onChange={(e, { value }) => {
                          setRenamingAttachmentName(value);
                        }}
                        action={{
                          size: "tiny",
                          icon: "save",
                          content: "Done",
                          onClick: () => {
                            setAnnouncement((prevState) => ({
                              ...prevState,
                              attachments: prevState.attachments.map((attachment) =>
                                attachment._id === file._id
                                  ? { ...attachment, name: renamingAttachmentName }
                                  : attachment,
                              ),
                            }));
                            setRenamingAttachmentId("");
                            setRenamingAttachmentName("");
                          },
                        }}
                      />
                    ) : (
                      file.name
                    )}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {!renamingAttachmentId ? (
                      <Button
                        content="Rename"
                        icon="edit"
                        size="tiny"
                        onClick={() => {
                          setRenamingAttachmentId(file._id);
                          setRenamingAttachmentName(file.name);
                        }}
                        basic
                      />
                    ) : null}
                    <Button
                      icon="trash"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        util
                          .confirm("Remove attachment", "Are you sure you want to remove this attachment?")
                          .then(() => {
                            setAnnouncement((prevState) => ({
                              ...prevState,
                              attachments: prevState.attachments.filter((attachment) => attachment._id !== file._id),
                            }));
                          })
                          .catch(() => {});
                      }}
                      size="tiny"
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table>
          ) : null}
          <FileChooserWithDropzone
            maxMBFileSize={10}
            forType={forType}
            forId={forId}
            allowMultiple={true}
            onCompleteMultiple={addAttachments}
            onError={toast.error}
            popupProps={{
              position: "bottom center",
              disabled: false,
            }}
          />
        </AccordionContent>
        <AccordionTitle index={2} active={activeAccordionIndexes.includes(2)} onClick={() => toggleActiveAccordion(2)}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <h3 style={{ marginBottom: 0 }}>Calendar event</h3>
            {calendarEvent?.name && (
              <Label style={{ marginLeft: 10 }} color="blue" size="small" content={calendarEvent?.name} />
            )}
          </div>
        </AccordionTitle>
        <AccordionContent active={activeAccordionIndexes.includes(2)}>
          <p>
            Create a calendar event that will be attached to emails sent with this announcement. This will enable
            recipients to add the event reminder to their own calendar.
          </p>
          <Button
            size="small"
            onClick={(e) => {
              if (calendarEvent) {
                setAnnouncement((prevState) => ({ ...prevState, calendarEvent: undefined }));
              } else {
                setAnnouncement((prevState) => ({ ...prevState, calendarEvent: {} }));
              }
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {calendarEvent ? "Remove calendar event" : "Add calendar event"}
          </Button>
          {calendarEvent && (
            <>
              <Divider hidden />
              <Form widths="equal" style={{ marginTop: 10, marginBottom: 10 }}>
                <Form.Group>
                  <DateTimeInput
                    label="Start date"
                    placeholder="Start date"
                    name="startDate"
                    value={calendarEvent?.startDate && moment(calendarEvent.startDate).format("DD-MM-YYYY HH:mm")}
                    iconPosition="left"
                    onChange={(e, { value }) => {
                      if (announcement?.calendarEvent?.endDate) {
                        setAnnouncement((prevState) => ({
                          ...prevState,
                          calendarEvent: {
                            ...prevState.calendarEvent,
                            startDate: moment(value, "DD-MM-YYYY HH:mm").toISOString(),
                          },
                        }));
                      } else {
                        setAnnouncement((prevState) => ({
                          ...prevState,
                          calendarEvent: {
                            ...prevState.calendarEvent,
                            startDate: moment(value, "DD-MM-YYYY HH:mm").toISOString(),
                            endDate: moment(value, "DD-MM-YYYY HH:mm").add(1, "hour").toISOString(),
                          },
                        }));
                      }
                    }}
                  />
                  <DateTimeInput
                    label="End date"
                    placeholder="End date"
                    name="endDate"
                    minDate={calendarEvent.startDate && moment(calendarEvent.startDate).format("DD-MM-YYYY HH:mm")}
                    value={calendarEvent.endDate && moment(calendarEvent.endDate).format("DD-MM-YYYY HH:mm")}
                    iconPosition="left"
                    onChange={(e, { value }) =>
                      setAnnouncement((prevState) => ({
                        ...prevState,
                        calendarEvent: {
                          ...prevState.calendarEvent,
                          endDate: moment(value, "DD-MM-YYYY HH:mm").toISOString(),
                        },
                      }))
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Input
                    label="Event name"
                    placeholder="Event name"
                    value={calendarEvent.name}
                    onChange={(e, { value }) =>
                      setAnnouncement((prevState) => ({
                        ...prevState,
                        calendarEvent: { ...prevState.calendarEvent, name: value },
                      }))
                    }
                  />
                  <Form.Input
                    label="Location"
                    placeholder="Location"
                    onChange={(e, { value }) =>
                      setAnnouncement((prevState) => ({
                        ...prevState,
                        calendarEvent: { ...prevState.calendarEvent, location: value },
                      }))
                    }
                    value={calendarEvent.location}
                  />
                </Form.Group>
                <Form.TextArea
                  label="Description"
                  placeholder="Description"
                  value={calendarEvent.description}
                  onChange={(e, { value }) =>
                    setAnnouncement((prevState) => ({
                      ...prevState,
                      calendarEvent: { ...prevState.calendarEvent, description: value },
                    }))
                  }
                />
              </Form>
              <p>{`The calendar invite will be displayed as originating from your email address "${user?.emails[0]?.address}". Note that RSVPs may be received at this address.`}</p>
            </>
          )}
        </AccordionContent>
        {accordionPanels}
      </Accordion>
    </Form>
  );
};

export default AnnouncementBase;
