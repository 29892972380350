import React from "react";
import { Image, Form, Card, Button, Divider, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";

import { Slider } from "components/lib/UI";
import RichTextViewer from "components/lib/Editors/RichTextViewer";
import ImageChooser from "components/lib/Choosers/ImageChooser";
import FieldSeparator from "../FieldSeparator";
import FieldHeader from "../FieldHeader";

const Beam = styled.div`
  margin-left: -5%;
  padding: 10px;
  width: 110%;
  min-height: 10px;
  border-radius: 2px;
  box-shadow: 0px 10px 5px rgba(0, 0, 0, 0.1);
  color: white;
  text-align: center;
  background-color: ${({ $includeSection }) => $includeSection.colour};
`;

class Compatibility extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.sliders = [
      {
        title: "How much do you love the thought of running your own business?",
        name: "Passion",
        key: "compatibilityPassion",
      },
      {
        title: "How well do you think you stick to tasks when things get tough?",
        name: "Resilience",
        key: "compatibilityBelief",
      },
      {
        title: "How confident are you at communicating your ideas?",
        name: "Communication",
        key: "compatibilityHistory",
      },
      {
        title: "How well do you believe you plan and keep on top of things?",
        name: "Planning",
        key: "compatibilityRisk",
      },
      {
        title: "How much support do you feel you will get from your network (e.g. family, friends)?",
        name: "Network",
        key: "compatibilityNetwork",
      },
    ];
  }

  updateProfile = (profileIndex, field, value) => {
    const people = Object.assign([], this.props.compatibilityPeople);
    people[profileIndex][field] = value;
    this.props.updateIdea(people, ["compatibilityPeople"]);
  };

  addProfile = () => {
    const people = Object.assign([], this.props.compatibilityPeople);
    people.push({});
    this.props.updateIdea(people, ["compatibilityPeople"]);
  };

  deleteProfile = (index) => {
    const people = (this.props.compatibilityPeople || []).filter((p, i) => i !== index);
    this.props.updateIdea(people, ["compatibilityPeople"]);
  };

  updateRatingField = (fieldName, fieldKey, value) => {
    const rating = { ...this.props[fieldName] };
    rating[fieldKey] = value;
    this.props.updateIdea(rating, [fieldName]);
  };

  calcCompatibility = () => {
    let total = 0;
    this.sliders.forEach((slider) => {
      total += (this.props[slider.key] && this.props[slider.key].rating) || 0;
    });
    return parseInt((total * 10) / this.sliders.length, 10);
  };

  render() {
    const { compatibilityPeople = [], ideaId, isEditing, includeSection, t, externalChanges } = this.props;
    return (
      <div>
        {isEditing ? (
          <div>
            <FieldHeader
              externalChange={externalChanges.compatibilityPeople}
              title={t("ideas.6Cs.sections.compatibility.people.name")}
              isEditing={isEditing}
              description={t("ideas.6Cs.sections.compatibility.people.title")}
            />
            <Button
              color="yellow"
              icon="plus"
              content={t("ideas.6Cs.sections.compatibility.people.add")}
              onClick={this.addProfile}
            />
          </div>
        ) : (
          compatibilityPeople.length > 0 && <FieldHeader title={t("ideas.6Cs.sections.compatibility.people.info")} />
        )}
        <Card.Group itemsPerRow={3} stackable centered style={{ marginTop: "15px" }}>
          {compatibilityPeople.map((person, i) =>
            isEditing ? (
              <>
                <Card key={i}>
                  {person.portrait && <Image src={person.portrait} />}
                  <Card.Content>
                    <ImageChooser
                      fluid
                      forType="idea"
                      forId={ideaId}
                      trigger={
                        <Button
                          size="small"
                          fluid
                          icon="image"
                          color="yellow"
                          content={t("ideas.actions.addPicture")}
                        />
                      }
                      onComplete={(p, n, src) => {
                        this.updateProfile(i, "portrait", src);
                      }}
                    />
                  </Card.Content>
                  <Card.Content>
                    <Form>
                      <Form.Input
                        fluid
                        label={t("ideas.6Cs.sections.compatibility.people.form.name.title")}
                        placeholder={t("ideas.6Cs.sections.compatibility.people.form.name.placeholder")}
                        value={person.name || ""}
                        icon="id badge outline"
                        onChange={(e) => this.updateProfile(i, "name", e.target.value)}
                      />
                      <Form.Input
                        fluid
                        label={t("ideas.6Cs.sections.compatibility.people.form.role.title")}
                        placeholder={t("ideas.6Cs.sections.compatibility.people.form.role.placeholder")}
                        value={person.role || ""}
                        icon="user"
                        onChange={(e) => this.updateProfile(i, "role", e.target.value)}
                      />
                      <Form.TextArea
                        label={t("ideas.6Cs.sections.compatibility.people.form.description.title")}
                        placeholder={t("ideas.6Cs.sections.compatibility.people.form.description.placeholder")}
                        value={person.description || ""}
                        onChange={(e) => this.updateProfile(i, "description", e.target.value)}
                        style={{ fontSize: "11px" }}
                      />
                    </Form>
                  </Card.Content>
                  <Card.Content extra textAlign="right">
                    <Button
                      basic
                      size="mini"
                      icon="trash"
                      content={t("generic.remove")}
                      onClick={() => this.deleteProfile(i)}
                    />
                  </Card.Content>
                </Card>
              </>
            ) : (
              <>
                {person.name ? (
                  <Card key={i}>
                    {person.portrait && <Image src={person.portrait} />}
                    <Card.Content>
                      <Card.Header>{person.name}</Card.Header>
                      {person.role && <Card.Meta>{person.role}</Card.Meta>}
                    </Card.Content>
                    {person.description && (
                      <Card.Content extra>
                        <RichTextViewer content={person.description} />
                      </Card.Content>
                    )}
                  </Card>
                ) : null}
              </>
            ),
          )}
        </Card.Group>

        <Divider section hidden />
        <FieldSeparator includeSection={includeSection} />

        {isEditing ? (
          <FieldHeader
            externalChange={(externalChanges.compatibilityPassion || [])
              .concat(externalChanges.compatibilityBelief || [])
              .concat(externalChanges.compatibilityHistory || [])
              .concat(externalChanges.compatibilityRisk || [])
              .concat(externalChanges.compatibilityNetwork || [])}
            title={t("ideas.6Cs.sections.compatibility.evaluation.info")}
          />
        ) : (
          <FieldHeader style={{ textAlign: "center" }} title={t("ideas.6Cs.sections.compatibility.evaluation.title")} />
        )}

        <Grid stackable centered style={{ marginTop: 30 }}>
          {this.sliders.map((slider, i) => (
            <Grid.Column key={i} computer={isEditing ? 16 : 3} textAlign={isEditing ? "left" : "center"}>
              {isEditing ? (
                <Grid stackable>
                  <Grid.Column computer={16}>
                    <h4>{t(`ideas.6Cs.sections.compatibility.sliders.${slider.key}.name`)}</h4>
                  </Grid.Column>
                  <Grid.Column textAlign="center" computer={3}>
                    <h2 style={{ marginTop: "20px" }}>
                      {(this.props[slider.key] && this.props[slider.key].rating) || 0}
                    </h2>
                  </Grid.Column>
                  <Grid.Column computer={6}>
                    <Slider
                      defaultValue={0}
                      value={(this.props[slider.key] && this.props[slider.key].rating) || 0}
                      min={1}
                      max={10}
                      step={1}
                      onChange={(s) => this.updateRatingField(slider.key, "rating", s)}
                      style={{ marginTop: "30px" }}
                    />
                  </Grid.Column>
                  <Grid.Column computer={7}>
                    <Form>
                      <Form.TextArea
                        label="Justify your rating"
                        value={(this.props[slider.key] && this.props[slider.key].notes) || ""}
                        onChange={(e) => this.updateRatingField(slider.key, "notes", e.target.value)}
                      />
                    </Form>
                  </Grid.Column>
                </Grid>
              ) : (
                <div>
                  <h4>{t(`ideas.6Cs.sections.compatibility.sliders.${slider.key}.name`)}</h4>
                  <h2>{(this.props[slider.key] && this.props[slider.key].rating) || 0}</h2>
                  <Divider clearing hidden />
                  <p>{this.props[slider.key] && this.props[slider.key].notes}</p>
                </div>
              )}
            </Grid.Column>
          ))}
        </Grid>

        {!isEditing && (
          <Beam $includeSection={includeSection}>
            <FieldHeader
              title={t("ideas.6Cs.sections.compatibility.rating", { compatibility: this.calcCompatibility() })}
            />
          </Beam>
        )}
      </div>
    );
  }
}

export default withTranslation()(Compatibility);
