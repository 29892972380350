import { useState, useCallback, useEffect } from "react";
import { Other } from "simplydo/interfaces";
import api from "api";
import toast from "react-hot-toast";
import { IPotentialAssessor, IExistingAssessor, IPendingInvitationAssessor } from ".";

export const useIdeas = (challengeId: string) => {
  const [potentialIdeas, setPotentialIdeas] = useState<Other.IIdea[]>([]);
  const [allIdeas, setAllIdeas] = useState<Other.IIdea[]>([]);
  const [loadingPotentialIdeas, setLoadingPotentialIdeas] = useState<boolean>(false);
  const [potentialIdeaSearchSettings, setPotentialIdeaSearchSettings] = useState<{
    query: string;
    page: number;
    showDraftIdeas: boolean;
  }>({
    query: "",
    page: 1,
    showDraftIdeas: false,
  });
  const [nextPageAvailable, setNextPageAvailable] = useState<boolean>(false);
  const [prevPageAvailable, setPrevPageAvailable] = useState<boolean>(false);
  const { query, page, showDraftIdeas } = potentialIdeaSearchSettings;

  const getPotentialIdeas = useCallback(() => {
    setLoadingPotentialIdeas(true);
    api.challenges.getIdeas(
      challengeId,
      {
        query,
        filter: showDraftIdeas ? [] : ["submitted"],
        limit: 10,
        page,
      },
      ({ ideas: newIdeas, nextPageAvailable: newNextPageAvailable, previousPageAvailable: newPrevPageAvailable }) => {
        setPotentialIdeas(newIdeas);
        setLoadingPotentialIdeas(false);
        setNextPageAvailable(newNextPageAvailable);
        setPrevPageAvailable(newPrevPageAvailable);
      },
      () => {
        setLoadingPotentialIdeas(false);
      },
    );
  }, [challengeId, query, page, showDraftIdeas]);

  useEffect(() => {
    getPotentialIdeas();
  }, [getPotentialIdeas]);

  const getAllIdeas = useCallback(() => {
    // Effectively get all using limit 1000
    api.challenges.getIdeas(
      challengeId,
      { limit: 1000 },
      ({ ideas: newIdeas }) => {
        setAllIdeas(newIdeas);
      },
      (err) => toast.error(err.message),
    );
  }, [challengeId]);

  useEffect(() => {
    getAllIdeas();
  }, [getAllIdeas]);

  return {
    potentialIdeas,
    allIdeas,
    loadingPotentialIdeas,
    potentialIdeaSearchSettings,
    setPotentialIdeaSearchSettings,
    nextPageAvailable,
    prevPageAvailable,
    setPotentialIdeas,
    setAllIdeas,
    getAllIdeas,
  };
};

export const useAssessors = (challengeId: string) => {
  const [potentialAssessors, setPotentialAssessors] = useState<IPotentialAssessor[]>([]);
  const [loadingPotentialAssessors, setLoadingPotentialAssessors] = useState<boolean>(false);
  const [existingAssessors, setExistingAssessors] = useState<IExistingAssessor[]>([]);
  const [existingAssessorsLoading, setExistingAssessorsLoading] = useState<boolean>(false);
  const [pendingInvitations, setPendingInvitations] = useState<IPendingInvitationAssessor[]>([]);

  const getPotentialAssessors = useCallback(() => {
    setLoadingPotentialAssessors(true);
    api.challenges.getPotentialAssessors(
      challengeId,
      ({ ownerUsers, ownerEmails }) => {
        setPotentialAssessors(
          [...ownerUsers, ...ownerEmails].map((assessor) => ({ ...assessor, type: "potentialAssessor" })),
        );
        setLoadingPotentialAssessors(false);
      },
      () => {
        setLoadingPotentialAssessors(false);
      },
    );
  }, [challengeId]);

  useEffect(() => {
    getPotentialAssessors();
  }, [getPotentialAssessors]);

  // Get existing assessors and invitations for this challenge
  const getExistingAssessors = useCallback(() => {
    setExistingAssessorsLoading(true);
    api.challenges.getAssessmentDashboard(
      challengeId,
      "",
      ({
        assessors: newAssessors,
        pendingInvitations: newPendingInvitations,
      }: {
        assessors: any;
        pendingInvitations: Other.IInvitation[];
      }) => {
        setExistingAssessors(newAssessors.map((assessor) => ({ ...assessor.ownerAssessor, type: "existingAssessor" })));
        setPendingInvitations(
          newPendingInvitations.map((pi) => ({ ...pi, type: "pendingInvitation", isEmailInvitee: true })),
        );
        setExistingAssessorsLoading(false);
      },
      () => {
        setExistingAssessorsLoading(false);
      },
    );
  }, [challengeId]);

  useEffect(() => {
    getExistingAssessors();
  }, [getExistingAssessors]);

  return {
    potentialAssessors,
    setPotentialAssessors,
    loadingPotentialAssessors,
    existingAssessors,
    setExistingAssessors,
    existingAssessorsLoading,
    pendingInvitations,
    setPendingInvitations,
  };
};
