import React, { useCallback, ReactNode, useMemo } from "react";
import { Dropdown, Popup } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";
import toast from "react-hot-toast";
import util from "utils/utils";
import api from "api";
import { useTranslation } from "react-i18next";
import { IProjectGeneralProps, IProjectLaneProps, IProjectIdeaProps, IProjectAssigneeProps } from ".";

export type LaneSettingsProps = IProjectGeneralProps & IProjectLaneProps & IProjectIdeaProps & IProjectAssigneeProps;

export type ILaneSettings = LaneSettingsProps & {
  laneId: string;
  editLaneName: () => void;
  editLaneDescription: () => void;
  updateLane: (data: Partial<Other.IProjectLane>, onComplete?: () => void, onError?: () => void) => void;
  updateIdeaPositionMulti: (fromLane: string, toLane: string) => void;
  reorderLaneIdeas: (sort: string, sortDirection: string) => void;
};

export const DropdownLaneSettings = ({
  editLaneName,
  editLaneDescription,
  laneId,
  setLanes,
  // updateDefaultLane,
  setIdeas,
  updateLane,
  setCurrentLane,
  lanes,
  ideas,
  handleWebsocketLanes,
  handleWebsocketIdeas,
  getBoard,
  forId,
  trigger,
  updateIdeaPositionMulti,
  reorderLaneIdeas,
}: ILaneSettings & { trigger: ReactNode }) => {
  const lane = useMemo(() => lanes.find((l) => l._id === laneId), [lanes, laneId]);
  const ideasOnLane = useMemo(() => ideas.filter((i) => getBoard(i)?.lane === laneId), [ideas, laneId, getBoard]);
  const { t } = useTranslation();

  const currentLaneId = lane?._id || "default";
  const isDefaultLane = currentLaneId === "default";
  const currentLaneMetaType = lane?.metaType || "normal";

  const LaneTypeOptions: { key: Other.IProjectLaneMetaType; value: Other.IProjectLaneMetaType; text: string }[] = [
    {
      key: "normal",
      value: "normal",
      text: "Normal lane",
    },
    {
      key: "notProgressing",
      value: "notProgressing",
      text: `${t("common:capitalise", { key: "generic.ideas" })} not progressing`,
    },
    {
      key: "completed",
      value: "completed",
      text: `${t("common:capitalise", { key: "generic.ideas" })} completed`,
    },
    {
      key: "resolved",
      value: "resolved",
      text: `${t("common:capitalise", { key: "generic.ideas" })} resolved`,
    },
  ];

  const LaneSortOptions: { text: string; value: string }[] = [
    {
      value: "projectUpdated",
      text: "Last update",
    },
    {
      value: "projectDeadline",
      text: "Project deadline",
    },
    {
      value: "ideaCreated",
      text: `${t("common:capitalise", { key: "generic.idea" })} creation`,
    },
    {
      value: "alphabetical",
      text: "Card name",
    },
  ];

  const moveLane = useCallback(
    (direction) => {
      if (direction === -1 && lane.order < 1) return;
      if (direction === 1 && lane.order >= lanes.length - 1) return;
      api.boards.updateProjectLane(
        forId,
        currentLaneId,
        { order: lane.order + direction },
        () => {
          api.boards.getProjectLanes(
            forId,
            ({ lanes: newLanes }) => {
              setLanes(newLanes);
              handleWebsocketLanes(newLanes);
              setCurrentLane(newLanes.filter((l) => l._id === currentLaneId)[0]);
            },
            () => {},
          );
        },
        (err) => toast.error(err.message),
      );
    },
    [lane, forId, currentLaneId, handleWebsocketLanes, setLanes, setCurrentLane, lanes.length],
  );

  const deleteLane = useCallback(() => {
    util
      .confirm(
        "Really delete this lane?",
        `All ${t("generic.ideas")} on this lane will be moved back to the default lane.`,
      )
      .then(
        () => {
          api.boards.deleteProjectLane(
            forId,
            currentLaneId,
            () => {
              const newLanes = Object.assign([], lanes).filter((l) => l._id !== currentLaneId);
              const newIdeas = Object.assign([], ideas).map((i) => {
                const board = getBoard(i);
                if (board?.lane === currentLaneId) board.lane = null;
                return i;
              });
              setLanes(newLanes);
              setIdeas(newIdeas);
              handleWebsocketLanes(newLanes);
              handleWebsocketIdeas(newIdeas);
            },
            (err) => toast.error(err.message),
          );
        },
        () => {},
      )
      .catch(() => {});
  }, [forId, getBoard, ideas, currentLaneId, setLanes, setIdeas, handleWebsocketLanes, handleWebsocketIdeas, lanes]);

  return (
    <Dropdown
      trigger={trigger}
      on="click"
      icon={null}
      closeOnChange={false}
      closeOnEscape={false}
      pointing="left"
      upward={false}
    >
      <Dropdown.Menu>
        <Dropdown.Header content="Lane Settings" />
        <Dropdown.Divider />
        {lanes.length > 1 && !isDefaultLane ? (
          <Dropdown item pointing="left" text="Move lane">
            <Dropdown.Menu>
              {lane.order > 0 ? <Dropdown.Item content="Move left" onClick={() => moveLane(-1)} /> : null}
              {lane.order < lanes.length - 1 ? (
                <Dropdown.Item content="Move right" onClick={() => moveLane(1)} />
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
        {ideasOnLane?.length ? (
          <Dropdown item pointing="left" text="Sort by...">
            <Dropdown.Menu>
              {LaneSortOptions.map((l, i) => (
                <>
                  {i !== 0 ? <Dropdown.Divider /> : null}
                  <Dropdown.Item
                    key={l.value}
                    content={`${l.text} (descending)`}
                    onClick={() => reorderLaneIdeas(l.value, "desc")}
                  />
                  <Dropdown.Item
                    key={`${l.value}-asc`}
                    content={`${l.text} (ascending)`}
                    onClick={() => reorderLaneIdeas(l.value, "asc")}
                  />
                </>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        ) : null}

        {!isDefaultLane ? (
          <Popup
            trigger={
              <Dropdown item pointing="left" text="Change lane type">
                <Dropdown.Menu>
                  {LaneTypeOptions.filter((l) => l.value !== currentLaneMetaType).map((l) => (
                    <Dropdown.Item key={l.key} content={l.text} onClick={() => updateLane({ metaType: l.value })} />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            }
            content={`${t("common:capitalise", { key: "generic.ideas" })} in typed lanes do not send notifications to assignees when the ${t("generic.idea")} has not been updated in a while`}
          />
        ) : null}

        {ideasOnLane?.length ? (
          <Dropdown item pointing="left" text={`Move all ${t("generic.ideas")} to other lane`}>
            <Dropdown.Menu>
              {lanes
                .filter((l) => l._id !== currentLaneId)
                .map((l) => (
                  <Dropdown.Item
                    key={l._id}
                    content={l.name}
                    onClick={() => updateIdeaPositionMulti(currentLaneId, l._id)}
                  />
                ))}
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
        {!isDefaultLane ? <Dropdown.Divider /> : null}
        <Dropdown.Item content="Edit lane name" onClick={() => editLaneName()} />
        <Dropdown.Item content="Edit lane description" onClick={() => editLaneDescription()} />
        {!isDefaultLane ? (
          <Dropdown.Item content="Delete lane" style={{ color: "#db2828" }} onClick={() => deleteLane()} />
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  );
};
