import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Modal, Button, Divider, Segment, Form, Dropdown, Search, Table, Checkbox } from "semantic-ui-react";
import toast from "react-hot-toast";
import api from "api";
import { useAppSelector } from "store";
import util from "utils/utils";
import AnnouncementBase from "./Base";
import { StyledModal } from "../StyledModal";
import { useTranslation } from "react-i18next";
import { UserChip } from "components/lib/Chips";
import { OpenAPI } from "simplydo/interfaces";
import { UserChooser } from "components/lib/Choosers";
import styled from "styled-components";

import {
  availableConditions,
  conditionChecks,
  getDefaultCondition,
  renderValueCheck,
} from "components/ideas/Body/Custom/fieldCondition";

const SearchResults = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    display: block;
    font-weight: bold;
  }
  img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 5px;
  }
`;

const AutomaticAnnouncementModal = ({
  deleteAutomaticAnnouncement,
  forType,
  forId,
  editingAutomaticAnnouncement,
  setExternalOpenEditor,
  setEditingAutomaticAnnouncement,
  selectedFromUser,
  handleCreatedAnnouncement,
  automaticConditions,
  setSelectedFromUser,
  setAutomaticAnnouncements,
  automaticAnnouncements,
  subconditionOptions,
}) => {
  const [sending, setSending] = useState(false);
  const [saving, setSaving] = useState(false);
  const [fromUserValue, setFromUserValue] = useState("");
  const [fromUsersState, setFromUsersState] = useState({
    users: [],
    loading: false,
  });
  const [activeAccordionIndexes, setActiveAccordionIndexes] = useState([]);
  const user = useAppSelector((state) => state.user);
  const orgId = user?.organisation?._id;
  const userIsAdmin = util.hasPermission(user, "org.viewDashboard", user?.organisation?._id);
  const { t } = useTranslation();

  const toggleActiveAccordion = useCallback((index: number) => {
    setActiveAccordionIndexes((prevState) => {
      if (prevState.includes(index)) {
        return prevState.filter((i) => i !== index);
      } else {
        return [...prevState, index];
      }
    });
  }, []);

  const createAutomatic = useCallback(() => {
    setSending(true);
    const {
      sendWhen,
      sendWhenConditions,
      subject,
      body,
      text,
      replyTo,
      callToActionText,
      callToActionLink,
      fullScreen,
      customRecipientsEnabled,
      calendarEvent,
      attachments,
    } = editingAutomaticAnnouncement;
    api.announcements.create(
      forType,
      forId,
      {
        type: "automatic",
        attachments,
        sendWhen,
        sendWhenConditions,
        subject,
        body,
        text,
        callToActionText,
        callToActionLink,
        fromUser: selectedFromUser?._id,
        replyTo,
        fullScreen,
        customRecipients: editingAutomaticAnnouncement?.customRecipients,
        customRecipientsEnabled,
        calendarEvent,
      },
      (announcement) => {
        handleCreatedAnnouncement({ ...announcement, ownerFromUser: selectedFromUser });
        setSending(false);
        setEditingAutomaticAnnouncement({});
        setExternalOpenEditor(null);
      },
      (err) => {
        toast.error(err.message);
        setSending(false);
      },
    );
  }, [
    forType,
    forId,
    editingAutomaticAnnouncement,
    selectedFromUser,
    handleCreatedAnnouncement,
    setExternalOpenEditor,
    setEditingAutomaticAnnouncement,
  ]);

  const getSubconditions = useCallback(
    (condition) => automaticConditions.find((sc) => sc.value === condition)?.subConditions,
    [automaticConditions],
  );

  const saveAutomaticEditing = useCallback(() => {
    setSaving(true);
    api.announcements.update(
      forType,
      forId,
      editingAutomaticAnnouncement._id,
      {
        recipientConditions: editingAutomaticAnnouncement.recipientConditions,
        fromUser: selectedFromUser?._id || null,
        subject: editingAutomaticAnnouncement.subject,
        body: editingAutomaticAnnouncement.body,
        text: editingAutomaticAnnouncement.text,
        sendWhen: editingAutomaticAnnouncement.sendWhen,
        sendWhenConditions: editingAutomaticAnnouncement.sendWhenConditions,
        replyTo: editingAutomaticAnnouncement.replyTo,
        callToActionText: editingAutomaticAnnouncement.callToActionText,
        callToActionLink: editingAutomaticAnnouncement.callToActionLink,
        fullScreen: editingAutomaticAnnouncement.fullScreen,
        customRecipients: editingAutomaticAnnouncement?.customRecipients,
        customRecipientsEnabled: editingAutomaticAnnouncement?.customRecipientsEnabled,
        calendarEvent: editingAutomaticAnnouncement?.calendarEvent,
        attachments: editingAutomaticAnnouncement?.attachments,
      },
      (announcement) => {
        const updatedAutomaticAnnouncements = Object.assign([], automaticAnnouncements).map((a) => {
          if (a._id === announcement._id) return { ...announcement, ownerFromUser: selectedFromUser };
          return a;
        });
        setAutomaticAnnouncements(updatedAutomaticAnnouncements);
        setSaving(false);
        setEditingAutomaticAnnouncement({});
        setExternalOpenEditor(null);
        toast.success(t("generic.saved", { type: "Announcement" }));
      },
      (err) => {
        toast.error(err.message);
        setSaving(false);
      },
    );
  }, [
    t,
    forType,
    forId,
    selectedFromUser,
    editingAutomaticAnnouncement,
    automaticAnnouncements,
    setAutomaticAnnouncements,
    setExternalOpenEditor,
    setEditingAutomaticAnnouncement,
  ]);

  const fromUserResults = useMemo(
    () =>
      fromUsersState.users.map((u) => ({
        id: u._id,
        title: u.profile.fullName,
        image: util.avatarUrl(u),
        user: u,
      })),
    [fromUsersState.users],
  );

  const getFromUsers = useCallback(() => {
    if (fromUserValue) {
      setFromUsersState((prevState) => ({ ...prevState, loading: true }));
      if (util.hasPermission(user, "org.listUsers", orgId)) {
        api.organisations.getUsers(
          orgId,
          { page: 1, view: "all", query: fromUserValue },
          (data) => {
            setFromUsersState((prevState) => ({
              ...prevState,
              users: data.users,
              loading: false,
            }));
          },
          () => {
            setFromUsersState((prevState) => ({ ...prevState, loading: false }));
          },
        );
      } else {
        api.organisations.getAdmins(
          orgId,
          { limit: 10, permissions: ["org.viewDashboard"] },
          ({ users }) => {
            setFromUsersState((prevState) => ({
              ...prevState,
              users,
              loading: false,
            }));
          },
          () => {
            setFromUsersState((prevState) => ({ ...prevState, loading: false }));
          },
        );
      }
    } else {
      setFromUsersState((prevState) => ({ ...prevState, loading: false }));
    }
  }, [orgId, fromUserValue, user]);

  useEffect(() => getFromUsers(), [getFromUsers]);

  const FromUserSelector = useMemo(() => {
    if (userIsAdmin) {
      return (
        <>
          <Divider hidden />
          <h5 style={{ marginBottom: 5 }}>From user</h5>
          <p>
            When your automatic announcement is sent to users, it will appear as a direct message. By default, that
            direct message will come from your account. If you want to change this, please search for a user below.
          </p>

          <Search
            style={{ marginTop: 5 }}
            placeholder="Search for a user.."
            loading={fromUsersState.loading}
            onSearchChange={(e, { value }) => setFromUserValue(value)}
            value={fromUserValue}
            results={fromUserResults}
            onResultSelect={(e, { result }) => setSelectedFromUser(result.user)}
            resultRenderer={(result) => (
              <SearchResults>
                <img src={result.image} alt="User avatar" />
                <span>{result.title}</span>
              </SearchResults>
            )}
          />
          {selectedFromUser ? (
            <div style={{ display: "flex", alignItems: "center", marginTop: 20 }}>
              <UserChip target="_blank" user={selectedFromUser} />
              <Button
                size="mini"
                style={{ marginLeft: 60 }}
                icon="trash"
                basic
                onClick={() => setSelectedFromUser(null)}
              />
            </div>
          ) : null}
        </>
      );
    }
    return null;
  }, [userIsAdmin, fromUsersState, fromUserValue, selectedFromUser, fromUserResults, setSelectedFromUser]);

  const customRecipientselector = useMemo(() => {
    if (userIsAdmin) {
      return (
        <>
          <Divider hidden />
          <h5 style={{ marginBottom: 5 }}>To user</h5>
          <p>
            When your announcement is triggered the announcement will be sent to the user that triggered the
            announcement. For example, if a an announcement is sent on submission of {t("generic.ideaWithArticle")}, the
            user that submitted the {t("generic.idea")} will be contacted. If you want to change this, please search for
            recipients below.
          </p>
          <Dropdown
            text={
              !editingAutomaticAnnouncement?.customRecipientsEnabled
                ? "User that triggered the condition"
                : "Select custom users"
            }
            className="selection"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() =>
                  setEditingAutomaticAnnouncement((prevState) => ({ ...prevState, customRecipientsEnabled: false }))
                }
              >
                User that triggered the condition.
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>
                  setEditingAutomaticAnnouncement((prevState) => ({ ...prevState, customRecipientsEnabled: true }))
                }
              >
                Select custom users:
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {editingAutomaticAnnouncement?.customRecipientsEnabled && (
            <>
              <UserChooser
                selectedUsers={editingAutomaticAnnouncement.customRecipientOwners || []}
                trigger={<Button style={{ marginTop: 5, marginLeft: 10 }} icon="plus" content="Add custom recipient" />}
                // @ts-ignore
                enabledFeatures={{ search: true }}
                searchFunction={(query, success, failure) => {
                  if (!query) {
                    success([]);
                    return;
                  }
                  api.search.omni(
                    {
                      query,
                      include: ["users"],
                    },
                    ({ users }) => users && success(users),
                    failure,
                  );
                }}
                onComplete={(users: OpenAPI.Schemas["User"][]) => {
                  if (users.length === 0) {
                    return;
                  }
                  setEditingAutomaticAnnouncement((prevState) => ({
                    ...prevState,
                    customRecipientOwners: users,
                    customRecipients: users.map((u) => u._id),
                  }));
                }}
              />
              {editingAutomaticAnnouncement?.customRecipientOwners?.length !== 0 ? (
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>User</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {editingAutomaticAnnouncement?.customRecipientOwners?.map((recipient) => (
                      <Table.Row key={recipient._id}>
                        <Table.Cell>
                          <UserChip target="_blank" user={recipient} />
                        </Table.Cell>
                        <Table.Cell collapsing>
                          <Button
                            icon="trash"
                            onClick={() =>
                              setEditingAutomaticAnnouncement((prevState) => ({
                                ...prevState,
                                customRecipients: prevState.customRecipients.filter((id) => id !== recipient._id),
                                customRecipientOwners: prevState.customRecipientOwners.filter(
                                  (u) => u._id !== recipient._id,
                                ),
                              }))
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              ) : null}
            </>
          )}
        </>
      );
    }
    return null;
  }, [userIsAdmin, editingAutomaticAnnouncement, setEditingAutomaticAnnouncement]);

  const fullScreenSelector = useMemo(() => {
    return (
      <>
        <Divider hidden />
        <h5 style={{ marginBottom: 5 }}>Full-screen announcement</h5>
        <p>
          If enabled, SimplyDo will also show this announcement in "full-screen mode" for the recipient, increasing the
          chance that they read it at the point of delivery.
        </p>
        <Checkbox
          toggle
          label="Enable full screen announcement"
          checked={editingAutomaticAnnouncement.fullScreen}
          onChange={(e, { checked }) =>
            setEditingAutomaticAnnouncement((prevState) => ({ ...prevState, fullScreen: checked }))
          }
        />
      </>
    );
  }, [editingAutomaticAnnouncement, setEditingAutomaticAnnouncement]);

  return (
    <>
      <StyledModal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={!util.isEmpty(editingAutomaticAnnouncement)}
        onClose={() => setEditingAutomaticAnnouncement({})}
      >
        <Modal.Header>
          {editingAutomaticAnnouncement._id ? (
            t("announcements.automatic.edit.title")
          ) : (
            <>
              {t("announcements.automatic.new.title")}
              <br />
              <small>{t("announcements.manual.sendInfo")}</small>
            </>
          )}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <>
              {editingAutomaticAnnouncement.countSent ? (
                <p>This announcement has been sent {editingAutomaticAnnouncement.countSent || 0} times.</p>
              ) : null}
              <Segment>
                <h3>Automation rule</h3>
                Please choose which condition must be met to send this announcement.
                <Divider hidden />
                <Form>
                  <Form.Select
                    style={{ display: "block" }}
                    label={t("announcements.automatic.list.sendWhen")}
                    placeholder="Select an event trigger"
                    required
                    options={automaticConditions.map((c) => ({ key: c.value, text: t(c.text), value: c.value }))}
                    value={editingAutomaticAnnouncement.sendWhen}
                    onChange={(e, { value }) => {
                      setEditingAutomaticAnnouncement((prevState) => ({ ...prevState, sendWhen: value as string }));
                    }}
                  />
                </Form>
                {getSubconditions(editingAutomaticAnnouncement.sendWhen) === "ideaFields" ? (
                  <>
                    <p>
                      Only send this announcement if{" "}
                      <strong>
                        <Dropdown
                          inline
                          value={editingAutomaticAnnouncement.sendWhenConditions?.conditionType ?? "any"}
                          options={[
                            { key: "any", text: "any of", value: "any" },
                            { key: "all", text: "all of", value: "all" },
                          ]}
                          onChange={(event, { value }) => {
                            setEditingAutomaticAnnouncement((prevState) => ({
                              ...prevState,
                              sendWhenConditions: {
                                ...(prevState.sendWhenConditions || {}),
                                conditionType: value as "any" | "all",
                              },
                            }));
                          }}
                        />
                      </strong>{" "}
                      the following conditions are met:
                    </p>
                    {editingAutomaticAnnouncement.sendWhenConditions?.conditions?.map((condition, i) => (
                      <Form.Group widths="equal" key={`${i}-${condition.field}-${condition.condition}`}>
                        <Form.Field>
                          {i === 0 ? <label>1. Field with name</label> : null}
                          <Form.Dropdown
                            placholder="Select a field"
                            clearable
                            selection
                            value={condition.field}
                            options={subconditionOptions.ideaFields
                              ?.filter((f) => f.type in conditionChecks)
                              ?.map((f) => ({
                                key: f.id,
                                description: f.section,
                                text: f.title,
                                value: f.id,
                              }))}
                            onChange={(e, { value }) => {
                              setEditingAutomaticAnnouncement((prevState) => ({
                                ...prevState,
                                sendWhenConditions: {
                                  ...(prevState.sendWhenConditions || {}),
                                  conditions: prevState.sendWhenConditions?.conditions?.map((c, j) => {
                                    if (i === j) {
                                      return {
                                        ...c,
                                        field: value as string,
                                        condition: getDefaultCondition(
                                          subconditionOptions.ideaFields?.find((f) => f.id === value)?.type,
                                        ),
                                        value: null,
                                      };
                                    }
                                    return c;
                                  }),
                                },
                              }));
                            }}
                          />
                        </Form.Field>
                        <Form.Field>
                          {i === 0 ? <label>2. Meets condition</label> : null}
                          <Form.Dropdown
                            placholder="Select a condition"
                            selection
                            value={condition.condition}
                            options={
                              availableConditions[
                                subconditionOptions.ideaFields?.find(
                                  (chosenField) => chosenField.id === condition.field,
                                )?.type
                              ]
                            }
                            onChange={(e, { value }) => {
                              setEditingAutomaticAnnouncement((prevState) => ({
                                ...prevState,
                                sendWhenConditions: {
                                  ...(prevState.sendWhenConditions || {}),
                                  conditions: prevState.sendWhenConditions?.conditions?.map((c, j) => {
                                    if (i === j) {
                                      return {
                                        ...c,
                                        condition: value as
                                          | "isEmpty"
                                          | "isNotEmpty"
                                          | "equalTo"
                                          | "includes"
                                          | "doesNotInclude",
                                        value: null,
                                      };
                                    }
                                    return c;
                                  }),
                                },
                              }));
                            }}
                          />
                        </Form.Field>
                        <Form.Field>
                          {i === 0 ? <label>&nbsp;</label> : null}
                          {renderValueCheck(
                            condition,
                            subconditionOptions.ideaFields?.find((chosenField) => chosenField.id === condition.field),
                            (value) => {
                              setEditingAutomaticAnnouncement((prevState) => ({
                                ...prevState,
                                sendWhenConditions: {
                                  ...(prevState.sendWhenConditions || {}),
                                  conditions: prevState.sendWhenConditions?.conditions?.map((c, j) => {
                                    if (i === j) {
                                      return {
                                        ...c,
                                        value,
                                      };
                                    }
                                    return c;
                                  }),
                                },
                              }));
                            },
                          )}
                        </Form.Field>
                        <Button
                          style={{ marginTop: i === 0 ? 25 : 0 }}
                          icon="trash"
                          onClick={() =>
                            setEditingAutomaticAnnouncement((prevState) => ({
                              ...prevState,
                              sendWhenConditions: {
                                ...(prevState.sendWhenConditions || {}),
                                conditions: prevState.sendWhenConditions?.conditions?.filter((c, j) => j !== i),
                              },
                            }))
                          }
                        />
                      </Form.Group>
                    ))}
                    <Form.Group>
                      <Form.Button
                        onClick={() => {
                          setEditingAutomaticAnnouncement((prevState) => ({
                            ...prevState,
                            sendWhenConditions: {
                              ...(prevState.sendWhenConditions || {}),
                              conditions: [
                                ...(prevState.sendWhenConditions?.conditions ?? []),
                                {
                                  field: null,
                                  condition: "isNotEmpty",
                                  value: null,
                                },
                              ],
                            },
                          }));
                        }}
                      >
                        Add a condition
                      </Form.Button>
                    </Form.Group>
                  </>
                ) : null}
              </Segment>
            </>
            <AnnouncementBase
              forType={forType}
              activeAccordionIndexes={activeAccordionIndexes}
              toggleActiveAccordion={toggleActiveAccordion}
              forId={forId}
              announcement={editingAutomaticAnnouncement}
              selectedFromUser={selectedFromUser}
              setAnnouncement={setEditingAutomaticAnnouncement}
              settings={
                <>
                  {FromUserSelector}
                  {customRecipientselector}
                  {fullScreenSelector}
                </>
              }
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button content={t("generic.cancel")} onClick={() => setEditingAutomaticAnnouncement({})} />
          {editingAutomaticAnnouncement._id ? (
            <Button
              secondary
              icon="save"
              content={t("generic.saveChanges")}
              onClick={saveAutomaticEditing}
              loading={saving}
            />
          ) : (
            <Button
              secondary
              icon="plus"
              content={t("generic.create")}
              onClick={createAutomatic}
              loading={sending}
              disabled={!editingAutomaticAnnouncement.sendWhen}
            />
          )}
        </Modal.Actions>
        {editingAutomaticAnnouncement._id ? (
          <Modal.Actions>
            <h4>{t("announcements.delete.title")}</h4>
            <Button
              onClick={() =>
                deleteAutomaticAnnouncement(editingAutomaticAnnouncement._id, () => {
                  setEditingAutomaticAnnouncement({});
                  setExternalOpenEditor(null);
                })
              }
              content={t("generic.delete")}
              icon="trash"
              basic
              size="small"
            />
          </Modal.Actions>
        ) : null}
      </StyledModal>
    </>
  );
};

export default AutomaticAnnouncementModal;
