import React, { useCallback } from "react";
import { Card, Button, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { UserChip } from "components/lib/Chips";
import actions from "actions";
import util from "utils/utils";
import { useAppDispatch } from "store";
import { InviteCardProps } from "./types";
import InviteeChip from "./InviteeChip";

const GroupInviteCard = ({ invitation, acceptInvite, rejectInvite, removeInvite }: InviteCardProps) => {
  const dispatch = useAppDispatch();
  const { inviterUser, group, createdByCurrentUser } = invitation;

  const onGroupJoined = useCallback(
    (group) => {
      dispatch(actions.user.joinGroup(group));
    },
    [dispatch],
  );

  const accept = useCallback(() => {
    util
      .confirm("Join group", `Are you sure you want to join "${group.name}"?`)
      .then(() => {
        acceptInvite(
          invitation,
          () => {
            onGroupJoined(invitation.group);
            toast.success("Joined group");
          },
          () => toast.error("Error joining group"),
        );
      })
      .catch(() => {});
  }, [acceptInvite, invitation, onGroupJoined, group?.name]);

  const reject = useCallback(() => {
    util
      .confirm(
        "Reject group invitation",
        `Are you sure you want to reject the invitation to join "${group?.name || "Deleted group"}"?`,
      )
      .then(() => {
        rejectInvite(
          invitation,
          () => {
            toast("Invitation rejected");
          },
          () => toast.error("Error rejecting invite"),
        );
      })
      .catch(() => {});
  }, [rejectInvite, invitation, group?.name]);

  const remove = useCallback(() => {
    util
      .confirm(
        "Remove group invitation",
        `Are you sure you want to remove this invitation to join "${group?.name || "Deleted group"}"?`,
      )
      .then(() => {
        removeInvite(
          invitation,
          () => {
            toast("Invitation removed");
          },
          () => toast.error("Error removing invite"),
        );
      })
      .catch(() => {});
  }, [removeInvite, invitation, group?.name]);

  if (!group) {
    invitation.canAccept = false;
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <div>
        <Header
          style={{ marginBottom: "0.3em" }}
          as={Link}
          disabled={!group}
          to={group ? `/groups/${group._id}` : null}
        >
          {group?.name || "Deleted group"}
        </Header>
        <Card.Description>
          {createdByCurrentUser ? (
            <InviteeChip invitation={invitation} preText="You invited" postText="to join this group" />
          ) : (
            <UserChip user={inviterUser} postText="has invited you to join this group" />
          )}
        </Card.Description>
      </div>
      <Card.Content extra>
        <div className="ui">
          {invitation.acceptedAt ? (
            <Button basic color="green" as={Link} disabled={!group} to={`/groups/${group?._id}`}>
              Go to group
            </Button>
          ) : (
            <>
              {invitation.canAccept ? (
                <Button basic color="green" onClick={accept}>
                  Accept
                </Button>
              ) : null}
              {invitation.canRemove ? (
                <Button
                  basic
                  color={createdByCurrentUser ? null : "red"}
                  onClick={createdByCurrentUser ? remove : reject}
                >
                  {createdByCurrentUser ? "Remove" : "Reject"}
                </Button>
              ) : null}
            </>
          )}
        </div>
      </Card.Content>
    </div>
  );
};

export default GroupInviteCard;
