import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import api from "api";
import moment from "moment";

class ProfileSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessions: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    api.users.getSessions(
      this.props.profileUser._id,
      ({ sessions }) => {
        this.setState({ sessions, loading: false });
      },
      () => this.setState({ loading: false }),
    );
  }

  render() {
    const { sessions } = this.state;
    return (
      <div>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Date</Table.HeaderCell>
              <Table.HeaderCell>Platform</Table.HeaderCell>
              <Table.HeaderCell>OS</Table.HeaderCell>
              <Table.HeaderCell>Device</Table.HeaderCell>
              <Table.HeaderCell>Client Version</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sessions.map((session) => (
              <Table.Row key={session._id}>
                <Table.Cell>{moment(session.createdAt).format("DD/MM/YY HH:mm")}</Table.Cell>
                <Table.Cell>{session.data.platform || "N/A"}</Table.Cell>
                <Table.Cell>
                  {session.data.os} {session.data.os_version || "N/A"}
                </Table.Cell>
                <Table.Cell>{session.data.device}</Table.Cell>
                <Table.Cell>{session.data.clientVersion}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default ProfileSessions;
