import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Segment, Input, Grid, Popup, Card, Button, Message, Icon } from "semantic-ui-react";
import { HandledIdeaPreview } from "components/lib/Ideas/IdeaPreview";
import styled from "styled-components";
import ImageChooser from "components/lib/Choosers/ImageChooser";
import toast from "react-hot-toast";
import api from "api";
import useThrottle from "utils/useThrottle";
import util from "utils/utils";
import SuggestedIdeaCard from "components/lib/Cards/SuggestedIdeaCard";
import { useTranslation } from "react-i18next";

const BodyContainer = styled(Grid.Column)`
  border: none;
`;

const SuggestedHeader = styled.div`
  > h4,
  span {
    margin: 0;
    color: #363636;
  }
  margin-top: 20px;
`;

const StyledParagraph = styled.p`
  margin-top: 0px;
  margin-bottom: 15px;
  font-size: 13px;
  color: #2a2828;
  padding: 3px;
  padding-left: 6px;
  border-left: 4px solid #ffe4b5;
  background-color: #fffad1;
  display: block;
`;

const RemoveCoverImage = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const ChangeCoverImage = styled.div``;

const IdeaCoverImage = styled.div`
  position: relative;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
  height: 250px;
  background-size: cover;
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-position: center;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    box-shadow: inset 0 0 0 2000px rgba(104, 104, 104, 0.3);
  }
  ${ChangeCoverImage} {
    opacity: ${({ theme }) => (!theme.sizes.isMobile ? 0 : 1)};
  }
  &:hover {
    ${ChangeCoverImage} {
      opacity: 1;
    }
  }
`;

const NameInput = React.forwardRef(
  ({ ideaName, updateName, isDefaultName, setIdeaNameFocused, handleKeyDown }, ref) => {
    const [defName, setDefName] = useState(isDefaultName ? "" : ideaName);
    useEffect(() => {
      setDefName(isDefaultName ? "" : ideaName);
    }, [isDefaultName, ideaName]);
    const { t } = useTranslation();

    return (
      <Input
        size="large"
        placeholder={`Name your ${t("generic.idea")}...`}
        fluid
        value={defName}
        onChange={(e) => {
          setDefName(e.target.value);
          if (ideaName !== e.target.value) {
            updateName(e.target.value);
          }
        }}
        onFocus={() => setIdeaNameFocused(true)}
        onBlur={() => setIdeaNameFocused(false)}
        ref={ref}
        onKeyDown={handleKeyDown}
      />
    );
  },
);
NameInput.displayName = "NameInput";

const IdeaNameField = (props) => {
  const [searchResults, setSearchResults] = useState([]);
  const [previewId, setPreviewId] = useState("");
  const [ideaNameFocused, setIdeaNameFocused] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const { t } = useTranslation();

  const {
    isEditing,
    ideaCoverImage,
    ideaId,
    ideaName: propIdeaName,
    challengeName,
    updateIdea,
    nameRef,
    handleKeyDown,
  } = props;
  const filteredResults = searchResults.filter((r) => r._id !== ideaId);

  const isDefaultCoverImage = ideaCoverImage === "https://cdn.simplydo.co.uk/images/idea.png";

  const [defaultIdeaName, setDefaultIdeaName] = useState("");
  const isDefaultName = propIdeaName === defaultIdeaName;
  const [ideaName, setIdeaName] = useState(isDefaultName ? "" : propIdeaName);

  useEffect(() => {
    setDefaultIdeaName(`${t("common:capitalise", { key: "generic.idea" })} for ${challengeName}`);
  }, [challengeName, t]);

  // Ensures name is correct if changed externally e.g. on submission
  useEffect(() => {
    setIdeaName(isDefaultName ? defaultIdeaName : propIdeaName);
  }, [defaultIdeaName, isDefaultName, propIdeaName]);

  const updateName = useCallback(
    (value) => {
      if (value.length === 0) {
        value = defaultIdeaName;
      }
      updateIdea(value, ["name"]);
    },
    [updateIdea, defaultIdeaName],
  );

  const throttledSearch = useThrottle((ideaId, term) => {
    if (!term) return;
    api.search.omni(
      { query: term, include: ["ideas"] },
      ({ ideas }) => {
        setSearchResults(ideas.filter((r) => r._id !== ideaId));
      },
      () => {},
    );
  }, 1000);

  const searchIdeas = useCallback(() => {
    throttledSearch(ideaId, ideaName);
  }, [ideaId, ideaName, throttledSearch]);

  useEffect(() => {
    if (!isDefaultName && ideaName?.length > 2) {
      searchIdeas(ideaName);
    } else {
      setSearchResults([]);
    }
  }, [ideaName, isDefaultName, searchIdeas]);

  const setCoverImage = useCallback(
    (filename, _name, url) => {
      updateIdea(filename, ["coverImage"]);
      updateIdea(url, ["coverImageUrl"], true);
      if (!url) {
        toast.success("Cover image removed.");
        updateIdea("https://cdn.simplydo.co.uk/images/idea.png", ["coverImageUrl"], true);
      }
    },
    [updateIdea],
  );

  useEffect(() => {
    if (filteredResults.length && ideaNameFocused) {
      setPopupOpen(true);
    }
    if (ideaNameFocused && !filteredResults.length) {
      setPopupOpen(false);
    }
  }, [filteredResults, ideaNameFocused]);

  const ChangeCoverImageButton = useMemo(
    () => (
      <div style={isDefaultCoverImage ? { marginTop: 10 } : {}}>
        <ImageChooser
          forType="idea"
          forId={ideaId}
          onComplete={setCoverImage}
          trigger={
            <Button icon="file image" primary fluid={isDefaultCoverImage}>
              <Popup
                content={`Your cover image will appear above your ${t("generic.idea")} as a banner along with the ${t("generic.idea")}'s name. It will also display as a thumbnail for the ${t("generic.idea")} when viewed in the ${t("generic.idea")} list.`}
                trigger={<p>{isDefaultCoverImage ? "Choose a cover image" : "Change the cover image"}</p>}
                position="top center"
                wide
              />
            </Button>
          }
        />
      </div>
    ),
    [isDefaultCoverImage, setCoverImage, ideaId, t],
  );

  if (!isEditing) return null;
  const sorted = filteredResults.sort((a, b) => b?.completeness || 0 - a?.completeness || 0);
  return (
    <BodyContainer style={{ borderRadius: 5, marginBottom: 25 }} computer={16}>
      <HandledIdeaPreview ideaId={previewId} closeModal={() => setPreviewId()} />
      <Segment style={{ borderRadius: 5, borderColor: "#3daaaea8" }}>
        <div style={{ marginBottom: 5 }}>
          <h3>{t("common:capitalise", { key: "generic.idea" })} name</h3>
        </div>
        <StyledParagraph>
          <Icon name="info circle" />
          Name your {t("generic.idea")} based on the problem it solves or the solution it provides.
        </StyledParagraph>
        <NameInput
          isDefaultName={isDefaultName}
          ideaName={ideaName}
          updateName={updateName}
          setIdeaNameFocused={setIdeaNameFocused}
          ref={nameRef}
          handleKeyDown={handleKeyDown}
        />
        {filteredResults.length > 0 && popupOpen && (
          <Message>
            <SuggestedHeader>
              <h4>Related {t("generic.ideas")}</h4>
              <span>Here are some similar {t("generic.ideas")} we found based on the name you entered...</span>
            </SuggestedHeader>
            <Card.Group style={{ marginBottom: 10, marginTop: 5 }} itemsPerRow={3} stackable>
              {sorted.slice(0, 3).map((result) => (
                <SuggestedIdeaCard onHeaderClicked={() => setPreviewId(result._id)} key={result._id} idea={result} />
              ))}
            </Card.Group>
          </Message>
        )}
        <h5>Cover image</h5>
        {isDefaultCoverImage ? (
          ChangeCoverImageButton
        ) : (
          <IdeaCoverImage $imageUrl={util.mixinCssUrlFallback(ideaCoverImage, util.ideaCoverImage())}>
            {!isDefaultCoverImage ? (
              <RemoveCoverImage>
                <Button
                  icon="trash"
                  size="small"
                  content="Remove cover image"
                  onClick={() =>
                    util
                      .confirm(
                        "Remove cover image",
                        `Are you sure you want to remove the cover image for this ${t("generic.idea")}?`,
                      )
                      .then(() => setCoverImage(null, null, null))
                  }
                />
              </RemoveCoverImage>
            ) : null}
            <ChangeCoverImage>{ChangeCoverImageButton}</ChangeCoverImage>
          </IdeaCoverImage>
        )}
      </Segment>
    </BodyContainer>
  );
};

export default IdeaNameField;
