import React from "react";
import { Label, Form, Checkbox } from "semantic-ui-react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const AssessmentContainer = styled.div`
  padding: 15px;
  background-color: #fff;
  ${({ $floating }) =>
    $floating
      ? `
    border-radius: 5px;
  `
      : `
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  `}

  border: 1.5px solid #6435c9;
  margin: 0 -1px;
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
  ${({ $noShadow }) => !$noShadow && "box-shadow:0px 10px 10px rgba(0,0,0,0.1);"}
  ${({ $isPDF }) =>
    $isPDF &&
    `
    margin: 20px;
  `}
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    display: block;
    margin: ${({ $noMargin }) => ($noMargin ? "0" : "0 0 0")};
  }
  > span {
    display: block;
    color: gray;
    text-decoration-line: underline;
    font-size: 12px;
  }
  margin-bottom: 10px;
`;

export const IdeaAssessmentConflictOfInterestBox = ({
  idea,
  assessment,
  updateAssessment,
  noShadow,
  style,
  className,
  isPDF,
}) => {
  const isSubmitted = assessment?.isSubmitted;
  const { conflictOfInterest = {} } = assessment || {};
  const { hasConflictOfInterest, notes } = conflictOfInterest;

  const { t } = useTranslation();

  if (idea?.isAssessor) {
    return (
      <AssessmentContainer $noShadow={noShadow} style={style} className={className} $floating $isPDF={isPDF}>
        <TitleContainer $noMargin>
          <h3>Conflict of interest</h3>
          {isSubmitted && (
            <Label size="small" color="green">
              Submitted
            </Label>
          )}
        </TitleContainer>
        <p>
          If you have a conflict of interest that would prevent you from objectively assessing this {t("generic.idea")}{" "}
          please declare it now. If you declare a conflict of interest you can submit the assessment without completing
          it. The managers of this {t("generic.challenge")} will be notified.
        </p>
        <Checkbox
          checked={hasConflictOfInterest}
          label="I would like to declare a conflict of interest for this assessment"
          onChange={() => updateAssessment(!hasConflictOfInterest, ["conflictOfInterest", "hasConflictOfInterest"])}
          disabled={isSubmitted}
        />
        {hasConflictOfInterest ? (
          <Form style={{ marginTop: 10 }}>
            <Form.TextArea
              placeholder="Please provide any further context or information.."
              disabled={isSubmitted}
              value={notes}
              onChange={(e, { value }) => updateAssessment(value, ["conflictOfInterest", "notes"])}
            />
          </Form>
        ) : null}
      </AssessmentContainer>
    );
  }
  return null;
};
