type Init = ConstructorParameters<typeof URLSearchParams>;

class SearchParams extends URLSearchParams {

  constructor(params: Init[0] | SearchParams | Record<string, string | string[] | number>) {
    if (Array.isArray(params) || typeof params === "string" || params instanceof URLSearchParams || params instanceof SearchParams) {
      super(params);
      return;
    }
    super();

    if (params === null || params === undefined) {
      return;
    }
    for (const [key, val] of Object.entries(params)) {
      if (val === null || val === undefined || val === "") {
        continue;
      } else if (Array.isArray(val)) {
        val.forEach((v) => this.append(key, v));
      } else {
        this.append(key, val.toString());
      }
    }
  };

  toSafeString(): string {
    const constructed = super.toString();
    return constructed ? `?${constructed}` : "";
  }
};

export default SearchParams;
