import React, { useState } from "react";

import BoardCreator from "components/lib/ProjectBoard/BoardCreator";

const NewProjectBoard = () => {
  const [creatorOpen, setCreatorOpen] = useState(true);

  return (
    <div>
      <BoardCreator isOpen={creatorOpen} onClose={() => setCreatorOpen(false)} />
    </div>
  );
};

export default NewProjectBoard;
