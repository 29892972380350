import React from "react";
import { Button, Form, Card, Icon, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import util from "utils/utils";

import RichText from "components/lib/Editors/RichText";
import RichTextViewer from "components/lib/Editors/RichTextViewer";
import { MultiLineParagraph } from "components/lib/UI";
import FieldSeparator from "../FieldSeparator";
import FieldHeader from "../FieldHeader";

export const overviewComponents = [
  {
    key: "staffWoman",
    icon: "👩‍💻",
    name: "Staff",
    suggestions: "admin, finance...",
  },
  {
    key: "staffMan",
    icon: "👨‍💻",
    name: "Staff",
    suggestions: "admin, finance...",
  },
  {
    key: "office",
    icon: "🏢",
    name: "Office",
    suggestions: "offices, workplace...",
  },
  {
    key: "shop",
    icon: "🏪",
    name: "Shop",
    suggestions: "shop, restaurant...",
  },
  {
    key: "factory",
    icon: "🏭",
    name: "Factory",
    suggestions: "factory, warehouse...",
  },
  {
    key: "utilities",
    icon: "🔌",
    name: "Utilities",
    suggestions: "electricity, water, gas...",
  },
  {
    key: "training",
    icon: "🎓",
    name: "Training",
    suggestions: "teaching, training...",
  },
  {
    key: "insurance",
    icon: "📄",
    name: "Insurance",
    suggestions: "business insurance...",
  },
  {
    key: "accountant",
    icon: "👨‍💼",
    name: "Accountant",
    suggestions: "accountant, finance firm...",
  },
  {
    key: "solicitor",
    icon: "👩‍💼",
    name: "Solicitor",
    suggestions: "lawyer, legal professional...",
  },
  {
    key: "rawMaterials",
    icon: "🌳",
    name: "Raw materials",
    suggestions: "wood, rock...",
  },
  {
    key: "otherMaterials",
    icon: "🎨",
    name: "Other materials",
    suggestions: "fabric, paint...",
  },
  {
    key: "website",
    icon: "🖥",
    name: "Website",
    suggestions: "website, blog...",
  },
  {
    key: "socialMedia",
    icon: "📱",
    name: "Social media",
    suggestions: "𝕏, Facebook...",
  },
  {
    key: "advertising",
    icon: "📰",
    name: "Advertising",
    suggestions: "flyers, magazines...",
  },
  {
    key: "equipment",
    icon: "📷",
    name: "Equipment",
    suggestions: "electronics, machines...",
  },
  {
    key: "media",
    icon: "🎬",
    name: "Media",
    suggestions: "videos, photos...",
  },
  {
    key: "communications",
    icon: "📞",
    name: "Communications",
    suggestions: "phone, internet...",
  },
  {
    key: "officeSupplies",
    icon: "📨",
    name: "Office supplies",
    suggestions: "stationery, postage...",
  },
  {
    key: "smallVehicles",
    icon: "🚗",
    name: "Small vehicles",
    suggestions: "cars, vans...",
  },
  {
    key: "largeVehicles",
    icon: "🚚",
    name: "Larger vehicles",
    suggestions: "trucks, lorries...",
  },
  {
    key: "peopleTransport",
    icon: "🚐",
    name: "People transport",
    suggestions: "bus, minibus...",
  },
  {
    key: "refreshments",
    icon: "🍔",
    name: "Refreshments",
    suggestions: "meals, drinks...",
  },
  {
    key: "extras",
    icon: "❓",
    name: "Extras",
    suggestions: "Anything else",
  },
];

const OverviewDragBench = styled.div`
  text-align: center;
  padding: 20px;
  background: ${({ $includeSection }) => util.adjustColour($includeSection.colour, 0.9)};
  border-radius: 4px;
  box-shadow: inset 0px 10px 10px rgba(0, 0, 0, 0.05);
`;
const OverviewDragItem = styled.div`
  display: inline-block;
  font-size: 40px;
  margin: 20px 10px;
  cursor: grab;
  opacity: 0.6;
  transition: opacity 0.5s;
  &:hover {
    opacity: 1;
  }
`;
const OverviewDragField = styled.div`
  margin-top: 20px;
  text-align: center;
  padding: 20px;
  border-radius: 4px;
  transition:
    background-color 0.5s,
    border 0.5s;
  border: 6px solid ${({ $isDragging, $includeSection }) => ($isDragging ? "green" : $includeSection.colour)};
  background-color: ${({ $isDragOver, $includeSection }) => ($isDragOver ? "green" : $includeSection.colour)};
`;
const OverviewItem = styled.div`
  word-break: break-word;
  h1 {
    font-size: 40px;
    margin-bottom: 5px;
  }
`;

class Contents extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  addOverviewComponent = (key) => {
    const overview = Object.assign([], this.props.contentsOverview);
    overview.push({ key });
    this.props.updateIdea(overview, ["contentsOverview"]);
  };

  updateComponent = (index, key, value) => {
    const overview = Object.assign([], this.props.contentsOverview);
    overview[index][key] = value;
    this.props.updateIdea(overview, ["contentsOverview"]);
  };

  deleteComponent = (index) => {
    const overview = Object.assign([], this.props.contentsOverview);
    overview.splice(index, 1);
    this.props.updateIdea(overview, ["contentsOverview"]);
  };

  getComponentMetadata = (component) => overviewComponents.filter((c) => c.key === component.key)[0];

  onDragStart = (event, key, icon) => {
    event.dataTransfer.setData("key", key);
    const canvas = document.createElement("canvas");
    canvas.width = 80;
    canvas.height = 80;
    const context = canvas.getContext("2d");
    context.font = "40px arial";
    context.fillText(icon, 20, 40);
    event.dataTransfer.setDragImage(canvas, 40, 40);
    this.setState({ dragging: true });
  };

  onDragOverDropZone = (event) => event.preventDefault();

  onDragEnterDropZone = () => this.setState({ draggingOverDropZone: true });

  onDragLeaveDropZone = () => {
    this.setState({ draggingOverDropZone: false });
  };

  onDrop = (event) => {
    event.preventDefault();
    this.addOverviewComponent(event.dataTransfer.getData("key"));
    this.setState({ draggingOverDropZone: false, dragging: false });
  };

  render() {
    const {
      contentsWho,
      contentsWhat,
      contentsWhere,
      contentsAdditionalInfo,
      contentsOverview,
      ideaId,
      isEditing,
      updateIdea,
      includeSection,
      t,
      externalChanges,
    } = this.props;

    return (
      <div>
        {isEditing ? (
          <div>
            <FieldHeader
              externalChange={externalChanges.contentsOverview}
              title={t("ideas.6Cs.sections.contents.title")}
              isEditing={isEditing}
              description={t("ideas.6Cs.sections.contents.info")}
            />
            <OverviewDragBench $includeSection={includeSection}>
              {overviewComponents.map((component) => (
                <OverviewDragItem
                  key={component.icon}
                  data-tooltip={`${t(`ideas.6Cs.sections.contents.components.${component.key}.name`)} ${t(`ideas.6Cs.sections.contents.components.${component.key}.suggestions`)}`}
                  draggable
                  onDragStart={(e) => this.onDragStart(e, component.key, component.icon)}
                  onClick={() => this.addOverviewComponent(component.key)}
                >
                  {component.icon}
                </OverviewDragItem>
              ))}
            </OverviewDragBench>
            <OverviewDragField
              $includeSection={includeSection}
              $isDragging={this.state.dragging}
              $isDragOver={this.state.draggingOverDropZone}
            >
              <Card.Group itemsPerRow={3} stackable>
                {contentsOverview && contentsOverview.length
                  ? contentsOverview.map((item, i) => (
                      <Card key={i}>
                        <Card.Content>
                          {this.getComponentMetadata(item) && (
                            <Card.Meta>
                              {this.getComponentMetadata(item).icon} {this.getComponentMetadata(item).name}
                            </Card.Meta>
                          )}
                        </Card.Content>
                        <Card.Content textAlign="left">
                          <Form>
                            <Form.Input
                              autoFocus
                              label={t("ideas.6Cs.sections.contents.form.title")}
                              size="mini"
                              value={item.title || ""}
                              onChange={(e) => this.updateComponent(i, "title", e.target.value)}
                            />
                            <Form.TextArea
                              label={t("ideas.6Cs.sections.contents.form.description")}
                              size="mini"
                              style={{ fontSize: "11px" }}
                              value={item.description || ""}
                              onChange={(e) => this.updateComponent(i, "description", e.target.value)}
                            />
                          </Form>
                        </Card.Content>
                        <Card.Content extra textAlign="right">
                          <Button size="mini" basic onClick={() => this.deleteComponent(i)}>
                            <Icon name="trash" /> {t("generic.remove")}
                          </Button>
                        </Card.Content>
                      </Card>
                    ))
                  : null}
              </Card.Group>
            </OverviewDragField>
          </div>
        ) : (
          <Grid stackable centered>
            {contentsOverview &&
              contentsOverview.map((item, i) => (
                <Grid.Column computer={4} tablet={5} key={i} textAlign="center">
                  <OverviewItem>
                    <h1>{this.getComponentMetadata(item)?.icon}</h1>
                    <h5
                      style={{
                        marginTop: 0,
                        marginBottom: 0,
                        marginLeft: 5,
                        marginRight: 5,
                      }}
                    >
                      {(item.title || "").toUpperCase()}
                    </h5>
                    <MultiLineParagraph content={item.description} />
                  </OverviewItem>
                </Grid.Column>
              ))}
          </Grid>
        )}

        <FieldSeparator $includeSection={includeSection} />

        {isEditing && <h3>{t("ideas.6Cs.sections.contents.detail.title")}</h3>}

        <Grid stackable centered>
          {(isEditing || contentsWho) && (
            <Grid.Column computer={isEditing ? 16 : 8} textAlign={isEditing ? "left" : "center"}>
              <FieldHeader
                externalChange={externalChanges.contentsWho}
                title={t("ideas.6Cs.sections.contents.detail.who.title")}
                isEditing={isEditing}
                description={t("ideas.6Cs.sections.contents.detail.who.info")}
              />
              {isEditing ? (
                <div>
                  <RichText
                    value={contentsWho || ""}
                    onChange={(val) => updateIdea(val, ["contentsWho"])}
                    forType="idea"
                    forId={ideaId}
                  />
                </div>
              ) : (
                <RichTextViewer content={contentsWho} forType="idea" forId={ideaId} />
              )}
            </Grid.Column>
          )}

          {(isEditing || contentsWhat) && (
            <Grid.Column computer={isEditing ? 16 : 8} textAlign={isEditing ? "left" : "center"}>
              <FieldHeader
                externalChange={externalChanges.contentsWhat}
                title={t("ideas.6Cs.sections.contents.detail.what.title")}
                isEditing={isEditing}
                description={t("ideas.6Cs.sections.contents.detail.what.info")}
              />
              {isEditing ? (
                <div>
                  <RichText
                    value={contentsWhat || ""}
                    onChange={(val) => updateIdea(val, ["contentsWhat"])}
                    forType="idea"
                    forId={ideaId}
                  />
                </div>
              ) : (
                <RichTextViewer content={contentsWhat} />
              )}
            </Grid.Column>
          )}

          {(isEditing || contentsWhere) && (
            <Grid.Column computer={isEditing ? 16 : 8} textAlign={isEditing ? "left" : "center"}>
              <FieldHeader
                externalChange={externalChanges.contentsWhere}
                title={t("ideas.6Cs.sections.contents.detail.where.title")}
                isEditing={isEditing}
                description={t("ideas.6Cs.sections.contents.detail.where.info")}
              />
              {isEditing ? (
                <div>
                  <RichText
                    value={contentsWhere || ""}
                    onChange={(val) => updateIdea(val, ["contentsWhere"])}
                    forType="idea"
                    forId={ideaId}
                  />
                </div>
              ) : (
                <RichTextViewer content={contentsWhere} />
              )}
            </Grid.Column>
          )}
        </Grid>

        <FieldSeparator $includeSection={includeSection} />

        {(isEditing || contentsAdditionalInfo) && (
          <Grid stackable centered>
            <Grid.Column computer={isEditing ? 16 : 10} textAlign={isEditing ? "left" : "center"}>
              <FieldHeader
                externalChange={externalChanges.contentsAdditionalInfo}
                title={t("ideas.6Cs.sections.contents.detail.extra.title")}
                isEditing={isEditing}
                description={t("ideas.6Cs.sections.contents.detail.extra.info")}
              />
              {isEditing ? (
                <div>
                  <RichText
                    value={contentsAdditionalInfo || ""}
                    onChange={(val) => updateIdea(val, ["contentsAdditionalInfo"])}
                    forType="idea"
                    forId={ideaId}
                  />
                </div>
              ) : (
                <RichTextViewer content={contentsAdditionalInfo} />
              )}
            </Grid.Column>
          </Grid>
        )}
      </div>
    );
  }
}

export default withTranslation()(Contents);
