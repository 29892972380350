import React, { useMemo } from "react";
import { Label, Popup } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ $noMargin }) => (!$noMargin ? "5px" : "0px")};
  border-radius: 5px;
  &&& > * {
    margin: ${({ $noMargin }) => (!$noMargin ? "5px" : "0px")};
  }
`;

const StyledDisabledSimpleTag = styled(Label)`
  color: rgba(16, 16, 16, 0.3) !important;
  background-color: rgba(239, 239, 239, 0.3) !important;
  cursor: default;
`;

const SimpleTag = ({
  // eslint-disable-next-line no-unused-vars
  staticContext,
  tag,
  onRemove,
  showCount,
  onClick,
  showDataEditor,
  noRedirect,
  ...props
}) => {
  const navigate = useNavigate();

  const LabelComponent = useMemo(
    () =>
      tag ? (
        <Label
          key={tag._id}
          size={showDataEditor ? "tiny" : "medium"}
          pointing="left"
          as={!onClick && noRedirect ? null : "a"}
          onClick={() => {
            if (onClick) {
              onClick(tag);
            }
            if (!noRedirect) {
              navigate(`/tags/${tag._id}`);
            }
          }}
          onRemove={
            onRemove
              ? (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onRemove(tag);
                }
              : null
          }
          content={
            showCount && "totalCount" in tag
              ? `${tag.value} (${tag.totalCount})`
              : tag.value +
                (tag.type === "financial" ? ` in ${tag?.typeData?.financialValue === "percentage" ? "%" : "£"}` : "")
          }
          style={showDataEditor ? { height: 29, fontSize: "0.8em", marginLeft: 2 } : { marginLeft: 2 }}
          {...props}
        />
      ) : null,
    [tag, navigate, onRemove, showCount, onClick, noRedirect, props, showDataEditor],
  );
  return LabelComponent;
};

const DisabledLabelComponent = ({ tag, showCount, showDataEditor, noRedirect: _removed, ...props }) => {
  const LabelComponent = useMemo(
    () =>
      tag ? (
        <StyledDisabledSimpleTag
          key={tag._id}
          size={showDataEditor ? "tiny" : "medium"}
          pointing="left"
          content={
            showCount && "totalCount" in tag
              ? `${tag.value} (${tag.totalCount})`
              : tag.value +
                (tag.type === "financial" ? ` in ${tag?.typeData?.financialValue === "percentage" ? "%" : "£"}` : "")
          }
          style={showDataEditor ? { height: 29, fontSize: "0.8em", marginLeft: 2 } : { marginLeft: 2 }}
          {...props}
        />
      ) : null,
    [tag, showCount, props, showDataEditor],
  );
  return LabelComponent;
};

export const Tag = ({ tag, noPopup, taggingDisabled, ...props }) => {
  const sharedProps = { tag };
  if (taggingDisabled) {
    return <DisabledLabelComponent {...sharedProps} {...props} />;
  }

  if (!tag.description || noPopup) {
    return <SimpleTag {...sharedProps} {...props} />;
  }
  return (
    <Popup
      trigger={
        <div>
          <SimpleTag {...sharedProps} {...props} />
        </div>
      }
      on="hover"
    >
      <Popup.Content>
        <h5>About this tag</h5>
        {tag.description}
      </Popup.Content>
    </Popup>
  );
};

export default Tag;
