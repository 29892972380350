import React, { useEffect, useCallback, useState } from "react";
import { Divider, Loader } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";
import api from "api";
import util from "utils/utils";

import AnnouncementLogs from "./Logs";
import AnnouncementTemplates from "./Templates";
import { AutomaticAnnouncementsManager, StandardAnnouncementsManager } from "./Announcements";
import { OpenAPI } from "simplydo/interfaces";

type ChallengeTemplate = OpenAPI.GET<"/challenges/{id}/template">["response"];

export const challengeStandardConditions = [
  { value: "following", text: "challenge.settings.announcements.recipients.conditions.following" },
  { value: "started", text: "challenge.settings.announcements.recipients.conditions.started" },
  { value: "submitted", text: "challenge.settings.announcements.recipients.conditions.submitted" },
  { value: "notSubmitted", text: "challenge.settings.announcements.recipients.conditions.notSubmitted" },
  { value: "pinned", text: "challenge.settings.announcements.recipients.conditions.pinned" },
  { value: "notPinned", text: "challenge.settings.announcements.recipients.conditions.notPinned" },
  { value: "stamp", text: "challenge.settings.announcements.recipients.conditions.hasStamp", subConditions: "stamps" },
  {
    value: "notStamp",
    text: "challenge.settings.announcements.recipients.conditions.notHasStamp",
    subConditions: "stamps",
  },
  { value: "audience", text: "challenge.settings.announcements.recipients.conditions.audience" },
  {
    value: "lane",
    text: "challenge.settings.announcements.recipients.conditions.lane",
    subConditions: "lanes",
    getOptions: (challengeId, callback) => {
      api.boards.getProjectLanes(
        challengeId,
        ({ lanes, defaultLane }) => {
          if (defaultLane) {
            callback([defaultLane, ...lanes]);
          } else {
            callback(lanes);
          }
        },
        () => {},
      );
    },
  },
  { value: "projectAssigned", text: "challenge.settings.announcements.recipients.conditions.projectAssigned" },
  {
    value: "projectAssignedNonCompleted",
    text: "challenge.settings.announcements.recipients.conditions.projectAssignedNonCompleted",
  },
  { value: "assessor", text: "challenge.settings.announcements.recipients.conditions.assessor" },
];

export const challengeAutomaticConditions = [
  { value: "createIdea", text: "challenge.settings.announcements.recipients.automatic.starts" },
  { value: "submitIdea", text: "challenge.settings.announcements.recipients.automatic.submits" },
  {
    value: "submittedAllAssessmentsForChallenge",
    text: "challenge.settings.announcements.recipients.automatic.assessmentsSubmitted",
  },
  {
    value: "submitIdeaWithConditions",
    text: "challenge.settings.announcements.recipients.automatic.ideaFieldCondition",
    subConditions: "ideaFields",
    getOptions: (challengeId, callback) => {
      api.challenges.getTemplate(
        challengeId,
        (template: ChallengeTemplate) => {
          const fields = template.body.flatMap((section) =>
            section.fields.map((field) => ({
              ...field,
              section: `in ${section.name}`,
            })),
          );
          callback(fields);
        },
        () => {},
      );
    },
  },
];

const AnnouncementManager = ({
  forType,
  forId,
  t,
  getAnnouncements,
  automaticConditions = [],
  standardConditions = [],
}) => {
  const [automaticAnnouncements, setAutomaticAnnouncements] = useState([]);
  const [scheduledAnnouncements, setScheduledAnnouncements] = useState([]);
  const [deliveredScheduledAnnouncements, setDeliveredScheduledAnnouncements] = useState([]);
  const [templatesAvailable, setTemplatesAvailable] = useState(false);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [editingAutomaticAnnouncement, setEditingAutomaticAnnouncement] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(false);
  const [nextPageAvailable, setNextPageAvailable] = useState(false);
  const [prevPageAvailable, setPrevPageAvailable] = useState(false);

  const fetchAnnouncements = useCallback(() => {
    setLoading(true);
    api.announcements.get(
      forType,
      forId,
      page,
      (data) => {
        setAutomaticAnnouncements(data.automaticAnnouncements);
        setScheduledAnnouncements(data.scheduledAnnouncements);
        setDeliveredScheduledAnnouncements(data.deliveredAnnouncements);
        setNextPageAvailable(data.nextPageAvailable);
        setPrevPageAvailable(data.prevPageAvailable);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        toast.error(err.message);
      },
    );
  }, [forType, forId, page]);

  useEffect(() => fetchAnnouncements(), [fetchAnnouncements]);

  const handleCreatedAnnouncement = useCallback(
    (announcement, openEditor = false) => {
      toast.success(t("announcements.created"));
      setAutomaticAnnouncements((prev) => [...prev, announcement]);
      setEditingAutomaticAnnouncement(openEditor ? announcement : null);
      if (getAnnouncements) getAnnouncements();
    },
    [t, getAnnouncements],
  );

  const deleteAnnouncement = useCallback(
    (announcementId, cb) => {
      return util
        .confirm(t("announcements.delete.title"), t("announcements.delete.info"))
        .then(() => {
          setLoading(true);
          api.announcements.delete(
            forType,
            forId,
            announcementId,
            () => {
              toast(t("generic.deleted", { type: "Announcement" }));
              setAutomaticAnnouncements((prev) => prev.filter((a) => a._id !== announcementId));
              setScheduledAnnouncements((prev) => prev.filter((a) => a._id !== announcementId));
              setLoading(false);
              if (getAnnouncements) getAnnouncements();
              if (cb) cb();
            },
            (err) => {
              toast.error(err.message);
              setLoading(false);
            },
          );
        })
        .catch(() => {});
    },
    [forType, forId, getAnnouncements, t],
  );

  const timeDifference = new Date().getTimezoneOffset() / 60 / -1;
  const isNegativeTime = timeDifference < 0;
  return (
    <React.Fragment>
      <h3>{t("announcements.manual.title")}</h3>
      <p>{t("announcements.manual.info")}</p>
      <p>
        We use the
        <b> UTC timezone </b>
        for deadlines. You are currently
        <b>{` ${timeDifference} hours ${isNegativeTime ? "behind" : "ahead"} `}</b>
        of
        <b> UTC</b>.
      </p>
      {loading ? <Loader active inline="centered" /> : null}

      <AnnouncementTemplates
        forType={forType}
        forId={forId}
        setTemplatesAvailable={setTemplatesAvailable}
        templateModalOpen={templateModalOpen}
        setTemplateModalOpen={setTemplateModalOpen}
        automaticAnnouncements={automaticAnnouncements}
        automaticConditions={automaticConditions}
        handleCreatedAnnouncement={handleCreatedAnnouncement}
      />

      <StandardAnnouncementsManager
        forType={forType}
        forId={forId}
        standardConditions={standardConditions}
        scheduledAnnouncements={scheduledAnnouncements}
        setScheduledAnnouncements={setScheduledAnnouncements}
        deliveredScheduledAnnouncements={deliveredScheduledAnnouncements}
        setPage={setPage}
        deleteAnnouncement={deleteAnnouncement}
        nextPageAvailable={nextPageAvailable}
        prevPageAvailable={prevPageAvailable}
      />

      <Divider hidden />

      <AutomaticAnnouncementsManager
        forType={forType}
        forId={forId}
        automaticAnnouncements={automaticAnnouncements}
        setAutomaticAnnouncements={setAutomaticAnnouncements}
        automaticConditions={automaticConditions}
        templatesAvailable={templatesAvailable}
        setTemplateModalOpen={(toOpen) => setTemplateModalOpen(toOpen)}
        handleCreatedAnnouncement={handleCreatedAnnouncement}
        externalOpenEditor={editingAutomaticAnnouncement}
        setExternalOpenEditor={(toOpen) => setEditingAutomaticAnnouncement(toOpen)}
        deleteAnnouncement={deleteAnnouncement}
      />

      <Divider hidden />

      <AnnouncementLogs
        forType={forType}
        forId={forId}
        standardConditions={standardConditions}
        automaticConditions={automaticConditions}
      />
    </React.Fragment>
  );
};

export default withTranslation()(AnnouncementManager);
