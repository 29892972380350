import React from "react";
import { Container, Divider, Grid, Button, Form, TextArea, Checkbox, Table, Icon, Label } from "semantic-ui-react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import util from "utils/utils";
import constants from "utils/constants";
import { useAppSelector } from "store";

import { ImageChooser, VideoChooser } from "components/lib/Choosers";
import Video from "components/lib/Video/Video";
import SettingActions from "components/lib/SettingActions";

const AvatarSources = {
  microsoft: "Microsoft",
};

const StyledAvatar = styled.div`
  background-size: cover;
  background-position: center center;
  background-image: url(${({ $image }) => $image});
  height: 200px;
  width: 200px;
  display: block;
  margin: 10px auto 40px auto;
  border: 5px solid white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
`;

const StyledDefaultAvatar = styled.div`
  display: inline-block;
  img {
    cursor: pointer;
    opacity: 0.8;
    transition: 0.3s opacity;
    width: 40px;
    margin-right: 10px;
    margin-bottom: 5px;
    border-radius: 50%;
    box-shadow: 2px 2px 4px rgba(50, 50, 50, 0.4);
    &:hover {
      opacity: 1;
    }
  }
`;

const StyledCover = styled.div`
  height: 200px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-image: url(${({ $image }) => $image});
  margin: 10px auto 40px auto;
  border: 5px solid white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
`;

const UserSettingsProfile = ({ profileUser, updateProfile, toggleTag, saveProfile, saving }) => {
  const user = useAppSelector((state) => state.user);
  const { t } = useTranslation();

  const removeUpload = (i) => {
    const uploads = Object.assign([], profileUser.profile.uploads);
    uploads.splice(i, 1);
    updateProfile("uploads", uploads);
  };

  if (!profileUser.profile) return null;
  return (
    <Container>
      <h3>{t("users.edit.name.title")}</h3>
      <Form>
        <Form.Group>
          <Form.Input
            disabled={user?.ssoUser}
            label={t("users.edit.name.firstName")}
            value={profileUser.profile.firstName}
            placeholder={t("users.edit.name.firstName")}
            onChange={(e) => updateProfile("firstName", e.target.value)}
          />
          <Form.Input
            disabled={user?.ssoUser}
            label={t("users.edit.name.lastName")}
            value={profileUser.profile.lastName}
            placeholder={t("users.edit.name.lastName")}
            onChange={(e) => updateProfile("lastName", e.target.value)}
          />
        </Form.Group>
      </Form>
      <Divider section />

      <h3>{t("users.edit.avatar.title")}</h3>
      <p>
        {profileUser.profile.avatarWasSetByExternalSource ? (
          <>
            Your avatar was automatically set from your{" "}
            <b>{AvatarSources[profileUser.profile.avatarWasSetByExternalSource]}</b> account.
          </>
        ) : (
          t("users.edit.avatar.info")
        )}
      </p>
      <div>
        <StyledAvatar $image={util.mixinCssUrlFallback(util.avatarUrl(profileUser), util.avatarUrl())} />
        {!profileUser.profile.avatarWasSetByExternalSource ? (
          <>
            <ImageChooser
              forType="user"
              forId={profileUser._id}
              trigger={<Button secondary content={t("users.edit.avatar.chooseImage")} icon="image" />}
              onComplete={(f, n, u) => {
                updateProfile("avatar", f);
                updateProfile("avatarUrl", u);
                updateProfile("avatarThumbnailUrls", {});
              }}
            />
            <p style={{ marginTop: 15 }}>{t("users.edit.avatar.default")}</p>
            <div>
              {constants.avatars.map((avatar, i) => (
                <StyledDefaultAvatar
                  key={i}
                  onClick={() => {
                    updateProfile("avatar", avatar);
                    updateProfile("avatarUrl", `https://cdn.simplydo.co.uk/images/avatars/${avatar}`);
                    updateProfile("avatarThumbnailUrls", {});
                  }}
                >
                  <img alt="Default avatar" src={`https://cdn.simplydo.co.uk/images/avatars/${avatar}`} />
                </StyledDefaultAvatar>
              ))}
            </div>
          </>
        ) : null}
      </div>
      <Divider />

      <h3>{t("users.edit.coverImage.title")}</h3>
      <p>{t("users.edit.coverImage.choose")}</p>
      <div>
        {profileUser.profile.coverImageUrl && (
          <StyledCover $image={util.getThumbnail(profileUser.profile, "coverImage", "300x300")} />
        )}
        <ImageChooser
          forType="user"
          forId={profileUser._id}
          trigger={<Button secondary content={t("users.edit.coverImage.select")} icon="image" />}
          onComplete={(f, n, u) => {
            updateProfile("coverImage", f);
            updateProfile("coverImageUrl", u);
            updateProfile("coverImageThumbnailUrls", {});
          }}
        />
        {profileUser.profile.coverImageUrl && (
          <Button
            basic
            icon="trash"
            size="small"
            onClick={() => {
              updateProfile("coverImage", null);
              updateProfile("coverImageUrl", null);
              updateProfile("coverImageThumbnailUrls", null);
            }}
            style={{ marginLeft: 10 }}
          />
        )}
      </div>

      <Divider section />
      <h3>{t("users.edit.video.title")}</h3>
      <p>{t("users.edit.video.info")}</p>
      {profileUser.profile.videoUrl && <Video src={profileUser.profile.videoUrl} />}
      <div style={{ marginTop: 20 }} />
      <div style={{ display: "flex" }}>
        <VideoChooser
          forType="user"
          forId={profileUser._id}
          onComplete={(v, n, u) => {
            updateProfile("video", v);
            updateProfile("videoUrl", u);
          }}
          onError={toast.error}
          trigger={<Button secondary size="small" content={t("users.edit.video.chooseVideo")} icon="video" />}
        />
        {profileUser.profile.video ? (
          <Button
            basic
            icon="trash"
            size="small"
            onClick={() => {
              updateProfile("video", "");
              updateProfile("videoUrl", null);
            }}
            style={{ marginLeft: 10 }}
          />
        ) : null}
      </div>

      <Divider />
      <h3>{t("generic.overview")}</h3>
      <p>{t("users.edit.overview.info")}</p>
      <p>
        <b>{t("users.edit.overview.tagline.title")}</b>
      </p>

      <Form>
        <Form.Input
          value={profileUser.profile.tagline}
          placeholder={t("users.edit.overview.tagline.placeholder")}
          onChange={(e) => updateProfile("tagline", e.target.value)}
        />
      </Form>
      <Divider hidden />
      <p>
        <b>{t("users.edit.overview.professionalProfile.title")}</b>
      </p>
      <Form>
        <TextArea
          value={profileUser.profile.overview}
          style={{ minHeight: 200 }}
          onChange={(e) => updateProfile("overview", e.target.value)}
        />
      </Form>

      <Divider />
      <Grid>
        <Grid.Column width={8}>
          <h3>{t("users.edit.overview.professionalPortfolio.title")}</h3>
          <Form>
            <Form.Field>
              <Form.Input
                icon="linkedin"
                iconPosition="left"
                value={profileUser.profile.linkedin}
                placeholder={t("users.edit.overview.professionalPortfolio.linkedIn")}
                onChange={(e) => updateProfile("linkedin", e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                icon="github"
                iconPosition="left"
                value={profileUser.profile.github}
                placeholder={t("users.edit.overview.professionalPortfolio.github")}
                onChange={(e) => updateProfile("github", e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                icon="instagram"
                iconPosition="left"
                value={profileUser.profile.instagram}
                placeholder={t("users.edit.overview.professionalPortfolio.instagram")}
                onChange={(e) => updateProfile("instagram", e.target.value)}
              />
            </Form.Field>
          </Form>
        </Grid.Column>
        <Grid.Column width={8}>
          <h3>{t("users.edit.overview.social.title")}</h3>
          <Form>
            <Form.Field>
              <Form.Input
                icon="mouse pointer"
                iconPosition="left"
                value={profileUser.profile.website}
                placeholder="https://www.example.com"
                onChange={(e) => updateProfile("website", e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                labelPosition="left"
                value={profileUser.profile.twitter}
                placeholder={t("users.edit.overview.social.twitter")}
                onChange={(e) => updateProfile("twitter", e.target.value)}
              >
                <Label basic style={{ borderRight: "0px", paddingLeft: 14, color: "gray" }}>
                  𝕏
                </Label>
                <input style={{ paddingLeft: 2 }} />
              </Form.Input>
            </Form.Field>
            <Form.Field>
              <Form.Input
                icon="facebook"
                iconPosition="left"
                value={profileUser.profile.facebook}
                placeholder={t("users.edit.overview.social.facebook")}
                onChange={(e) => updateProfile("facebook", e.target.value)}
              />
            </Form.Field>
          </Form>
        </Grid.Column>
      </Grid>
      <span
        style={{
          display: "block",
          opacity: 0.6,
          fontStyle: "italic",
          marginTop: 10,
          fontSize: "0.85em",
        }}
      >
        For online accounts, please only include your account id, not the entire URL. For example, rather than{" "}
        <b>"https://facebook.com/brianmcbrain"</b>, just use <b>"brianmcbrain"</b>.
      </span>

      {profileUser.profile.uploads && profileUser.profile.uploads.length > 0 && (
        <React.Fragment>
          <Divider hidden />
          <h3>{t("users.edit.files.uploads")}</h3>
          <Table style={{ marginTop: 0 }} fixed basic="very">
            <Table.Body>
              {profileUser.profile.uploads.map((a, i) => (
                <Table.Row key={a.storedName}>
                  <Table.Cell>
                    <Icon name={util.getFileIcon(a.storedName)} /> {a.title}
                  </Table.Cell>
                  <Table.Cell>
                    <a target="_blank" rel="noreferrer noopener" href={a.url}>
                      {a.name}
                    </a>
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Button size="small" basic icon="trash" onClick={() => removeUpload(i)} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </React.Fragment>
      )}

      {user.organisation.tagList?.length > 0 && (
        <>
          <Divider section />
          <h3>{t("users.edit.overview.professionalInterests.title")}</h3>
          <p>{t("users.edit.overview.professionalInterests.info")}</p>
          <Grid>
            {user.organisation.tagList.map((tag) => (
              <Grid.Column computer={5} mobile={8} key={tag.value}>
                <Checkbox
                  toggle
                  label={tag.value}
                  checked={(profileUser.tags || []).indexOf(tag._id) > -1}
                  onChange={(e, c) => toggleTag(tag, c.checked)}
                />
              </Grid.Column>
            ))}
          </Grid>
        </>
      )}

      <Divider section hidden />
      <SettingActions>
        <Button primary icon="check" content={t("generic.saveChanges")} onClick={saveProfile} loading={saving} />
      </SettingActions>
    </Container>
  );
};

export default UserSettingsProfile;
