import React, { Component } from "react";
import { Grid, Button, Message, Checkbox } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import actions from "actions";
import api from "api";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";

class TagInterestsChooser extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  toggleTag = (tag) => {
    const { user, onUpdateTags } = this.props;
    const { fullTags = [], tags = [] } = user;
    const isTagged = tags.indexOf(tag._id) !== -1;

    if (isTagged) {
      api.tags.untagSingle("users", tag._id, user._id, (newTag) => {
        const updatedTags = Object.assign([], fullTags);
        updatedTags.splice(
          fullTags.findIndex((ft) => ft._id === newTag._id),
          1,
        );
        onUpdateTags(updatedTags);
      });
    } else {
      api.tags.tagSingle(
        "users",
        tag._id,
        user._id,
        (newTag) => {
          const updatedTags = Object.assign([], fullTags);
          updatedTags.push(newTag);
          onUpdateTags(updatedTags);
        },
        (err) => toast.error(err.message),
      );
    }
  };

  deselectAll = () => {
    const { user, onUpdateTags } = this.props;
    const tagIds = this.props.user.organisation.tagList.map((t) => t._id);
    api.tags.untagDocuments("users", tagIds, [user._id], () => {
      onUpdateTags([]);
    });
  };

  selectAll = () => {
    const { user, onUpdateTags } = this.props;
    const tagIds = this.props.user.organisation.tagList.map((t) => t._id);
    api.tags.tagDocuments("users", tagIds, [user._id], (fullTags) => {
      onUpdateTags(fullTags);
    });
  };

  render() {
    const { user = {}, t, setVisible } = this.props;
    const tags = user?.tags || [];
    const selected = user?.organisation?.tagList.length === tags?.length;
    return (
      <div>
        <Helmet title={t("interests.title")} />
        <Grid stackable centered style={{ marginTop: 40, marginBottom: 20 }}>
          <Grid.Column computer={10} tablet={14}>
            <h1>
              <span role="img" aria-label="Wave">
                👋
              </span>{" "}
              {t("interests.hi", { name: user?.profile?.firstName })}
            </h1>
            <h3>{t("interests.selectTags")}</h3>
            <p>{t("interests.interests")}</p>
            <Checkbox
              toggle
              style={{ float: "right" }}
              label={selected ? "Deselect all" : "Select All"}
              checked={selected}
              onChange={() => (selected ? this.deselectAll() : this.selectAll())}
            />
            <Grid stackable columns={2} style={{ marginTop: 20, marginBottom: 20, clear: "both" }}>
              {user?.organisation?.tagList
                ? user.organisation.tagList.map((c) => (
                    <Grid.Column>
                      <Checkbox
                        toggle
                        checked={tags.indexOf(c._id) > -1}
                        onChange={() => this.toggleTag(c)}
                        label={c.value}
                      />
                    </Grid.Column>
                  ))
                : null}
            </Grid>
            {tags?.length ? (
              <Button
                onClick={() => {
                  setVisible(false);
                  window.scrollTo(0, 0);
                }}
                primary
                content={t("generic.continue")}
              />
            ) : (
              <Message info>{t("interests.chooseMany")}</Message>
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  onUpdateTags: (tags) => dispatch(actions.user.updateTags(tags)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TagInterestsChooser));
