import React from "react";
import { Card, Icon, Label, Divider } from "semantic-ui-react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useTheme from "theme/useTheme";
import util from "utils/utils";
import moment from "moment";

import { UserChip, ProfileChip, AnonymousChip } from "components/lib/Chips";
import { ShadowedCard } from "components/lib/UI";
import { HotChallengeMeta } from "./HotChallengeCard";
import { ImageWithFallback } from "../ImageWithFallback";

const MiniIdeaCard = styled.div`
  padding: 2.5px 5px;
  display: flex;
  align-items: center;
  border-radius: 2.5px;
  background-color: #fff;
  margin: 2.5px 5px;
  cursor: pointer;
  &:last-of-type {
    margin-bottom: 10px;
  }
  &:hover {
    background-color: #e9ebee;
  }
  > img {
    height: 30px;
    width: 30px;
    border-radius: 2.5px;
    object-fit: cover;
    margin-right: 5px;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow: hidden;
    .title {
      font-weight: bold;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 0.9em;
    }
    > div {
      display: flex;
      align-items: center;
      > .status-label {
        height: 10px;
        width: 10px;
        border-radius: 5px;
        margin-right: 2.5px;
      }
      > span {
        display: block;
        color: gray;
        font-size: 0.9em;
      }
    }
  }
`;

const ChallengeCard = ({ challenge, isNew, ideas, user, showIdeas }) => {
  const userIdeas = (ideas || []).filter((i) => i.user === user._id);
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <ShadowedCard>
      <Link to={util.challengeLink(challenge)}>
        <div
          style={{
            width: "100%",
            height: "75px",
            overflow: "hidden",
            backgroundImage: `url(${util.mixinCssUrlFallback(util.getImageUrl(challenge, ["bannerImage", "coverImage"], ["640x100", "300x300"]), util.challengeImage())})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7,
          }}
        >
          {challenge.stage === "published" && (
            <Label
              size="small"
              color="green"
              style={{
                marginTop: 7,
                paddingLeft: 10,
                marginLeft: -14,
              }}
              ribbon="right"
            >
              Open
            </Label>
          )}
          {!challenge.stage && (
            <Label size="small" style={{ marginTop: 7, marginLeft: -14 }} color="yellow" ribbon="right">
              Draft
            </Label>
          )}
          {challenge.stage === "closed" && (
            <Label
              size="small"
              style={{
                marginTop: 7,
                paddingLeft: 10,
                color: "#fff",
                marginLeft: -14,
              }}
              color="red"
              ribbon="right"
            >
              Closed
            </Label>
          )}
          {challenge.stage === "published" && challenge.deadline && !isNew && (
            <div
              style={{ float: "right", marginTop: 35, marginRight: -2 }}
              className="ui tiny basic label information-label"
            >
              Closes {moment(challenge.deadline).fromNow()}
            </div>
          )}
          {isNew && (
            <Label style={{ float: "right", marginTop: 35 }} color="purple">
              <Icon name="stara" /> New
            </Label>
          )}
        </div>
      </Link>

      <Card.Content style={{ position: "relative" }}>
        {challenge?.coverImageUrl && challenge.bannerImageUrl ? (
          <>
            <div
              style={{
                width: 80,
                height: 80,
                overflow: "hidden",
                position: "absolute",
                marginTop: -80,
                boxShadow: "rgb(0 0 0 / 30%) 0.5px 2px 10px 4px",
                border: "2px solid white",
                backgroundImage: `url(${util.mixinCssUrlFallback(util.challengeImage(challenge), util.challengeImage())})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                borderRadius: 7,
              }}
            />
            <h3 style={{ marginBottom: challenge.tagline ? 5 : null, marginTop: 5 }}>
              <Link
                data-testid="challenge-card-name-link"
                to={util.challengeLink(challenge)}
                style={{ color: "#4d4949" }}
              >
                {challenge.name}
              </Link>
            </h3>
          </>
        ) : (
          <h3 style={{ display: "block", flex: 1, marginBottom: challenge.tagline || userIdeas?.length ? 5 : null }}>
            <Link to={util.challengeLink(challenge)} style={{ color: "#4d4949" }}>
              {challenge.name}
            </Link>
          </h3>
        )}

        {challenge.tagline ? <HotChallengeMeta>{challenge.tagline}</HotChallengeMeta> : null}
        {userIdeas.length && !showIdeas ? (
          <HotChallengeMeta icon="lightbulb">
            You have {util.pluralise(userIdeas.length, `${t("generic.idea")}`, `${t("generic.ideas")}`)}
          </HotChallengeMeta>
        ) : null}
        <div>
          {challenge.anonymous && <AnonymousChip />}
          {!challenge.anonymous &&
            challenge.postedBy &&
            (challenge.profile ? (
              <ProfileChip profile={challenge.postedBy} />
            ) : (
              <UserChip precedingText={"Posted by"} user={challenge.postedBy} />
            ))}
        </div>
      </Card.Content>

      {showIdeas && userIdeas.length ? (
        <div>
          <Divider style={{ margin: "0 10px 10px" }} />
          {userIdeas.map((idea) => (
            <MiniIdeaCard
              key={idea._id}
              color={idea.isSubmitted ? "green" : "beige"}
              onClick={() => navigate(`/ideas/${idea._id}`)}
            >
              <ImageWithFallback src={util.ideaCoverImage(idea)} fallbackSrc={util.ideaCoverImage()} alt="" />
              <div>
                <span className="title">{idea.name}</span>
                <div>
                  <div className="status-label" />
                  <span>
                    {idea.isSubmitted
                      ? `Submitted ${moment(idea.submittedAt).format("DD/MM")}`
                      : `Updated ${moment(idea.updatedAt).format("DD/MM")}`}
                  </span>
                </div>
              </div>
            </MiniIdeaCard>
          ))}
        </div>
      ) : null}

      <div
        className="content"
        style={{
          backgroundColor: theme.primaryColour,
          textAlign: "center",
          fontWeight: 600,
          maxHeight: 50,
        }}
      >
        <Link to={util.challengeLink(challenge)} style={{ color: "#fff", textTransform: "uppercase" }}>
          {t("generic.view", { entity: t("generic.challenge") })} <Icon name="arrow alternate circle right outline" />
        </Link>
      </div>
    </ShadowedCard>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, null)(ChallengeCard);
