import { useEffect } from "react";
import { useBlocker } from "react-router-dom";

const Prompt = ({ when, message }) => {
  const blocker = useBlocker(() => {
    return when;
  });

  if (blocker.state === "blocked") {
    const result = confirm(message);
    if (result) {
      blocker.proceed();
    } else {
      blocker.reset();
    }
  }

  useEffect(() => {
    if (when) {
      const listener = (e) => {
        e.preventDefault();
        e.returnValue = message;
      };
      window.addEventListener("beforeunload", listener);
      return () => {
        window.removeEventListener("beforeunload", listener);
      };
    }
  }, [when, message]);
  return null;
};

export default Prompt;
