import React from "react";
import { Button, Input, Label, Icon, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import RichText from "components/lib/Editors/RichText";
import RichTextViewer from "components/lib/Editors/RichTextViewer";
import { ImageChooser, VideoChooser } from "components/lib/Choosers";
import Video from "components/lib/Video/Video";
import FieldSeparator from "../FieldSeparator";
import FieldHeader from "../FieldHeader";

class Concept extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { newTag: "" };
  }

  addTag = () => {
    let { conceptKeywords } = this.props;
    if (!conceptKeywords) conceptKeywords = [];
    this.props.updateIdea(Object.assign([], [this.state.newTag, ...conceptKeywords]), ["conceptKeywords"]);
    this.setState({ newTag: "" });
  };

  removeTag = (index) => {
    let { conceptKeywords } = this.props;
    if (!conceptKeywords) conceptKeywords = [];
    conceptKeywords.splice(index, 1);
    this.props.updateIdea(
      conceptKeywords.filter((k, i) => index !== i),
      ["conceptKeywords"],
    );
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") this.addTag();
  };

  render() {
    const {
      ideaId,
      conceptVideo,
      conceptSupportingImage,
      conceptRoughIdea,
      conceptKeywords,
      isEditing,
      updateIdea,
      includeSection,
      t,
      externalChanges,
    } = this.props;

    return (
      <div>
        {isEditing ? (
          <div>
            <FieldHeader
              externalChange={externalChanges.conceptRoughIdea}
              title={t("ideas.6Cs.sections.concept.title")}
              isEditing={isEditing}
              description={t("ideas.6Cs.sections.concept.info")}
            />
            <RichText
              value={conceptRoughIdea || ""}
              onChange={(val) => updateIdea(val, ["conceptRoughIdea"])}
              forType="idea"
              forId={ideaId}
            />
          </div>
        ) : (
          <Grid stackable centered>
            <Grid.Column computer={10}>
              <RichTextViewer content={conceptRoughIdea} />
            </Grid.Column>
          </Grid>
        )}

        <FieldSeparator includeSection={includeSection} />

        {isEditing && (
          <div>
            <FieldHeader
              externalChange={externalChanges.conceptKeywords}
              title={t("ideas.6Cs.sections.concept.keywords.title")}
              isEditing={isEditing}
              description={t("ideas.6Cs.sections.concept.keywords.info")}
            />
            <Input
              icon="tags"
              iconPosition="left"
              label={{
                tag: true,
                content: t("ideas.6Cs.sections.concept.keywords.add"),
                onClick: this.addTag,
                style: { cursor: "pointer" },
                color: "yellow",
              }}
              labelPosition="right"
              placeholder={t("ideas.6Cs.sections.concept.keywords.placeholder")}
              value={this.state.newTag || ""}
              onKeyPress={this.handleKeyPress}
              onChange={(e) => this.setState({ newTag: e.target.value })}
            />
          </div>
        )}
        <div style={{ marginTop: "20px", textAlign: "center" }}>
          {conceptKeywords &&
            conceptKeywords.map((tag, i) => (
              <Label key={i} tag size="large" color="teal">
                {tag}
                {isEditing && <Icon name="delete" onClick={() => this.removeTag(i)} />}
              </Label>
            ))}
        </div>

        <FieldSeparator includeSection={includeSection} />

        {isEditing && (
          <div>
            <FieldHeader
              externalChange={externalChanges.conceptSupportingImage}
              title={t("ideas.6Cs.sections.concept.supportingImage.title")}
              isEditing={isEditing}
              description={t("ideas.6Cs.sections.concept.supportingImage.info")}
            />
            <ImageChooser
              forType="idea"
              forId={ideaId}
              trigger={
                <Button color="yellow" icon="image" content={t("ideas.6Cs.sections.concept.supportingImage.choose")} />
              }
              onComplete={(i, n, src) => updateIdea(src, ["conceptSupportingImage"])}
            />
          </div>
        )}
        {conceptSupportingImage && (
          <Grid stackable centered>
            <Grid.Column computer={8}>
              <img
                alt="Concept support"
                src={conceptSupportingImage}
                style={{
                  maxWidth: "100%",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  border: "5px solid white",
                  borderRadius: "5px",
                  marginTop: "20px",
                }}
              />
            </Grid.Column>
          </Grid>
        )}

        <FieldSeparator includeSection={includeSection} />

        {isEditing && (
          <div>
            <FieldHeader
              externalChange={externalChanges.conceptVideo}
              title={t("ideas.6Cs.sections.concept.video.title")}
              isEditing={isEditing}
              description={t("ideas.6Cs.sections.concept.video.info")}
            />
            <VideoChooser
              forType="idea"
              forId={ideaId}
              trigger={<Button color="yellow" icon="film" content={t("ideas.6Cs.sections.concept.video.choose")} />}
              onComplete={(v) => updateIdea(v, ["conceptVideo"])}
            />
          </div>
        )}
        {conceptVideo && (
          <Video src={conceptVideo} style={{ marginTop: "20px" }} forType="idea" forItem={{ _id: ideaId }} />
        )}
      </div>
    );
  }
}

export default withTranslation()(Concept);
