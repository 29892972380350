import React from "react";
import { Card, Icon, Label } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import useTheme from "theme/useTheme";
import util from "utils/utils";
import moment from "moment";

import { AnonymousChip } from "components/lib/Chips";
import { ShadowedCard } from "components/lib/UI";

const StyledHotChallengeMeta = styled.span`
  color: gray;
  margin-bottom: 5;
  display: block;
  margin: 2.5px 0;
  .i.icon {
    margin-right: 5px;
  }
`;

export const HotChallengeMeta = ({ icon: hotIcon, children }) => (
  <StyledHotChallengeMeta>
    {hotIcon && <Icon name={hotIcon} />}
    {children}
  </StyledHotChallengeMeta>
);

const ActivityIndicator = ({ type, count, isRecent = false }) => {
  const { t } = useTranslation();
  const activityTypes = {
    ideaCount: {
      icon: "lightbulb",
      text: `${t("generic.ideas")} created`,
      textSingular: `idea created`,
    },
    commentCount: {
      icon: "comment",
      text: `idea comments left`,
      textSingular: `${t("generic.idea")} comment left`,
    },
    voteCount: {
      icon: "thumbs up",
      text: `${t("generic.idea")} likes left`,
      textSingular: `${t("generic.idea")} like left`,
    },
  };

  const recentActivityTypes = {
    recentIdeaCount: {
      icon: "lightbulb",
      text: `${t("generic.ideas")} created recently`,
      textSingular: `idea created recently`,
    },
    recentCommentCount: {
      icon: "comment",
      text: `${t("generic.idea")} comments left recently`,
      textSingular: `${t("generic.idea")} comment left recently`,
    },
    recentVoteCount: {
      icon: "thumbs up",
      text: `${t("generic.idea")} likes left recently`,
      textSingular: `${t("generic.idea")} like left recently`,
    },
  };
  const data = isRecent ? recentActivityTypes : activityTypes;
  if (!data[type]) return null;
  const { text: infoText, textSingular: infoTextSingular, icon: infoIcon } = data[type];
  if (isRecent && count === 0) return null;
  if (!isRecent && count < 7) return null;
  return <HotChallengeMeta icon={infoIcon}>{util.pluralise(count, infoTextSingular, infoText)}</HotChallengeMeta>;
};

const HotChallengeCard = ({ challenge }) => {
  const { activityInfo = {}, publishedAt } = challenge;
  const { t } = useTranslation();
  const {
    lastActivityAt = "",
    /*
      ideaCount = 0,
      commentCount = 0,
      voteCount = 0,
      recentIdeaCount = 0,
      recentCommentCount = 0,
      recentVoteCount = 0,
    */
    mostCommonActivity = "",
    mostCommonRecentActivity = "",
  } = activityInfo;
  const momentLastActivityAt = moment(lastActivityAt).valueOf();
  const momentPublishedAt = moment(publishedAt).valueOf();
  const threeDaysAgo = moment().subtract(3, "days").valueOf();
  const oneDayAgo = moment().subtract(1, "day").valueOf();
  const isNewChallenge = momentPublishedAt > threeDaysAgo;
  const isHotChallenge = momentLastActivityAt > oneDayAgo;
  const hideRecentActivity =
    challenge.ideaVisibility !== "users" ||
    (challenge.ideaVisibilityLimits?.length === 1 && challenge.ideaVisibilityLimits.includes("pinned"));
  const theme = useTheme();

  return (
    <ShadowedCard>
      <Link to={util.challengeLink(challenge)}>
        <div
          style={{
            width: "100%",
            height: "100px",
            overflow: "hidden",
            backgroundImage: `url(${util.mixinCssUrlFallback(util.getImageUrl(challenge, ["coverImage", "bannerImage"], ["640x100", "300x300"]), util.challengeImage())})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7,
          }}
        >
          {(isNewChallenge || isHotChallenge) && !hideRecentActivity ? (
            <Label
              size="large"
              color={isNewChallenge ? "green" : "red"}
              style={{
                marginTop: 7,
                paddingLeft: 10,
                marginLeft: -14,
                color: "#fff",
              }}
              ribbon="right"
            >
              {isNewChallenge ? "NEW!" : "HOT!"}
            </Label>
          ) : (
            <>
              {challenge.stage === "published" ? (
                <Label
                  size="small"
                  style={{
                    marginTop: 7,
                    paddingLeft: 10,
                    marginLeft: -14,
                  }}
                  color="green"
                  ribbon="right"
                >
                  Open
                </Label>
              ) : null}
            </>
          )}
        </div>
      </Link>
      <Card.Content>
        <h3 style={{ display: "block", flex: 1, marginBottom: 0 }}>
          <Link to={util.challengeLink(challenge)} style={{ color: "#4d4949" }}>
            {challenge.name}
          </Link>
        </h3>
        {!hideRecentActivity && (
          <>
            <HotChallengeMeta icon="clock">
              Last activity <b>{moment(lastActivityAt).fromNow()}</b>
            </HotChallengeMeta>
            {challenge.anonymous && <AnonymousChip />}
            <ActivityIndicator type={mostCommonActivity} count={activityInfo[mostCommonActivity]} />
            <ActivityIndicator
              type={mostCommonRecentActivity}
              count={activityInfo[mostCommonRecentActivity]}
              isRecent
            />
          </>
        )}
      </Card.Content>

      <div
        className="content"
        style={{
          backgroundColor: theme.primaryColour,
          textAlign: "center",
          fontWeight: 600,
          maxHeight: 50,
        }}
      >
        <Link to={util.challengeLink(challenge)} style={{ color: "#fff", textTransform: "uppercase" }}>
          {t("generic.view", { entity: t("generic.challenge") })} <Icon name="arrow alternate circle right outline" />
        </Link>
      </div>
    </ShadowedCard>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(HotChallengeCard);
