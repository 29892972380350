import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Loader, Container, Card, Grid, Button, Input, Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import useTheme from "theme/useTheme";
import toast from "react-hot-toast";
import actions from "actions";
import util from "utils/utils";
import api from "api";

import DiscoverImage from "src/images/discover.png";

import { Banner, EmptyBox } from "components/lib/UI";
import ChallengeCard from "components/lib/Cards/ChallengeCard";
import HotChallengeCard from "components/lib/Cards/HotChallengeCard";
import NewChallengeModal from "components/challenges/NewChallengeModal";

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  > h3,
  h1,
  span {
    margin: 0 0 0 5px;
    display: block;
    color: rgb(77, 73, 73);
  }
  > span {
    opacity: 80%;
  }
`;

const PaginationContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > .total {
    display: block;
    color: gray;
    font-size: 0.9em;
    height: 20px;
  }
  > .empty {
    width: 95px;
  }
`;

const ChallengesTitle = ({ subtitle, children }) => (
  <TitleContainer>
    <h1>{children}</h1>
    {subtitle && <span>{subtitle}</span>}
  </TitleContainer>
);

const Challenges = (props) => {
  const {
    t,
    user,
    challengePage: page,
    challengeLimit: limit,
    challengeNextPageAvailable: nextPageAvailable,
    challengePrevPageAvailable: prevPageAvailable,
    setChallengesNextPage,
    setChallengesPrevPage,
    setChallengesPagesAvailable,
    discoverPage,
    discoverNextPageAvailable,
    discoverPrevPageAvailable,
    setDiscoverNextPage,
    setDiscoverPrevPage,
    setDiscoverPagesAvailable,
  } = props;
  const [filter, setFilter] = useState("");
  const [involvedChallenges, setInvolvedChallenges] = useState([]);
  const [involvedChallengesExist, setInvolvedChallengesExist] = useState(false);
  const [involvedTotal, setInvolvedTotal] = useState(0);
  const [discoverableChallenges, setDiscoverableChallenges] = useState([]);
  const [loading, setLoading] = useState(false);
  const [discoverableChallengesExist, setDiscoverableChallengesExist] = useState(false);
  const [newChallengeOpen, setNewChallengeOpen] = useState(false);
  const theme = useTheme();

  const getChallenges = useCallback(() => {
    setLoading(true);
    api.challenges.getAll(
      {
        discoverQuery: filter,
        involvedPage: page,
        involvedLimit: limit,
        discoverPage,
      },
      ({
        involved,
        discoverable,
        involvedTotal: newInvolvedTotal,
        nextInvolvedPageAvailable,
        nextDiscoverPageAvailable,
        prevInvolvedPageAvailable,
        prevDiscoverPageAvailable,
      }) => {
        setInvolvedChallengesExist((prev) => prev || involved.length);
        setInvolvedTotal(newInvolvedTotal);
        setDiscoverableChallengesExist((prev) => prev || discoverable.length);
        setInvolvedChallenges(involved);
        setDiscoverableChallenges(discoverable);
        setChallengesPagesAvailable({
          nextPageAvailable: nextInvolvedPageAvailable,
          prevPageAvailable: prevInvolvedPageAvailable,
        });
        setDiscoverPagesAvailable({
          nextPageAvailable: nextDiscoverPageAvailable,
          prevPageAvailable: prevDiscoverPageAvailable,
        });
        setLoading(false);
      },
      (err) => {
        toast.error(err.message);
        setLoading(false);
      },
    );
  }, [discoverPage, filter, limit, page, setChallengesPagesAvailable, setDiscoverPagesAvailable]);

  useEffect(() => {
    getChallenges();
  }, [getChallenges]);

  const filterChallenges = useMemo(
    () => (
      <Input
        placeholder={`Filter discoverable ${t("generic.challenges")}...`}
        loading={loading}
        onChange={(e, { value }) => setFilter(value)}
        value={filter}
        style={{ width: "33%", marginTop: "1rem" }}
      />
    ),
    [loading, filter, t],
  );

  const hasChallenges = involvedChallenges.length || discoverableChallenges.length;

  const onlyOneKindOfChallenge =
    (involvedChallengesExist || discoverableChallengesExist) &&
    !(involvedChallengesExist && discoverableChallengesExist);

  const windowWidth = window.innerWidth;
  const countNormalCols = useMemo(() => Math.max(1, Math.min(4, Math.round(windowWidth / 2 / 250))), [windowWidth]);
  const countInvolvedCols = useMemo(() => Math.max(1, Math.min(2, Math.round(windowWidth / 4 / 250))), [windowWidth]);

  return (
    <div>
      {newChallengeOpen ? (
        <NewChallengeModal newChallengeOpen={newChallengeOpen} closeModal={() => setNewChallengeOpen(false)} />
      ) : null}
      <Helmet title={t("common:capitalise", { key: "generic.challenge" })} />
      <Banner marginless>
        <Container
          fluid={!onlyOneKindOfChallenge}
          style={{ marginTop: 0, padding: !onlyOneKindOfChallenge ? "0 4.25rem" : undefined }}
        >
          <Grid stackable>
            <Grid.Row columns={2}>
              <Grid.Column computer={9}>
                <h1 style={{ color: "#3b3b3b", margin: "0px" }}>
                  {t("challenges.yours.orgTitle", { orgName: user.organisation.name })}
                </h1>
                <p style={{ marginBottom: !theme.sizes.isComputer && "-20px" }}>{t("challenges.yours.description")}</p>
              </Grid.Column>
              <Grid.Column computer={7} textAlign="right" verticalAlign="bottom">
                {util.canCreateChallenges(user) && (
                  <Button
                    size={!theme.sizes.isComputer ? "tiny" : "medium"}
                    icon="plus"
                    content={
                      !theme.sizes.isComputer
                        ? `Create ${t("generic.challengeWithArticle")}`
                        : t("challenges.new.title")
                    }
                    inverted
                    onClick={() => setNewChallengeOpen(true)}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Banner>

      {loading && !hasChallenges ? (
        <div>
          <Divider hidden />
          <Loader active inline="centered" />
        </div>
      ) : null}
      <Container fluid={!onlyOneKindOfChallenge} style={{ padding: onlyOneKindOfChallenge ? "1rem 0" : "1rem 2rem" }}>
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column
              computer={(onlyOneKindOfChallenge && discoverableChallengesExist) || !hasChallenges ? 16 : 10}
              mobile={16}
              tablet={16}
            >
              <div style={{ padding: onlyOneKindOfChallenge ? "0" : "0 2rem" }}>
                {!discoverableChallengesExist && !filter ? (
                  <>
                    {!hasChallenges && !filter && !loading ? (
                      <EmptyBox style={{ width: "100%", margin: "1rem" }}>
                        <img
                          src={DiscoverImage}
                          style={{
                            maxHeight: 150,
                            maxWidth: "70%",
                            display: "block",
                            margin: "10px auto",
                          }}
                          alt="Discover"
                        />
                        {util.canCreateChallenges(user) ? (
                          <div>
                            <h2>{t("challenges.yours.noneCreator")}</h2>
                            <div />
                            <Button
                              onClick={() => setNewChallengeOpen(true)}
                              primary
                              icon="plus"
                              content={t("challenges.new.title")}
                            />
                          </div>
                        ) : (
                          <React.Fragment>
                            <h2>{t("challenges.yours.none")}</h2>
                          </React.Fragment>
                        )}
                      </EmptyBox>
                    ) : null}
                  </>
                ) : (
                  <div>
                    {filterChallenges}
                    {!discoverableChallenges.length && filter ? (
                      <EmptyBox style={{ width: "100%", margin: "1rem 0" }}>
                        <img
                          src={DiscoverImage}
                          style={{
                            maxHeight: 150,
                            maxWidth: "70%",
                            display: "block",
                            margin: "10px auto",
                          }}
                          alt="Discover"
                        />
                        <h2>{t("ideas.yours.sidebar.noResults")}</h2>
                      </EmptyBox>
                    ) : (
                      <Card.Group
                        itemsPerRow={countNormalCols}
                        stackable
                        style={{ marginBottom: "1rem", marginTop: "1rem" }}
                      >
                        {discoverableChallenges.slice(0, 4).map((challenge) => (
                          <HotChallengeCard challenge={challenge} ideas={challenge.ideas} key={challenge._id} />
                        ))}
                        {discoverableChallenges.slice(4).map((challenge) => (
                          <ChallengeCard challenge={challenge} ideas={challenge.ideas} key={challenge._id} />
                        ))}
                      </Card.Group>
                    )}
                    {discoverNextPageAvailable || discoverPrevPageAvailable ? (
                      <PaginationContainer>
                        {discoverPrevPageAvailable ? (
                          <Button size="tiny" content="Prev page" onClick={() => setDiscoverPrevPage()} />
                        ) : (
                          <div className="empty" />
                        )}
                        <span />
                        {discoverNextPageAvailable ? (
                          <Button size="tiny" content="Next page" onClick={() => setDiscoverNextPage()} />
                        ) : (
                          <div className="empty" />
                        )}
                      </PaginationContainer>
                    ) : null}
                  </div>
                )}
              </div>
            </Grid.Column>
            {hasChallenges ? (
              <Grid.Column
                computer={onlyOneKindOfChallenge && involvedChallengesExist ? 16 : 6}
                mobile={16}
                tablet={16}
              >
                {involvedChallenges.length ? (
                  <div style={{ padding: onlyOneKindOfChallenge ? "0" : "0 2rem" }}>
                    <ChallengesTitle
                      subtitle={`${t("common:capitalise", { key: "generic.challenges" })} you have engaged with`}
                    >
                      Involved {t("generic.challenges")}
                    </ChallengesTitle>
                    <Card.Group itemsPerRow={onlyOneKindOfChallenge ? countNormalCols : countInvolvedCols} stackable>
                      {involvedChallenges.map((challenge) => (
                        <ChallengeCard showIdeas challenge={challenge} ideas={challenge.ideas} key={challenge._id} />
                      ))}
                    </Card.Group>
                    {nextPageAvailable || prevPageAvailable ? (
                      <PaginationContainer>
                        {prevPageAvailable ? (
                          <Button size="tiny" content="Prev page" onClick={() => setChallengesPrevPage()} />
                        ) : (
                          <div className="empty" />
                        )}
                        <span className="total">
                          {involvedTotal} total - Page {page}
                        </span>
                        {nextPageAvailable ? (
                          <Button size="tiny" content="Next page" onClick={() => setChallengesNextPage()} />
                        ) : (
                          <div className="empty" />
                        )}
                      </PaginationContainer>
                    ) : null}
                  </div>
                ) : null}
              </Grid.Column>
            ) : null}
          </Grid.Row>
        </Grid>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    challengeFilter,
    challengeAuthor,
    challengeIncludes,
    challengeOrder,
    challengePage,
    challengeLimit,
    challengeNextPageAvailable,
    challengePrevPageAvailable,
    discoverPage,
    discoverNextPageAvailable,
    discoverPrevPageAvailable,
    setDiscoverNextPage,
    setDiscoverPrevPage,
    setDiscoverPagesAvailable,
  } = state.challenges;
  return {
    user: state.user,
    challengeFilter,
    challengeAuthor,
    challengeIncludes,
    challengeOrder,
    challengePage,
    challengeLimit,
    challengeNextPageAvailable,
    challengePrevPageAvailable,
    discoverPage,
    discoverNextPageAvailable,
    discoverPrevPageAvailable,
    setDiscoverNextPage,
    setDiscoverPrevPage,
    setDiscoverPagesAvailable,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateFilters: (data) => dispatch(actions.challenges.updateChallengeFilters(data)),
  setChallengesNextPage: () => dispatch(actions.challenges.setChallengesNextPage()),
  setChallengesPrevPage: () => dispatch(actions.challenges.setChallengesPrevPage()),
  setChallengesPagesAvailable: (data) => dispatch(actions.challenges.setChallengesPagesAvailable(data)),
  setDiscoverNextPage: () => dispatch(actions.challenges.setDiscoverNextPage()),
  setDiscoverPrevPage: () => dispatch(actions.challenges.setDiscoverPrevPage()),
  setDiscoverPagesAvailable: (data) => dispatch(actions.challenges.setDiscoverPagesAvailable(data)),
});

const ChallengesContainer = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Challenges));

export default ChallengesContainer;
