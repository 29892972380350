import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu, Popup, Loader, Header, Divider, Button, Icon, Pagination } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { supportsPush } from "utils/pushMessaging";
import styled from "styled-components";
import api from "api";
import util from "utils/utils";
import websocketApi from "api/websocket";
import { EmptyBox } from "components/lib/UI";
import NotificationItem from "./NotificationItems";
import CircularButtonWithNumber from "../CircularButtonWithNumber";
import { useAppSelector } from "store";
import { OpenAPI } from "simplydo/interfaces";
import useTheme from "theme/useTheme";

const StyledNotificationTray = styled.div`
  min-width: ${({ theme }) => (theme.sizes.isMobile ? "100%" : "500px;")};
  max-height: ${({ theme }) => (theme.sizes.isMobile ? null : "600px")} !important;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 9999;
`;
const StyledMenu = styled(Menu)`
  margin: 0 !important;
  min-width: ${({ theme }) => (theme.sizes.isMobile ? "100%" : "500px")} !important;
  max-height: ${({ theme }) => (theme.sizes.isMobile ? null : "600px")} !important;
  overflow: auto;
`;

type Notifications = OpenAPI.GET<`/notifications`>["response"]["notifications"];

type NotificationTrayProps = {
  isMobile?: boolean;
  style?: React.CSSProperties;
};

const NotificationTray = ({ isMobile, style }: NotificationTrayProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const user = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  const [popupOpen, setPopupOpen] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [notifications, setNotifications] = useState<Notifications>([]);
  const [total, setTotal] = useState(0);
  const [unseen, setUnseen] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const userId = user?._id;
  const acceptableInvitations = useMemo(
    () => invitations.filter((invitation) => !invitation.createdByCurrentUser),
    [invitations],
  );

  const getInvitations = useCallback(() => {
    api.invitations.get(
      ({ invitations: newInvitations }) => setInvitations(newInvitations),
      () => {},
    );
  }, []);

  const onInvitationHandled = useCallback((invitationId) => {
    setInvitations((prev) => prev.filter((i) => i._id !== invitationId));
  }, []);

  const getNotifications = useCallback((nextPage) => {
    setPage(nextPage);
    setLoading(true);
    api.notifications.get(
      nextPage,
      ({ total: newTotal, unseen: newUnseen, notifications: newNotifications }) => {
        setTotal(newTotal);
        setUnseen(newUnseen);
        setNotifications(newNotifications);
        setLoading(false);
      },
      () => {
        setLoading(false);
      },
    );
  }, []);

  useEffect(() => {
    const notificationSubscription = websocketApi.subscribe(`pushNotification-${userId}`, () => {
      getNotifications(1);
    });
    return notificationSubscription.unsubscribe;
  }, [getNotifications, userId]);

  useEffect(() => {
    getNotifications(1);
    getInvitations();
  }, [getNotifications, getInvitations]);

  const markAsInteracted = useCallback((notificationId) => {
    setPopupOpen(false);
    api.notifications.markAsInteracted(
      notificationId,
      () => {
        setNotifications((prev) =>
          prev.map((notification) => {
            if (notification._id === notificationId) {
              return { ...notification, interacted: true };
            }
            return notification;
          }),
        );
      },
      () => {},
    );
  }, []);

  const markAsSeen = useCallback((newNotifications, markAllAsSeen) => {
    api.notifications.markAsSeen(
      newNotifications,
      markAllAsSeen,
      () => {
        setNotifications((prev) =>
          prev.map((notification) => {
            if (markAllAsSeen || newNotifications.includes(notification._id)) {
              return { ...notification, seen: true };
            }
            return notification;
          }),
        );
      },
      () => {},
    );
  }, []);

  const markAllAsSeen = useCallback(() => {
    markAsSeen([], true);
  }, [markAsSeen]);

  const notificationContent = (
    <StyledNotificationTray>
      <StyledMenu borderless secondary vertical>
        <div style={{ padding: 5 }}>
          <Header
            style={{
              marginBottom: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>{t("notifications.title")}</div>
            <div>
              {supportsPush() && Notification.permission === "default" && !theme.sizes.isMobile ? (
                <Button
                  secondary
                  size="tiny"
                  onClick={() => {
                    setPopupOpen(false);
                    if (util.localStorageIsSupported()) {
                      localStorage.removeItem("hasSeenPushSetupModal");
                    }
                    navigate("/preferences/notifications");
                  }}
                >
                  Set up push notifications
                </Button>
              ) : null}
            </div>
          </Header>
          <Divider style={{ marginTop: 3, marginBottom: 0 }} />
        </div>
        {loading ? <Loader active /> : null}
        {!loading && acceptableInvitations.length ? (
          <Menu.Item
            style={{
              borderBottom: "1px solid",
              borderColor: "#E9EBEE",
              padding: ".833em 1em",
              borderRadius: 0,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              textOverflow: "ellipsis",
              marginTop: 5,
              marginBottom: 5,
              width: "99%",
              gap: 10,
            }}
            fitted
            link
            as={Link}
            to="/invitations"
          >
            <Icon name="paper plane" size="big" style={{ width: 50, margin: 0 }} />
            <div>
              <h4>Invitations</h4>
              <div>
                There {util.pluralise(acceptableInvitations.length, "is", "are", false)}{" "}
                {util.pluralise(acceptableInvitations.length, "invitation", "invitations")} waiting for you.
              </div>
            </div>
          </Menu.Item>
        ) : null}
        {!loading && notifications.length
          ? notifications.map((notification) => (
              // @ts-ignore
              <NotificationItem
                notification={notification}
                user={user}
                key={notification._id}
                markNotificationAsInteracted={markAsInteracted}
                invitations={invitations}
                onInvitationHandled={onInvitationHandled}
              />
            ))
          : null}
      </StyledMenu>
      {!loading && notifications.length ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: 4,
          }}
        >
          <Pagination
            totalPages={Math.ceil(total / 30)}
            activePage={page}
            onPageChange={(e, { activePage }) => getNotifications(activePage)}
          />
        </div>
      ) : null}
      {!loading && !notifications.length ? (
        <Menu.Item>
          <EmptyBox>
            <h5>{t("notifications.empty")}</h5>
          </EmptyBox>
        </Menu.Item>
      ) : null}
    </StyledNotificationTray>
  );

  if (isMobile) {
    return notificationContent;
  }
  return (
    <Popup
      on="click"
      // If there are any pending invites when the notification tray is opened, we re fetch the notifications as the invite may have been accepted
      onOpen={() => {
        if (acceptableInvitations) {
          getNotifications(1);
          getInvitations();
        }
      }}
      style={{ width: "fit-content" }}
      positionFixed
      wide="very"
      position="bottom right"
      offset={[5, 0]}
      open={popupOpen}
      onClose={() => setPopupOpen(false)}
      trigger={
        <div style={style}>
          <CircularButtonWithNumber
            onClick={() => {
              if (!popupOpen) {
                markAllAsSeen();
              }
              if (theme.sizes.isMobile) {
                if (location.pathname === "/notifications") {
                  navigate("..");
                } else {
                  navigate("/notifications");
                }
              } else {
                setPopupOpen(!popupOpen);
              }
            }}
            icon="bell"
            count={unseen === 0 && acceptableInvitations.length !== 0 ? "●" : unseen}
          />
        </div>
      }
      content={notificationContent}
    />
  );
};

export default NotificationTray;
