import React, { useCallback, useState, useEffect } from "react";
import api from "api";
import { Icon, Header, Container, Message, Loader, Button, Segment, Divider } from "semantic-ui-react";
import { useParams, useNavigate } from "react-router-dom";
import { OpenAPI, Other } from "simplydo/interfaces";
import { useAppSelector, useAppDispatch } from "store";
import { useTranslation } from "react-i18next";
import { FakeLink } from "components/lib/UI";
import Login from "components/entry/Login";
import actions from "actions";
// @ts-ignore
import i18n from "src/i18n";
import util from "utils/utils";

const getEntityAction = (persistentToken: OpenAPI.Schemas["PersistentToken"]) => {
  const { forEntity } = persistentToken;
  if (forEntity === "invitation") {
    return "invited";
  }
  return "";
};

const GetForTypeText = (persistentToken: OpenAPI.Schemas["PersistentToken"]) => {
  const { forType } = persistentToken;
  if (forType === "idea") {
    const { ownerIdea } = persistentToken;
    return `join the ${i18n.t("generic.idea")} "${ownerIdea?.name ?? "Unknown"}"`;
  }
  return "";
};

const getActionText = (persistentToken: OpenAPI.Schemas["PersistentToken"]) => {
  const { forEntity } = persistentToken;
  if (forEntity === "invitation") {
    return "accept this invitation";
  }
  return "";
};

const PersistentTokenInvitation = ({
  invitation,
  onReject,
  onFail,
}: {
  invitation: OpenAPI.POST<"/persistent-tokens/redeem/{token}">["response"]["invitation"];
  onReject: () => void;
  onFail: (err: { message: string }) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const acceptInvitation = useCallback(() => {
    setLoading(true);
    api.invitations.accept(
      invitation._id,
      () => {
        setLoading(false);
        if (invitation.forType === "idea") {
          navigate(`/ideas/${invitation.forId}`);
        }
      },
      (err) => {
        setLoading(false);
        onFail(err);
      },
    );
  }, [navigate, invitation._id, invitation.forId, invitation.forType, onFail]);

  const rejectInvitation = useCallback(() => {
    setLoading(true);
    util
      .confirm("Reject invitation", "Are you sure you want to reject this invitation?")
      .then(() => {
        api.invitations.reject(
          invitation._id,
          () => {
            onReject();
            setLoading(false);
          },
          (err) => {
            onFail(err);
            setLoading(false);
          },
        );
      })
      .catch(() => {});
  }, [invitation._id, onFail, onReject]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 35,
        marginTop: 20,
        width: "100%",
      }}
    >
      <Button primary fluid icon="check" loading={loading} onClick={acceptInvitation} style={{ maxWidth: 500 }}>
        Accept
      </Button>
      <FakeLink withBorder onClick={rejectInvitation}>
        Reject invitation
      </FakeLink>
    </div>
  );
};

const PersistentToken = () => {
  const [persistentToken, setPersistentToken] = useState<
    OpenAPI.GET<"/persistent-tokens/redeem/{token}">["response"]["persistentToken"] | null
  >(null);
  const hasPersistentToken = !!persistentToken;
  const [persistentTokenLoading, setPersistentTokenLoading] = useState(false);
  const [persistentTokenErrorMessage, setPersistentTokenErrorMessage] = useState<string>("");
  const [persistentTokenResultMessage, setPersistentTokenResultMessage] = useState<string>("");

  const [redeemingPersistentToken, setRedeemingPersistentToken] = useState(false);
  const [redeemedToken, setRedeemedToken] = useState<
    OpenAPI.POST<"/persistent-tokens/redeem/{token}">["response"]["invitation"] | null
  >(null);

  const user: OpenAPI.GET<"/users/me">["response"] = useAppSelector((state) => state.user);
  const hasUser = !!user;

  const urlOrganisation: Other.IOrganisation = useAppSelector((state) => state.organisations.urlOrganisation);
  const dispatch = useAppDispatch();
  const onSelectOrganisation = useCallback(
    (organisation: Other.IOrganisation | null) => {
      dispatch(actions.organisations.receiveUrlOrganisation(organisation));
    },
    [dispatch],
  );

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { token } = useParams<{ token: string }>();

  // If we have data indicating the user has previously visited this link, and created an entity/accepted an invitation, redirect them to the entity
  const handleRedirect = useCallback(
    (persistentToken: OpenAPI.GET<"/persistent-tokens/redeem/{token}">["response"]["persistentToken"]) => {
      if (persistentToken.forEntity === "invitation" && persistentToken.forType === "idea") {
        navigate(`/ideas/${persistentToken.forId}`);
      }
    },
    [navigate],
  );

  const getPersistentToken = useCallback(() => {
    setPersistentTokenLoading(true);
    setPersistentTokenErrorMessage("");
    api.persistentTokens.getByToken(
      token,
      (res: OpenAPI.GET<"/persistent-tokens/redeem/{token}">["response"]) => {
        if (res.userHasRedeemedToken) {
          handleRedirect(res.persistentToken);
        }
        setPersistentToken(res.persistentToken);
        setPersistentTokenLoading(false);
      },
      (err) => {
        setPersistentTokenErrorMessage(err.message);
        setPersistentTokenLoading(false);
      },
    );
  }, [handleRedirect, token]);

  useEffect(() => {
    getPersistentToken();
  }, [getPersistentToken]);

  const redeemPersistentToken = useCallback(() => {
    setRedeemingPersistentToken(true);
    setPersistentTokenErrorMessage("");
    api.persistentTokens.redeem(
      token,
      (res: OpenAPI.POST<"/persistent-tokens/redeem/{token}">["response"]) => {
        if (res.invitation) {
          setRedeemedToken(res.invitation);
        }
        setRedeemingPersistentToken(false);
      },
      (err) => {
        setRedeemingPersistentToken(false);
        setPersistentTokenErrorMessage(err.message);
      },
    );
  }, [token]);

  useEffect(() => {
    if (hasUser && hasPersistentToken) {
      redeemPersistentToken();
    }
  }, [redeemPersistentToken, hasUser, hasPersistentToken]);

  return (
    <Container
      style={{
        paddingTop: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {persistentToken ? (
        <Header as="h2" icon textAlign="center">
          <Icon name="users" circular />
          <Header.Content>
            {persistentToken.ownerUser?.profile?.fullName ?? "Someone"}
            {" has "}
            {getEntityAction(persistentToken)}
            {" you to "}
            {GetForTypeText(persistentToken)}
          </Header.Content>
        </Header>
      ) : null}

      {persistentTokenResultMessage ? (
        <Message success>
          <Message.Content>{persistentTokenResultMessage}</Message.Content>
        </Message>
      ) : null}

      {persistentTokenErrorMessage ? (
        <Message error>
          <Message.Header>Error</Message.Header>
          <Message.Content>{persistentTokenErrorMessage}. Your link may be invalid, or has expired.</Message.Content>
        </Message>
      ) : null}

      {persistentTokenLoading || redeemingPersistentToken ? <Loader active inline="centered" /> : null}

      {persistentToken ? (
        user ? (
          <Segment fluid style={{ width: 700, maxWidth: 700 }}>
            {redeemedToken ? (
              <PersistentTokenInvitation
                invitation={redeemedToken}
                onReject={() => {
                  setRedeemedToken(null);
                  setPersistentTokenResultMessage(
                    "You have rejected this invitation. You can revisit this link to accept it again.",
                  );
                }}
                onFail={(err) => setPersistentTokenErrorMessage(err.message)}
              />
            ) : null}
          </Segment>
        ) : (
          <Segment style={{ maxWidth: 700 }}>
            <Message info style={{ marginBottom: 20 }}>
              To {getActionText(persistentToken)}, please login or create an account.
            </Message>

            {urlOrganisation ? (
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h5 style={{ display: "inline-block", marginTop: 20, marginBottom: 0 }}>
                    {t("entry.register.org.signingUpWith", { organisation: urlOrganisation })}
                  </h5>
                  <Button
                    size="tiny"
                    type="button"
                    content={t("entry.register.org.changeThis")}
                    onClick={() => onSelectOrganisation(null)}
                    basic
                    style={{ margin: "10px 10px 10px 0px" }}
                  />
                </div>

                <Divider />
              </div>
            ) : null}

            <Login />
          </Segment>
        )
      ) : null}
    </Container>
  );
};

export default PersistentToken;
