import React from "react";
import util from "utils/utils";
import { Trans } from "react-i18next";

const ProjectAssignedManager = ({ events, ownerIdea = { name: "Undefined" } }) => {
  return (
    <Trans i18nKey="notifications.projectAssignedManager">
      <b>{{ name: util.getEventUsers(events) }}</b>
      has assigned
      <b>{{ assignee: util.getEventUsers(events, "ownerAssignee") }}</b>
      to help project-manage the idea
      <b>{{ idea: ownerIdea.name }}</b>.
    </Trans>
  );
};

export default ProjectAssignedManager;
