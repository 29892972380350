import React, { ReactNode } from "react";
import { Container, Accordion } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import useTheme from "theme/useTheme";
import styled from "styled-components";
import { Sticky } from "components/lib/UI";
import SideNavLink, { SideNavLinkProps } from "./SideNavLink";

const StyledContainer = styled.div<{ $fluid?: boolean | string }>`
  flex: 1;
  display: flex;
  ${({ theme, $fluid }) =>
    !theme.sizes.isComputer
      ? `
      flex-direction: column;
    `
      : `
      flex-direction: row;
      ${$fluid && ($fluid === "very" ? "margin: 0;" : "margin: 0 2rem;")}
  `}
`;

const StyledSideBar = styled.div<{ $fluid?: boolean | string; $hasNavItems?: boolean; $isCollapsed: boolean }>`
  ${({ theme, $fluid }) =>
    !theme.sizes.isComputer
      ? `
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 10px;
    `
      : `
    flex: 0 0 ${$fluid ? "15%" : "25%"};
    min-height: 80vh;
    max-width: 25%;
  `}
  ${({ $isCollapsed }) =>
    $isCollapsed &&
    `
    flex: 0 0 42px;
  `}
  ${({ $hasNavItems }) =>
    !$hasNavItems
      ? `
    background-color: rgba(0,0,0,0.04);
  `
      : `
    background-color: none;
  `}
`;

const StyledMain = styled.div<{ $fluid?: boolean | string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 100%;
  min-width: 0;
  ${({ theme, $fluid }) =>
    !theme.sizes.isComputer
      ? ""
      : `flex: 1 1 ${$fluid ? "15%" : "25%"};
      margin-left:15px;
      margin-top: ${$fluid === "very" ? "0" : "15px"};
      padding-bottom: 50px;
  `}
  > div:not(.stackable) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

const MobileContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

type SideNavCustomProps = {
  content: ReactNode;
};

export type SideNavComponentCollapsibleProps = {
  // Controlled collapsible
  // Custom nav items are not displayed in collapsible side nav links
  collapsible: true;
  isCollapsed: boolean;
  onCollapse: () => void;
  onExpand: () => void;
};

type SideNavItemProps = {
  key: string;
} & (({ type: "link" } & SideNavLinkProps) | ({ type: "custom" } & SideNavCustomProps)) &
  Partial<SideNavComponentCollapsibleProps>;

type SideNavComponentProps = {
  nav?: ReactNode;
  navItems?: SideNavItemProps[];
  main: ReactNode;
  fluid?: boolean | "very";
  top?: number;
  loading?: boolean;
} & Partial<SideNavComponentCollapsibleProps>;

const SideNavItem = (props: SideNavItemProps) => {
  const { type = "link", content } = props;
  if (type === "link") {
    const {
      iconLeft,
      iconLeftAlt,
      iconRight,
      to,
      active,
      compact,
      external,
      showExternalIcon,
      onClick,
      rightIcon,
      collapsible,
      isCollapsed,
    } = props as SideNavLinkProps & Partial<SideNavComponentCollapsibleProps>;
    return (
      <SideNavLink
        to={to}
        onClick={onClick}
        content={content}
        iconLeft={iconLeft}
        iconLeftAlt={iconLeftAlt}
        iconRight={iconRight}
        active={active}
        compact={compact}
        external={external}
        showExternalIcon={showExternalIcon}
        rightIcon={rightIcon}
        collapsible={collapsible}
        isCollapsed={isCollapsed}
      />
    );
  }
  if (type === "custom" && !props.isCollapsed) {
    return content as JSX.Element;
  }
  return null;
};

const SideNavContainer = ({ children, top, fluid }) => {
  const theme = useTheme();

  if (theme.sizes.isMobile || theme.sizes.isTablet) {
    return (
      <Accordion
        styled
        fluid
        panels={[
          {
            key: "navigation",
            title: "Navigation",
            content: {
              content: children,
            },
          },
        ]}
      />
    );
  }
  return (
    <Sticky
      top={top !== undefined ? top : 120}
      bottom={undefined}
      style={{
        ...(!theme.sizes.isComputer
          ? {
              paddingTop: 10,
              paddingBottom: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100%",
            }
          : {
              marginTop: fluid ? 0 : 15,
            }),
      }}
    >
      {children}
    </Sticky>
  );
};

const SideNav = ({
  t,
  nav,
  navItems,
  main,
  fluid,
  top,
  loading,
  collapsible,
  isCollapsed,
  onCollapse,
  onExpand,
}: SideNavComponentProps) => {
  const theme = useTheme();
  const ContainerComponent = !theme.sizes.isComputer ? MobileContainer : Container;
  // Remove empty nav items
  const filteredNavItems = navItems ? navItems.filter((item) => item !== null) : null;

  const shouldShowCollapsed = isCollapsed && !theme.sizes.isMobile;
  return (
    <ContainerComponent
      fluid={!!fluid}
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <StyledContainer $fluid={fluid}>
        {!navItems && !nav ? null : (
          <StyledSideBar $fluid={fluid} $hasNavItems={!!navItems} $isCollapsed={shouldShowCollapsed}>
            {nav || null}
            {filteredNavItems ? (
              <SideNavContainer top={top} fluid={fluid}>
                {filteredNavItems.map((item) => {
                  const extraProps =
                    (item.type === "link" || !item.type) && !theme.sizes.isMobile
                      ? {
                          collapsible,
                          isCollapsed,
                          onCollapse,
                          onExpand,
                        }
                      : {};
                  return <SideNavItem {...extraProps} {...item} key={item.key} />;
                })}
                {collapsible && onCollapse && onExpand && !theme.sizes.isMobile && !loading ? (
                  <div style={{ marginTop: 15 }}>
                    <SideNavLink
                      key="collapse"
                      onClick={isCollapsed ? onExpand : onCollapse}
                      content={isCollapsed ? t("sidebar.expand") : t("sidebar.collapse")}
                      isCollapsed={isCollapsed}
                      iconLeft={isCollapsed ? "angle double right" : "angle double left"}
                      active={false}
                      secondary
                      compact="very"
                    />
                  </div>
                ) : null}
              </SideNavContainer>
            ) : null}
          </StyledSideBar>
        )}

        <StyledMain theme={theme} $fluid={fluid}>
          {main}
        </StyledMain>
      </StyledContainer>
    </ContainerComponent>
  );
};

export default withTranslation()(SideNav);
