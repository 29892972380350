import React, { useState, useEffect, useCallback } from "react";
import {
  Loader,
  Form,
  Divider,
  Button,
  Message,
  Icon,
  Modal,
  ModalContent,
  ModalActions,
  Input,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import api from "api";
import util from "utils/utils";
import constants from "utils/constants";
import actions from "actions";

import OrganisationChooser from "./OrganisationChooser";

function Login({
  email,
  password,
  loading,
  challengeResponse,
  organisation,
  onUpdateEmail,
  onUpdatePassword,
  onUpdateChallengeResponse,
  onUpdateLoading,
  t,
  errorMessage: authErrorMessage,
  utmLink,
  utmChallenge,
  embedded,
}) {
  const [otp, setOtp] = useState("");
  const [otpModal, setOtpModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [securityPhrase, setSecurityPhrase] = useState("");
  const [showCustomChallenge, setShowCustomChallenge] = useState(false);
  const [showPasswordEntry, setShowPasswordEntry] = useState();
  const [proceedToLogin, setProceedToLogin] = useState(false);
  const [exchangeToken, setExchangeToken] = useState("");
  const [otherOrgs, setOtherOrgs] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();

  const paramMessage = params.get("message");

  useEffect(() => {
    if (organisation?.auth?.types?.length === 1 && organisation?.code) {
      const authType = organisation.auth.types[0];
      const redirectAuthTypes = ["microsoft", "linkedin", "apple", "google"];
      if (redirectAuthTypes.includes(authType)) util.loginWithOAuth(authType, organisation.code);
      if (authType === "saml") util.loginWithSaml(organisation.code);
    }
  }, [organisation?.code, organisation?.auth?.types]);

  useEffect(() => {
    const exchangeTokenChecker = setInterval(() => {
      if (exchangeToken && document.visibilityState === "visible") {
        api.auth.loginWithToken(exchangeToken, () => {
          clearInterval(exchangeTokenChecker);
        });
      }
    }, 2000);
    return function cleanup() {
      clearInterval(exchangeTokenChecker);
    };
  }, [exchangeToken]);

  const authIsEnabled = (type) =>
    (type === "standard" || paramMessage !== "loginRequired") && organisation?.auth?.types?.indexOf(type) > -1;
  const ssoIsEnabled = () => {
    const types = organisation?.auth?.types || [];
    return (paramMessage !== "loginRequired" && types.length > 1) || (types.length === 1 && types[0] !== "standard");
  };

  const checkAccount = useCallback(
    (withEmail) => {
      const emailToCheck = withEmail || email;
      setErrorMessage("");
      setOtherOrgs([]);
      if (!emailToCheck || !emailToCheck.match(constants.emailRegex)) return;
      if (!organisation) {
        setShowPasswordEntry(true);
        setProceedToLogin(true);
        return;
      }
      onUpdateLoading(true);
      api.auth.verifyAccount(
        organisation._id,
        emailToCheck,
        challengeResponse,
        (data) => {
          onUpdateLoading(false);
          if (data.exists) {
            setOtherOrgs(data.otherOrgs);
            if (data.challengePassed) {
              setShowPasswordEntry(true);
              setProceedToLogin(true);
            } else {
              setErrorMessage(data.challengeMessage);
              if (data.customChallengeRequired) {
                setShowCustomChallenge(true);
              }
            }
          } else if (data.challengePassed) {
            if (organisation.loginLinkEnabled) {
              setErrorMessage(
                `This email address is not registered with ${organisation.name}. Please check that it is correct or retry with another email.`,
              );
            } else {
              let then;
              const path = location.pathname + location?.search;
              if (path && path !== "/") then = path;

              const challengeUtm = params.get("utm-challenge");
              const thenParam = params.get("then");
              if (thenParam && thenParam !== "/") then = thenParam;
              navigate(
                `/register${then ? `?then=${encodeURIComponent(then)}` : ""}${challengeUtm ? `&utm-challenge=${challengeUtm}` : ""}`,
              );
            }
          } else {
            setErrorMessage(data.challengeMessage);
            if (data.customChallengeRequired) {
              setShowCustomChallenge(true);
            }
          }
        },
        (err) => {
          setErrorMessage(err.message);
          onUpdateLoading(false);
        },
      );
    },
    [challengeResponse, email, organisation, onUpdateLoading, navigate, location, params],
  );

  useEffect(() => {
    const emailParam = params.get("email");
    if (emailParam && organisation) {
      params.delete("email");
      setParams(params);
      onUpdateEmail(emailParam);
      checkAccount(emailParam);
    }
  }, [onUpdateEmail, checkAccount, setParams, params, organisation]);

  const login = () => {
    onUpdateLoading(true);
    setErrorMessage("");
    api.auth.login(
      {
        email,
        password,
        challengeResponse,
        exchangeToken,
        otp,
        org: organisation?._id,
        utmLink,
        utmChallenge,
      },
      (data) => {
        if (data.otpRequired) {
          setOtpModal(true);
          setExchangeToken(data.exchangeToken);
          onUpdateLoading(false);
        } else {
          onUpdateLoading(false);
          const then = params.get("then");
          if (then) {
            navigate(then);
          }
        }
      },
      (err) => {
        onUpdateLoading(false);
        setErrorMessage(err.message);
        setOtpModal(false);
        setOtp("");
      },
    );
  };

  const loginMagicLink = useCallback(() => {
    onUpdateLoading(true);
    setErrorMessage("");
    api.auth.requestLoginEmail(
      email,
      ({ securityPhrase: newSecurityPhrase, exchangeToken: newExchangeToken }) => {
        onUpdateLoading(false);
        setSecurityPhrase(newSecurityPhrase);
        setExchangeToken(newExchangeToken);
      },
      (err) => {
        onUpdateLoading(false);
        setErrorMessage(err.message);
      },
    );
  }, [email, onUpdateLoading]);

  if (organisation?.isBlocked) {
    return (
      <div style={{ maxWidth: 500, display: "block", margin: "0px auto" }}>
        <Message error>
          <p>
            The {organisation.name} licence has now expired. You are no longer able to login and access your account. If
            you have any problems or questions, please contact your organisational administrator.
          </p>
          <p>
            If you don't know who your organisational administrator is then you can request this by emailing{" "}
            <a href="mailto:support@simplydo.co.uk">support@simplydo.co.uk</a>.
          </p>
        </Message>
      </div>
    );
  }
  return (
    <div style={{ maxWidth: 450, display: "block", margin: "0px auto" }}>
      {securityPhrase ? (
        <div style={{ textAlign: "center", marginTop: 30 }}>
          <Loader active inline="centered" />
          <h3>Waiting for email authorisation...</h3>
          <p>
            We've sent you an email with a link to authorise this login. If you haven't received it, please check your
            spam folder.
          </p>
          <p>
            <strong>Please do not close this window or refresh the page.</strong>
          </p>

          <Divider />
          <h4>The email should contain this security phrase:</h4>
          <div
            style={{
              marginTop: 5,
              padding: 20,
              background: "rgb(230,230,230)",
              borderRadius: 10,
              textAlign: "center",
            }}
          >
            <strong>{securityPhrase}</strong>
          </div>
        </div>
      ) : (
        <>
          {!embedded ? <h2>{t("entry.login.welcome")}</h2> : null}
          <Divider hidden />
          {paramMessage && paramMessage === "loginRequired" ? (
            <Message info>
              We detected that you are trying to access SimplyDo using an email you had already used before. Please log
              in with your existing account details.
            </Message>
          ) : null}
          {authErrorMessage ? (
            <Message info>We need to verify your details to confirm it's you. Please login again.</Message>
          ) : null}
          {!util.localStorageIsSupported() ? (
            <Message warning>
              Your device does not support saving data locally. Your login information will not be saved when revisiting{" "}
              {util.appName()}.
            </Message>
          ) : null}
          {!organisation && <OrganisationChooser />}

          {authIsEnabled("saml") && (
            <Button
              size="small"
              fluid
              primary
              icon="key"
              content={organisation.auth?.saml?.buttonText ?? t("entry.login.sso")}
              onClick={() => util.loginWithSaml(organisation.code)}
              style={{ marginBottom: 5 }}
            />
          )}
          {authIsEnabled("linkedin") && (
            <Button
              size="small"
              fluid
              color="linkedin"
              icon="linkedin"
              content="Sign in with LinkedIn"
              onClick={() => util.loginWithOAuth("linkedin", organisation.code)}
              style={{ marginBottom: 5 }}
            />
          )}
          {authIsEnabled("google") && (
            <Button
              size="small"
              fluid
              color="google plus"
              icon="google"
              content="Sign in with Google"
              onClick={() => util.loginWithOAuth("google", organisation.code)}
              style={{ marginBottom: 5 }}
            />
          )}
          {authIsEnabled("apple") && (
            <Button
              size="small"
              fluid
              color="black"
              icon="apple"
              content="Sign in with Apple"
              onClick={() => util.loginWithOAuth("apple", organisation.code)}
              style={{ marginBottom: 5 }}
            />
          )}
          {authIsEnabled("microsoft") && (
            <Button
              size="small"
              fluid
              color="msteams"
              icon="microsoft"
              content={organisation.auth?.microsoft?.buttonText ?? "Sign in with Microsoft"}
              onClick={() => util.loginWithOAuth("microsoft", organisation.code)}
              style={{ marginBottom: 5 }}
            />
          )}
          {authIsEnabled("standard") && (
            <>
              <Divider hidden />

              <p>
                {t(ssoIsEnabled() ? "entry.login.ssoLoginWithSimplyDo" : "entry.login.info", {
                  appName: util.appName(),
                })}
              </p>
              {errorMessage && !loading && (
                <Message size="small" warning>
                  <Icon name="warning circle" />
                  {errorMessage}
                </Message>
              )}
              <Form>
                <Form.Field>
                  {proceedToLogin && otherOrgs.length ? (
                    <Message info>
                      You already have a Simply Do account with{" "}
                      {otherOrgs.length > 1
                        ? `the following organisations: ${otherOrgs.map((o) => o.name).join(", ")}`
                        : otherOrgs[0].name}
                      . You can use your existing account details to login to {organisation.name}.
                    </Message>
                  ) : null}
                  <label>{t("entry.login.email")}</label>
                  <input
                    tabIndex="1"
                    autoFocus
                    value={email}
                    onChange={(e) => onUpdateEmail(e.target.value)}
                    name="email"
                    type="email"
                    placeholder="mary@example.com"
                  />
                </Form.Field>
                {showCustomChallenge && (
                  <Form.Field>
                    <Form.Input
                      autoFocus
                      fluid
                      label={organisation?.registrationChallenge?.question}
                      value={challengeResponse}
                      onChange={(e) => onUpdateChallengeResponse(e.target.value)}
                    />
                  </Form.Field>
                )}
                {organisation.loginLinkEnabled && proceedToLogin ? (
                  <Button tabIndex="3" onClick={loginMagicLink} primary fluid type="submit">
                    Send magic login link
                  </Button>
                ) : null}
                {!organisation.loginLinkEnabled ? (
                  <>
                    <Form.Field
                      style={{
                        visibility: showPasswordEntry && proceedToLogin ? "visible" : "hidden",
                        maxHeight: showPasswordEntry && proceedToLogin ? "unset" : "0px",
                      }}
                    >
                      <label>
                        {t("entry.login.password")}
                        <Link tabIndex="4" to="/password/forgotten" style={{ float: "right" }}>
                          {t("entry.forgottenPassword.title")}{" "}
                        </Link>
                      </label>
                      <input
                        autoFocus
                        tabIndex="2"
                        value={password}
                        onChange={(e) => onUpdatePassword(e.target.value)}
                        name="password"
                        type="password"
                        placeholder={t("entry.login.yourPass")}
                      />
                    </Form.Field>
                    {proceedToLogin ? (
                      <Button
                        tabIndex="3"
                        style={{ marginTop: 30 }}
                        fluid
                        loading={loading}
                        type="submit"
                        primary
                        onClick={login}
                      >
                        {t("entry.login.title")}
                      </Button>
                    ) : null}
                  </>
                ) : null}
                {!proceedToLogin ? (
                  <Button
                    tabIndex="2"
                    style={{ marginTop: 30 }}
                    fluid
                    loading={loading}
                    type="submit"
                    primary
                    onClick={() => checkAccount()}
                  >
                    {t("generic.continue")}
                  </Button>
                ) : null}
              </Form>
            </>
          )}
        </>
      )}

      <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={otpModal}>
        <Modal.Header>
          <Icon name="lock" style={{ paddingRight: "5px" }} />
          Multi-factor authentication
          <br />
        </Modal.Header>
        <ModalContent>
          <p>
            To complete your login, please enter the six digit one-time password generated by your authentication app.
          </p>
          <div style={{ textAlign: "center" }}>
            <Input
              onKeyDown={(e) => {
                e.keyCode === 13 && login();
              }}
              onChange={(e) => setOtp(e.target.value)}
              value={otp}
              placeholder="123456"
              size="large"
              autoFocus
            />
          </div>
        </ModalContent>
        <ModalActions>
          <Button
            content={t("generic.cancel")}
            onClick={() => {
              setOtpModal(false);
              onUpdateLoading(false);
              setOtp("");
            }}
          />
          <Button primary content="Continue" loading={loading} onClick={login} />
        </ModalActions>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { errorMessage, utmLink, utmChallenge } = state.auth;
  const { email, password, challengeResponse, loading } = state.entry;
  return {
    organisation: state.organisations.urlOrganisation,
    email,
    password,
    challengeResponse,
    loading,
    errorMessage,
    utmLink,
    utmChallenge,
  };
};
const mapDispatchToProps = (dispatch) => ({
  onSelectOrganisation: (org) => dispatch(actions.organisations.receiveUrlOrganisation(org)),
  onUpdateEmail: (e) => dispatch(actions.entry.updateEmail(e)),
  onUpdatePassword: (p) => dispatch(actions.entry.updatePassword(p)),
  onUpdateChallengeResponse: (r) => dispatch(actions.entry.updateChallengeResponse(r)),
  onUpdateLoading: (l) => dispatch(actions.entry.updateLoading(l)),
});
const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);

export default withTranslation()(LoginContainer);
