import React from "react";
import util from "utils/utils";
import { Trans } from "react-i18next";

export default function IdeaSubmittedContent({ events, ownerChallenge = { name: "Deleted" } }) {
  return (
    <Trans i18nKey="notifications.ideaSubmitted">
      <b>{{ name: util.getEventUsers(events) }}</b>
      submitted the idea
      {events.length > 1 ? "s" : ""}
      <b>
        {{
          idea: `${events.map((e, index) => {
            let name = index < events.length && index !== 0 ? " " : "";
            name += e.ownerIdea.name || "[Deleted]";
            return name;
          })}`,
        }}
      </b>
      to your challenge
      <b>{{ challenge: ownerChallenge.name || "Deleted" }}</b>
    </Trans>
  );
}
