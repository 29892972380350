import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Segment, Modal, Image, Icon, Popup } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";
import styled from "styled-components";
import toast from "react-hot-toast";
import api from "api";

import utils from "utils/utils";
import BusinessProfileEditor from "./BusinessProfileEditor";
import FullBusinessProfile from "./FullBusinessProfile";
import BusinessProfileSelector from "./ProfileSelector";
import { useTranslation } from "react-i18next";

const IdeaSection = styled.div`
  > h5 {
    display: block;
    margin: 0;
  }
  > .title-explainer {
    color: gray;
    display: block;
    margin: 2.5px 0 20px;
  }
`;

const DefaultCompanyDetails: Other.ICompanyDetails = {
  name: "",
  address: {
    streetAddress: "",
    country: "",
    city: "",
  },
  phoneNumber: "",
  websiteUrl: "",
  imageUrl: "",
  crunchbaseId: "",
  primaryContact: {
    fullName: "",
    email: "",
    phoneNumber: "",
    jobTitle: "",
  },
};

type IIdeaBusinessProfileComponent = {
  idea: Other.IIdea;
  isEditing?: boolean;
  style?: object;
  updateIdea: (updatedValue: any, path: string[], suppressSave?: boolean) => void;
};

const IdeaBusinessProfileComponent = ({ idea, isEditing, style, updateIdea }: IIdeaBusinessProfileComponent) => {
  // Track state of the profile editor - what stage the user is at
  const [creditsafeEnabled, setCreditsafeEnabled] = useState(false);
  const [showCreditsafeLookup, setShowCreditsafeLookup] = useState(false);
  const [hasHandledShowingCreditsafeLookuo, setHasHandledShowingCreditsafeLookuo] = useState(false);
  const [selectedCreditsafeCompanyDetails, setSelectedCreditsafeCompanyDetails] =
    useState<Partial<Other.ICompanyDetails> | null>(null);
  const [companyDetails, setCompanyDetails] = useState<Other.ICompanyDetails>(DefaultCompanyDetails);

  const onUseSelectedCreditsafeCompanyDetails = useCallback(() => {
    setCompanyDetails((prevState) => ({
      ...prevState,
      ...selectedCreditsafeCompanyDetails,
    }));
    setSelectedCreditsafeCompanyDetails(null);
    setShowCreditsafeLookup(false);
  }, [selectedCreditsafeCompanyDetails]);

  const { t } = useTranslation();

  const [saving, setSaving] = useState<boolean>(false);

  // The user's existing (previously created) organisation profiles
  const [loadingExistingProfiles, setLoadingExistingProfiles] = useState<boolean>(false);
  const [existingProfiles, setExistingProfiles] = useState<Other.IIdeaBusinessProfile[]>([]);

  const [generatingCreditReport, setGeneratingCreditReport] = useState<boolean>(false);

  const [businessProfileEditorOpen, setBusinessProfileEditorOpen] = useState<boolean>(false);
  const [editingBusinessProfile, setEditingBusinessProfile] = useState<string>("");

  // _id of profile being deleted, if applicable
  const [deletingBusinessProfile, setDeletingBusinessProfile] = useState<string>("");

  const ideaId = idea?._id;
  const challengeId = idea?.challenge;
  const ideaBusinessProfile = idea["lockedOwnerIdeaBusinessProfile"];

  const creditsafeRegNo = companyDetails?.creditsafeRegNo;
  useEffect(() => {
    if (!hasHandledShowingCreditsafeLookuo) {
      api.challenges.get(
        challengeId,
        (challenge) => {
          setHasHandledShowingCreditsafeLookuo(true);
          setCreditsafeEnabled(challenge.creditsafeAvailable);
          if (!creditsafeRegNo) {
            setShowCreditsafeLookup(challenge.creditsafeAvailable);
          } else {
            setShowCreditsafeLookup(false);
          }
        },
        () => {
          setShowCreditsafeLookup(false);
        },
      );
    }
  }, [challengeId, creditsafeRegNo, hasHandledShowingCreditsafeLookuo]);

  // Attach a new, or previously created organisation profile
  // Once done, update the idea locally and display the preview of the organisation profile
  const attachBusinessProfile = useCallback(
    (ideaBusinessProfile) => updateIdea(ideaBusinessProfile, ["ideaBusinessProfile"]),
    [updateIdea],
  );

  const unattachBusinessProfile = useCallback(() => {
    utils
      .confirm(
        `Remove ${ideaBusinessProfile?.profile.name} from this ${t("generic.idea")}`,
        `Are you sure you want to remove the organisation profile ${ideaBusinessProfile?.profile.name} from this ${t("generic.idea")}?`,
      )
      .then(() => {
        updateIdea(null, ["ideaBusinessProfile"]);
        updateIdea(null, ["lockedOwnerIdeaBusinessProfile"], true);
      });
  }, [updateIdea, ideaBusinessProfile, t]);

  const viewReportForCompany = useCallback(() => {
    if (ideaBusinessProfile?._id) {
      setGeneratingCreditReport(true);
      api.users.generateIdeaBusinessProfileCreditReport(
        ideaBusinessProfile?._id,
        ({ success, creditsafeReportUrl }) => {
          if (!success) {
            toast.error("Could not generate CreditSafe report");
            setGeneratingCreditReport(false);
            return;
          }
          updateIdea(creditsafeReportUrl, ["lockedOwnerIdeaBusinessProfile", "creditsafeReportUrl"], true);
          setGeneratingCreditReport(false);
          window.open(creditsafeReportUrl, "_blank", "noopener noreferrer");
        },
        (err) => {
          toast.error(err.message);
          setGeneratingCreditReport(false);
        },
      );
    }
  }, [ideaBusinessProfile?._id, updateIdea]);

  useEffect(() => {
    setLoadingExistingProfiles(true);
    api.users.getExistingProfiles(
      ({ profiles }) => {
        setLoadingExistingProfiles(false);
        setExistingProfiles(profiles);
      },
      (err) => {
        setLoadingExistingProfiles(false);
        toast.error(err.message);
      },
    );
  }, []);

  // Create new profile, if needed
  // If we are editing, save the edit instead
  const saveProfile = useCallback(() => {
    if (!editingBusinessProfile) {
      setSaving(true);
      api.users.createProfile(
        companyDetails,
        ({ ideaBusinessProfile: newIdeaBusinessProfile }) => {
          toast.success("Profile updated");
          setExistingProfiles((prevProfiles) => [...prevProfiles, newIdeaBusinessProfile]);
          setSaving(false);
          setBusinessProfileEditorOpen(false);
          attachBusinessProfile(newIdeaBusinessProfile._id);
          updateIdea(newIdeaBusinessProfile, ["lockedOwnerIdeaBusinessProfile"], true);
          updateIdea(newIdeaBusinessProfile._id, ["ideaBusinessProfile"]);
        },
        (err) => {
          toast.error(err.message);
          setSaving(false);
        },
      );
    } else {
      setSaving(true);
      api.users.updateProfile(
        editingBusinessProfile,
        companyDetails,
        ({ ideaBusinessProfile: updatedIdeaBusinessProfile }) => {
          toast.success("Profile updated");
          setExistingProfiles((prevProfiles) => [
            ...prevProfiles.map((prevProfile) => {
              if (prevProfile._id !== editingBusinessProfile) return prevProfile;
              return updatedIdeaBusinessProfile;
            }),
          ]);
          setEditingBusinessProfile("");
          updateIdea(updatedIdeaBusinessProfile, ["lockedOwnerIdeaBusinessProfile"], true);
          updateIdea(updatedIdeaBusinessProfile._id, ["ideaBusinessProfile"]);
          setSaving(false);
          setBusinessProfileEditorOpen(false);
        },
        (err) => {
          toast.error(err.message);
          setSaving(false);
        },
      );
    }
  }, [companyDetails, editingBusinessProfile, attachBusinessProfile, updateIdea]);

  const deleteProfile = useCallback(
    (ideaBusinessProfile) => {
      const existingBusinessProfile = existingProfiles.find((p) => p._id === ideaBusinessProfile);
      if (!existingBusinessProfile) return;

      utils
        .confirm(
          `Really delete the organisation profile ${existingBusinessProfile.profile.name}?`,
          `Deleting the organisation profile ${existingBusinessProfile.profile.name} is permanent and cannot be undone.`,
        )
        .then(() => {
          setDeletingBusinessProfile(ideaBusinessProfile);
          // Attempt to delete the profile.
          // If the profile is attached to another idea, we alert the user, and don't continue with this action

          api.users.deleteProfile(
            ideaBusinessProfile,
            ({ success: deletingSuccess, reason }: { success: boolean; reason: string }) => {
              if (!deletingSuccess) {
                toast(reason);
                setDeletingBusinessProfile("");
              } else {
                // Have to take a number of actions if deleting and unattaching was successful
                // Update the local states, so the organisation profile has been cleared from:
                // - The local idea state
                // - The local existing profiles
                // - The active company details
                // Then also close the editor
                updateIdea(null, ["lockedOwnerIdeaBusinessProfile"], true);
                updateIdea(null, ["ideaBusinessProfile"]);
                setExistingProfiles((prevProfiles) => prevProfiles.filter((p) => p._id !== ideaBusinessProfile));
                setEditingBusinessProfile("");
                setCompanyDetails(DefaultCompanyDetails);
                setBusinessProfileEditorOpen(false);
              }
            },
            (err) => {
              toast.error(err.message);
              setDeletingBusinessProfile("");
            },
          );
        });
    },
    [existingProfiles, updateIdea],
  );

  // Given a organisation profile id to edit, set the details
  const setEditingProfile = useCallback(
    (profileId: string) => {
      const profileObject = existingProfiles.find((p) => p._id === profileId);
      if (profileObject) {
        setCompanyDetails(profileObject.profile);
        setEditingBusinessProfile(profileId);
        setBusinessProfileEditorOpen(true);
        setShowCreditsafeLookup(false);
      }
    },
    [existingProfiles],
  );

  const createNewCompany = useCallback(() => {
    setEditingBusinessProfile("");
    setCompanyDetails(DefaultCompanyDetails);
    setBusinessProfileEditorOpen(true);
  }, []);

  const editingFoundCompany = useMemo(
    () =>
      companyDetails.crunchbaseId
        ? companyDetails
        : existingProfiles.find((existingProfile) => existingProfile._id === editingBusinessProfile)?.profile,
    [companyDetails, existingProfiles, editingBusinessProfile],
  );

  const editorProps = {
    setCompanyDetails,
  };

  if (!isEditing && !ideaBusinessProfile) {
    return null;
  }

  return (
    <IdeaSection style={style}>
      <h3>
        Organisation details
        {isEditing && idea.ideaTemplate?.requireIdeaBusinessProfiles ? (
          <Popup
            size="small"
            trigger={
              <Icon name="asterisk" style={{ position: "relative", top: -5, marginLeft: 3 }} color="red" size="tiny" />
            }
            content={`This field must be filled in to submit your ${t("generic.idea")}`}
          />
        ) : null}
      </h3>
      {isEditing ? (
        <span className="title-explainer">
          Please provide your organisation's details to supplement your {t("generic.idea")}.
        </span>
      ) : (
        <div style={{ marginBottom: 5 }} />
      )}

      {!isEditing ? (
        <FullBusinessProfile
          viewReportForCompany={viewReportForCompany}
          generatingCreditReport={generatingCreditReport}
          ideaBusinessProfile={ideaBusinessProfile}
          idea={idea}
        />
      ) : (
        [
          ideaBusinessProfile ? (
            <Segment>
              <FullBusinessProfile
                idea={idea}
                ideaBusinessProfile={ideaBusinessProfile}
                viewReportForCompany={viewReportForCompany}
                generatingCreditReport={generatingCreditReport}
              />
            </Segment>
          ) : null,
          <BusinessProfileSelector
            key={ideaBusinessProfile?._id}
            attachedBusinessProfile={ideaBusinessProfile}
            loadingExistingProfiles={loadingExistingProfiles}
            existingProfiles={existingProfiles}
            createNewCompany={createNewCompany}
            attachBusinessProfile={(ideaBusinessProfile) => attachBusinessProfile(ideaBusinessProfile)}
          />,
          ideaBusinessProfile ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
                marginBottom: 5,
              }}
            >
              <Button
                basic
                content="Edit profile"
                icon="edit"
                onClick={() => setEditingProfile(ideaBusinessProfile._id)}
                size="small"
              />
              <Button
                basic
                content={`Remove from ${t("generic.idea")}`}
                icon="trash"
                onClick={() => unattachBusinessProfile()}
                size="small"
                style={{ marginRight: 0 }}
              />
            </div>
          ) : null,
        ].filter((segment) => !!segment)
      )}

      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={businessProfileEditorOpen}
        onClose={() => setBusinessProfileEditorOpen(false)}
      >
        <Modal.Header>
          {editingFoundCompany ? (
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                {editingFoundCompany.imageUrl ? (
                  <Image
                    avatar
                    style={{ objectFit: "contain", width: 45, height: 30 }}
                    src={editingFoundCompany.imageUrl}
                  />
                ) : (
                  <Icon name="building" size="large" />
                )}
                <span style={{ display: "block", margin: "0 0 0 5px" }}>
                  Editing organisation profile for {editingFoundCompany.name}
                </span>
              </div>

              {editingBusinessProfile ? (
                <Button
                  icon="trash"
                  content="Delete profile"
                  floated="right"
                  basic
                  onClick={() => deleteProfile(editingBusinessProfile)}
                  loading={deletingBusinessProfile === editingBusinessProfile}
                />
              ) : null}
            </div>
          ) : (
            "Create new organisation profile"
          )}
        </Modal.Header>
        <Modal.Content scrolling style={{ height: "100%" }}>
          <BusinessProfileEditor
            {...editorProps}
            ideaId={ideaId}
            companyDetails={companyDetails}
            selectedCreditsafeCompanyDetails={selectedCreditsafeCompanyDetails}
            setSelectedCreditsafeCompanyDetails={setSelectedCreditsafeCompanyDetails}
            showCreditsafeLookup={showCreditsafeLookup}
            creditsafeEnabled={creditsafeEnabled}
          />
        </Modal.Content>
        <Modal.Actions>
          {showCreditsafeLookup && creditsafeEnabled && (
            <>
              <Button
                icon="arrow right"
                labelPosition="right"
                content="Enter organisation details manually"
                onClick={() => setShowCreditsafeLookup(false)}
              />
              {selectedCreditsafeCompanyDetails ? (
                <Button
                  icon="check"
                  content="Use selected organisation details"
                  primary
                  labelPosition="right"
                  onClick={onUseSelectedCreditsafeCompanyDetails}
                />
              ) : null}
            </>
          )}

          {creditsafeEnabled && !showCreditsafeLookup ? (
            <Button
              icon="arrow left"
              labelPosition="left"
              content="Return to search"
              onClick={() => setShowCreditsafeLookup(true)}
            />
          ) : null}
          <Button
            disabled={!companyDetails.name}
            primary
            onClick={() => saveProfile()}
            content="Save profile"
            icon="check"
            loading={saving}
          />
        </Modal.Actions>
      </Modal>
    </IdeaSection>
  );
};

export default IdeaBusinessProfileComponent;
