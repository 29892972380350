import React from "react";
import { Popup, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import util from "utils/utils";
import styled from "styled-components";

import { StyledLandscapeSearchItem } from "./SearchItem";
import { ImageWithFallback } from "../ImageWithFallback";

const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  justify-content: center;
`;

function UserSearchItem({ user, active, onClick }) {
  const { profile = {} } = user;
  const { fullName = "", jobTitle = "", department = "" } = profile;
  return (
    <StyledLandscapeSearchItem onClick={onClick} $active={active} as={Link} to={`/users/${user._id}`}>
      <ImageWithFallback
        avatar
        src={util.avatarUrl(user)}
        fallbackSrc={util.avatarUrl()}
        style={{ height: "1.3rem", width: "1.3rem" }}
      />
      <StyledInfoContainer>
        <p style={{ marginBottom: 0 }}>
          {fullName}
          {user.iconRoles?.length ? (
            <Popup
              content={`This user has the following role: ${user.iconRoles[0].name}`}
              trigger={
                <Icon
                  name={user.iconRoles[0].icon.name}
                  color={user.iconRoles[0].icon.colour}
                  style={{ marginLeft: 5 }}
                />
              }
            />
          ) : null}
        </p>
        {(jobTitle || department) && (
          <p style={{ marginBottom: 0, color: "#4e585c", fontSize: 12 }}>
            {jobTitle}
            {jobTitle && department && " - "}
            {department}
          </p>
        )}
      </StyledInfoContainer>
    </StyledLandscapeSearchItem>
  );
}

export default UserSearchItem;
