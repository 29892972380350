import React, { useCallback } from "react";
import { Card, Button, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { UserChip } from "components/lib/Chips";
import util from "utils/utils";
import { InviteCardProps } from "./types";
import InviteeChip from "./InviteeChip";
import { useTranslation } from "react-i18next";

const IdeaInviteCard = ({ invitation, acceptInvite, rejectInvite, removeInvite }: InviteCardProps) => {
  const { inviterUser, inviteeUser, invitee, idea, createdByCurrentUser } = invitation;

  const { t } = useTranslation();

  const accept = useCallback(() => {
    util
      .confirm(
        `Join ${t("generic.idea")} team`,
        `Are you sure you want to collaborate with ${inviterUser?.profile?.fullName} on the ${t("generic.idea")} "${idea.name}"?`,
      )
      .then(() => {
        acceptInvite(
          invitation,
          () => {
            toast.success(`Joined ${t("generic.idea")} team`);
          },
          () => toast.error(`Error joining ${t("generic.idea")} team`),
        );
      })
      .catch(() => {});
  }, [acceptInvite, invitation, idea?.name, inviterUser?.profile?.fullName, t]);

  const reject = useCallback(() => {
    util
      .confirm(
        "Reject join",
        `Are you sure you want to reject ${inviterUser?.profile?.fullName} invitation to join the ${t("generic.idea")} "${idea?.name || `Deleted ${t("generic.idea")}`}"?`,
      )
      .then(() => {
        rejectInvite(
          invitation,
          () => {
            toast("Invitation rejected");
          },
          () => toast.error("Error rejecting invite"),
        );
      })
      .catch(() => {});
  }, [rejectInvite, invitation, idea?.name, inviterUser?.profile?.fullName, t]);

  const remove = useCallback(() => {
    util
      .confirm(
        "Remove invitation",
        `Are you sure you want to remove your invitation to let ${inviteeUser?.profile?.fullName || invitee} join your ${t("generic.idea")} "${idea?.name || `Deleted ${t("generic.idea")}`}"?`,
      )
      .then(() => {
        removeInvite(
          invitation,
          () => {
            toast("Invitation removed");
          },
          () => toast.error("Error removing invite"),
        );
      })
      .catch(() => {});
  }, [removeInvite, invitation, idea?.name, inviteeUser?.profile?.fullName, invitee, t]);

  if (!idea) {
    invitation.canAccept = false;
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <div>
        <Header as={Link} disabled={!idea} to={idea ? `/ideas/${idea._id}` : null}>
          {idea?.name || `Deleted ${t("generic.idea")}`}
        </Header>
        <Card.Description>
          {createdByCurrentUser ? (
            <InviteeChip invitation={invitation} preText="You invited" postText={`to join this ${t("generic.idea")}`} />
          ) : (
            <UserChip user={inviterUser} postText={`has invited you to join this ${t("generic.idea")}`} />
          )}
        </Card.Description>
      </div>
      <Card.Content extra>
        <div className="ui">
          {invitation.acceptedAt ? (
            <Button basic color="green" as={Link} disabled={!idea} to={`/ideas/${idea?._id}`}>
              Go to {t("generic.idea")}
            </Button>
          ) : (
            <>
              {invitation.canAccept ? (
                <Button basic color="green" onClick={accept}>
                  Accept
                </Button>
              ) : null}
              {invitation.canRemove ? (
                <Button
                  basic
                  color={createdByCurrentUser ? null : "red"}
                  onClick={createdByCurrentUser ? remove : reject}
                >
                  {createdByCurrentUser ? "Remove" : "Reject"}
                </Button>
              ) : null}
            </>
          )}
        </div>
      </Card.Content>
    </div>
  );
};

export default IdeaInviteCard;
