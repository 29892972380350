const challengesActions = {
  RECEIVE_CHALLENGE: "RECEIVE_CHALLENGE",
  UPDATE_CHALLENGE: "UPDATE_CHALLENGE",
  UPDATE_IDEA_FILTERS: "UPDATE_IDEA_FILTERS",
  UPDATE_CHALLENGE_FILTERS: "UPDATE_CHALLENGE_FILTERS",
  UPDATE_DISCOVER_FILTERS: "UPDATE_DISCOVER_FILTERS",
  DELETE_CHALLENGE: "DELETE_CHALLENGE",
  SET_IDEA_VIEW_TYPE: "SET_IDEA_VIEW_TYPE",
  SET_CHALLENGES_NEXT_PAGE: "CHALLENGES_NEXT_PAGE",
  SET_CHALLENGES_PREV_PAGE: "CHALLENGES_PREV_PAGE",
  SET_CHALLENGES_PAGES_AVAILABLE: "CHALLENGES_PAGE_AVAILABLE",
  SET_DISCOVER_NEXT_PAGE: "SET_DISCOVER_NEXT_PAGE",
  SET_DISCOVER_PREV_PAGE: "SET_DISCOVER_PREV_PAGE",
  SET_DISCOVER_PAGES_AVAILABLE: "SET_DISCOVER_PAGES_AVAILABLE",

  receive(challenge) {
    return { type: this.RECEIVE_CHALLENGE, challenge };
  },
  update(id, data) {
    return { type: this.UPDATE_CHALLENGE, id, data };
  },
  updateIdeaFilters(data) {
    return { type: this.UPDATE_IDEA_FILTERS, data };
  },
  updateChallengeFilters(data) {
    return { type: this.UPDATE_CHALLENGE_FILTERS, data };
  },
  updateDiscoverFilters(data) {
    return { type: this.UPDATE_DISCOVER_FILTERS, data };
  },
  setIdeaViewType(ideaViewType) {
    return { type: this.SET_IDEA_VIEW_TYPE, ideaViewType };
  },
  delete(id) {
    return { type: this.DELETE_CHALLENGE, id };
  },
  setChallengesNextPage() {
    return { type: this.SET_CHALLENGES_NEXT_PAGE };
  },
  setChallengesPrevPage() {
    return { type: this.SET_CHALLENGES_PREV_PAGE };
  },
  setChallengesPagesAvailable({ nextPageAvailable, prevPageAvailable }) {
    return { type: this.SET_CHALLENGES_PAGES_AVAILABLE, nextPageAvailable, prevPageAvailable };
  },
  setDiscoverNextPage() {
    return { type: this.SET_DISCOVER_NEXT_PAGE };
  },
  setDiscoverPrevPage() {
    return { type: this.SET_DISCOVER_PREV_PAGE };
  },
  setDiscoverPagesAvailable({ nextPageAvailable, prevPageAvailable }) {
    return { type: this.SET_DISCOVER_PAGES_AVAILABLE, nextPageAvailable, prevPageAvailable };
  },
};

export default challengesActions;
