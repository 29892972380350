import React from "react";
import styled from "styled-components";

const StyledSeparator = styled.div`
  margin: 40px -1rem 40px -1rem;
  border-top: 1px dashed ${({ $includeSection }) => $includeSection?.colour || "gray"};
`;

function FieldSeparator({ includeSection }) {
  return <StyledSeparator $includeSection={includeSection} />;
}

export default FieldSeparator;
