import actions from "actions";

const initialState = {
  challenges: [],
  ideaFilter: "",
  tagFilter: [],
  ideaIncludes: null,
  groupFilter: [],
  stampsFilter: [],
  orgFilter: [],
  ideaOrder: "default",
  ideaOrderDirection: "desc",
  ideaPage: 1,
  ideaLimit: 16,
  ideaFieldChoices: [],
  projectBoardLanesFilter: [],
  postFetchPreviewIndex: null,
  ideaViewType: "card",
  challengeFilter: "",
  challengeAuthor: "everyone",
  challengeIncludes: ["published", "closed", "draft"],
  challengeOrder: "newest",
  challengePage: 1,
  challengeLimit: 8,
  challengeNextPageAvailable: false,
  challengePrevPageAvailable: false,
  discoverFilter: "",
  discoverIncludes: ["published"],
  discoverOrder: "newest",
  discoverPage: 1,
  discoverNextPageAvailable: false,
  discoverPrevPageAvailable: false,
  filtersVisible: false,
  adminHasCommented: "all",
  projectBoardFilter: [],
  impactFilter: [],
};

export const defaultInitialState = { ...initialState };

export type ChallengesReducer = typeof initialState;

function challenges(state = initialState, action) {
  switch (action.type) {
    case actions.challenges.RECEIVE_CHALLENGE: {
      const newChallenges = [];
      const stateChallenges = Object.assign([], state?.challenges || []);
      let found = false;
      stateChallenges.forEach((challenge) => {
        if (challenge._id === action.challenge._id) {
          newChallenges.push(action.challenge);
          found = true;
        } else newChallenges.push(challenge);
      });
      if (!found) newChallenges.push(action.challenge);
      return { ...state, challenges: newChallenges };
    }
    case actions.challenges.UPDATE_CHALLENGE:
      return {
        ...state,
        challenges: state.challenges.map((p) => {
          if (p._id === action.id) return { ...p, ...action.data };
          return { ...p };
        }),
      };
    case actions.challenges.UPDATE_IDEA_FILTERS:
      return { ...state, ...action.data };
    case actions.challenges.SET_IDEA_VIEW_TYPE: {
      return {
        ...state,
        ideaViewType: action.ideaViewType,
        ideaLimit: action.ideaViewType === "card" ? 16 : 30,
      };
    }
    case actions.challenges.UPDATE_CHALLENGE_FILTERS:
      return { ...state, ...action.data };
    case actions.challenges.SET_CHALLENGES_NEXT_PAGE: {
      const { challengePage, challengeNextPageAvailable } = state;
      if (!challengeNextPageAvailable) return state;
      return { ...state, challengePage: challengePage + 1 };
    }
    case actions.challenges.SET_CHALLENGES_PREV_PAGE: {
      const { challengePage, challengePrevPageAvailable } = state;
      if (!challengePrevPageAvailable || challengePage === 1) return state;
      return { ...state, challengePage: challengePage - 1 };
    }
    case actions.challenges.SET_CHALLENGES_PAGES_AVAILABLE:
      return {
        ...state,
        challengeNextPageAvailable: action.nextPageAvailable,
        challengePrevPageAvailable: action.prevPageAvailable,
      };
    case actions.challenges.SET_DISCOVER_NEXT_PAGE: {
      const { discoverPage, discoverNextPageAvailable } = state;
      if (!discoverNextPageAvailable) return state;
      return { ...state, discoverPage: discoverPage + 1 };
    }
    case actions.challenges.SET_DISCOVER_PREV_PAGE: {
      const { discoverPage, discoverPrevPageAvailable } = state;
      if (!discoverPrevPageAvailable) return state;
      return { ...state, discoverPage: discoverPage - 1 };
    }
    case actions.challenges.SET_DISCOVER_PAGES_AVAILABLE:
      return {
        ...state,
        discoverNextPageAvailable: action.nextPageAvailable,
        discoverPrevPageAvailable: action.prevPageAvailable,
      };
    case actions.user.GHOST:
      return {
        ...state,
        challengePage: 1,
        challengeNextPageAvailable: false,
        challengePrevPageAvailable: false,
      };
    case actions.user.UNGHOST:
      return {
        ...state,
        challengePage: 1,
        challengeNextPageAvailable: false,
        challengePrevPageAvailable: false,
      };
    case actions.challenges.UPDATE_DISCOVER_FILTERS:
      return { ...state, ...action.data };
    case actions.challenges.DELETE_CHALLENGE: {
      const filteredChallenges = state.challenges.filter((c) => c._id !== action._id);
      return { ...state, challenges: filteredChallenges };
    }
    default:
      return state;
  }
}

export default challenges;
