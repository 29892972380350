import React from "react";
import util from "utils/utils";
import { Trans, useTranslation } from "react-i18next";

export default function IdeaVotedContent({ events, ownerIdea = { name: "Unknown" } }) {
  const { t } = useTranslation();
  const textSuffix = events[0]?.isFollower ? `${t("generic.ideaWithArticle")} you follow` : `your ${t("generic.idea")}`;
  return (
    <Trans i18nKey="notifications.ideaVoted">
      <b>{{ name: util.getEventUsers(events) }}</b> liked {{ suffix: textSuffix }} <b> {{ idea: ownerIdea.name }}</b>
    </Trans>
  );
}
