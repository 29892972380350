import { Dispatch, SetStateAction, useState, useCallback, useEffect } from "react";
import { Form, Divider, Button, Popup, Dropdown, Label, Icon, Card } from "semantic-ui-react";
import styled from "styled-components";
import api from "api";
import { Other } from "simplydo/interfaces";
import toast from "react-hot-toast";

import AdvancedProfileEditor from "./AdvancedProfileEditor";
import PrimaryContactEditor from "./PrimaryContactEditor";
import util from "utils/utils";
import { countries } from "utils/countries";
import useThrottle from "utils/useThrottle";
import constants from "utils/constants";

const EditorContainer = styled.div`
  height: 100%;
`;

const EditorContent = styled.div`
  height: 100%;
`;

const AccreditationsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  .ui.label {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const SuggestedCompaniesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CompanyCardHeader = styled(Card.Header)`
  font-size: 1em;
  color: black;
  font-weight: 600;
`;

const CompanyCardMeta = styled(Card.Meta)`
  font-size: 0.8em;
  color: grey;
`;

type IBusinessProfileEditor = {
  ideaId: string;
  companyDetails: Other.ICompanyDetails;
  setCompanyDetails: Dispatch<SetStateAction<Other.ICompanyDetails>>;
  selectedCreditsafeCompanyDetails: Partial<Other.ICompanyDetails> | null;
  setSelectedCreditsafeCompanyDetails: Dispatch<SetStateAction<Partial<Other.ICompanyDetails> | null>>;
  showCreditsafeLookup: boolean;
  creditsafeEnabled: boolean;
};

export const businessTypeOptions = [
  { key: "public", value: "public", text: "Public Sector" },
  { key: "private", value: "private", text: "Private Sector" },
  { key: "notForProfit", value: "notForProfit", text: "Not For-Profit" },
  { key: "academic", value: "academic", text: "Academic" },
];

export const businessSizeOptions = [
  { key: "micro", value: "micro", text: "Micro <10 Employees" },
  { key: "small", value: "small", text: "Small <50 Employees" },
  { key: "medium", value: "medium", text: "Medium <250 Employees" },
  { key: "large", value: "large", text: "Large >250 Employees" },
];

export const businessStatusOptions = [
  { key: "preStartup", value: "preStartup", text: "Pre-start-up" },
  { key: "startup", value: "startup", text: "Start up < 1 year" },
  { key: "established_small", value: "established_small", text: "Established 1 - 5 years" },
  { key: "established_medium", value: "established_medium", text: "Established 6 - 10 years" },
  { key: "established_large", value: "established_large", text: "Established > 10 years" },
];

const BusinessProfileEditor = ({
  ideaId,
  companyDetails,
  setCompanyDetails,
  selectedCreditsafeCompanyDetails,
  setSelectedCreditsafeCompanyDetails,
  showCreditsafeLookup,
  creditsafeEnabled,
}: IBusinessProfileEditor) => {
  const [advancedSearchResults, setAdvancedSearchResults] = useState<{
    companies: any[];
  }>({
    companies: [],
  });
  const [generatingDescription, setGeneratingDescription] = useState(false);
  const [customAccreditation, setCustomAccreditation] = useState("");
  const [addingCustomAccreditation, setAddingCustomAccreditation] = useState(false);
  const [country, setCountry] = useState("GB");
  const [companyNumber, setCompanyNumber] = useState("");

  const companyName = companyDetails.name;

  const searchForCreditsafeCompanies = useThrottle(
    () => {
      if (creditsafeEnabled && showCreditsafeLookup && (companyName || companyNumber || country)) {
        api.verifications.searchForCompanies(
          country,
          companyNumber,
          companyName,
          "idea",
          ideaId,
          (data) => {
            setAdvancedSearchResults(data);
          },
          (err) => {
            toast.error(err.message);
          },
          undefined,
        );
      }
    },
    400,
    [companyName, country, companyNumber, creditsafeEnabled, showCreditsafeLookup],
  );

  useEffect(() => {
    searchForCreditsafeCompanies();
  }, [searchForCreditsafeCompanies]);

  const onClickCompany = useCallback(
    (company) => {
      if (selectedCreditsafeCompanyDetails?.creditsafeCompanyId === company.id) {
        setSelectedCreditsafeCompanyDetails(null);
      } else {
        setSelectedCreditsafeCompanyDetails({
          name: company.name,
          address: {
            city: company.address.city ?? "",
            country: company.country ?? "",
            streetAddress: company.address?.street ?? "",
            postcode: company.address?.postCode ?? "",
          },
          phoneNumber: company.phoneNumbers?.length ? company.phoneNumbers[0] : "",
          status: company.status,
          creditsafeCompanyId: company.id,
          creditsafeRegNo: company.regNo,
          vatNo: company?.vatNo?.length ? company.vatNo[0] : "",
        });
      }
    },
    [selectedCreditsafeCompanyDetails?.creditsafeCompanyId, setSelectedCreditsafeCompanyDetails],
  );

  const generateDescription = useCallback(() => {
    setGeneratingDescription(true);
    api.users.generateBusinessDescription(
      {
        companyName,
        websiteUrl: companyDetails.websiteUrl,
      },
      ({ success, error, description }) => {
        if (!success) {
          toast.error(error);
          setGeneratingDescription(false);
        } else {
          setCompanyDetails((prevState) => ({ ...prevState, description }));
          setGeneratingDescription(false);
        }
      },
      (err) => {
        toast.error(err.message);
        setGeneratingDescription(false);
      },
    );
  }, [companyDetails.websiteUrl, companyName, setCompanyDetails]);

  return (
    <EditorContainer>
      <EditorContent>
        {creditsafeEnabled && showCreditsafeLookup ? (
          <Form>
            <h3>Search for your organisation</h3>
            <Form.Field>
              <label>Organisation name</label>
              <Form.Input
                required
                placeholder="Type your organisation name.."
                value={companyName}
                onChange={(e, { value }) => {
                  setCompanyDetails((prevState) => ({ ...prevState, name: value }));
                }}
              />
            </Form.Field>

            <Form.Field>
              <label>Registration country (required)</label>
              <Form.Select
                search
                value={country}
                onChange={(e, s) => setCountry(s.value as string)}
                placeholder="Select one"
                options={countries.map((c) => ({ key: c.iso2, value: c.iso2, text: c.name }))}
              />
            </Form.Field>

            <Form.Field>
              <label>Registration number</label>
              <Form.Input
                value={companyNumber}
                onChange={(e) => setCompanyNumber(e.target.value)}
                placeholder="12345678"
              />
            </Form.Field>

            <div>
              {advancedSearchResults.companies?.length > 0 && (
                <>
                  <SuggestedCompaniesContainer>
                    <Card.Group itemsPerRow={3}>
                      {advancedSearchResults.companies.slice(0, 10).map((company) => {
                        const isSelected = selectedCreditsafeCompanyDetails?.creditsafeCompanyId === company.id;

                        return (
                          <Card key={company.id} onClick={() => onClickCompany(company)} style={{ padding: 5 }} fluid>
                            <CompanyCardHeader>{company.name}</CompanyCardHeader>
                            <CompanyCardMeta>Reg No: {company.regNo}</CompanyCardMeta>
                            <CompanyCardMeta>{`${company.address.city || "City unknown"}, ${company.country || "Country unknown"} `}</CompanyCardMeta>
                            <Button
                              content={isSelected ? "Selected" : "Use this company"}
                              icon={isSelected ? "check" : undefined}
                              style={{ marginTop: 5 }}
                              onClick={() => onClickCompany(company)}
                              primary={isSelected}
                            />
                          </Card>
                        );
                      })}
                    </Card.Group>
                  </SuggestedCompaniesContainer>
                </>
              )}
            </div>
          </Form>
        ) : (
          <>
            <Form>
              <h3>Organisation overview</h3>
              <Form.Input
                required
                placeholder="Type your organisation name.."
                value={companyName}
                onChange={(e, { value }) => {
                  setCompanyDetails((prevState) => ({ ...prevState, name: value }));
                }}
              />
              <Form.Group widths={"equal"}>
                <Form.Input
                  label="Phone number"
                  placeholder="+44 0000 000000"
                  value={companyDetails.phoneNumber}
                  required
                  onChange={(e, { value }) => setCompanyDetails((prevState) => ({ ...prevState, phoneNumber: value }))}
                />
                <Form.Input
                  label="Website URL"
                  required
                  placeholder="Provide a link to your website, portfolio site, or social media"
                  value={companyDetails.websiteUrl}
                  onChange={(e, { value }) => setCompanyDetails((prevState) => ({ ...prevState, websiteUrl: value }))}
                />
              </Form.Group>
              <Form>
                <Form.TextArea
                  label="About the organisation"
                  value={companyDetails.description}
                  onChange={(e, { value }) => setCompanyDetails((prevState) => ({ ...prevState, description: value }))}
                />
              </Form>
              {util.validateUrl(companyDetails.websiteUrl ?? "") ? (
                <Popup
                  content="SimplyDo will visit the website URL provided and use the content to generate a organisation description."
                  trigger={
                    <Button
                      content="Generate organisation description from website"
                      floated="right"
                      loading={generatingDescription}
                      onClick={generateDescription}
                      size="tiny"
                      style={{ marginTop: 5 }}
                      icon="magic"
                    />
                  }
                />
              ) : null}

              <Divider hidden />
              <h3>Organisation address</h3>
              <Form.Input
                required
                label="Address"
                value={companyDetails.address?.streetAddress}
                onChange={(e, { value }) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    address: { ...prevState.address, streetAddress: value },
                  }))
                }
              />
              <Form.Group widths={"equal"}>
                <Form.Input
                  required
                  label="Country"
                  value={companyDetails.address?.country}
                  onChange={(e, { value }) =>
                    setCompanyDetails((prevState) => ({
                      ...prevState,
                      address: { ...prevState.address, country: value },
                    }))
                  }
                />
                <Form.Input
                  required
                  label="City"
                  value={companyDetails.address?.city}
                  onChange={(e, { value }) =>
                    setCompanyDetails((prevState) => ({
                      ...prevState,
                      address: { ...prevState.address, city: value },
                    }))
                  }
                />
                <Form.Input
                  required
                  label="Postcode"
                  value={companyDetails.address?.postcode}
                  onChange={(e, { value }) =>
                    setCompanyDetails((prevState) => ({
                      ...prevState,
                      address: { ...prevState.address, postcode: value },
                    }))
                  }
                />
              </Form.Group>

              <Divider hidden />
              <h3>Additional information</h3>
              <Form.Group widths={"equal"}>
                <Form.Select
                  required
                  value={companyDetails.size}
                  label={"Organisation size"}
                  options={businessSizeOptions}
                  onChange={(e, { value }) => setCompanyDetails((prevState) => ({ ...prevState, size: value }))}
                />
                <Form.Select
                  required
                  value={companyDetails.type}
                  label={"Organisation type"}
                  options={businessTypeOptions}
                  onChange={(e, { value }) => setCompanyDetails((prevState) => ({ ...prevState, type: value }))}
                />
                <Form.Select
                  required
                  value={companyDetails.status}
                  label={"Organisation status"}
                  options={businessStatusOptions}
                  onChange={(e, { value }) => setCompanyDetails((prevState) => ({ ...prevState, status: value }))}
                />
              </Form.Group>
            </Form>

            <AdvancedProfileEditor setCompanyDetails={setCompanyDetails} companyDetails={companyDetails} />

            <h3>Accreditations</h3>
            <AccreditationsContainer>
              {(companyDetails.accreditations ?? []).map((accreditation) => (
                <Label key={accreditation} size="medium">
                  <span>{constants.accreditations.find((a) => a.key === accreditation)?.text || accreditation}</span>
                  <Icon
                    name="delete"
                    onClick={() =>
                      setCompanyDetails((prevState) => ({
                        ...prevState,
                        accreditations: prevState.accreditations?.filter((a) => a !== accreditation),
                      }))
                    }
                  />
                </Label>
              ))}
              <Dropdown button className="icon" labeled icon="add" scrolling text="Add accreditation">
                <Dropdown.Menu>
                  {constants.accreditations.map((accreditation) => (
                    <Dropdown.Item
                      key={accreditation.key}
                      value={accreditation.key}
                      onClick={(e, { value }) => {
                        if (companyDetails.accreditations?.includes(value as string)) {
                          toast.error("This accreditation is already added");
                          return;
                        }
                        if (value === "other") {
                          setAddingCustomAccreditation(true);
                        } else {
                          setCompanyDetails((prevState) => ({
                            ...prevState,
                            accreditations: [...(prevState.accreditations ?? []), value as string],
                          }));
                        }
                      }}
                    >
                      {accreditation.text}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              {addingCustomAccreditation && (
                <>
                  <Form.Input
                    placeholder="Enter accreditation"
                    value={customAccreditation}
                    onChange={(e) => setCustomAccreditation(e.target.value)}
                  />
                  <Button
                    onClick={() => {
                      setCompanyDetails((prevState) => ({
                        ...prevState,
                        accreditations: [...(prevState.accreditations ?? []), customAccreditation],
                      }));
                      setCustomAccreditation("");
                      setAddingCustomAccreditation(false);
                    }}
                  >
                    Add
                  </Button>
                </>
              )}
            </AccreditationsContainer>

            <Divider section hidden />
            <PrimaryContactEditor setCompanyDetails={setCompanyDetails} companyDetails={companyDetails} />
          </>
        )}
      </EditorContent>
    </EditorContainer>
  );
};

export default BusinessProfileEditor;
