import React from "react";
import { Pagination as SemanticPagination, Select } from "semantic-ui-react";
import styled from "styled-components";
import useTheme from "theme/useTheme";

const PaginationContainer = styled.div<{ $shadowed?: boolean }>`
  padding: 8px;
  border-radius: 6px;
  ${({ $shadowed }) =>
    $shadowed &&
    `
    background-color: #fff;
    box-shadow: 0 5px 10px rgb(0, 0, 0, 0.2);
  `}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LimitContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
  .ui.selection.dropdown {
    min-width: 70px !important;
    width: 70px;
  }
  > span {
    display: block;
    margin: 0 10px;
    height: 20px;
  }
`;

type PaginationProps = {
  limit: number;
  page: number;
  total: number;
  limitValues?: number[];
  updateLimit: (limit: number) => void;
  updatePage: (page: number) => void;
  shadowed?: boolean;
  text?: string;
};

const Pagination = ({
  limit,
  page,
  total,
  limitValues = [15, 30, 50, 100],
  updateLimit,
  updatePage,
  shadowed,
  text = "ideas",
}: PaginationProps) => {
  const theme = useTheme();
  return (
    <PaginationContainer $shadowed={shadowed}>
      {!theme.sizes.isMobile && (
        <LimitContainer>
          <span>Showing</span>
          <Select
            options={limitValues.map((v) => ({ key: v.toString(), text: v, value: v.toString() }))}
            value={limit.toString()}
            onChange={(e, { value }) => updateLimit(parseInt(value as string, 10))}
          />
          <span>{`of ${total} total ${text}`}</span>
        </LimitContainer>
      )}
      <SemanticPagination
        onPageChange={(e, { activePage }) => updatePage(activePage as number)}
        activePage={page || 1}
        totalPages={Math.floor(total / limit) + 1}
        boundaryRange={1}
        siblingRange={1}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
      />
    </PaginationContainer>
  );
};

export default Pagination;
