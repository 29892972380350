import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Modal, Button, Tab, Icon, Menu } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";
import api from "api";
import toast from "react-hot-toast";

import { Overview } from "./Overview";
import { Users } from "./Users";
import { Ideas } from "./Ideas";
import util from "utils/utils";
import { useAppSelector } from "store";

type IIdeaBusinessProfileProps = {
  businessProfile: Other.IIdeaBusinessProfile;
  onClose: () => void;
};

const IdeaBusinessProfileModal = ({ businessProfile, onClose }: IIdeaBusinessProfileProps) => {
  const [fullProfile, setFullProfile] = useState(businessProfile);
  const user = useAppSelector((state) => state.user);

  const [searchParams, setSearchParams] = useSearchParams();
  const tabActiveIndex = parseInt(searchParams.get("profileTab") || "0", 10);

  const updateActiveTab = useCallback(
    (newIndex: number | string) => {
      setSearchParams((prev) => {
        prev.set("profileTab", newIndex.toString());
        return prev;
      });
    },
    [setSearchParams],
  );

  const clearTab = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete("profileTab");
      return prev;
    });
  }, [setSearchParams]);

  useEffect(() => {
    if (!businessProfile?._id) {
      return;
    }

    let mounted = true;
    api.users.getProfile(
      businessProfile._id,
      (result) => {
        if (mounted) {
          setFullProfile(result.ideaBusinessProfile);
        }
      },
      () => {
        toast.error("Failed to load business profile");
      },
    );

    return () => {
      mounted = false;
    };
  }, [businessProfile._id]);

  if (!businessProfile) return null;

  const { profile } = fullProfile;

  return (
    <Modal
      open={!!businessProfile}
      onClose={() => {
        onClose && onClose();
        clearTab();
      }}
      mountNode={document.getElementById("semantic-modal-mount-node")}
    >
      <Modal.Header>{profile?.name}</Modal.Header>
      <Modal.Content>
        {util.hasPermission(user, "org.listUsers", user.organisation) ? (
          <Tab
            menu={{ secondary: true, pointing: true }}
            activeIndex={tabActiveIndex}
            onTabChange={(e, { activeIndex }) => updateActiveTab(activeIndex)}
            panes={[
              {
                menuItem: (
                  <Menu.Item>
                    <Icon name="building" />
                    Profile
                  </Menu.Item>
                ),
                render: () => <Overview businessProfile={fullProfile} />,
              },
              {
                menuItem: (
                  <Menu.Item>
                    <Icon name="users" />
                    Users
                  </Menu.Item>
                ),
                render: () => <Users businessProfile={fullProfile} />,
              },
              {
                menuItem: (
                  <Menu.Item>
                    <Icon name="lightbulb" />
                    Ideas
                  </Menu.Item>
                ),
                render: () => <Ideas businessProfile={fullProfile} />,
              },
            ]}
          />
        ) : (
          <Overview businessProfile={fullProfile} />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            onClose && onClose();
            clearTab();
          }}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default IdeaBusinessProfileModal;
