import { SearchParams } from "simplydo/core";

export const reports = (reportsApi) => ({
  challengeReport(id, data, success, fail) {
    reportsApi.authenticatedRequest("POST", `/challenges/${id}`, data, success, fail);
  },
  challengeAssessmentReport(id, data, success, fail) {
    reportsApi.authenticatedRequest("POST", `/challenges/${id}/assessments`, data, success, fail);
  },
  individualAssessorReport(id, success, fail) {
    reportsApi.authenticatedRequest("GET", `/challenges/${id}/assessments/individual`, null, success, fail);
  },
  pollReport(id, data, success, fail) {
    reportsApi.authenticatedRequest("POST", `/polls/${id}`, data, success, fail);
  },
  orgReport(id, data, success, fail) {
    reportsApi.authenticatedRequest("POST", `/organisations/${id}`, data, success, fail);
  },
  exportIdeaPdfs(
    ideaIds: string[],
    options: { appendCreditsafeReport?: "true" },
    success: (data: any) => void,
    fail: (err: any) => void,
  ) {
    const queryParams = new SearchParams(options ?? {}).toSafeString();
    reportsApi.authenticatedRequest("GET", `/ideas/${ideaIds.join(",")}/pdf${queryParams}`, null, success, fail);
  },
});
