import React, { useEffect, useRef, useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Segment, Divider, Header, Message, Button, Popup, Label } from "semantic-ui-react";
import { useAppSelector } from "store";

import { PlaceholderItem, PlaceholderStack, Sticky } from "components/lib/UI";
import { IdeaAssessmentConflictOfInterestBox } from "components/ideas/Assessment";
import IPStatementInfo from "components/lib/IPStatementInfo";
import { SubmitIdeaButton } from "components/lib/Ideas";
import { AssessmentClosureInfo } from "components/challenges/Challenge/Assessments/YourAssessments";
import util from "utils/utils";
import IdeaBusinessProfile from "../Components/IdeaBusinessProfile";
import Concept from "./Concept";
import Customers from "./Customers";
import Competitors from "./Competitors";
import Compatibility from "./Compatibility";
import Contents from "./Contents";
import Cash from "./Cash";
import Custom from "./Custom";

import IdeaAttachments from "../Attachments";
import IdeaComments from "../Comments";
import IdeaNameField from "./Name";
import IdeaSectionHeader from "../SectionHeader.jsx";
import { Other, OpenAPI } from "simplydo/interfaces";
import useIdeaScrollTracking from "../useIdeaScrollTracking";

export const includeSections = {
  concept: {
    key: "concept",
    title: "Concept",
    description: "A short, sharp description of your idea!",
    video: "concept.mp4",
    colour: "#f4cde2",
  },
  customers: {
    key: "customers",
    title: "Customers",
    description: "Identify your target customers and potential market.",
    video: "customers.mp4",
    colour: "#b4e6f4",
  },
  competitors: {
    key: "competitors",
    title: "Competitors",
    description: "Identify the competition and find your gap.",
    video: "competitors.mp4",
    colour: "#f5ccb1",
  },
  compatibility: {
    key: "compatibility",
    title: "Compatibility",
    description: "Are you the right individual or team to make this idea happen?",
    video: "compatibility.mp4",
    colour: "#cdc4de",
  },
  contents: {
    key: "contents",
    title: "Contents",
    description: "This is the shopping list of essential ingredients you need to make your idea happen.",
    video: "contents.mp4",
    colour: "#fae7bd",
  },
  cash: {
    key: "cash",
    title: "Cash",
    description: "Understanding the money coming in and going out.",
    video: "cash.mp4",
    colour: "#cae6c9",
  },
};

export class IncludeSection extends React.Component<{
  type: string;
  idea: Other.IIdea;
  isEditing?: boolean;
  updateIdea?: (idea: Other.IIdea) => void;
  externalChanges?: any;
}> {
  section: any;
  constructor(props) {
    super(props);

    this.section = includeSections[props.type];
  }

  render() {
    const { type, idea, isEditing, updateIdea, externalChanges } = this.props;
    const sectionProps = { isEditing, updateIdea, externalChanges };
    const ideaId = idea._id;

    switch (type) {
      case "concept":
        return (
          <Concept
            ideaId={ideaId}
            conceptSupportingImage={idea.conceptSupportingImage}
            conceptRoughIdea={idea.conceptRoughIdea}
            conceptVideo={idea.conceptVideo}
            conceptKeywords={idea.conceptKeywords}
            key={type}
            includeSection={this.section}
            {...sectionProps}
          />
        );
      case "customers":
        return (
          <Customers
            key={type}
            ideaId={ideaId}
            customersPeople={idea.customersPeople}
            includeSection={this.section}
            customersStrategy={idea.customersStrategy}
            customersMarket={idea.customersMarket}
            {...sectionProps}
          />
        );
      case "competitors":
        return (
          <Competitors
            competitors={idea.competitors}
            competitorsStrategy={idea.competitorsStrategy}
            ideaId={ideaId}
            key={type}
            includeSection={this.section}
            {...sectionProps}
          />
        );
      case "compatibility":
        return (
          <Compatibility
            compatibilityPeople={idea.compatibilityPeople}
            ideaId={ideaId}
            compatibilityPassion={idea.compatibilityPassion}
            key={type}
            includeSection={this.section}
            compatibilityBelief={idea.compatibilityBelief}
            compatibilityHistory={idea.compatibilityHistory}
            compatibilityRisk={idea.compatibilityRisk}
            compatibilityNetwork={idea.compatibilityNetwork}
            {...sectionProps}
          />
        );
      case "contents":
        return (
          <Contents
            contentsOverview={idea.contentsOverview}
            contentsWhat={idea.contentsWhat}
            contentsWho={idea.contentsWho}
            contentsWhere={idea.contentsWhere}
            ideaId={ideaId}
            key={type}
            contentsAdditionalInfo={idea.contentsAdditionalInfo}
            includeSection={this.section}
            {...sectionProps}
          />
        );
      case "cash":
        return (
          <Cash
            cashFunding={idea.cashFunding}
            cashFundingNotes={idea.cashFundingNotes}
            cashMoneyTable={idea.cashMoneyTable}
            cashMoneyOut={idea.cashMoneyOut}
            cashMoneyIn={idea.cashMoneyIn}
            ideaId={ideaId}
            key={type}
            includeSection={this.section}
            {...sectionProps}
          />
        );
      default:
        return null;
    }
  }
}

const IdeaSection = styled.div<{
  $isPreview?: boolean;
  $include?: string;
  $isAssessment?: boolean;
}>`
  border-radius: 6px 6px 0 0;
  box-shadow: ${({ $isPreview }) => ($isPreview ? null : "0px 10px 10px rgba(0,0,0,0.1)")};
  ${({ $include, $isPreview }) =>
    $include && !$isPreview ? `border: 4px solid ${includeSections[$include].colour};` : ""}
  ${({ $isAssessment }) => ($isAssessment ? `border: 3px solid #6435c9;` : "")};
  @media print {
    page-break-after: always;
    margin: 2cm 30px;
  }
`;

const IdeaSectionBody = styled(Segment)<{ $isPreview?: boolean }>`
  border: ${({ $isPreview }) => ($isPreview ? "none !important" : "none")};
`;

export const IdeaBodySection = (props) => {
  const { section, sectionIndex, isPreview, externalChanges, idea, setRef, updateIdea, isEditing } = props;
  if (!section) return null;
  return (
    <>
      <IdeaSection
        $isPreview={isPreview}
        $include={section.include}
        id={section.include ? section.include : section.name}
        ref={(ref) => setRef && setRef(ref, section.id)}
        $isAssessment={section.type === "assessment"}
      >
        <IdeaSectionHeader
          isPreview={isPreview}
          sectionInclude={includeSections[section.include]}
          section={section}
          sectionIndex={sectionIndex}
          {...props}
        />
        <IdeaSectionBody $isPreview={isPreview} attached>
          {section.type === "businessProfile" ? (
            <IdeaBusinessProfile idea={idea} isEditing={isEditing} updateIdea={updateIdea} />
          ) : section.include ? (
            <IncludeSection
              type={section.include}
              externalChanges={externalChanges || {}}
              idea={idea}
              updateIdea={updateIdea}
              isEditing={isEditing}
            />
          ) : (
            <Custom {...props} section={section} />
          )}
        </IdeaSectionBody>
      </IdeaSection>
    </>
  );
};

export const TextFields = ["shorttext", "longtext", "richtext"];

const ChildIdeaChooser = styled.div`
  z-index: 999;
  padding: 5px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border: 0.5px solid #e9ebee;
  > .title {
    opacity: 0.8;
    display: block;
    margin: 0 5px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  > .buttons {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 5px;
    .ui.button.merge-button-tab {
      width: 250px;
    }
    .button-idea-name {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const StickyMergeIdeaHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  align-items: center;
  .jump-to-section-button {
    margin-top: 5px;
    height: 25px;
    padding: 5px 12.5px;
  }
  ${ChildIdeaChooser} {
    width: 100%;
  }
`;

type ChallengeIdea = OpenAPI.GET<"/challenges/{id}/ideas">["response"]["ideas"][0];

type BodyProps = {
  idea: ChallengeIdea;
  setRef?: (ref: HTMLDivElement, key: string) => void;
  challenge?: OpenAPI.GET<"/challenges/{id}">["response"];
  loading?: boolean;
  updateIdea?: (idea: ChallengeIdea) => void;
  scrollToRequired?: () => void;
  onSubmit?: () => void;
  isEditing?: boolean;
  isPreview?: boolean;
  hideComments?: boolean;
  externalChanges?: any;
  isPDF?: boolean;
  assessment?: any;
  updateAssessment?: (assessment: any) => void;
  usingAssessmentTemplate?: any;
  submitAssessment?: () => void;
  isViewingMergedIdea?: boolean;
};

type BodyContainerProps = BodyProps & {
  divRef?: React.RefObject<HTMLDivElement>;
  removeIdeaFromMerge?: (parentId: string, childId: string) => void;
  removingIdeaFromMerge?: boolean;
  sectionRefs?: React.MutableRefObject<any>;
};

const BodyContainer = ({
  idea,
  divRef,
  removeIdeaFromMerge,
  removingIdeaFromMerge,
  setRef,
  sectionRefs,
  ...rest
}: BodyContainerProps) => {
  /*
    Code for handling merged idea bodies

    One idea is set as the "active" idea, and the content from that idea will be what is shown
  */

  const user: OpenAPI.GET<"/users/me">["response"] = useAppSelector((state) => state.user);
  const { t } = useTranslation();
  const {
    activeItemId,
    // setActiveItemId,
    scrollToSection,
    // @ts-ignore
  } = useIdeaScrollTracking(idea._id, sectionRefs, divRef, 210);
  /* */

  const usingMergedIdea = useMemo(() => !!idea?.children?.length, [idea.children]);
  const numberOfChildren = useMemo(() => idea?.ownerChildren?.length ?? 0, [idea.ownerChildren]);
  const [activeChildIdea, setActiveChildIdea] = useState<ChallengeIdea>(null);
  const [prevSectionViewed, setPrevSectionViewed] = useState<string>("");

  useEffect(() => {
    if (numberOfChildren > 0 && !activeChildIdea) {
      setActiveChildIdea(idea.ownerChildren[0]);
    }
  }, [activeChildIdea, idea.ownerChildren, numberOfChildren]);

  const changeActiveChildIdea = useCallback(
    (childIdea) => {
      if (divRef?.current) {
        divRef.current.scrollTop = 0;
      } else {
        window.scrollTo(0, 0);
      }
      setPrevSectionViewed(activeItemId);
      setActiveChildIdea(childIdea);
    },
    [activeItemId, divRef],
  );

  // You can jump to the section that you were viewing in the previous idea, if the section exists in the new idea, and it's not the first section
  const canJumpToSectionInIdea = useMemo(() => {
    if (activeChildIdea === null || prevSectionViewed === "") {
      return false;
    }
    const sectionIds = Object.keys(sectionRefs.current);
    const prevSectionIndex = sectionIds.findIndex((sectionId) => sectionId === prevSectionViewed);
    return sectionIds.length > 0 && sectionIds.indexOf(prevSectionViewed) !== -1 && prevSectionIndex !== 0;
  }, [activeChildIdea, prevSectionViewed, sectionRefs]);

  const onScrollToPrevSection = useCallback(
    (sectionId) => {
      setPrevSectionViewed("");
      scrollToSection(sectionId);
    },
    [scrollToSection],
  );

  const usingIdea = useMemo(() => (usingMergedIdea ? activeChildIdea : idea), [usingMergedIdea, activeChildIdea, idea]);

  const prevSectionName = useMemo(
    () => activeChildIdea?.ideaTemplate?.body?.find((section) => section.id === prevSectionViewed)?.name,
    [activeChildIdea?.ideaTemplate?.body, prevSectionViewed],
  );

  if (usingMergedIdea) {
    if (activeChildIdea === null) {
      return null;
    }
    return (
      <div>
        <Sticky
          top={window.location.pathname === `/ideas/${idea._id}` ? 110 : 5}
          bottom={undefined}
          style={{ zIndex: 100 }}
        >
          <StickyMergeIdeaHeader>
            <ChildIdeaChooser>
              <span className="title">Viewing merged idea...</span>
              <div className="buttons">
                {idea.ownerChildren.map((childIdea) => (
                  <Button.Group key={childIdea._id}>
                    <Button
                      active={childIdea._id === activeChildIdea?._id}
                      key={childIdea._id}
                      onClick={() => changeActiveChildIdea(childIdea)}
                      className="merge-button-tab"
                    >
                      <Button.Content>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <span className="button-idea-name">{childIdea.name}</span>
                          {childIdea?.owner ? (
                            <span style={{ opacity: 0.5, fontSize: "0.8em" }}>
                              By {childIdea?.owner?.profile?.fullName}
                            </span>
                          ) : null}
                        </div>
                      </Button.Content>
                    </Button>
                    {util.canManageChallenge(user, idea.ownerChallenge) && removeIdeaFromMerge ? (
                      <Popup
                        on="hover"
                        trigger={<Button icon="trash" onClick={() => removeIdeaFromMerge(idea._id, childIdea._id)} />}
                        content={`Remove this ${t("generic.idea")} from the merge`}
                      />
                    ) : null}
                  </Button.Group>
                ))}
              </div>
              {idea.ownerChildren?.length !== idea.children?.length ? (
                <Message info size="tiny">
                  You are not able to view all {t("generic.ideas")} in this merge. You may not have permission to view
                  some of the {t("generic.ideas")}, or they may have been deleted.
                </Message>
              ) : null}
            </ChildIdeaChooser>
            {canJumpToSectionInIdea && (divRef ? divRef.current.scrollTop < 100 : window.scrollY < 100) ? (
              <div>
                <Button
                  // color='purple'
                  icon="arrow circle down"
                  content={`Jump to ${!prevSectionName ? "previous section" : `section "${prevSectionName}"`}`}
                  onClick={() => onScrollToPrevSection(prevSectionViewed)}
                  className="jump-to-section-button"
                  size="tiny"
                />
              </div>
            ) : null}
          </StickyMergeIdeaHeader>
        </Sticky>
        {user &&
        activeChildIdea &&
        (activeChildIdea?.user === user?._id || activeChildIdea?.collaborators?.indexOf(user?._id) !== -1) &&
        !(idea.user === user?._id) ? (
          <Message info>
            Your {t("generic.idea")} has been merged with other {t("generic.ideas")}; You are currently viewing the
            merge result. You can still view your individual {t("generic.idea")}{" "}
            <Link to={`/ideas/${activeChildIdea._id}`}>here</Link>.
          </Message>
        ) : null}
        <Body
          idea={usingIdea}
          setRef={setRef}
          usingAssessmentTemplate={idea.ideaTemplate}
          isViewingMergedIdea
          {...rest}
        />
        {!removingIdeaFromMerge ? (
          <div>
            <div ref={(ref) => setRef && setRef(ref, "comments")} id="comments">
              <Divider hidden />
              <Header as="h4">Comments</Header>
              <IdeaComments idea={idea} forId={idea._id} isViewingMergedIdea />
              <Divider hidden />
              <Divider hidden />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
  return <Body idea={usingIdea} setRef={setRef} {...rest} />;
};

const Body = (props: BodyProps) => {
  const {
    idea,
    challenge,
    loading,
    setRef,
    updateIdea,
    scrollToRequired,
    onSubmit,
    isEditing,
    isPreview,
    hideComments,
    externalChanges,
    isPDF,
    assessment,
    updateAssessment,
    usingAssessmentTemplate,
    submitAssessment,
    isViewingMergedIdea,
  } = props;
  const { t } = useTranslation();

  const user: OpenAPI.GET<"/users/me">["response"] = useAppSelector((state) => state.user);
  const {
    parent,
    ideaTemplate,
    attachments = [],
    commentCount = 0,
    ownerChallenge = { preventIdeaComments: false, ideaAnonymity: "", stage: "" },
  } = idea;
  const { body, disableAttachments } = useMemo<typeof ideaTemplate>(() => {
    return ideaTemplate ?? { body: [], createdAt: undefined };
  }, [ideaTemplate]);
  const { stage } = ownerChallenge ?? {};
  const inputRefs = useRef({});
  const [focusableFields, setFocusableFields] = useState([]);

  const assignTabIndexes = useCallback(
    (sections, refsObj) => {
      const focusableFieldsArray = [];

      // The name field is outside the scope of the sections, so we need to add it to the refs manually
      // We know it's going to be focusable so we add that
      refsObj.current.name = React.createRef();
      focusableFieldsArray.push("name");

      // Add each field in each section to the refs
      // Also track which of those fields we deem to be focusable - e.g. text fields
      sections?.forEach((section) => {
        section?.fields?.forEach((field) => {
          const { id, type } = field;

          if (TextFields.includes(type)) {
            refsObj.current[id] = React.createRef();
            focusableFieldsArray.push(id);
          } else {
            refsObj.current[id] = null;
          }
        });
      });

      if (stage !== "closed") {
        refsObj.current.submitButton = React.createRef();
        focusableFieldsArray.push("submitButton");
      }

      setFocusableFields(focusableFieldsArray);
    },
    [stage],
  );

  useEffect(() => {
    if (!idea.parent) {
      inputRefs.current = {};
      assignTabIndexes(body, inputRefs);
    }
  }, [idea.parent, body, assignTabIndexes]);

  useEffect(() => {
    // Once we have a list of all focusable fields, we want to focus on the first one
    // This should always be the name field
    if (focusableFields?.length > 0) {
      const firstFocusableFieldId = focusableFields[0];
      const firstFocusableField = inputRefs.current[firstFocusableFieldId];
      if (firstFocusableField?.current?.focus && document.activeElement === document.body) {
        firstFocusableField.current.focus();
      }
    }
  }, [focusableFields]);

  const handleKeyDown = useCallback(
    (event, fieldId) => {
      if (event.key === "Tab") {
        event.preventDefault();

        // inputRefs is an object containing all idea fields on the current page
        // The only non-null elements will be valid input elements that we are able to focus on
        // Whenever the user presses tab (and depending on whether they are holding shift), we want to focus on the next/previous input element in the list
        // If the user is tabbing forward, we need to iterate through the objects values until we find the current input element, then focus on the next one
        // If the user is tabbing backwards, we need to iterate through the objects values until we find the current input element, then focus on the previous one
        // In order to know what the next valid focusable element is, we need to create a new object that associates each focusable fieldId with its index in the list
        // We can then use the current fieldId to find its index, and then use the shift key to determine whether we should focus on the next or previous element

        const currentIndex = focusableFields.findIndex((focusableFieldId) => focusableFieldId === fieldId);
        const nextIndex = event.shiftKey ? currentIndex - 1 : currentIndex + 1;

        if (nextIndex >= 0 && nextIndex < focusableFields.length) {
          const newIndexInInputRefs = focusableFields[nextIndex];
          inputRefs.current[newIndexInInputRefs].current.focus();
        }
      }
    },
    [focusableFields],
  );

  const handleNameKeyDown = useCallback(
    (event) => {
      handleKeyDown(event, "name");
    },
    [handleKeyDown],
  );

  const canEdit = util.canEditIdea(user, idea);
  const showAttachments = !disableAttachments && ((canEdit && isEditing) || attachments.length > 0);
  const showComments =
    (!isEditing || commentCount) &&
    !ownerChallenge?.preventIdeaComments &&
    !hideComments &&
    idea._id &&
    !isPDF &&
    (!isViewingMergedIdea || (isViewingMergedIdea && !idea.parent));
  const submitAssessmentContent = useMemo(
    () => (usingAssessmentTemplate ? `Submit assessment for all ${t("generic.ideas")}` : "Submit assessment"),
    [usingAssessmentTemplate, t],
  );

  return (
    <>
      {idea?.ipStatement?.ipStatementEnabled ? <IPStatementInfo ipStatement={idea.ipStatement} /> : null}

      {ownerChallenge?.ideaAnonymity && canEdit ? (
        <Message
          info
          icon="user secret"
          header="You are working anonymously"
          content={`${t("common:capitalise", { key: "generic.ideas" })} in this ${t("generic.challenge")} have their identity hidden to anyone who is not ${t("generic.challengeWithArticle")} administrator. Only you and ${t("generic.challenge")} administrators will be able to see that you created this ${t("generic.idea")}.`}
        />
      ) : null}

      {idea?.isAssessor && <AssessmentClosureInfo ideaTemplate={ideaTemplate} />}

      <IdeaAssessmentConflictOfInterestBox
        idea={idea}
        assessment={assessment}
        updateAssessment={updateAssessment}
        noShadow={undefined}
        style={undefined}
        className={undefined}
        isPDF={isPDF}
      />
      {assessment?.conflictOfInterest?.hasConflictOfInterest && idea?.isSubmitted ? null : (
        <>
          {loading && (
            <div style={{ display: "flex", flexDirection: "column", gap: 30 }}>
              <IdeaSection>
                <IdeaSectionBody>
                  <PlaceholderStack gap={20}>
                    <PlaceholderItem height={30} fluid />
                    <PlaceholderItem height={150} fluid />
                  </PlaceholderStack>
                </IdeaSectionBody>
              </IdeaSection>

              {Array(3)
                .fill(0)
                .map((_, index) => (
                  <IdeaSection key={index}>
                    <Segment attached="top" style={{ margin: 0 }}>
                      <PlaceholderItem height={30} fluid />
                    </Segment>
                    <IdeaSectionBody attached="bottom" style={{ margin: 0 }}>
                      <PlaceholderStack gap={10}>
                        <PlaceholderItem height={40} fluid />
                        <PlaceholderItem height={40} fluid />
                        <PlaceholderItem height={40} fluid />
                      </PlaceholderStack>
                    </IdeaSectionBody>
                  </IdeaSection>
                ))}
            </div>
          )}

          <IdeaNameField
            isEditing={isEditing}
            ideaId={idea?._id}
            ideaName={idea?.name}
            // @ts-ignore
            ideaCoverImage={idea?.coverImageUrl}
            challengeName={challenge?.name}
            updateIdea={updateIdea}
            // @ts-ignore
            nameRef={inputRefs.current.name}
            handleKeyDown={handleNameKeyDown}
          />
          {body.map((section, sectionIndex) => {
            if (!util.shouldShowIdeaSection(idea, section)) return null;
            if (!util.shouldShowIdeaEdiSection(user, idea, section)) return null;
            if (assessment?.conflictOfInterest?.hasConflictOfInterest && section.type === "assessment") return null;
            return (
              <div style={{ marginBottom: 30 }} key={section.id}>
                <IdeaBodySection
                  section={section}
                  sectionIndex={sectionIndex}
                  isPreview={isPreview}
                  setRef={setRef}
                  updateIdea={updateIdea}
                  externalChanges={externalChanges}
                  idea={idea}
                  inputRefs={inputRefs}
                  handleKeyDown={handleKeyDown}
                  {...props}
                />
              </div>
            );
          })}
        </>
      )}

      {idea?.isAssessor && !isPDF ? (
        <div style={{ marginBottom: 30 }}>
          {assessment?.isSubmitted ? (
            <Label color="teal" content="Assessment submitted" />
          ) : (
            <>
              <Button
                style={{ marginLeft: 3 }}
                content={submitAssessmentContent}
                primary
                onClick={submitAssessment}
                disabled={idea?.ideaTemplate?.assessmentClosed}
                loading={assessment.isSaving}
              />
            </>
          )}
        </div>
      ) : null}

      {parent || idea?.isAssessor || idea?.children || isPDF ? null : (
        <SubmitIdeaButton
          user={user}
          // @ts-ignore
          idea={idea}
          updateIdea={updateIdea}
          onSubmit={onSubmit}
          scrollToRequired={scrollToRequired}
          size="large"
          // @ts-ignore
          submitRef={inputRefs.current.submitButton}
          handleKeyDown={(event) => handleKeyDown(event, "submitButton")}
        />
      )}

      {showAttachments ? (
        <div>
          <div ref={(ref) => setRef && setRef(ref, "attachments")} id="attachments">
            <Divider section hidden />
            <IdeaAttachments idea={idea} user={user} isEditing={isEditing} updateIdea={updateIdea} />
          </div>
        </div>
      ) : null}
      {showComments ? (
        <div>
          <div ref={(ref) => setRef && setRef(ref, "comments")} id="comments">
            <Divider hidden />
            <Header as="h4">{t("generic.comments")}</Header>
            <IdeaComments idea={idea} forId={idea._id} />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default BodyContainer;
