import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Message, Button } from "semantic-ui-react";
import util from "utils/utils";
import toast from "react-hot-toast";
import api from "api";
import actions from "actions";

import DiscussionFeed from "components/lib/Discussion";

class Discussion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: [],
      loading: false,
      feed: [],
      commentText: "",
      commentLoading: false,
      commentMedia: [],
      // Reply format
      /*
        parentId: {
          media: [],
          text: ''
        }
      */
      replies: {},
      isPinned: false,
      additionalMentions: {
        comment: [],
        reply: [],
      },
      page: 1,
      limit: 15,
      nextPageAvailable: false,
      previousPageAvailable: false,
      total: 0,
      authors: [],
    };
  }

  componentDidMount() {
    const authors = [];
    this.props.idea.authors && this.props.idea.authors.map((author) => authors.push(author._id));
    this.getComments();
    this.setState({ authors });
  }

  componentDidUpdate(prevProps) {
    if (this.props.forId !== prevProps.forId) this.getComments();
  }

  getComments = () => {
    // We want users to view this component, so we can redirect
    // them to login, but we don't want them to load comments.
    if (!this.props.user) return;
    this.setState({ loading: true });
    const { page, limit } = this.state;
    api.ideas.getComments(
      this.props.forId,
      { page, limit },
      ({ comments, nextPageAvailable, previousPageAvailable, total }) => {
        this.setState({
          loading: false,
          comments,
          nextPageAvailable,
          previousPageAvailable,
          total,
        });
      },
      (err) => {
        this.setState({ loading: false });
        toast.error(err.message);
      },
    );
  };

  onPageChange = (page) => this.setState({ page }, () => this.getComments());

  handlePost = (data, parentCommentId = "") => {
    const { t, forId, onIdeaActivity, onFollow } = this.props;
    this.setState({ commentLoading: true });

    const media = data.media?.map((item) => {
      const mediaItem = { ...item };
      if ("signedDownloadRequest" in mediaItem) {
        delete mediaItem.signedDownloadRequest;
      }
      return mediaItem;
    });

    api.ideas.postComment(
      forId,
      { ...data, media, message: data.message.replace("<?insert!>", "") },
      (comment) => {
        const currentComments = Object.assign([], this.state.comments);
        currentComments.splice(0, 0, comment);
        toast.success(t("ideas.comments.posted"));
        this.updateCommentCount();
        onFollow("idea", forId);
        if (parentCommentId) {
          this.setState(
            (prevState) => ({
              currentComments,
              commentLoading: false,
              replies: {
                ...prevState.replies,
                [parentCommentId]: {},
              },
            }),
            () => this.getComments(),
          );
        } else {
          this.setState(
            {
              page: 1,
              commentText: "",
              commentMedia: [],
              commentLoading: false,
            },
            () => this.getComments(),
          );
        }
        if (onIdeaActivity) onIdeaActivity(forId);
      },
      (err) => {
        this.setState({ commentLoading: false });
        toast.error(err.message);
      },
    );
  };

  updateCommentCount = (increment = true) => {
    const { updateCommentCount, idea } = this.props;
    if (updateCommentCount) {
      const commentCount = idea.commentCount || 0;
      if (increment) {
        updateCommentCount(commentCount + 1);
      } else {
        updateCommentCount(commentCount - 1);
      }
    }
  };

  post = () => {
    if (this.state.commentText || this.state.commentMedia) {
      const data = {
        message: this.state.commentText,
        media: this.state.commentMedia,
        isPinned: this.state.isPinned,
      };
      this.handlePost(data);
    }
  };

  postReply = (parentCommentId) => {
    const replyData = this.state.replies[parentCommentId] || {};
    const { text = "", media = [] } = replyData;
    if (text || media) {
      const data = {
        message: text,
        media,
        parent: parentCommentId,
      };
      this.handlePost(data, parentCommentId);
    }
  };

  deleteComment = (commentId) => {
    const { t, forId } = this.props;
    return util
      .confirm(t("ideas.comments.delete.title"), t("ideas.comments.delete.info"))
      .then(() => {
        api.ideas.deleteComment(
          forId,
          commentId,
          () => {
            const currentComments = Object.assign([], this.state.comments).filter((c) => c._id !== commentId);
            this.setState({ comments: currentComments });
            this.updateCommentCount(false);
            toast(t("ideas.comments.delete.success"));
          },
          (err) => toast.error(err.message),
        );
      })
      .catch(() => {});
  };

  handleAddAttachment = (attachment, parentCommentId = "") => {
    if (parentCommentId) {
      this.setState((prevState) => {
        const updatedAttachments = [...(prevState.replies[parentCommentId].media || []), attachment];
        return {
          replies: {
            ...prevState.replies,
            [parentCommentId]: {
              text: prevState.replies[parentCommentId].text || "",
              media: updatedAttachments,
            },
          },
        };
      });
    } else {
      const attachments = Object.assign([], this.state.commentMedia);
      attachments.push(attachment);
      this.setState({ commentMedia: attachments });
    }
  };

  addAttachment = (storedName, name, signedDownloadRequest, parentId) => {
    const attachment = {
      value: storedName,
      name,
      signedDownloadRequest,
      type: name.match(/\.(jpg)|(png)|(gif)|(jpeg)$/i) ? "image" : "file",
    };
    this.handleAddAttachment(attachment, parentId);
  };

  addGiphy = (gif, parentCommentId = "") => {
    const attachment = {
      value: gif.url,
      name: gif.title,
      type: "gif",
      data: gif,
    };
    this.handleAddAttachment(attachment, parentCommentId);
  };

  deleteAttachment = (index, parentCommentId = "") => {
    if (parentCommentId) {
      this.setState((prevState) => {
        const prevAttachments = Object.assign([], prevState.replies[parentCommentId].media);
        prevAttachments.splice(index, 1);
        return {
          replies: {
            ...prevState.replies,
            [parentCommentId]: {
              text: prevState.replies[parentCommentId].text || "",
              media: prevAttachments,
            },
          },
        };
      });
    } else {
      const attachments = Object.assign([], this.state.commentMedia);
      attachments.splice(index, 1);
      this.setState({ commentMedia: attachments });
    }
  };

  getFile = (att) => att.signedDownloadRequest || att.value;

  updateComment = (commentText) => {
    this.setState({ commentText });
  };

  updateReply = (parentCommentId, replyText) => {
    this.setState((prevState) => ({
      replies: {
        ...prevState.replies,
        [parentCommentId]: {
          ...(prevState.replies[parentCommentId] || {}),
          text: replyText,
        },
      },
    }));
  };

  pin = (commentId) => {
    api.ideas.pinComment(
      this.props.idea._id,
      commentId,
      true,
      () => {
        const feed = Object.assign([], this.state.comments);
        const commentIndex = feed.map((f) => f._id).indexOf(commentId);
        const comment = feed[commentIndex];
        if (comment) {
          comment.isPinned = true;
          feed.splice(commentIndex, 1);
          feed.splice(0, 0, comment);
          this.setState({ comments: feed });
        }
      },
      (err) => toast.error(err.message),
    );
  };

  unpin = (commentId) => {
    api.ideas.pinComment(
      this.props.idea._id,
      commentId,
      false,
      () => {
        const feed = Object.assign([], this.state.comments);
        const commentIndex = feed.map((f) => f._id).indexOf(commentId);
        const nonPinnedIndex = feed.map((f) => !!f.isPinned).indexOf(false);
        const comment = feed[commentIndex];
        if (comment) {
          comment.isPinned = false;
          feed.splice(commentIndex, 1);
          feed.splice(nonPinnedIndex > -1 ? nonPinnedIndex : feed.length, 0, comment);
          this.setState({ comments: feed });
        }
      },
      (err) => toast.error(err.message),
    );
  };

  reactComment = (commentId, emoji, adding) => {
    const feed = Object.assign([], this.state.comments);
    const commentIndex = feed.map((f) => f._id).indexOf(commentId);
    const comment = feed[commentIndex];
    const func = adding ? api.ideas.commentAddReaction : api.ideas.commentRemoveReaction;
    func(this.props.idea._id, commentId, emoji, (data) => {
      comment.reactCount = data?.reacts?.length || 0;
      comment.reacts = data.reacts;
      this.setState({ comments: feed });
    });
  };

  render() {
    const { user, idea, isPreview, isViewingMergedIdea } = this.props;
    const { comments, loading, commentLoading, replies } = this.state;
    const { t } = this.props;
    return (
      <div data-testid="idea-comments">
        {user ? (
          <DiscussionFeed
            {...this.props}
            {...this.state}
            getReplyData={(parentCommentId) => replies[parentCommentId] || {}}
            canManage={!isPreview && util.canManageChallenge(user, idea.ownerChallenge)}
            post={this.post}
            deleteComment={this.deleteComment}
            feed={comments}
            loading={loading}
            type={"idea"}
            updateComment={this.updateComment}
            commentLoading={commentLoading}
            forId={this.props.forId}
            getFile={this.getFile}
            fileChooserForType="idea"
            fileChooserForId={this.props.forId}
            addGiphy={this.addGiphy}
            addAttachment={this.addAttachment}
            deleteAttachment={this.deleteAttachment}
            canPost={true}
            pin={this.pin}
            unpin={this.unpin}
            postReply={this.postReply}
            updateReply={this.updateReply}
            reactComment={this.reactComment}
            onPageChange={this.onPageChange}
            authors={this.state.authors}
            commentText={this.state.commentText}
            message={
              idea.parent && isViewingMergedIdea
                ? `You are commenting on the ${t("generic.idea")} "${idea.name}"`
                : undefined
            }
          />
        ) : (
          <Message>
            <h4>Please login to view comments</h4>
            <p>
              Register or login to your Simply Do account to view and interact with comments on this {t("generic.idea")}
              .
            </p>
            <Button primary as={Link} to={`/login?then=/ideas/${idea._id}`}>
              Login or register
            </Button>
          </Message>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  onFollow: (context, ideaId) => dispatch(actions.user.onFollow(context, ideaId)),
});
const IdeaDiscussionContainer = connect(mapStateToProps, mapDispatchToProps)(Discussion);

export default withTranslation()(IdeaDiscussionContainer);
