import React, { useMemo, useState } from "react";
import { Dropdown } from "semantic-ui-react";

const getButtonText = (selected) => {
  if (selected.length === 1) {
    return selected[0].value;
  }
  return `${selected.length} tags selected`;
};

const TagDropdownChooser = ({
  tags = [],
  availableTags = [],
  removeTag,
  addTag,
  canEdit = true,
  showCount = true,
  onStateChange,
  style,
  size = undefined,
}) => {
  const [search, setSearch] = useState("");
  const selectedItems = useMemo(() => tags.map((tag) => tag._id), [tags]);
  const buttonText = useMemo(() => getButtonText(tags), [tags]);
  const filteredTags = useMemo(
    () => availableTags.filter((t) => t.value.toLowerCase().includes(search)),
    [search, availableTags],
  );

  return (
    <Dropdown
      selection
      multiple
      search={canEdit}
      onBlur={() => setSearch("")}
      onSearchChange={(e, { searchQuery }) => {
        setSearch(searchQuery.toLowerCase());
      }}
      style={style}
      placeholder={selectedItems.length ? null : "Filter by tags"}
      text={
        selectedItems.length > 0 ? (
          <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{buttonText}</div>
        ) : null
      }
      onChange={(e, data) => onStateChange && onStateChange(data)}
      size={size}
    >
      <Dropdown.Menu>
        {filteredTags.map((tag) => {
          const selected = selectedItems.includes(tag._id);
          return (
            <Dropdown.Item
              key={tag._id}
              text={showCount ? `${tag.value} (${tag.count})` : tag.value}
              icon={selected ? "checkmark" : null}
              selected={selected}
              onClick={() => {
                if (!canEdit) {
                  return;
                }
                if (selected) {
                  removeTag(tag);
                } else {
                  addTag(tag);
                }
              }}
            />
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TagDropdownChooser;
