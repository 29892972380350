import React from "react";
import { Icon, Popup } from "semantic-ui-react";
import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.5px solid #c9c9c9;
  border-radius: 5px;
  background-color: #fff;
  padding: 6px 3px;
`;

export const ActionArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 5px 5px 0 5px;
  border-top: 0.5px solid silver;
  padding-top: 5px;
  > div {
    display: flex;
    align-items: center;
  }
  > .ui.button {
    margin: 0;
  }
  i {
    margin: 0 0 3px 0;
  }
`;

export const StyledActionButton = styled.div`
  padding: 3px 7.5px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 33px;
  ${({ $disabled }) => !$disabled && "cursor: pointer;"}
  span {
    display: block;
    margin-right: 7.5px;
    color: gray;
    font-weight: 600;
    text-transform: uppercase;
  }
  i {
    color: gray;
    height: 1.2em;
    font-size: 1.2em;
    margin: 0 0 3px 0;
  }
  &:hover {
    background-color: #e9ebee;
  }
`;

export const ActionButton = ({ title, position, iconName, content, disabled, onClick, loading: _loading, style }) => (
  <Popup
    position={position || "top center"}
    content={title}
    disabled={!title}
    trigger={
      <StyledActionButton onClick={disabled ? null : onClick} $disabled={disabled} style={style}>
        {content ? <span>{content}</span> : null}
        <Icon name={iconName} />
      </StyledActionButton>
    }
  />
);
