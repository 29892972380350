import React, { lazy, useMemo } from "react";
import { connect } from "react-redux";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import useTheme from "theme/useTheme";
import util from "utils/utils";
import { SearchParams } from "simplydo/core";

import Entry from "components/entry/Entry";
import PublicChallenges from "components/challenges/PublicChallenges";
import ProjectBoard from "components/boards";
import NewProjectBoard from "components/boards/New";
import MobileMessageWidget from "components/messages/MobileMessageWidget";
import MobileNotificationsWidget from "components/notifications/MobileNotificationsWidget";
import Home from "components/home/Home";
import Challenges from "components/challenges/Challenges";
import Groups from "components/groups/Groups";
import NewGroup from "components/groups/New";
import Tags from "components/tags";
import UserProfile from "components/users/Profile";
import UserUnsubscribe from "components/users/Unsubscribe";
import MessageCentre from "components/messages/MessageCentre";
import Preferences from "components/users/Settings";
import UserInvitations from "components/users/Invitations";
import OAuthConnection from "components/entry/OAuthConnection";
import Idea from "components/ideas";
import Challenge from "components/challenges/Challenge";
import NotFound from "components/NotFound";
import PersistentToken from "components/app/PersistentToken";
import CustomPage from "./OrganisationCustomPage";

// Code-split routes. Note this does not work properly on some networks (e.g. Betsi Cadwaladr Healthboard) so
// we only lazy-load a few routes.
const Group = lazy(() => import("components/groups/Group"));
const SuperAdministration = lazy(() => import("components/superadmin/SuperAdministration"));
const Administration = lazy(() => import("components/admin/Administration"));
const ChallengeSettings = lazy(() => import("components/challenges/Challenge/Settings"));
const InnovationIntelligence = lazy(() => import("components/innovationintelligence/InnovationIntelligence"));
const Achievements = lazy(() => import("components/users/Achievements"));
const ChallengeProfile = lazy(() => import("components/profile/ChallengeProfile"));
const YourIdeas = lazy(() => import("components/challenges/YourIdeas"));
const GenAIChallengeWizard = lazy(() => import("components/challenges/GenAIChallengeWizard"));

const UserSwitch = ({ user }) => {
  const theme = useTheme();
  const location = useLocation();
  const params = new SearchParams(location.search);

  const canAccessInnovationIntelligence = useMemo(
    () =>
      user &&
      util.organisationFeaturesEnabled(user, ["innovationIntelligence"]) &&
      (util.hasPermission(user, "super.viewDashboard") ||
        util.hasPermission(user, "org.innovationIntelligence", user?.organisation?._id)),
    [user],
  );

  if (location.pathname !== "/invitations" && params.get("invite_token")) {
    return <Navigate replace to={`/invitations?invite_token=${params.get("invite_token")}`} />;
  }
  if (!user) return null;
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="challenges/:id/format" element={<Navigate replace to="challenges/:id/settings/format" />} />
      <Route path="custom-pages/:pageId" element={<CustomPage />} />
      <Route path="unsubscribe" element={<UserUnsubscribe />} />
      <Route path="invitations" element={<UserInvitations />} />
      <Route path="achievements" element={<Achievements />} />
      {(util.organisationFeaturesEnabled(user, ["openAI"]) || util.hasPermission(user, "super.viewDashboard")) && (
        <Route path="challenges/new/genai" element={<GenAIChallengeWizard />} />
      )}
      ≈ <Route path="challenges/public" element={<PublicChallenges />} />
      <Route path="challenges" element={<Challenges />} />
      <Route path="challenges/discover" element={<Challenges />} />
      <Route path="challenges/:id/settings/*" element={<ChallengeSettings />} />
      <Route path="challenges/:id/*" element={<Challenge />} />
      <Route path="boards/new" element={<NewProjectBoard />} />
      <Route path="boards/:id" element={<ProjectBoard />} />
      <Route path="ideas" element={<YourIdeas />} />
      <Route path="profiles/:id" element={<ChallengeProfile />} />
      <Route path="ideas/:id" element={<Idea />} />
      <Route path="groups/discover" element={<Groups />} />
      <Route path="groups" element={<Groups />} />
      <Route path="groups/new" element={<NewGroup />} />
      <Route path="groups/:id/*" element={<Group />} />
      <Route path="tags" element={<Tags />} />
      <Route path="tags/:ids" element={<Tags />} />
      <Route path="users/:id/*" element={<UserProfile />} />
      <Route path="messages" element={user?.ghostingAs ? <Home /> : <MessageCentre />} />
      {canAccessInnovationIntelligence && (
        <Route path="innovationintelligence/*" element={<InnovationIntelligence />} />
      )}
      {theme.sizes.isMobile && (
        <Route path="messagetray" element={user?.ghostingAs ? <Home /> : <MobileMessageWidget />} />
      )}
      {theme.sizes.isMobile && <Route path="/notifications" element={<MobileNotificationsWidget />} />}
      <Route path="preferences/*" element={<Preferences />} />
      <Route path="account/*" element={<Preferences />} />
      {util.hasPermission(user, "org.viewDashboard", user.organisation._id) && (
        <Route path="admin/*" element={<Administration />} />
      )}
      {util.hasPermission(user, "super.viewDashboard") && (
        <Route path="superadmin/*" element={<SuperAdministration />} />
      )}
      <Route path="oauth/connections/:type" element={<OAuthConnection />} />
      <Route path="join/:token" element={<PersistentToken />} />
      {/* Check Entry.jsx to match all routes here */}
      <Route path="password/forgotten" element={<Entry />} />
      <Route path="password/reset" element={<Entry />} />
      <Route path="login/organisation" element={<Entry />} />
      <Route path="enrol" element={<Entry />} />
      <Route path="register" element={<Entry />} />
      <Route path="login" element={<Entry />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(UserSwitch);
