import React, { CSSProperties, useCallback } from "react";
import { Link } from "react-router-dom";
import { Icon, Popup, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import styled from "styled-components";
import util from "utils/utils";

const StyledTitle = styled.div<{ $inverted?: boolean; $compact?: boolean | "very" }>`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold !important;

  font-size: ${({ $compact }) => ($compact ? ($compact === "very" ? "11px" : "12px") : "13px")} !important;
  line-height: ${({ $compact }) => ($compact ? ($compact === "very" ? "12px" : "14px") : "15px")} !important;
  text-decoration: none !important;

  width: fit-content;
  max-width: 100%;
  position: relative;
  margin-right: ${({ $compact }) => ($compact === "very" ? "0" : "4px")};

  ${({ $inverted, theme }) => ($inverted ? "color: white !important;" : `color: black !important;`)}
`;

const StyledAvatar = styled.div<{
  $compact?: boolean | "very";
  $imageUrl: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.primaryColour};

  height: ${({ $compact }) => ($compact ? ($compact === "very" ? "15px" : "25px") : "35px")};
  width: ${({ $compact }) => ($compact ? ($compact === "very" ? "15px" : "25px") : "35px")};
  min-height: ${({ $compact }) => ($compact ? ($compact === "very" ? "15px" : "25px") : "35px")};
  min-width: ${({ $compact }) => ($compact ? ($compact === "very" ? "15px" : "25px") : "35px")};
  border-radius: 50%;
  background-color: white;
  background-size: cover;
  background-position: center;
  margin-right: ${({ $compact }) => ($compact === "very" ? "6px" : "8px")};
  ${({ $imageUrl }) =>
    $imageUrl
      ? `background-image: url(${util.mixinCssUrlFallback($imageUrl, util.avatarUrl())});`
      : "background: #ededed;"};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
`;

const StyledChip = styled.div<{ $inverted?: boolean; $withLink?: boolean; $compact: boolean | "very" }>`
  display: inline-flex;
  align-items: center;

  border-radius: 50px;
  padding: ${({ $compact }) => ($compact === "very" ? "3px 5px 3px 3px;" : "3px 12px 3px 3px;")};

  ${({ $inverted }) =>
    $inverted &&
    `
    background: rgba(0, 3, 45, 0.3);
  `}
  transition: background 0.1s, box-shadow 0.1s;

  ${({ $withLink, $inverted }) =>
    $withLink
      ? `
      &:hover {
        box-shadow: 0 0 3px 1px rgba(255, 255, 255, .1) inset, 0 1px 4px 0 ${$inverted ? "rgba(0, 0, 0, 0.4)" : "rgba(0, 0, 0, 0.12)"} inset;

        ${StyledAvatar} {
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
        }
      }
      &:active {
        box-shadow: 0 0 3px 1px rgba(0, 0, 0, .1) inset, 0 1px 4px 0 rgba(0, 0, 0, 0.25) inset;
        background: ${$inverted ? "rgba(0, 3, 45, 0.37)" : "rgba(0, 0, 0, 0.04)"};

        ${StyledAvatar} {
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.05);
        }
      }
    `
      : ""}
`;

const ChipTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  text-wrap: nowrap;
  max-width: 135px;
`;

const StyledPostText = styled.span<{ $inverted?: boolean }>`
  cursor: default;
  font-size: 12px !important;
  line-height: 24px;
  color: black;
`;

const StyledDescription = styled.p<{ $inverted?: boolean; $compact?: boolean | "very" }>`
  ${({ $compact }) => ($compact ? "font-size: 10px !important;" : "font-size: 11px !important;")}
  line-height: 12px;
  min-height: 10px;
  min-width: 1px;
  text-overflow: ellipsis;
  overflow: hidden;

  font-weight: normal !important;

  ${({ $inverted }) => ($inverted ? "color: white !important;" : `color: black !important;`)}
  margin: 0px !important;
`;

const ActionIcon = styled(Icon)<{ $inverted?: boolean; $compact?: boolean | "very" }>`
  &&&& {
    color: ${({ $inverted }) => ($inverted ? "white" : "gray")};
    margin: 0;
    font-size: 1.2em;
    margin-left: 0px;
    margin-right: -8.75px;
    cursor: pointer;
    padding: ${({ $compact }) => ($compact ? "12px" : "18px")};
    border-radius: 50%;
    position: relative;

    &::before {
      position: absolute;
      top: ${({ $compact }) => ($compact ? "3px" : "9px")};
      bottom: 0;
      left: 0;
      right: 0;
    }
    &:hover {
      background: ${({ inverted }) => (inverted ? "white" : "gray")};
      color: ${({ inverted }) => (inverted ? "gray" : "white")};
    }
  }
`;

const PopupContent = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 300px;
`;

const PopupLargerImage = styled.div<{
  $imageUrl: string;
}>`
  width: 70px;
  height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.primaryColour};

  border-radius: 50%;
  background-color: white;
  background-size: cover;
  background-position: center;
  margin-right: 10px;

  ${({ $imageUrl }) =>
    $imageUrl ? `background-image: url(${$imageUrl}), url(${util.avatarUrl()});` : "background: #ededed;"};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
`;

const PopupInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export type ChipConfiguration = {
  style?: CSSProperties;
  inverted?: boolean;
  description?: string;
  details?: string;
  labels?: { name?: string; icon?: { name?: string; colour?: string } }[];

  precedingText?: string;
  postText?: string;
  linkTo?: string;
  target?: "_blank";

  onClick?: (e) => void;
  compact?: boolean | "very";
  actionIcon?: SemanticICONS;
  actionClick?: () => void;
  actionTooltip?: string;
};

type BaseChipProps = ({ imageUrl: string; icon?: SemanticICONS } | { imageUrl?: string; icon: SemanticICONS }) & {
  title: string;
} & ChipConfiguration;

const BaseChip = ({
  imageUrl,
  icon,
  style,
  inverted,
  title,
  description,
  details,
  labels,

  precedingText,
  postText,
  linkTo,
  onClick,
  compact,
  target,
  actionIcon,
  actionClick,
  actionTooltip,
}: BaseChipProps) => {
  const handleClickWithDefault = useCallback(
    (e) => {
      if (onClick) {
        e.stopPropagation();
        e.preventDefault();
        onClick(e);
      }
    },
    [onClick],
  );

  return (
    <Popup
      wide="very"
      on="hover"
      trigger={
        <Link
          style={{
            display: "inline-block",
            verticalAlign: "middle",
            maxWidth: "100%",
            width: "fit-content",
            cursor: linkTo || onClick ? "pointer" : "default",
            ...(style || {}),
          }}
          to={linkTo ?? "#"}
          target={target}
        >
          <StyledChip
            onClick={handleClickWithDefault}
            $compact={compact}
            $inverted={!!inverted}
            $withLink={!!linkTo || !!onClick}
          >
            <StyledAvatar $imageUrl={imageUrl} $compact={compact}>
              {!imageUrl && icon ? (
                <Icon
                  name={icon}
                  style={compact === "very" ? { marginTop: 1, marginLeft: 3 } : { marginTop: -4, marginLeft: 2 }}
                  size={compact === "very" ? "small" : undefined}
                />
              ) : null}
            </StyledAvatar>
            <ChipTextContainer>
              {compact !== "very" && precedingText ? (
                <StyledDescription $inverted={!!inverted}>{precedingText}</StyledDescription>
              ) : null}
              <StyledTitle $inverted={inverted} $compact={compact}>
                {title}
              </StyledTitle>
              {compact !== "very" && description ? (
                <StyledDescription $inverted={inverted} $compact={compact}>
                  {description}
                </StyledDescription>
              ) : null}
            </ChipTextContainer>
            {compact !== "very" &&
              labels?.map((label) =>
                label.icon ? (
                  <Icon
                    key={`${label.name}-${label.icon?.name}-${label.icon?.colour}`}
                    name={label.icon.name as SemanticICONS}
                    color={label.icon.colour as SemanticCOLORS}
                    style={{
                      marginRight: 3,
                      position: "relative",
                      top: compact ? -2.5 : -3,
                    }}
                  />
                ) : null,
              )}
            {compact !== "very" && actionIcon && actionClick ? (
              <Popup
                disabled={!actionTooltip}
                content={actionTooltip}
                trigger={
                  <ActionIcon
                    name={actionIcon}
                    $inverted={inverted}
                    $compact={compact}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      actionClick();
                    }}
                  />
                }
              />
            ) : null}
          </StyledChip>
          {postText && <StyledPostText $inverted={!!inverted}>{postText}</StyledPostText>}
        </Link>
      }
      content={
        <PopupContent>
          <PopupLargerImage $imageUrl={imageUrl}>
            {!imageUrl && icon ? <Icon name={icon} size="big" style={{ marginLeft: 4 }} /> : null}
          </PopupLargerImage>
          <PopupInfo>
            <h3 style={{ marginBottom: 5, marginTop: 0, fontWeight: "bolder" }}>{title}</h3>
            {description ? <h5 style={{ marginBottom: 5, marginTop: 0 }}>{description}</h5> : null}
            {details ? (
              <StyledDescription>
                <i>{details}</i>
              </StyledDescription>
            ) : null}
            {labels?.map((label) => (
              <span key={`${label.name}-${label.icon?.name}-${label.icon?.colour}`}>
                {label.name}
                {label.icon ? (
                  <Icon
                    name={label.icon.name as SemanticICONS}
                    color={label.icon.colour as SemanticCOLORS}
                    style={{ marginLeft: 5 }}
                  />
                ) : null}
              </span>
            ))}
          </PopupInfo>
        </PopupContent>
      }
    />
  );
};

export default BaseChip;
