import React from "react";
import { Label, Placeholder } from "semantic-ui-react";
import styled from "styled-components";

const CardContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  margin-bottom: 10px;
  max-width: 100%;
  :first-of-type {
    margin-top: 0;
  }
  :last-of-type {
    margin-bottom: 0;
  }
`;

const IdeaListCard = styled.tr`
  h4 {
    margin: 0;
  }
`;

const SelectIdeaChip = styled.div`
  display: flex;
  width: ${({ $compact }) => ($compact ? "25px" : "35px")};
  height: ${({ $compact }) => ($compact ? "40px" : "55px")};
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  // border: 1px solid lightgray;
  background-color: ${({ $isSelected, theme }) => ($isSelected ? theme.accent : "#e9ebee")};
  color: ${({ $isSelected }) => ($isSelected ? "#fff" : "gray")};

  cursor: pointer;
  > i {
    font-size: ${({ $compact }) => ($compact ? "1.2em" : "2em")};
    width: 1em;
    margin-right: 0;
    margin-top: ${({ $compact }) => ($compact ? "0 !important" : "7px")};
    ${({ $isSelected }) => !$isSelected && "font-size: 0"}
  }
  &:hover {
    > i {
      font-size: ${({ $compact }) => ($compact ? "1.2em" : "2em")} !important;
    }
  }
`;

const IdeaCoverImage = styled.div`
  ${({ $isGroup }) =>
    $isGroup
      ? `
    min-width: 40px;
    margin-right: 5px;
  `
      : `
    display: flex;
    width: 100%;
  `}
  height: 40px;
  border-radius: 5px;
  background-image: url(${({ $coverImage }) => $coverImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  &:hover {
    box-shadow: inset 0 0 0 2000px rgba(211, 211, 211, 0.3);
  }
`;

const IdeaInfo = styled.div`
  display: flex;
  ${({ $isGroup, $canManage, theme }) =>
    $isGroup
      ? `
    padding-right: 5px;
    margin: 0;
    margin-right: 10px;
    border-right: 0.5px solid gray;
    width: ${theme.sizes.isMobile && $canManage ? "30%" : "40%"};
  `
      : `
    height: 100%;
    width: 100%;
    justify-content: center;
    padding: 5px 7.5px;
  `}
  flex-direction: column;
  align-items: flex-start;
  h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    margin-bottom: 2px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .idea-authors {
    display: block;
    color: gray;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    .idea-others {
      cursor: pointer;
      user-select: none;
      text-decoration: underline;
      &:hover {
        color: ${({ theme }) => theme.primaryColour};
      }
    }
  }
  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
  }
`;

const StatusLabel = ({ style, ...props }) => <Label {...props} style={{ ...style, marginRight: 5 }} circular empty />;

const IdeaMeta = styled.div`
  display: flex;
  height: 100%;
  ${({ $isGroup }) =>
    $isGroup
      ? `
    margin: 0;
  `
      : `
    height: 100%;
    width: 100%;
    padding-left: 7.5px;
    justify-content: center;
  `}
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  span {
    color: gray;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
`;

const IdeaUpdateStatus = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 2px;
  span {
    color: black;
  }
  a {
    text-decoration: underline;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ui.label,
  i.icon {
    width: 1.3em !important;
    height: 1.3em !important;
    margin-bottom: 1.5px;
  }
`;

const IdeaActions = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ $justify }) => $justify || "flex-end"};
`;

const IdeaSocialChipPlaceholder = ({ compact }) => (
  <Placeholder
    style={{
      height: compact ? 22 : 40,
      width: compact ? 22 : 55,
      borderRadius: 5,
      margin: "0 0 0 5px",
    }}
  >
    <Placeholder.Image square />
  </Placeholder>
);

const IdeaSocialChip = styled.div`
  height: ${({ $compact }) => (!$compact ? "40px" : "22px")};
  width: ${({ $compact, $fitContent }) => ($fitContent ? "fit-content" : !$compact ? "55px" : "22px")};
  border-radius: 5px;
  background-color: ${({ $actioned, theme, $backgroundColor }) =>
    $backgroundColor || ($actioned ? theme.accent : "none")};
  ${({ $warning }) => $warning && "background-color: #e28024;"}
  ${({ $primary }) => $primary && "background-color: #408ad4;"}
  margin-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
  justify-self: flex-end;
  cursor: pointer;
  ${({ $hideBackground }) =>
    $hideBackground
      ? ""
      : `
    &:hover {
      background-color: ${({ $actioned, theme }) => ($actioned ? theme.accent : "#e6e6e6")};
    }
  `}
  i, span {
    color: ${({ $actioned, $backgroundColor }) => ($actioned || $backgroundColor ? "white" : "#505050")};
  }
  i {
    font-size: ${({ $compact }) => (!$compact ? "1.3em" : "1em")};
    ${({ $compact }) => $compact && "line-height: 1em;"}
  }
  i.check-mark {
    right: 6px;
    position: relative;
    top: -7px;
  }
  span {
    display: block;
    font-size: 1.4em;
    font-weight: bold;
  }
`;

const IdeaProject = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;

  span {
    ${({ $isAssigned }) =>
      $isAssigned
        ? `
    color: #e16464;
    `
        : `
    color: #787878;
    `}
  }

  > .lane-name {
    text-decoration: underline;
    display: block;
    margin: 0;
    font-size: 0.85em;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

const IdeaPinnedIcon = styled.div`
  background-color: ${({ isPinned }) => (isPinned ? "#048C85" : "#f5f5f5")};
  display: flex;
  width: 35px;
  height: 55px;
  justify-content: center;
  align-items: center;
  color: ${({ isPinned }) => (isPinned ? "#fff" : "#505050")};
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  margin: 0;
  padding: 0 5px;
  // border-top: 1px solid ${({ isPinned }) => (isPinned ? "#048C85" : "lightgray")};
  // border-bottom: 1px solid ${({ isPinned }) => (isPinned ? "#048C85" : "lightgray")};
  &:hover {
    ${({ isPinned }) => !isPinned && "background-color: #e6e6e6"}
  }
  i {
    font-size: 1.2em;
    margin: 0;
  }
  ${({ canManage }) =>
    canManage &&
    `
    cursor: pointer;
  `}
`;

const AuthorPopup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const AssessmentSummary = styled.span`
  span {
    ${({ $isComplete }) =>
      !$isComplete
        ? `
      color: #e16464;
    `
        : `
      color: #6435C9;
    `}
  }

  text-decoration: underline;
  cursor: pointer;
  display: block;
`;

export {
  CardContainer,
  SelectIdeaChip,
  IdeaListCard,
  IdeaCoverImage,
  IdeaInfo,
  StatusLabel,
  IdeaMeta,
  IdeaUpdateStatus,
  IdeaActions,
  IdeaSocialChip,
  IdeaSocialChipPlaceholder,
  IdeaPinnedIcon,
  AuthorPopup,
  AssessmentSummary,
  IdeaProject,
};
