import React from "react";
import { Button, Label } from "semantic-ui-react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import SlidingModal from "components/lib/SlidingModal";
import useTheme from "theme/useTheme";
import { Link } from "react-router-dom";
import ChallengeBody from "components/challenges/Challenge";

const ModalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PreviewContainer = styled.div`
  width: ${({ theme }) => (theme.sizes.isMobile ? "100vw" : "100%")};
  max-height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
  background-color: #f2f2f3;
`;

const PreviewHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
  background-color: #e9ebee;
  border-bottom: 1px solid lightgray;
  z-index: 999;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ChallengeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  margin-top: 57px;
`;

const BodyContainer = styled.div`
  width: 100%;
  padding-bottom: 20px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ $justifyContent }) => $justifyContent || "flex-end"};
  align-items: center;
`;

const ChallengePreview = ({ challengeId, closeModal }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <SlidingModal
      isOpen={!!challengeId}
      onClose={closeModal}
      width={theme.sizes.isComputer ? "60%" : "100%"}
      style={theme.sizes.isMobile ? { height: "100vh" } : {}}
    >
      <ModalContainer>
        <PreviewContainer theme={theme}>
          <PreviewHeader>
            <FlexRow $justifyContent="space-between">
              <Button icon="chevron left" onClick={() => closeModal()} content="Close preview" />
              <Button
                primary
                content="View full challenge"
                icon="file alternate"
                as={Link}
                to={`/challenges/${challengeId}`}
              />
              <Label basic content={`Non admin view of ${t("generic.challenge")}`} color="violet" />
            </FlexRow>
          </PreviewHeader>
          <ChallengeContainer>
            <BodyContainer>
              <ChallengeBody isPreview previewId={challengeId} />
            </BodyContainer>
          </ChallengeContainer>
        </PreviewContainer>
      </ModalContainer>
    </SlidingModal>
  );
};

export default ChallengePreview;
