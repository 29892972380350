import React, { Component } from "react";
import { Icon, Label, Popup, Image } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import util from "utils/utils";
import moment from "moment";
import styled, { withTheme } from "styled-components";
import { SearchParams } from "simplydo/core";
import FileChooser from "components/lib/Choosers/FileChooser/FileChooser";

import { MultiLineParagraph } from "components/lib/UI";
import { GiphyGif, GiphyPicker } from "components/lib/Giphy";
import Emoji from "components/lib/Emoji/Emoji";
import EmojiChooser from "components/lib/Emoji/EmojiChooser";
import MentionText from "components/lib/Editors/RichText/MentionText";
import { ActionArea, ActionButton, InputContainer } from "./Actions";
import UserChip from "components/lib/Chips/UserChip";

export const MediaContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  align-items: flex-start;
  justify-content: flex-start;
  ${({ $margined }) => ($margined ? "margin-top: 7.5px;" : "")}
  padding: 0 5px;

  .ui.label:not(.circular),
  .ui.button:not(.circular) {
    max-width: 47%;
  }
`;

const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  padding-bottom: 7.5px;
  min-width: 100%;
  margin: 0 5px 5px;
  ${({ $isChild }) => $isChild && "margin-left: 0; margin-bottom: 0; padding-bottom: 0;"}
  ${({ $isPinned }) =>
    $isPinned &&
    `
    padding: 10px;
    border-radius: 5px;
    background-color: #f6f6db;
    border: 2px solid #dadba0;
    margin-left: 0;
    margin-bottom: 15px;
  `}
  ${({ $isHighlighted }) =>
    $isHighlighted &&
    `
    padding: 10px;
    border-radius: 5px;
    background-color: #88e4d51c;
    border: 2px solid #5da2b3;
    margin-left: 0;
    margin-bottom: 15px;
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding-left: 35px;
  ${({ $isChild, $lastComment, $isPinned, $isHighlighted }) =>
    !$isChild && !$lastComment && !$isPinned && !$isHighlighted && "border-bottom: 1px solid #c9c9c9;"}
`;

const AuthorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 3.5px;
  a {
    font-weight: 700;
    font-size: 0.9em;
    color: black;
    display: block;
    line-height: 1.2;
  }
  span {
    display: block;
    font-size: 0.9em;
    color: gray;
    margin-right: 7.5px;
    line-height: 1.2;
    margin-left: 7.5px;
  }
`;

const TextContainer = styled.div`
  p {
    color: #2d2d2d;
    font-size: 1.05em;
  }
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3.5px 0 7.5px;
  span {
    display: block;
    color: gray;
    font-weight: 550;
    cursor: pointer;
    margin-right: 7.5px;
    font-size: 0.85em;
    &:hover {
      text-decoration-line: underline;
    }
  }
`;

class FeedComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      repliesCollapsed: true,
      allChildrenCollapsed: false,
      replyBoxOpen: false,
      giphyPickerOpen: false,
      highlightComment: false,
    };

    this.replyRef = React.createRef();
  }

  componentDidMount() {
    const params = new SearchParams(window.location.search);
    this.setState({ highlightComment: params.get("comment") === this.props.comment?._id });
  }

  handleCollapsing = () => {
    this.setState((prevState) => {
      // If we are just showing a preview, but not full replies
      if (!prevState.allChildrenCollapsed && prevState.repliesCollapsed) {
        return {
          repliesCollapsed: false,
        };
      }
      // If everything is open, close everything
      if (prevState.allChildrenCollapsed) return { repliesCollapsed: false, allChildrenCollapsed: false };
      // If everything is closed, open everything
      return { repliesCollapsed: true, allChildrenCollapsed: true };
    });
  };

  postReply = (commentId) => {
    const { postReply } = this.props;
    if (postReply) {
      postReply(commentId);
    }
    this.setState({ replyBoxOpen: false, repliesCollapsed: false });
  };

  openGiphyPicker = () => this.setState({ giphyPickerOpen: true });

  closeGiphyPicker = () => this.setState({ giphyPickerOpen: false });

  addGiphy = (gif) => {
    this.setState({ giphyPickerOpen: false });
    this.props.addGiphy(gif, this.props.comment._id);
  };

  addAttachment = (s, f, d) => {
    this.props.addAttachment(s, f, d, this.props.comment._id);
  };

  replyToExistingReply = (user) => {
    // If we are replying to an existing reply
    // We just open the comment box for the parent instead
    // With a pre-populated mention to the user we were originally attempting to reply to
    const { addAutoMention } = this.props;
    if (!addAutoMention) return;
    this.setState({ replyBoxOpen: true }, () => {
      addAutoMention(this.props.comment._id, user);
      // This is a helper function to focus the rich text box
      // It finds the last one open on the page (which we are assuming is the reply box)
      // And focusing the cursor after the @ mention
      setTimeout(() => {
        const remirrors = document.getElementsByClassName("remirror-editor");
        const last = remirrors[remirrors.length - 1];
        last.focus();

        const range = document.createRange();
        const sel = window.getSelection();

        const lastChild = last.childNodes[last.childNodes.length - 1];
        range.setStart(lastChild, lastChild.childNodes.length);
        range.collapse(true);

        sel.removeAllRanges();
        sel.addRange(range);
      }, 150);
    });
  };

  render() {
    const {
      comment,
      user,
      canManage,
      getReplyData = () => ({ text: "", media: [] }),
      feed = [],
      updateReply,
      deleteComment,
      t,
      deleteAttachment,
      getFile,
      pin,
      unpin,
      reactComment,
      authors,
      isLastChild,
      commentLoading,
      disableReacts,
      disableAttachments,
      disableReplies,
      replyToParent,
    } = this.props;

    const { repliesCollapsed, allChildrenCollapsed, replyBoxOpen, giphyPickerOpen, highlightComment } = this.state;
    const children = feed.filter((f) => f.parent === comment._id);
    if (!comment.owner) return null;
    const reacts = [];
    comment.reacts &&
      comment.reacts.forEach((react) => {
        let index = reacts.map((r) => r.emoji).indexOf(react.emoji);
        if (index === -1) {
          reacts.push({
            ...react,
            count: 0,
            users: [],
            hasReacted: false,
          });
          index = reacts.length - 1;
        }
        reacts[index].users.push(react.ownerUser);
        reacts[index].count += 1;
        if (react.user === user._id) reacts[index].hasReacted = true;
      });
    const { text: replyText = "", media: replyMedia = [] } = getReplyData(comment._id);

    const isChildComment = comment.ownerIdea && comment.ownerIdea.parent;

    return (
      <CommentContainer
        key={comment._id}
        $isPinned={comment.isPinned}
        $isChild={!!comment.parent}
        $isHighlighted={highlightComment}
      >
        <div>
          {comment.isPinned ? (
            <Popup
              content="This comment is pinned"
              trigger={
                <Label size="small" color="yellow">
                  <Icon name="pin" style={{ margin: 0 }} /> Pinned
                </Label>
              }
            />
          ) : null}
          {comment.byAssignee ? (
            <Popup
              content={`${comment.owner.profile.fullName} is assigned to this ${t("generic.idea")}`}
              trigger={
                <Label size="small" color="teal">
                  Assigned to this {t("generic.idea")}
                </Label>
              }
            />
          ) : null}
          {comment.isMergeJustification ? (
            <Popup
              content={`This comment was left as an explanation for why these ${t("generic.ideas")} were merged`}
              trigger={
                <Label size="small" color="blue">
                  This comment is {t("generic.ideaWithArticle")} merge explanation
                </Label>
              }
            />
          ) : null}
          {isChildComment ? (
            <Popup
              content={`This comment is originally from the ${t("generic.idea")} "${comment.ownerIdea.name}"`}
              trigger={<Label size="small">This comment is from a merged {t("generic.idea")}</Label>}
            />
          ) : null}
        </div>
        <AuthorContainer $isChild={!!comment.parent}>
          <UserChip
            precedingText={
              comment.userStatus === "ideaOwner" ? `${t("common:capitalise", { key: "generic.idea" })} author` : null
            }
            user={comment.owner}
            compact
          />
          <span>{moment(comment.createdAt).fromNow()}</span>
        </AuthorContainer>
        <ContentContainer
          $isChild={!!comment.parent}
          $isPinned={comment.isPinned}
          $isHighlighted={highlightComment}
          $lastComment={isLastChild}
          className="content-container"
        >
          <TextContainer>
            <MultiLineParagraph
              mentionedUsers={comment.mentionedUsers}
              mentionedUserAccess={comment.mentionedUserAccess}
              content={comment.comment || ""}
            />
          </TextContainer>
          {comment.media && comment.media.length ? (
            <MediaContainer $margined>
              {comment.media.map((media, i) => {
                if (media.type === "image") {
                  if (comment.media.length === 1) {
                    return (
                      <div key={i} style={{ width: "100%" }}>
                        <a href={getFile(media)} rel="noopener noreferrer" target="_blank">
                          <Image
                            rounded
                            bordered
                            style={{ objectFit: "contain" }}
                            alt="Comment attachment"
                            className="comment-img"
                            src={getFile(media)}
                          />
                        </a>
                      </div>
                    );
                  }
                  return (
                    <div
                      key={i}
                      style={{
                        width: "150px",
                      }}
                    >
                      <a href={getFile(media)} rel="noopener noreferrer" target="_blank">
                        <Image
                          rounded
                          bordered
                          style={{ objectFit: "contain" }}
                          alt="Comment attachment"
                          className="comment-img"
                          src={getFile(media)}
                        />
                      </a>
                    </div>
                  );
                }
                if (media.type === "gif") {
                  return <GiphyGif style={{ borderRadius: 5 }} key={media.value} gif={media.data} size="fixed" />;
                }
                return (
                  <a
                    href={getFile(media)}
                    key={i}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={media.name}
                    className="ui small button secondary"
                    style={{ borderRadius: 5, minWidth: "fit-content" }}
                  >
                    <i className="paperclip icon"></i>
                    {media.name}
                  </a>
                );
              })}
              <div style={{ clear: "both" }} />
            </MediaContainer>
          ) : null}

          {reacts?.length ? (
            <MediaContainer $margined>
              {reacts?.map((react, idx) => (
                <Popup
                  key={idx}
                  clickable="true"
                  trigger={
                    <Label
                      size="tiny"
                      style={{ height: 27, cursor: "pointer" }}
                      color={react.hasReacted ? "teal" : null}
                      basic
                      circular
                      onClick={() => reactComment(comment._id, react.emoji, !react.hasReacted)}
                    >
                      <Emoji style={{ display: "inline-block" }} emoji={{ id: react.emoji }} size={15} />
                      <span
                        style={{
                          verticalAlign: "top",
                          display: "inline-block",
                          marginLeft: 5,
                          marginTop: 2,
                        }}
                      >
                        {react.count}
                      </span>
                    </Label>
                  }
                  content={
                    <p style={{ fontSize: 11 }}>
                      {`${react?.users
                        ?.map((u) => (u?._id === user._id ? "You" : u?.profile?.fullName || "Someone"))
                        .join(" and ")} reacted with :${react?.emoji}:`}
                    </p>
                  }
                />
              ))}
            </MediaContainer>
          ) : null}
          <ActionsContainer>
            {!disableReacts && (
              <Popup
                flowing
                on="click"
                basic
                size="tiny"
                position="top right"
                trigger={
                  <span>
                    <Icon name={(comment.reactCount || 0) > 0 ? "smile" : "smile outline"} />
                    {util.pluralise(comment.reactCount || 0, " react", " reacts")}
                  </span>
                }
                content={<EmojiChooser onComplete={(em) => reactComment(comment._id, em, true)} />}
              />
            )}
            {!disableReplies ? (
              <span
                onClick={() => {
                  if (!comment.parent) {
                    updateReply(comment._id, "");
                    this.setState({ replyBoxOpen: !replyBoxOpen });
                  } else if (replyToParent) {
                    replyToParent(comment.owner);
                  }
                }}
              >
                {t("feeds.comments.reply")}
              </span>
            ) : null}
            {comment.owner._id === user._id || canManage ? (
              <span onClick={() => deleteComment(comment._id)}>{t("generic.delete")}</span>
            ) : null}
            {(canManage || (authors && authors.indexOf(user._id) !== -1)) && !comment.parent ? (
              <>
                {comment.isPinned ? (
                  <span onClick={() => unpin(comment._id)}>{t("feeds.pin.unPin")}</span>
                ) : (
                  <span onClick={() => pin(comment._id)}>{t("feeds.pin.pin")}</span>
                )}
              </>
            ) : null}
            {children.length && children.length > 3 ? (
              <span onClick={() => this.handleCollapsing()}>
                {!repliesCollapsed ? "Hide" : "Show"} {util.pluralise(children.length, "reply", "replies")}
              </span>
            ) : null}
          </ActionsContainer>
          {!allChildrenCollapsed && (
            <>
              {children.length && repliesCollapsed ? (
                <>
                  {children.slice(0, 3).map((child) => (
                    <FeedComment
                      {...this.props}
                      key={child._id}
                      comment={child}
                      replyToParent={this.replyToExistingReply}
                    />
                  ))}
                </>
              ) : null}
              {!comment.parent && !repliesCollapsed ? (
                <>
                  {children.map((child) => (
                    <FeedComment
                      {...this.props}
                      key={child._id}
                      comment={child}
                      replyToParent={this.replyToExistingReply}
                    />
                  ))}
                </>
              ) : null}
              {children.length > 3 && repliesCollapsed ? (
                <span
                  style={{
                    cursor: "pointer",
                    textDecorationLine: "underline",
                    color: "gray",
                    marginLeft: 40,
                    marginBottom: 10,
                  }}
                  onClick={() => this.setState({ repliesCollapsed: false })}
                >
                  Show {children.length - 3} more...
                </span>
              ) : null}
            </>
          )}
          {replyBoxOpen && (
            <div style={{ alignSelf: "stretch", marginBottom: 7.5 }} className="feed-replies">
              <InputContainer>
                <MentionText
                  value={replyText}
                  onChange={(value) => updateReply(comment._id, value)}
                  editorRef={this.replyRef}
                />

                <MediaContainer $margined>
                  {replyMedia.map((file, index) => {
                    switch (file.type) {
                      case "gif":
                        return (
                          <GiphyGif
                            onClickGif={() => deleteAttachment(index, comment._id)}
                            canDelete={true}
                            key={file.value}
                            gif={file.data}
                            size="fixed_small"
                          />
                        );
                      case "image":
                        return (
                          <div style={{ position: "relative" }}>
                            <Image
                              bordered
                              rounded
                              spaced
                              src={file.signedDownloadRequest}
                              width={75}
                              height={75}
                              style={{ objectFit: "cover" }}
                            />
                            <Icon
                              name="close"
                              onClick={() => deleteAttachment(index, comment._id)}
                              style={{ cursor: "pointer", position: "absolute", top: 3, right: 8 }}
                            />
                          </div>
                        );
                      default:
                        return (
                          <Label key={file.value}>
                            {file.name} <Icon name="close" onClick={() => deleteAttachment(index, comment._id)} />
                          </Label>
                        );
                    }
                  })}
                </MediaContainer>

                <ActionArea>
                  <div>
                    <ActionButton
                      title={"Mention user"}
                      position="top left"
                      iconName="user"
                      onClick={(e) => {
                        e.preventDefault();
                        const newComment = `${replyText}@`;
                        updateReply(comment._id, newComment);

                        setTimeout(() => {
                          if (this.replyRef.current) {
                            this.replyRef.current.focus(100000);
                          }
                        }, 150);
                      }}
                    />
                    {!disableAttachments && this.props.addAttachment ? (
                      <FileChooser
                        forType={this.props.fileChooserForType}
                        forId={this.props.fileChooserForId}
                        trigger={<ActionButton title={t("feeds.attachments.attach")} iconName="paperclip" />}
                        onComplete={(s, f, d) => this.addAttachment(s, f, d)}
                      />
                    ) : null}
                    {!disableAttachments && this.props.addGiphy ? (
                      <GiphyPicker
                        onChoose={(gif) => this.addGiphy(gif)}
                        trigger={
                          <ActionButton
                            iconName="file image outline"
                            title="Find a GIF"
                            onClick={this.openGiphyPicker}
                          />
                        }
                        open={giphyPickerOpen}
                        onClose={this.closeGiphyPicker}
                      />
                    ) : (
                      <div />
                    )}
                  </div>
                  <ActionButton
                    disabled={!(replyText || replyMedia.length)}
                    onClick={() => this.postReply(comment._id)}
                    content="Post reply"
                    iconName={commentLoading ? "spinner" : "send"}
                  />
                </ActionArea>
              </InputContainer>
            </div>
          )}
        </ContentContainer>
      </CommentContainer>
    );
  }
}

export default withTranslation()(withTheme(FeedComment));
