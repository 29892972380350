import * as Sentry from "@sentry/react";

/* eslint-disable no-use-before-define */
import util from "utils/utils";
import actions from "actions";
import reportsApi from "utils/reports";
import { store } from "store";
import { auth } from "./auth";
import { search } from "./search";
import { challenges } from "./challenges";
import { ideas } from "./ideas";
import { groups } from "./groups";
import { uploads } from "./uploads";
import { users } from "./users";
import { organisations } from "./organisations";
import { messages } from "./messages";
import { notifications } from "./notifications";
import { polls } from "./polls";
import { tags } from "./tags";
import { webhooks } from "./webhooks";
import { announcements } from "./announcements";
import { invitations } from "./invitations";
import { verifications } from "./verifications";
import { superadmin } from "./superadmin";
import { reports } from "./reports";
import { boards } from "./boards";
import { innovationIntelligence } from "./innovationIntelligence";
import { journey } from "./journey";
import { roles } from "./roles";
import { persistentTokens } from "./persistentTokens";
import { connections } from "./connections";

type IAPIReq = (
  method: string,
  path: string,
  data: object | null,
  success: ((payload: typeof JSON.parse) => void) | null,
  failure: ((payload: { status: number; message: string }) => void) | null,
  withAuth?: boolean,
) => void;

const APIModules = {
  auth,
  search,
  challenges,
  ideas,
  groups,
  uploads,
  users,
  organisations,
  messages,
  notifications,
  polls,
  tags,
  webhooks,
  announcements,
  invitations,
  verifications,
  superadmin,
  reports,
  boards,
  innovationIntelligence,
  journey,
  roles,
  persistentTokens,
  connections,
};

type IAPIModules = {
  [apiFunction in keyof typeof APIModules]: ReturnType<(typeof APIModules)[apiFunction]>;
};

export type IAPIFuncs = {
  token?: string;
  revertToken?: string;
  webVersion?: string;
  store?: typeof store;
  req: IAPIReq;
  authenticatedRequest: IAPIReq;
  unauthenticatedRequest: IAPIReq;
  maybeAuthenticatedRequest: IAPIReq;
};

export type IAPI = IAPIFuncs & IAPIModules;

export const api: IAPI = {
  token: null,
  revertToken: null,
  webVersion: null,

  req(method, path, data, success, fail, withAuth) {
    const now = new Date();
    const { user } = store.getState();
    if (
      user &&
      user?._id &&
      util.localStorageIsSupported() &&
      now.getTime() - new Date(localStorage.getItem("heartbeat")).getTime() > 3600000
    ) {
      localStorage.setItem("heartbeat", now.toString());
      this.users.heartbeat(
        user._id,
        () => {},
        () => {},
      );
    }
    const xhr = new XMLHttpRequest();
    xhr.open(method, `${import.meta.env.VITE_API_URL}${path}`);
    xhr.setRequestHeader("Content-Type", "application/json");
    if (withAuth && api.token) {
      xhr.setRequestHeader("Authorization", `Bearer ${api.token}`);
    }
    xhr.setRequestHeader("SimplyDo-Device", navigator.userAgent);
    xhr.setRequestHeader("SimplyDo-ClientVersion", import.meta.env.VITE_GIT_SHA);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          let response;
          api.webVersion = xhr.getResponseHeader("SimplyDo-WebVersion");
          try {
            response = JSON.parse(xhr.responseText);
          } catch (err) {
            Sentry.captureException(err, { extra: { originalResponse: xhr.responseText } });
            console.error(err);
            if (fail) fail({ status: 400, message: "There was a problem with this request" });
            return;
          }
          if (success) success(response);
        } else {
          if (xhr.status === 530)
            store.dispatch(
              actions.auth.ipNotAllowed({ status: xhr.status, message: "Access from this IP address is not allowed" }),
            );
          if (xhr.status === 401 && api.token)
            this.auth.localLogout(() =>
              store.dispatch(actions.auth.getUserFail({ status: xhr.status, message: "Failed to authenticate" })),
            );
          let message;
          try {
            message = JSON.parse(xhr.responseText).message;
          } catch (err) {
            if (fail) fail({ status: xhr.status, message: "There was a problem with this request" });
          }
          if (fail) {
            // If the user is logged in, and gets a response saying their email isn't verified
            // Then we know email verification has been turned on since they attempted login
            // If so, we restrict app access and show them the verify email screen
            if (user && message === "Email verification required") {
              store.dispatch(actions.user.updateOrganisation("emailValidationEnforcing", true));
            }
            fail({ status: xhr.status, message });
          }
        }
      }
    };
    xhr.send(data && JSON.stringify(data));
  },

  unauthenticatedRequest(method, path, data, success, fail) {
    api.req(method, path, data, success, fail);
  },

  authenticatedRequest(method, path, data, success, fail) {
    if (!api.token) return;
    api.req(method, path, data, success, fail, true);
  },

  maybeAuthenticatedRequest(method, path, data, success, fail) {
    if (api.token) api.authenticatedRequest(method, path, data, success, fail);
    else api.unauthenticatedRequest(method, path, data, success, fail);
  },
} as IAPI;

api.journey = journey(api);
api.auth = auth(api);
api.search = search(api);
api.reports = reports(reportsApi);
api.superadmin = superadmin(api);
api.challenges = challenges(api);
api.ideas = ideas(api);
api.groups = groups(api);
api.uploads = uploads(api);
api.users = users(api);
api.organisations = organisations(api);
api.messages = messages(api);
api.notifications = notifications(api);
api.polls = polls(api);
api.tags = tags(api);
api.webhooks = webhooks(api);
api.announcements = announcements(api);
api.invitations = invitations(api);
api.verifications = verifications(api);
api.boards = boards(api);
api.innovationIntelligence = innovationIntelligence(api);
api.roles = roles(api);
api.persistentTokens = persistentTokens(api);
api.connections = connections(api);

export default api;
