import React from "react";
import util from "utils/utils";
import { Trans } from "react-i18next";

export default function IdeaAddedAsCollaboratorContent({ events, ownerIdea = { name: "Undefined" }, removed }) {
  return (
    <Trans i18nKey="notifications.ideaAddedAsCollaborator">
      <b>{{ name: util.getEventUsers(events) }}</b>
      {{ action: removed ? "removed" : "added" }}
      you as a collaborator to the idea
      <b>{{ idea: ownerIdea.name }}</b>
    </Trans>
  );
}
