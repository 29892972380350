import React, { useMemo } from "react";
import { Button, ButtonProps, Popup } from "semantic-ui-react";

type TooltipButtonProps = ButtonProps & {
  tooltip?: string;
  wrapperStyle?: React.CSSProperties;
};

const TooltipButton = ({ tooltip, wrapperStyle = {}, ...props }: TooltipButtonProps) => {
  const button = useMemo(() => <Button {...props} />, [props]);
  if (!tooltip) {
    return button;
  }
  return <Popup content={tooltip} trigger={<div style={{ display: "inline-block", ...wrapperStyle }}>{button}</div>} />;
};

export default TooltipButton;
