import React from "react";
import util from "utils/utils";
import { Trans } from "react-i18next";

const ProjectIdeaMoved = ({ events, ownerIdea = { name: "Undefined" } }) => {
  return (
    <Trans i18nKey="notifications.projectIdeaMoved">
      <b>{{ name: util.getEventUsers(events) }}</b>
      has moved the idea
      <b>{{ idea: ownerIdea.name }}</b>
      from the
      <b>{{ prevStage: events[0]?.previousLane }}</b>
      stage to the
      <b>{{ newStage: events[events.length - 1]?.newLane }}</b>
      stage
    </Trans>
  );
};

export default ProjectIdeaMoved;
