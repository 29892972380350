import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "semantic-ui-react";
import styled from "styled-components";
import { Other } from "simplydo/interfaces";

const AdvancedProfileContainer = styled.div`
  .ui.input {
    width: 100%;
  }
  .ui.list {
    margin-top: 10px;
    .ui.avatar.image {
      object-fit: contain;
    }
  }
  .or {
    margin: 5px 0;
    > span {
      color: gray;
      display: block;
    }
  }
`;

type IPrimaryContactDetails = {
  companyDetails: Other.ICompanyDetails;
  setCompanyDetails: Dispatch<SetStateAction<Other.ICompanyDetails>>;
};

const PrimaryContactEditor = ({ setCompanyDetails, companyDetails }: IPrimaryContactDetails) => {
  const user = useSelector((state: { user: Other.IUserMe }) => state?.user);

  const {
    primaryContact = {
      fullName: "",
      phoneNumber: "",
      email: user?.emails?.length ? user.emails[0].address : "",
      jobTitle: "",
    },
  } = companyDetails;
  const { fullName, phoneNumber, email, jobTitle } = primaryContact;

  const updatePrimaryContact = useCallback(
    (key: keyof Other.IPrimaryContact, value: string) => {
      setCompanyDetails((prevState: Other.ICompanyDetails) => ({
        ...prevState,
        primaryContact: {
          ...(prevState.primaryContact || {}),
          [key]: value,
        },
      }));
    },
    [setCompanyDetails],
  );

  useEffect(() => {
    setCompanyDetails((prevState: Other.ICompanyDetails) => {
      const { primaryContact: prevPrimaryContact } = prevState;
      const updatedPrimaryContact = { ...(prevPrimaryContact || {}) };
      if (!prevPrimaryContact?.fullName) updatedPrimaryContact.fullName = user.profile.fullName;
      if (!prevPrimaryContact?.email && user.emails?.length) updatedPrimaryContact.email = user.emails[0].address;
      if (!prevPrimaryContact?.phoneNumber && user.phoneNumbers?.length)
        updatedPrimaryContact.phoneNumber = user.phoneNumbers[0].number;
      return {
        ...prevState,
        primaryContact: updatedPrimaryContact,
      };
    });
  }, [user, setCompanyDetails]);

  return (
    <div>
      <AdvancedProfileContainer>
        <h3>Contact details</h3>
        <Form>
          <Form.Group widths={"equal"}>
            <Form.Input
              placeholder="Brian McBrain"
              label="Full name"
              required
              value={fullName}
              onChange={(e, { value }) => updatePrimaryContact("fullName", value)}
            />
            <Form.Input
              placeholder="Engineer"
              label="Position or job title"
              required
              value={jobTitle}
              onChange={(e, { value }) => updatePrimaryContact("jobTitle", value)}
            />
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Input
              placeholder="+44 0000 000000"
              label="Phone number"
              required
              value={phoneNumber}
              onChange={(e, { value }) => updatePrimaryContact("phoneNumber", value)}
            />

            <Form.Input
              placeholder="brian@simplydoideas.co.uk"
              required
              label="Contact email"
              value={email}
              onChange={(e, { value }) => updatePrimaryContact("email", value)}
            />
          </Form.Group>
        </Form>
      </AdvancedProfileContainer>
    </div>
  );
};

export default PrimaryContactEditor;
