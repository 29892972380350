import React, { useState, useCallback, useEffect, useRef, useContext, useMemo } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Grid, List, Popup, Input, Icon, Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";
import actions from "actions";
import api from "api";
import util from "utils/utils";
import useThrottle from "utils/useThrottle";
import { getPath } from "utils/locations";
import { useContainerDimensions } from "utils/useContainerDimensions";

import UserSearchItem from "./UserSearchItem";

const StyledSearchBar = styled.div`
  margin-right: ${({ theme }) => (theme.sizes.isMobile ? 0 : 20)}px;
  width: 100%;
  .ui.input {
    ${({ theme }) => (theme.sizes.isMobile ? "border-radius:0px !important;" : "")}
    background-color: '#e9ebee';
    width: 100%;
    input {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: ${({ theme }) => (theme.sizes.isMobile ? 0 : 5)};
      background-color: #e9ebee;
    }
  }
`;

const StyledRecentSearches = styled.div`
  width: 170px;
  display: ${({ $resultsAvailable }) => ($resultsAvailable ? "block" : "none")};
  width: ${({ theme, $width }) => (theme.sizes.isMobile ? "calc(100vw - 30px)" : `calc(${$width}px - 30px)`)};
  overflow-wrap: break-word;
  overflow: hidden;
`;

const StyledSearchResults = styled.div`
  max-height: 760px;
  overflow: auto;
  overflow-x: hidden;
  width: ${({ theme, $width }) => (theme.sizes.isMobile ? "calc(100vw - 30px)" : `calc(${$width}px - 30px)`)};
  padding: 0 !important;
  display: ${({ $resultsAvailable }) => ($resultsAvailable ? "block" : "none")};
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 !important;
  ${({ $spaceTop }) => $spaceTop && "margin-top: 10px !important;"}
`;

const StyledTitle = styled.h4`
  margin: 0;
`;
const StyledListItem = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #e9ebee;
  }
`;

const StyledSpotlightResult = styled.div`
  background-color: #e9ebee;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 10px;
  border-radius: 5px;
  &:hover {
    background-color: #babcbe;
  }
  ${({ $active }) => $active && "background-color: #babcbe;"}
`;

const useKeyDown = function (targetKey) {
  const [keyDown, setKeyDown] = useState(false);

  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyDown(true);
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyDown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyDown;
};

const searchResultsAreEmpty = (searchResults) => {
  return util.isEmpty(searchResults) || Object.values(searchResults).every((val) => val.length === 0);
};

function OmniBar({ user, onClose, onSwitchOrganisation }) {
  const theme = useContext(ThemeContext);
  const searchInput = useRef();
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState({});
  const [searching, setSearching] = useState(false);
  const [hovered, setHovered] = useState({});
  const [spotlightResults, setSpotlightResults] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const downPress = useKeyDown("ArrowDown");
  const upPress = useKeyDown("ArrowUp");
  const enterPress = useKeyDown("Enter");
  const [cursor, setCursor] = useState(0);
  const { width } = useContainerDimensions(searchInput);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { users = [], challenges = [], ideas = [], groups = [], organisations = [], tags = [] } = searchResults;
  const totalResults = useMemo(
    () => [...spotlightResults, ...users, ...challenges, ...ideas, ...groups, ...(organisations || []), ...tags],
    [spotlightResults, users, challenges, ideas, groups, organisations, tags],
  );

  const userStartIndex = spotlightResults.length;
  const challengeStartIndex = userStartIndex + searchResults.users?.length || 0;
  const ideaStartIndex = challengeStartIndex + searchResults.challenges?.length || 0;
  const groupStartIndex = ideaStartIndex + searchResults.ideas?.length || 0;
  const organisationStartIndex = groupStartIndex + searchResults.groups?.length || 0;
  const tagStartIndex = organisationStartIndex + searchResults.organisations?.length || 0;

  useEffect(() => {
    if (downPress) {
      setCursor((prevState) => {
        const totalResultsLength = totalResults.length - 1;
        if (prevState === totalResultsLength) return 0;
        if (prevState < totalResultsLength) return prevState + 1;
        return prevState;
      });
    }
  }, [downPress, totalResults.length]);

  useEffect(() => {
    if (!searchTerm) {
      api.search.getRecentSearches(({ recentSearches: newRecentSearches }) => setRecentSearches(newRecentSearches));
    }
  }, [searchTerm]);

  useEffect(() => {
    if (upPress) {
      setCursor((prevState) => {
        const totalResultsLength = totalResults.length - 1;
        if (prevState === 0) return totalResultsLength;
        return prevState - 1;
      });
    }
  }, [upPress, totalResults.length]);

  const switchOrganisation = useCallback(
    (e, org) => {
      api.users.switchOrganisation(
        user._id,
        org._id,
        (data) => {
          onSwitchOrganisation(data.organisation);
        },
        (err) => toast.error(err.message),
      );
    },
    [user, onSwitchOrganisation],
  );

  const inputClicked = () => {
    api.journey.record("users", null, "clickedOmniBar");
    if (searchTerm || recentSearches) setOpen(true);
  };

  const addRecentSearch = (type, name) => {
    api.search.recentSearch({ type, search: name });
  };

  useEffect(() => {
    const isSpotlight = cursor < userStartIndex;
    const isUser = cursor >= userStartIndex && cursor < challengeStartIndex;
    const isChallenge = cursor >= challengeStartIndex && cursor < ideaStartIndex;
    const isIdea = cursor >= ideaStartIndex && cursor < groupStartIndex;
    const isGroup = cursor >= groupStartIndex && cursor < organisationStartIndex;
    const isOrganisation = cursor >= organisationStartIndex;
    const isTag = cursor >= tagStartIndex;
    if (spotlightResults.length && enterPress && open) {
      if (isSpotlight) {
        navigate(hovered.link);
      } else if (isUser) {
        addRecentSearch("user", hovered.profile.fullName);
        navigate(`/users/${hovered._id}`);
      } else if (isChallenge) {
        addRecentSearch("challenge", hovered.name);
        navigate(`/challenges/${hovered._id}`);
      } else if (isIdea) {
        addRecentSearch("idea", hovered.name);
        navigate(`/ideas/${hovered._id}`);
      } else if (isGroup) {
        addRecentSearch("group", hovered.name);
        navigate(`/groups/${hovered._id}`);
      } else if (isOrganisation) {
        addRecentSearch("organisation", hovered.name);
        switchOrganisation(null, hovered);
      } else if (isTag) {
        navigate(`/tags/${hovered._id}`);
      }
    }
  }, [
    cursor,
    enterPress,
    userStartIndex,
    challengeStartIndex,
    ideaStartIndex,
    groupStartIndex,
    organisationStartIndex,
    navigate,
    searchResults,
    spotlightResults,
    hovered,
    tagStartIndex,
    switchOrganisation,
    open,
  ]);

  useEffect(() => {
    setHovered(totalResults[cursor]);
  }, [cursor, totalResults, hovered, switchOrganisation]);

  const handleKeyPress = () => {};

  useEffect(() => {
    window.addEventListener("keypress", handleKeyPress);
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  const search = useThrottle(
    (term) => {
      if (!term) return;
      setSearching(true);
      api.search.omni(
        { query: term },
        (results) => {
          setOpen(true);
          setSearching(false);
          setSearchResults(results);
        },
        () => setSearching(false),
      );
    },
    1000,
    [],
  );

  const updateSearchTerm = (e) => {
    const term = e.target.value;
    if (term[term.length - 1] !== "/") setSearchTerm(e.target.value);
    if (term) {
      setSearching(true);
      search(e.target.value);
      setSpotlightResults(getPath(user, term));
    } else {
      setSearching(false);
    }
  };

  useEffect(() => {
    if (cursor > totalResults.length) setCursor(totalResults.length - 1);
    if (totalResults.length) setOpen(true);
  }, [cursor, totalResults.length]);

  const pathName = location?.pathname;
  useEffect(() => {
    setOpen(false);
  }, [pathName]);

  const RegularStyle = {
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 5,
    fontSize: "1rem",
  };
  const ActiveStyle = { backgroundColor: "#babcbe" };
  const userIsAdmin = util.hasPermission(user, "org.viewDashboard", user && user?.organisation?._id);

  const hasSearchResults = useMemo(() => !searchResultsAreEmpty(searchResults), [searchResults]);

  return (
    <StyledSearchBar>
      <Popup
        basic
        hoverable={true}
        hideOnScroll={false}
        position="bottom left"
        offset={[0, -2]}
        open={open}
        onClose={() => setOpen(false)}
        style={{ maxHeight: 800, width: theme.sizes.isMobile ? "100vw" : "fit-content" }}
        trigger={
          <Input
            autoFocus={theme.sizes.isMobile}
            value={searchTerm}
            icon="search"
            iconPosition="left"
            style={{ border: "0px" }}
            size={theme.sizes.isMobile ? "mini" : "small"}
            placeholder={!userIsAdmin ? `Search ${util.appName()}...` : "Find users and more..."}
            onChange={updateSearchTerm}
            loading={searching}
            onClick={inputClicked}
            ref={searchInput}
          />
        }
        content={
          searchTerm ? (
            <StyledSearchResults $resultsAvailable={searchResults} onClick={onClose} $width={width}>
              {!hasSearchResults && !spotlightResults.length ? (
                !searching ? (
                  <p style={{ padding: 10 }}>No results found</p>
                ) : (
                  <div style={{ height: 35 }}>
                    <Loader inline="centered" active size="tiny" />
                  </div>
                )
              ) : null}
              {(searchResults || spotlightResults) && (
                <Grid stackable style={{ padding: theme.sizes.isMobile ? 0 : 10, paddingBottom: 0 }}>
                  {spotlightResults.length ? (
                    <StyledTitleRow $spaceTop>
                      <StyledTitle>Pages</StyledTitle>
                    </StyledTitleRow>
                  ) : null}
                  {spotlightResults.map((result, i) => (
                    <StyledSpotlightResult key={result.title} $active={cursor === i} as={Link} to={result.link}>
                      <Icon name={result.icon} style={{ marginRight: 5 }} />
                      <p>
                        <b>{result.title}</b> - {result.link}
                      </p>
                    </StyledSpotlightResult>
                  ))}
                  {searchResults.users && searchResults.users.length > 0 ? (
                    <Grid.Column width={8} style={{ marginTop: 0 }}>
                      <h4>People</h4>
                      {searchResults.users.map((searchUser, index) => (
                        <UserSearchItem
                          active={index + userStartIndex === cursor}
                          key={searchUser._id}
                          user={searchUser}
                          onClick={() => addRecentSearch("user", searchUser.profile.fullName)}
                        />
                      ))}
                    </Grid.Column>
                  ) : null}

                  {searchResults.challenges && searchResults.challenges.length > 0 ? (
                    <Grid.Column width={8} style={{ marginTop: 0 }}>
                      <h4>{t("common:capitalise", { key: "generic.challenges" })}</h4>
                      <List>
                        {searchResults.challenges.map((challenge, index) => (
                          <List.Item
                            style={{ ...RegularStyle, ...(index + challengeStartIndex === cursor && ActiveStyle) }}
                            as={Link}
                            to={`/challenges/${challenge._id}`}
                            key={challenge._id}
                            icon="star"
                            content={challenge.name}
                            onClick={() => addRecentSearch("challenge", challenge.name)}
                          />
                        ))}
                      </List>
                    </Grid.Column>
                  ) : null}

                  {searchResults.ideas && searchResults.ideas.length > 0 ? (
                    <Grid.Column width={8}>
                      <h4>{t("common:capitalise", { key: "generic.ideas" })}</h4>
                      <List>
                        {searchResults.ideas.map((idea, index) => (
                          <List.Item
                            style={{
                              ...RegularStyle,
                              paddingLeft: 10,
                              paddingRight: 10,
                              ...(index + ideaStartIndex === cursor && ActiveStyle),
                            }}
                            as={Link}
                            to={`/ideas/${idea._id}`}
                            key={idea._id}
                            icon="lightbulb"
                            content={idea.name}
                            onClick={() => addRecentSearch("idea", idea.name)}
                          />
                        ))}
                      </List>
                    </Grid.Column>
                  ) : null}

                  {searchResults.groups && searchResults.groups.length > 0 ? (
                    <Grid.Column width={8}>
                      <h4>Groups</h4>
                      <List>
                        {searchResults.groups.map((group, index) => (
                          <List.Item
                            style={{ ...RegularStyle, ...(index + groupStartIndex === cursor && ActiveStyle) }}
                            as={Link}
                            to={`/groups/${group._id}`}
                            key={group._id}
                            icon="users"
                            content={group.name}
                            onClick={() => addRecentSearch("group", group.name)}
                          />
                        ))}
                      </List>
                    </Grid.Column>
                  ) : null}

                  {searchResults.organisations && searchResults.organisations.length > 0 ? (
                    <Grid.Column width={8}>
                      <h4>Organisations</h4>
                      <List>
                        {searchResults.organisations.map((org, index) => (
                          <List.Item
                            style={{
                              ...RegularStyle,
                              paddingLeft: 10,
                              paddingRight: 10,
                              ...(index + organisationStartIndex === cursor && ActiveStyle),
                            }}
                            as={Link}
                            to={"#"}
                            key={org._id}
                            onClick={(e) => {
                              addRecentSearch("organisation", org.name);
                              switchOrganisation(e, org);
                            }}
                            icon="sitemap"
                            content={org.name}
                          />
                        ))}
                      </List>
                    </Grid.Column>
                  ) : null}
                  {searchResults.tags && searchResults.tags.length > 0 ? (
                    <Grid.Column width={8}>
                      <h4>Tags</h4>
                      <List>
                        {searchResults.tags.map((tag, index) => (
                          <List.Item
                            style={{
                              ...RegularStyle,
                              paddingLeft: 10,
                              paddingRight: 10,
                              ...(index + tagStartIndex === cursor && ActiveStyle),
                            }}
                            as={Link}
                            to={`/tags/${tag._id}`}
                            key={tag._id}
                            icon="hashtag"
                            content={tag.value}
                          />
                        ))}
                      </List>
                    </Grid.Column>
                  ) : null}
                </Grid>
              )}
            </StyledSearchResults>
          ) : (
            <StyledRecentSearches $resultsAvailable={recentSearches} $width={width}>
              <h4 style={{ marginBottom: 10 }}>Recent searches</h4>
              {recentSearches.slice(0, 5).map((sr, index) => (
                <StyledListItem
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    updateSearchTerm({ target: { value: sr.search } });
                  }}
                >
                  <Icon name="search" />
                  <div style={{ display: "flex", flexDirection: "column", marginLeft: 5 }}>
                    <span style={{ color: "grey" }}>{sr.type}</span>
                    <span style={{ fontWeight: "bold" }}>{sr.search}</span>
                  </div>
                </StyledListItem>
              ))}
            </StyledRecentSearches>
          )
        }
      />
    </StyledSearchBar>
  );
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  onSwitchOrganisation: (org) => dispatch(actions.user.switchOrganisation(org)),
});
const OmniBarContainer = connect(mapStateToProps, mapDispatchToProps)(OmniBar);

export default OmniBarContainer;
