import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button } from "semantic-ui-react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import api from "api";
import actions from "actions";
import { useTranslation } from "react-i18next";

export const startIdeaChat = (
  forType,
  forId,
  setSearching = () => {},
  setThread = () => {},
  onMessageThreadSelected = () => {},
  closeThread = true,
) => {
  setSearching(true);
  if (closeThread) onMessageThreadSelected(null);
  api.messages.findThreads(
    { forType, forId },
    (data) => {
      setSearching(false);
      if (data.threads.length) {
        setThread(data.threads[0]);
        onMessageThreadSelected(data.threads[0]);
      } else {
        setThread(null);
      }
    },
    (err) => {
      setSearching(false);
      toast.error(err.message);
    },
  );
};

export const handleIdeaChat = (thread, name, forType, forId, searching, idea, currentUser, onMessageThreadSelected) => {
  if (searching) return;
  if (thread) {
    onMessageThreadSelected(thread);
  } else {
    const { user, collaborators = [] } = idea;
    const chatUsers = currentUser === user ? collaborators : [user, ...collaborators.filter((c) => c !== currentUser)];
    api.messages.createThread(
      {
        users: chatUsers,
        name: `${name} Team Chat`,
        forType,
        forId,
      },
      (data) => {
        onMessageThreadSelected(data);
      },
      (err) => toast.error(err.message),
    );
  }
};

const IdeaTeamStartChat = ({ idea, onMessageThreadSelected, style, compact, user, size, fluid, basic }) => {
  const [searching, setSearching] = useState(false);
  const [thread, setThread] = useState(null);
  const collaborators = useMemo(() => idea?.collaborators || [], [idea]);
  const forId = idea?._id;
  const forType = "idea";
  const name = idea?.name;
  const { t } = useTranslation();

  const getIdeaChat = useCallback(() => {
    startIdeaChat(forType, forId, setSearching, setThread, onMessageThreadSelected);
  }, [forType, forId, setSearching, setThread, onMessageThreadSelected]);

  useEffect(() => {
    if (collaborators.length) getIdeaChat();
  }, [getIdeaChat, collaborators]);

  const startChat = useCallback(() => {
    handleIdeaChat(thread, name, forType, forId, searching, idea, user._id, onMessageThreadSelected);
  }, [thread, name, forType, forId, searching, idea, user._id, onMessageThreadSelected]);

  return (
    <Button
      primary={!basic}
      basic={basic}
      size={size}
      content={`Message ${t("generic.idea")} team`}
      icon="mail"
      onClick={() => startChat()}
      loading={searching}
      style={style}
      compact={compact}
      fluid={fluid}
    />
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => ({
  onMessageThreadSelected: (threadId) => dispatch(actions.messages.selectThread(threadId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IdeaTeamStartChat);
