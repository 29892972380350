import React from "react";
import { Header, Icon, Button } from "semantic-ui-react";
import { ImageChooser } from "components/lib/Choosers";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import toast from "react-hot-toast";
import { BodySegment } from "./styles";

const StyledImage = styled.img`
  max-width: 100%;
  display: block;
  margin: 20px auto;
  object-fit: contain;
`;

const Image = ({ user, field, ideaId, fieldValue = "", updateIdea, isEditing, t }) => {
  if (!isEditing) {
    if (fieldValue) {
      return <StyledImage alt={`${t("common:capitalise", { key: "generic.idea" })} field content`} src={fieldValue} />;
    }
    return null;
  }
  return (
    <BodySegment placeholder>
      <Header icon>
        <Icon name="camera" /> Choose an image
      </Header>
      <ImageChooser
        user={user}
        trigger={<Button primary icon="camera" content={t("ideas.actions.chooseImage")} />}
        forType="idea"
        forId={ideaId}
        onComplete={(value, name, imageSrc) => updateIdea(imageSrc, ["templated", field.id])}
        onError={toast.error}
      />
      {fieldValue ? (
        <>
          <StyledImage alt={`${t("common:capitalise", { key: "generic.idea" })} field content`} src={fieldValue} />
          <Button
            size="small"
            style={{ marginTop: 15 }}
            basic
            icon="close"
            color="orange"
            content={t("generic.remove")}
            onClick={() => updateIdea(null, ["templated", field.id])}
          />
        </>
      ) : null}
    </BodySegment>
  );
};

export default withTranslation()(Image);
