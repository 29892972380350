import React, { useCallback } from "react";
import { Card, Button, Header } from "semantic-ui-react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { UserChip } from "components/lib/Chips";
import util from "utils/utils";
import { InviteCardProps } from "./types";
import InviteeChip from "./InviteeChip";
import { useTranslation } from "react-i18next";

const IdeaInviteCard = ({ invitation, acceptInvite, rejectInvite, removeInvite }: InviteCardProps) => {
  const { inviterUser, inviteeUser, invitee, challenge, forIdeas = [], createdByCurrentUser } = invitation;

  const { t } = useTranslation();

  const accept = useCallback(() => {
    util
      .confirm(
        `Become ${t("generic.idea")} assessor`,
        `Accept invitation and start assessing ${util.pluralise(forIdeas.length, t("generic.idea"), t("generic.ideas"))} in "${challenge.name}"?`,
      )
      .then(() => {
        acceptInvite(
          invitation,
          () => {
            toast.success(`Became ${t("generic.idea")} assessor`);
          },
          () => toast.error(`Error becoming ${t("generic.idea")} assessor`),
        );
      })
      .catch(() => {});
  }, [acceptInvite, invitation, forIdeas, challenge?.name, t]);

  const reject = useCallback(() => {
    util
      .confirm(
        "Reject assessment invitation",
        `Are you sure you want to reject an invitation from ${inviterUser?.profile?.fullName} to assess ${util.pluralise(forIdeas.length, t("generic.idea"), t("generic.ideas"))} in "${challenge?.name || `Deleted ${t("generic.challenge")}`}?`,
      )
      .then(() => {
        rejectInvite(
          invitation,
          () => {
            toast("Invitation rejected");
          },
          () => toast.error("Error rejecting invite"),
        );
      })
      .catch(() => {});
  }, [rejectInvite, invitation, inviterUser?.profile?.fullName, forIdeas, challenge?.name, t]);

  const remove = useCallback(() => {
    util
      .confirm(
        "Remove assessment invitation",
        `Are you sure you want to remove this invitation to ${inviteeUser?.profile?.fullName || invitee} to assess ${t("generic.ideas")} in "${challenge?.name || `Deleted ${t("generic.challenge")}`}"?`,
      )
      .then(() => {
        removeInvite(
          invitation,
          () => {
            toast("Invitation removed");
          },
          () => toast.error("Error removing invite"),
        );
      })
      .catch(() => {});
  }, [removeInvite, invitation, challenge?.name, inviteeUser?.profile?.fullName, invitee, t]);

  if (!challenge) {
    invitation.canAccept = false;
  }

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <div>
        <Header
          style={{ marginBottom: "0.3em" }}
          as={Link}
          disabled={!challenge}
          to={challenge ? `/challenges/${challenge._id}` : null}
        >
          {challenge?.name || `Deleted ${t("generic.challenge")}`}
        </Header>
        <Card.Description>
          {createdByCurrentUser ? (
            <InviteeChip
              invitation={invitation}
              preText="You invited"
              postText={`to assess ${util.pluralise(forIdeas.length, t("generic.idea"), t("generic.ideas"))} in this ${t("generic.challenge")}`}
            />
          ) : (
            <UserChip
              user={inviterUser}
              postText={`has invited you to assess ${util.pluralise(forIdeas.length, t("generic.idea"), t("generic.ideas"))} in this ${t("generic.challenge")}`}
            />
          )}
        </Card.Description>
      </div>
      <Card.Content extra>
        <div className="ui">
          {invitation.acceptedAt ? (
            <Button
              basic
              color="green"
              as={Link}
              disabled={!challenge}
              to={`/challenges/${challenge?._id}/assessments/assigned`}
            >
              Go to assessments
            </Button>
          ) : (
            <>
              {invitation.canAccept ? (
                <Button basic color="green" onClick={accept}>
                  Accept
                </Button>
              ) : null}
              {invitation.canRemove ? (
                <Button
                  basic
                  color={createdByCurrentUser ? null : "red"}
                  onClick={createdByCurrentUser ? remove : reject}
                >
                  {createdByCurrentUser ? "Remove" : "Reject"}
                </Button>
              ) : null}
            </>
          )}
        </div>
      </Card.Content>
    </div>
  );
};

export default IdeaInviteCard;
