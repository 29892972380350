import React, { useMemo } from "react";
import { useDrag } from "react-dnd";
import { Popup, Table } from "semantic-ui-react";

import styled from "styled-components";

import { LargeCheckbox } from "components/lib/UI";
import ActionDropdown from "./ActionDropdown";

const actionKey = "__actions";
const minCellWidth = 75;

const SizedDataCell = styled(Table.Cell)<{ $preferredWidth?: number; $isActionCell?: boolean; $index: number }>`
  padding: 8px 0;
  background: #fff;

  ${({ $preferredWidth }) =>
    !$preferredWidth &&
    `
    min-width: ${minCellWidth}px;
  `}

  ${({ $isActionCell, $index }) =>
    $isActionCell
      ? `
    position: sticky;
    right: 0;
    box-shadow: -1px 0 1px #ddd;
    z-index: ${299 - $index * 2};
  `
      : null}
`;

const CellContentWrapper = styled.div<{ $lines?: number; $center?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: ${({ $center }) => ($center ? "center" : "flex-start")};
  align-items: ${({ $center }) => ($center ? "center" : "flex-start")};
  width: 100%;

  ${({ $lines }) =>
    $lines &&
    `
    -webkit-line-clamp: ${$lines};
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `}
`;

const StyledRow = styled(Table.Row)<{ $isDragging?: boolean }>`
  ${({ $isDragging }) =>
    $isDragging
      ? `
  opacity: 0.5;
  `
      : ""}

  &:hover {
    ${SizedDataCell} {
      background: #f5f5f5 !important;
    }
  }
`;

const DataRow = ({
  onRowClick,
  item,
  selectable,
  selected,
  enabledColumns,
  onSelect,
  actions,
  user,
  draggable,
  index,
  getRowStyle,
  getCellStyle,
}) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "innointel-company",
      options: {
        dropEffect: "copy",
      },
      canDrag: draggable,
      item: { company: item },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [],
  );
  const actionColumnItems = useMemo(() => [item], [item]);

  return (
    <StyledRow
      ref={draggable ? drag : undefined}
      $isDragging={isDragging}
      style={getRowStyle ? getRowStyle(item) : {}}
      onMouseDown={() => {
        // clear selection
        if (window.getSelection) {
          window.getSelection().removeAllRanges();
        }
      }}
      onClick={(event) => {
        // Only when the row is the primary target
        if (event.defaultPrevented) {
          return;
        }
        // Only if the user did not select content
        const selection = window.getSelection();
        if (selection && selection.toString()) {
          return;
        }
        onRowClick && onRowClick(item, event);
      }}
    >
      {selectable ? (
        <SizedDataCell $preferredWidth={40} $index={0} style={getCellStyle ? getCellStyle({}, item) : {}}>
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LargeCheckbox
              checked={selected}
              onChange={(e, checked) => {
                e.preventDefault();
                onSelect && onSelect(item, checked);
              }}
            />
          </div>
        </SizedDataCell>
      ) : null}
      {enabledColumns.map((col) => {
        const content = col.render ? col.render({ cell: item[col.key], item }) : item[col.key];
        return (
          <SizedDataCell
            $preferredWidth={col.width}
            $isActionCell={col.key === actionKey}
            collapsing={col.key === actionKey || col.collapsing}
            key={col.key}
            $index={index}
            style={getCellStyle ? getCellStyle(col, item) : {}}
          >
            <CellContentWrapper $lines={col.lines} $center={col.center}>
              {col.key === actionKey ? (
                <ActionDropdown actions={actions} items={actionColumnItems} type="single" user={user} />
              ) : (
                <>
                  {col.lines ? (
                    <Popup hoverable trigger={<span>{content}</span>} content={<span>{content}</span>} />
                  ) : (
                    content
                  )}
                </>
              )}
            </CellContentWrapper>
          </SizedDataCell>
        );
      })}
    </StyledRow>
  );
};

export default React.memo(DataRow);
