import React from "react";
import { Image, Form, Card, Button, Divider, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import util from "utils/utils";

import RichText from "components/lib/Editors/RichText";
import RichTextViewer from "components/lib/Editors/RichTextViewer";
import ImageChooser from "components/lib/Choosers/ImageChooser";
import FieldHeader from "../FieldHeader";

const Competitor = styled.div`
  margin-top: 30px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed ${({ $includeSection }) => $includeSection.colour};
`;

class Competitors extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.defaultCompetitors = [{}, {}, {}];
  }

  updateProfile = (profileIndex, field, value) => {
    const people = Object.assign(this.defaultCompetitors, this.props.competitors);
    people[profileIndex][field] = value;
    this.props.updateIdea(people, ["competitors"]);
  };

  render() {
    const { competitors, competitorsStrategy, isEditing, ideaId, updateIdea, includeSection, t, externalChanges } =
      this.props;
    return (
      <div>
        <FieldHeader externalChange={externalChanges.competitors} title={t("ideas.6Cs.sections.competitors.title")} />
        {(competitors || this.defaultCompetitors).map((competitor, i) =>
          isEditing ? (
            <>
              <Competitor key={i} $includeSection={includeSection}>
                <Grid stackable>
                  <Grid.Column computer={5}>
                    {competitor.logo && <Image src={competitor.logo} style={{ marginBottom: "15px" }} />}
                    <ImageChooser
                      forType="idea"
                      forId={ideaId}
                      trigger={
                        <Button
                          size="small"
                          fluid
                          icon="image"
                          color="yellow"
                          content={t("ideas.6Cs.sections.competitors.form.addLogo")}
                        />
                      }
                      onComplete={(p, n, src) => this.updateProfile(i, "logo", src)}
                    />
                  </Grid.Column>
                  <Grid.Column computer={11}>
                    <Form>
                      <Form.Group widths="equal">
                        <Form.Input
                          fluid
                          label={t("ideas.6Cs.sections.competitors.form.name.title")}
                          placeholder={t("ideas.6Cs.sections.competitors.form.name.placeholder")}
                          value={competitor.name || ""}
                          icon="id badge outline"
                          onChange={(e) => this.updateProfile(i, "name", e.target.value)}
                        />
                        <Form.Input
                          fluid
                          label={t("ideas.6Cs.sections.competitors.form.website.title")}
                          placeholder={t("ideas.6Cs.sections.competitors.form.website.placeholder")}
                          value={competitor.website || ""}
                          icon="globe"
                          onChange={(e) => this.updateProfile(i, "website", e.target.value)}
                        />
                      </Form.Group>
                    </Form>
                    <Grid>
                      <Grid.Column computer={8}>
                        <p>
                          <strong>{t("ideas.6Cs.sections.competitors.form.doingWell")}</strong>
                        </p>
                        <RichText
                          value={competitor.goodPoints || ""}
                          onChange={(val) => this.updateProfile(i, "goodPoints", val)}
                          forType="idea"
                          forId={ideaId}
                        />
                      </Grid.Column>
                      <Grid.Column computer={8}>
                        <p>
                          <strong>{t("ideas.6Cs.sections.competitors.form.missing")}</strong>
                        </p>
                        <RichText
                          value={competitor.badPoints || ""}
                          onChange={(val) => this.updateProfile(i, "badPoints", val)}
                          forType="idea"
                          forId={ideaId}
                        />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Competitor>
            </>
          ) : (
            <>
              {competitor.name ? (
                <Competitor key={i} $includeSection={includeSection}>
                  <Grid stackable>
                    <Grid.Column computer={6}>
                      <Card>
                        {competitor.logo && <Image src={competitor.logo} />}
                        <Card.Content>
                          <Card.Header style={{ overflowWrap: "break-word" }}>{competitor.name}</Card.Header>
                          {competitor.website && (
                            <Card.Meta style={{ overflowWrap: "break-word" }}>
                              <a href={util.ensureHttp(competitor.website)} target="_blank" rel="noopener noreferrer">
                                {competitor.website}
                              </a>
                            </Card.Meta>
                          )}
                        </Card.Content>
                      </Card>
                    </Grid.Column>
                    <Grid.Column computer={5}>
                      {competitor.goodPoints && (
                        <div>
                          <h4>{t("ideas.6Cs.sections.competitors.form.strongPoints")}</h4>
                          <RichTextViewer content={competitor.goodPoints} />
                        </div>
                      )}
                    </Grid.Column>
                    <Grid.Column computer={5}>
                      {competitor.badPoints && (
                        <div>
                          <h4>{t("ideas.6Cs.sections.competitors.form.weakPoints")}</h4>
                          <RichTextViewer content={competitor.badPoints} />
                        </div>
                      )}
                    </Grid.Column>
                  </Grid>
                </Competitor>
              ) : null}
            </>
          ),
        )}

        <Divider section hidden />

        {isEditing ? (
          <div>
            <FieldHeader
              externalChange={externalChanges.competitorsStrategy}
              title={t("ideas.6Cs.sections.competitors.form.howAreYouBetter.title")}
              isEditing={isEditing}
              description={t("ideas.6Cs.sections.competitors.form.howAreYouBetter.info")}
            />
            <RichText
              value={competitorsStrategy || ""}
              onChange={(val) => updateIdea(val, ["competitorsStrategy"])}
              forType="idea"
              forId={ideaId}
            />
          </div>
        ) : (
          competitorsStrategy && (
            <Grid stackable centered>
              <Grid.Column computer={10}>
                <FieldHeader title={t("ideas.6Cs.sections.competitors.form.howAreYouBetter.usp")} />
                <RichTextViewer content={competitorsStrategy} />
              </Grid.Column>
            </Grid>
          )
        )}
      </div>
    );
  }
}

export default withTranslation()(Competitors);
