import React from "react";
import { Table, Checkbox, Divider, Grid } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import RichText from "components/lib/Editors/RichText";
import RichTextViewer from "components/lib/Editors/RichTextViewer";
import FieldHeader from "../FieldHeader";
import FieldSeparator from "../FieldSeparator";

class Cash extends React.PureComponent {
  tableChanged = (changes) => {
    if (changes && changes[0]) {
      const row = changes[0][0];
      const column = changes[0][1];
      if (!Number.isNaN(row) && !Number.isNaN(column)) {
        const table = Object.assign(
          [
            [null, null, null, null],
            [null, null, null, null],
            [null, null, null, null],
          ],
          this.props.cashMoneyTable,
        );
        if (row > table.length - 1) {
          while (row > table.length - 1) table.push([]);
        }
        if (column > table[row].length - 1) {
          while (column > table[row].length - 1) table[row].push("");
        }
        const [firstChange] = changes;
        // only named variables for array indices
        const magicThree = 3;
        table[row][column] = firstChange[magicThree];
        this.props.updateIdea(table, ["cashMoneyTable"]);
      }
    }
  };

  displayTable = () => {
    const table = this.props.cashMoneyTable;
    if (!table) return false;
    let display = false;
    table.forEach(
      (col) =>
        col &&
        Array.isArray(col) &&
        col.forEach((row) => {
          if (row && row.length) {
            display = true;
          }
        }),
    );
    return display;
  };

  render() {
    const {
      cashMoneyIn,
      cashMoneyOut,
      cashMoneyTable,
      cashFunding,
      cashFundingNotes,
      ideaId,
      isEditing,
      updateIdea,
      includeSection,
      t,
      externalChanges,
    } = this.props;
    const editTable = cashMoneyTable ?? [
      [[""], [""], [""]],
      [[""], [""], [""]],
      [[""], [""], [""]],
    ];

    const cols = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];
    return (
      <div>
        <Grid stackable centered>
          {(isEditing || cashMoneyIn) && (
            <Grid.Column computer={isEditing ? 16 : 8} textAlign={isEditing ? "left" : "center"}>
              {isEditing ? (
                <div>
                  <FieldHeader
                    externalChange={externalChanges.cashMoneyIn}
                    title={t("ideas.6Cs.sections.cash.makingMoney.title")}
                    isEditing={isEditing}
                    description={t("ideas.6Cs.sections.cash.makingMoney.info")}
                  />
                  <RichText
                    value={cashMoneyIn}
                    onChange={(val) => updateIdea(val, ["cashMoneyIn"])}
                    forType="idea"
                    forId={ideaId}
                  />
                </div>
              ) : (
                <div>
                  <FieldHeader title={t("ideas.6Cs.sections.cash.makingMoney.name")} />
                  <RichTextViewer content={cashMoneyIn} />
                </div>
              )}
            </Grid.Column>
          )}

          {(isEditing || cashMoneyOut) && (
            <Grid.Column computer={isEditing ? 16 : 8} textAlign={isEditing ? "left" : "center"}>
              {isEditing ? (
                <div>
                  <FieldHeader
                    externalChange={externalChanges.cashMoneyOut}
                    title={t("ideas.6Cs.sections.cash.spendingMoney.title")}
                    isEditing={isEditing}
                    description={t("ideas.6Cs.sections.cash.spendingMoney.info")}
                  />
                  <RichText
                    value={cashMoneyOut}
                    onChange={(val) => updateIdea(val, ["cashMoneyOut"])}
                    forType="idea"
                    forId={ideaId}
                  />
                </div>
              ) : (
                <div>
                  <FieldHeader title={t("ideas.6Cs.sections.cash.spendingMoney.name")} />
                  <RichTextViewer content={cashMoneyOut} />
                </div>
              )}
            </Grid.Column>
          )}
        </Grid>

        <FieldSeparator includeSection={includeSection} />

        {isEditing ? (
          <div style={{ marginBottom: "10px" }}>
            <FieldHeader
              externalChange={externalChanges.cashMoneyTable}
              title={t("ideas.6Cs.sections.cash.spreadsheet.title")}
              isEditing={isEditing}
              description={t("ideas.6Cs.sections.cash.spreadsheet.info")}
            />
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell />
                  {editTable[0].map((c, i) => (
                    <Table.HeaderCell key={i} style={{ textAlign: "center" }}>
                      {cols[i]}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {editTable.map((row, i) => (
                  <Table.Row key={i}>
                    <Table.HeaderCell textAlign="center" style={{ background: "#f9fafb" }}>
                      {i + 1}
                    </Table.HeaderCell>
                    {row && row.map((col, j) => <Table.Cell key={j}>{col}</Table.Cell>)}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        ) : (
          this.displayTable() && (
            <div style={{ width: "100%", overflowX: "scroll" }}>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    {cashMoneyTable[0] &&
                      cashMoneyTable[0].map((c, i) => (
                        <Table.HeaderCell key={i} style={{ textAlign: "center" }}>
                          {cols[i]}
                        </Table.HeaderCell>
                      ))}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {cashMoneyTable.map((row, i) => (
                    <Table.Row key={i}>
                      <Table.HeaderCell textAlign="center" style={{ background: "#f9fafb" }}>
                        {i + 1}
                      </Table.HeaderCell>
                      {row && row.map((col, j) => <Table.Cell key={j}>{col}</Table.Cell>)}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          )
        )}

        <FieldSeparator includeSection={includeSection} />

        {isEditing && (
          <div>
            <FieldHeader
              externalChange={externalChanges.cashFunding}
              title={t("ideas.6Cs.sections.cash.startupFunding.title")}
            />
            <Checkbox
              toggle
              label="I need startup funding!"
              checked={cashFunding}
              onChange={(e, c) => updateIdea(c.checked, ["cashFunding"])}
              style={{ marginTop: 20 }}
            />
            <Divider clearing hidden />
          </div>
        )}
        <Grid stackable centered>
          {cashFunding && (isEditing || cashFundingNotes) && (
            <Grid.Column computer={isEditing ? 16 : 10} textAlign={isEditing ? "left" : "center"}>
              {isEditing ? (
                <div>
                  <FieldHeader
                    externalChange={externalChanges.cashFundingNotes}
                    isEditing={isEditing}
                    description={t("ideas.6Cs.sections.cash.startupFunding.info")}
                  />
                  <RichText
                    value={cashFundingNotes}
                    onChange={(val) => updateIdea(val, ["cashFundingNotes"])}
                    forType="idea"
                    forId={ideaId}
                  />
                </div>
              ) : (
                <div>
                  <FieldHeader title={t("ideas.6Cs.sections.cash.startupFunding.name")} />
                  <RichTextViewer content={cashFundingNotes} />
                </div>
              )}
            </Grid.Column>
          )}
        </Grid>
      </div>
    );
  }
}

export default withTranslation()(Cash);
