import { SearchParams } from "simplydo/core";
import { OpenAPI } from "simplydo/interfaces";

export const organisations = (api) => ({
  getActivity(id, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/activity`, data, success, fail);
  },
  create(org, success, fail) {
    api.authenticatedRequest("POST", "/organisations", org, success, fail);
  },
  get(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}`, null, success, fail);
  },
  update(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}`, data, success, fail);
  },
  delete(id, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}`, null, success, fail);
  },
  block(id, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/block`, null, success, fail);
  },
  unblock(id, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}/block`, null, success, fail);
  },
  getAll(params, success, fail) {
    const queryParams = new SearchParams(params).toSafeString();
    api.authenticatedRequest("GET", `/organisations${queryParams}`, null, (data) => success && success(data), fail);
  },
  getByCode(code, success, fail) {
    api.unauthenticatedRequest("GET", `/organisations/code/${code}`, null, success, fail);
  },
  updateSettings(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/settings`, data, success, fail);
  },
  getOverview(id, params, success, fail) {
    const queryParams = new SearchParams(params).toSafeString();
    api.authenticatedRequest("GET", `/organisations/${id}/overview${queryParams}`, null, success, fail);
  },
  getAdmins(id, params, success, fail) {
    const queryParams = new SearchParams(params).toSafeString();
    api.authenticatedRequest("GET", `/organisations/${id}/admins${queryParams}`, null, success, fail);
  },
  contactAdmin(id, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/admins`, data, success, fail);
  },
  getUsers(id, params, success, fail) {
    const queryParams = new SearchParams(params).toSafeString();
    api.authenticatedRequest(
      "GET",
      `/organisations/${id}/users${queryParams}`,
      null,
      (data) => success && success(data),
      fail,
    );
  },
  updateFeatureFlags(id, featureFlags, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/users/features`, { featureFlags }, success, fail);
  },
  exportUsersToCsv(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/users/export`, null, success, fail);
  },
  getChallenges(id, params, success, fail) {
    const queryParams = new SearchParams(params).toSafeString();
    api.authenticatedRequest(
      "GET",
      `/organisations/${id}/challenges${queryParams}`,
      null,
      (data) => success && success(data.challenges),
      fail,
    );
  },
  getUploads(id, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/organisations/${id}/uploads`,
      null,
      (data) => success && success(data.uploads),
      fail,
    );
  },
  getStockUploads(id, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/organisations/${id}/uploads/stock`,
      null,
      (data) => success && success(data.uploads),
      fail,
    );
  },
  createUpload(id, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/uploads`, data, success, fail);
  },
  setAsStockImage(id, uploadId, set, success, fail) {
    api.authenticatedRequest(
      set ? "PUT" : "DELETE",
      `/organisations/${id}/uploads/${uploadId}/stock`,
      null,
      success,
      fail,
    );
  },
  deleteUpload(id, uploadId, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}/uploads/${uploadId}`, null, success, fail);
  },
  getStandardAuthSettings(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/auth/standard`, null, success, fail);
  },
  updateStandardAuthSettings(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/auth/standard`, data, success, fail);
  },
  getSamlSettings(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/auth/saml`, null, success, fail);
  },
  updateSamlSettings(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/auth/saml`, data, success, fail);
  },
  getMicrosoftSettings(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/auth/microsoft`, null, success, fail);
  },
  updateMicrosoftSettings(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/auth/microsoft`, data, success, fail);
  },
  getAuthTypes(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/auth/types`, null, success, fail);
  },
  updateAuthTypes(id, types, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/auth/types`, { types }, success, fail);
  },
  getPolls(id, params, success, fail) {
    const queryParams = new SearchParams(params).toSafeString();
    api.authenticatedRequest("GET", `/organisations/${id}/polls${queryParams}`, null, success, fail);
  },
  createPoll(id, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/polls`, data, success, fail);
  },
  getDynamics365Connection(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/dynamics365`, null, success, fail);
  },
  updateDynamics365Connection(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/dynamics365`, data, success, fail);
  },
  deleteDynamics365Connection(id, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}/dynamics365`, null, success, fail);
  },
  getDynamics365Companies(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/dynamics365/companies`, null, success, fail);
  },
  getDynamics365Customers(id, page, success, fail) {
    const queryParams = new SearchParams({ page }).toSafeString();
    api.authenticatedRequest("GET", `/organisations/${id}/dynamics365/customers${queryParams}`, null, success, fail);
  },
  getADConnection(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/activedirectory`, null, success, fail);
  },
  updateADConnection(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/activedirectory`, data, success, fail);
  },
  deleteADConnection(id, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}/activedirectory`, null, success, fail);
  },
  getPowerbiConnection(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/powerbi`, null, success, fail);
  },
  deletePowerbiConnection(id, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}/powerbi`, null, success, fail);
  },
  getPowerbiWorkspaces(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/powerbi/workspaces`, null, success, fail);
  },
  createPowerbiDataset(id, datasetType, workspaceId, workspaceName, success, fail) {
    api.authenticatedRequest(
      "POST",
      `/organisations/${id}/powerbi/datasets`,
      { datasetType, workspaceId, workspaceName },
      success,
      fail,
    );
  },
  syncPowerbiDataset(id, datasetId, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/powerbi/datasets/${datasetId}`, null, success, fail);
  },
  deletePowerbiDataset(id, datasetId, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}/powerbi/datasets/${datasetId}`, null, success, fail);
  },
  getCreditsafeSettings(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/creditsafe`, null, success, fail);
  },
  updateCreditsafeSettings(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/creditsafe`, data, success, fail);
  },
  getSmtpSettings(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/smtp`, null, success, fail);
  },
  updateSmtpSettings(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/smtp`, data, success, fail);
  },
  createDashboard(id, name, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/dashboards`, { name }, success, fail);
  },
  getDashboards(id, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/organisations/${id}/dashboards`,
      null,
      (data) => success && success(data.dashboards),
      fail,
    );
  },
  getDashboard(id, dashboardId, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/dashboards/${dashboardId}`, null, success, fail);
  },
  updateDashboard(id, dashboardId, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/dashboards/${dashboardId}`, data, success, fail);
  },
  deleteDashboard(id, dashboardId, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}/dashboards/${dashboardId}`, null, success, fail);
  },
  createShortenedUrl(id, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/shortenedUrls`, data, success, fail);
  },
  updateShortenedUrl(id, linkId, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/shortenedUrls/${linkId}`, data, success, fail);
  },
  getShortenedUrls(id, params, success, fail) {
    const queryParams = new SearchParams(params).toSafeString();
    api.authenticatedRequest("GET", `/organisations/${id}/shortenedUrls${queryParams}`, null, success, fail);
  },
  getShortenedUrl(id, urlId, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/shortenedUrls/${urlId}`, null, success, fail);
  },
  getAllShortenedUrls(orgId, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${orgId}/shortenedUrls/all`, null, success, fail);
  },
  deleteShortenedUrl(id, urlId, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}/shortenedUrls/${urlId}`, null, success, fail);
  },
  searchPotentialShortenedUrls(id, filter, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/shortenedUrls/search?filter=${filter}`, null, success, fail);
  },
  getGroups(id, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/organisations/${id}/groups`,
      null,
      (data) => success && success(data.groups),
      fail,
    );
  },
  getPostable(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/postable`, null, success, fail);
  },
  getShopWindow(id, success) {
    api.maybeAuthenticatedRequest("GET", `/organisations/${id}/challenges/public`, null, success);
  },
  createRule(id, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/rules`, data, success, fail);
  },
  updateRule(id, ruleId, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/rules/${ruleId}`, data, success, fail);
  },
  getRules(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/rules`, null, success, fail);
  },
  deleteRule(id, ruleId, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}/rules/${ruleId}`, null, success, fail);
  },
  runRule(id, ruleId, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/rules/${ruleId}/runs`, null, success, fail);
  },
  pinnedChallenges(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/challenges/pinned`, null, success, fail);
  },
  homepagePolls(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/polls/pinned`, null, success, fail);
  },
  postToActivityFeed(id, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/activityfeed`, data, success, fail);
  },
  updateActivityFeedPost(id, postId, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/activityfeed/${postId}`, data, success, fail);
  },
  deletePostFromActivityFeed(id, postId, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}/activityfeed/${postId}`, null, success, fail);
  },
  getAchievements(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/achievements`, null, success, fail);
  },
  createAchievement(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/achievements`, data, success, fail);
  },
  updateAchievement(id, achievementId, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/achievements/${achievementId}`, data, success, fail);
  },
  deleteAchievement(id, achievementId, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}/achievements/${achievementId}`, null, success, fail);
  },
  bulkAwardAchievement(id, achievementId, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/achievements/${achievementId}/award`, data, success, fail);
  },
  awardAchievement(id, achievementId, userId, success, fail) {
    api.authenticatedRequest(
      "PUT",
      `/organisations/${id}/achievements/${achievementId}/award/${userId}`,
      null,
      success,
      fail,
    );
  },
  removeAwardAchievement(id, achievementId, userId, success, fail) {
    api.authenticatedRequest(
      "DELETE",
      `/organisations/${id}/achievements/${achievementId}/award/${userId}`,
      null,
      success,
      fail,
    );
  },
  getOnboarding(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/onboarding`, null, success, fail);
  },
  getOnboardingTemplates(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/onboarding/templates`, null, success, fail);
  },
  enableOnboarding(id, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/onboarding`, null, success, fail);
  },
  disableOnboarding(id, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}/onboarding`, null, success, fail);
  },
  addOnboardingScreen(id, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/onboarding/screens`, null, success, fail);
  },
  removeOnboardingScreen(id, screenId, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}/onboarding/screens/${screenId}`, null, success, fail);
  },
  updateOnboardingScreen(id, screenId, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/onboarding/screens/${screenId}`, data, success, fail);
  },
  trackOnboardingCompletion(id, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/onboarding/seen`, null, success, fail);
  },
  importOnboardingTemplate(id, templateId, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/onboarding/templates/${templateId}`, null, success, fail);
  },
  getGoals(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/goals`, null, success, fail);
  },
  createGoal(id, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/goals`, data, success, fail);
  },
  updateGoal(id, goalId, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/goals/${goalId}`, data, success, fail);
  },
  deleteGoal(id, goalId, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}/goals/${goalId}`, null, success, fail);
  },
  getGoalEntityCount(id, entity, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/goals/entity/${entity}`, null, success, fail);
  },
  createImpact(id, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/impacts`, data, success, fail);
  },
  getImpacts(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/impacts`, null, success, fail);
  },
  deleteImpact(id, impactId, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${id}/impacts/${impactId}`, null, success, fail);
  },
  updateImpact(id, impactId, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/impacts/${impactId}`, data, success, fail);
  },
  getEmailTemplates(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/communication/emails`, null, success, fail);
  },
  updateEmailTemplate(id, templateName, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/communication/emails/${templateName}`, data, success, fail);
  },
  deleteEmailTemplate(id, templateName, success, fail) {
    api.authenticatedRequest(
      "DELETE",
      `/organisations/${id}/communication/emails/${templateName}`,
      null,
      success,
      fail,
    );
  },
  getIpAddress(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/settings/validateIpAction`, null, success, fail);
  },
  checkValidIpAction(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${id}/settings/validateIpAction`, data, success, fail);
  },
  exploreIdeasOptions(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/explore/ideas/options`, null, success, fail);
  },
  exploreIdeas(id, params, success, fail) {
    const queryParams = new SearchParams(params).toSafeString();
    api.authenticatedRequest("GET", `/organisations/${id}/explore/ideas${queryParams}`, null, success, fail);
  },
  getIdeaImpact(
    id,
    params: {
      filter: "all" | "completed";
      fromDaysAgo?: number;
      toDaysAgo?: number;
      includeChallenges?: string[];
      includeTags?: string[];
    },
    success,
    fail,
  ) {
    const queryParams = new SearchParams(params).toSafeString();
    api.authenticatedRequest("GET", `/organisations/${id}/impact${queryParams}`, null, success, fail);
  },
  getProductTours(id, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/producttours`, null, success, fail);
  },
  getProductTour(id, tourId, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/producttours/${tourId}`, null, success, fail);
  },
  updateProductTour(id, tourId, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/producttours/${tourId}`, data, success, fail);
  },
  completeProductTour(id, tourId, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/producttours/${tourId}/complete`, null, success, fail);
  },
  resetProductTour(id, tourId, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${id}/producttours/${tourId}/reset`, null, success, fail);
  },
  ipScopeInUse(id, scopeId, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${id}/ipscopes/${scopeId}`, null, success, fail);
  },
  revokeTeamsAdminConsent(tenantId, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/tenantId/${tenantId}/teams/consent`, null, success, fail);
  },
  getAvailableProfiles(id, challengeId, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/organisations/${id}/profiles/available?challenge=${challengeId}`,
      null,
      success,
      fail,
    );
  },
  getProfile(orgId, profileId, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${orgId}/profiles/${profileId}`, null, success, fail);
  },
  createProfile(orgId, name, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${orgId}/profiles`, { name }, success, fail);
  },
  updateProfile(orgId, profileId, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${orgId}/profiles/${profileId}`, data, success, fail);
  },
  getLogs(orgId, params, success, fail) {
    const urlParams = new SearchParams(params);
    api.authenticatedRequest(
      "GET",
      `/organisations/${orgId}/communication/emails/logs${urlParams.toSafeString()}`,
      null,
      success,
      fail,
    );
  },
  getLog(orgId, logId, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${orgId}/communication/emails/logs/${logId}`, null, success, fail);
  },
  getPointOfContactRoles(orgId, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${orgId}/contact/roles`, null, success, fail);
  },
  createPointOfContactRole(orgId, data, success, fail) {
    api.authenticatedRequest("POST", `/organisations/${orgId}/contact/roles`, data, success, fail);
  },
  updatePointOfContactRole(orgId, roleId, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/${orgId}/contact/roles/${roleId}`, data, success, fail);
  },
  reorderPointOfContactRole(orgId, roleId, direction, success, fail) {
    api.authenticatedRequest(
      "PUT",
      `/organisations/${orgId}/contact/roles/${roleId}/move/${direction}`,
      null,
      success,
      fail,
    );
  },
  deletePointOfContactRole(orgId, roleId, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/${orgId}/contact/roles/${roleId}`, null, success, fail);
  },
  assignPointOfContact(orgId, roleId, userId, success, fail) {
    api.authenticatedRequest(
      "PUT",
      `/organisations/${orgId}/contact/roles/${roleId}/assign/${userId}`,
      null,
      success,
      fail,
    );
  },
  unassignPointOfContact(orgId, roleId, userId, success, fail) {
    api.authenticatedRequest(
      "DELETE",
      `/organisations/${orgId}/contact/roles/${roleId}/assign/${userId}`,
      null,
      success,
      fail,
    );
  },
  // Custom pages
  listCustomPages(success, fail) {
    api.authenticatedRequest("GET", "/organisations/pages", null, success, fail);
  },
  listPinnedCustomPages(success: (response: OpenAPI.GET<"/organisations/pages/pinned">["response"]) => void, fail) {
    api.authenticatedRequest("GET", "/organisations/pages/pinned", null, success, fail);
  },
  createCustomPage(success, fail) {
    api.authenticatedRequest("POST", "/organisations/pages", null, success, fail);
  },
  getCustomPage(pageId, success, fail) {
    api.maybeAuthenticatedRequest("GET", `/organisations/pages/${pageId}`, null, success, fail);
  },
  editCustomPage(pageId, data, success, fail) {
    api.authenticatedRequest("PUT", `/organisations/pages/${pageId}`, data, success, fail);
  },
  deleteCustomPage(pageId, success, fail) {
    api.authenticatedRequest("DELETE", `/organisations/pages/${pageId}`, null, success, fail);
  },
  testCustomMailServer(orgId, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${orgId}/communication/emails/test`, null, success, fail);
  },
  getBusinesses(orgId, success, fail) {
    api.authenticatedRequest("GET", `/organisations/${orgId}/businesses`, null, success, fail);
  },
});
