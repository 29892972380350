import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "url-search-params-polyfill";
import "unorm";
import "leaflet/dist/leaflet.css";

import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import "./serviceWorkerBridge";

import App from "./components/App";
import { store } from "./store";

// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/append()/append().md
(function (arr) {
  arr.forEach((item) => {
    if (Object.hasOwnProperty.call(item, "append")) {
      return;
    }
    Object.defineProperty(item, "append", {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function append({ ...args }) {
        const argArr = Array.prototype.slice.call(args);
        const docFrag = document.createDocumentFragment();

        argArr.forEach((argItem) => {
          const isNode = argItem instanceof Node;
          docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
        });

        this.appendChild(docFrag);
      },
    });
  });
})([Element.prototype, Document.prototype, DocumentFragment.prototype]);

if (import.meta.env.VITE_IS_PRODUCTION === "true") {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: import.meta.env.MODE,
    normalizeDepth: 10,
    release: import.meta.env.VITE_GIT_SHA,
    maxBreadcrumbs: 50,
    denyUrls: [/support\.simplydo\.co\.uk/i, /^chrome:\/\//i, /^chrome-extension:\/\//i, /^webkit-masked-url:\//i],
  });
}

const router = createBrowserRouter([{ path: "*", element: <App /> }]);
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>,
);
