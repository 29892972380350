import { Dispatch, SetStateAction } from "react";
import { Checkbox, Form } from "semantic-ui-react";
import styled from "styled-components";
import { Other } from "simplydo/interfaces";

const AdvancedProfileContainer = styled.div``;

type IAdvancedProfileEditor = {
  companyDetails: Other.ICompanyDetails;
  setCompanyDetails: Dispatch<SetStateAction<Other.ICompanyDetails>>;
};

const AdvancedProfileEditor = ({ setCompanyDetails, companyDetails }: IAdvancedProfileEditor) => {
  return (
    <div>
      <AdvancedProfileContainer>
        <Form style={{ marginTop: 20 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "48%" }}>
              <Form.Input
                placeholder="01234567"
                required
                label="Company registration number"
                value={companyDetails?.creditsafeRegNo}
                onChange={(e, { value }) =>
                  setCompanyDetails((prevState) => ({ ...prevState, creditsafeRegNo: value }))
                }
                disabled={companyDetails?.creditsafeRegNoSkipped}
              />
              <Checkbox
                label="My business does not have a registration number"
                style={{ marginBottom: 10 }}
                onChange={(e, { checked }) =>
                  setCompanyDetails((prevState) => ({
                    ...prevState,
                    creditsafeRegNoSkipped: checked,
                    creditsafeRegNo: "",
                  }))
                }
                checked={companyDetails?.creditsafeRegNoSkipped}
              />
            </div>
            <div style={{ width: "48%" }}>
              <Form.Input
                required
                placeholder="GB-000000"
                label="VAT registration number"
                value={companyDetails?.vatNo}
                onChange={(e, { value }) => setCompanyDetails((prevState) => ({ ...prevState, vatNo: value }))}
                disabled={companyDetails?.vatNoSkipped}
              />
              <Checkbox
                label="My business does not have a VAT number"
                checked={companyDetails?.vatNoSkipped}
                onChange={(e, { checked }) =>
                  setCompanyDetails((prevState) => ({ ...prevState, vatNoSkipped: checked, vatNo: "" }))
                }
                style={{ marginBottom: 10 }}
              />
            </div>
          </div>
        </Form>
      </AdvancedProfileContainer>
    </div>
  );
};

export default AdvancedProfileEditor;
