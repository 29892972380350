import React from "react";
import styled from "styled-components";
import FormattedComment from "components/lib/FormattedComment";

const StyledParagraph = styled.p`
  white-space: pre-line;
  ${({ $redacted }) =>
    $redacted &&
    `
    color: black;
    background-color: black;
    -moz-box-shadow:3px 0 2px #444;
    border:1px dotted #555;
    background: -moz-linear-gradient(180deg, #000, #222);
  `}
`;

function MultiLineParagraph({ style, className, children, content, redacted, mentionedUsers, mentionedUserAccess }) {
  return (
    <StyledParagraph style={style} className={className} $redacted={redacted}>
      <FormattedComment mentionedUsers={mentionedUsers} mentionedUserAccess={mentionedUserAccess}>
        {children || content}
      </FormattedComment>
    </StyledParagraph>
  );
}

export default MultiLineParagraph;
