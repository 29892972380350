import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Segment, Popup } from "semantic-ui-react";
import api from "api";
import styled from "styled-components";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { StyledModal } from "./StyledModal";

const TemplateSegment = styled(Segment)`
  span {
    display: block;
  }
  p {
    white-space: pre-line;
  }
  .item-type {
    &:not(:first-of-type) {
      margin-top: 10px;
    }
    font-weight: bold;
  }
  .action-area {
    display: flex;
    justify-content: flex-end;
  }
`;

const AnnouncementTemplates = ({
  forType,
  forId,
  setTemplatesAvailable,
  templateModalOpen,
  setTemplateModalOpen,
  automaticAnnouncements,
  automaticConditions,
  handleCreatedAnnouncement,
}) => {
  const [templates, setTemplates] = useState([]);
  const [templateImporting, setTemplateImporting] = useState("");
  const { t } = useTranslation();

  const fetchTemplates = useCallback(() => {
    api.announcements.getTemplates(forType, forId, ({ templates: responseTemplates }) => {
      setTemplatesAvailable(!!responseTemplates.length);
      setTemplates(responseTemplates);
    });
  }, [forType, forId, setTemplatesAvailable]);

  const importAutomaticTemplate = useCallback(
    (templateId, condition, subject, body) => {
      setTemplateImporting(templateId);
      api.announcements.create(
        forType,
        forId,
        {
          type: "automatic",
          sendWhen: condition,
          subject,
          body,
        },
        (announcement) => {
          handleCreatedAnnouncement(announcement, true);
          setTemplateImporting("");
        },
        (err) => {
          setTemplateImporting("");
          toast.error(err.message);
        },
      );
    },
    [forType, forId, handleCreatedAnnouncement],
  );

  useEffect(() => fetchTemplates(), [fetchTemplates]);

  return (
    <StyledModal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={templateModalOpen}
      onClose={() => setTemplateModalOpen(false)}
    >
      <Modal.Header>Announcement templates</Modal.Header>
      <Modal.Content>
        <span style={{ color: "gray" }}>
          These are suggested announcements, created by the Simply Do team, to engage users when they complete certain
          actions in the platform.
        </span>
        {templates.map((template, index) => {
          const relatedCondition = automaticConditions.find((c) => c.value === template.condition);
          const hasAnnouncementForCondition = automaticAnnouncements.find((a) => a.sendWhen === template.condition);
          if (relatedCondition) {
            return (
              <TemplateSegment key={index}>
                <span className="item-type">Condition</span>
                <span>{t(relatedCondition.text)}</span>

                <span className="item-type">Subject</span>
                <span>{template.subject}</span>

                <span className="item-type">Content</span>
                <p>{template.content}</p>

                <div className="action-area">
                  {hasAnnouncementForCondition ? (
                    <Popup
                      hoverable
                      on="hover"
                      content="There is already an automatic announcement for this condition"
                      trigger={
                        <div>
                          <Button primary content="Import announcement" icon="cloud download" disabled />
                        </div>
                      }
                    />
                  ) : (
                    <Button
                      primary
                      content="Import announcement"
                      icon="cloud download"
                      loading={templateImporting === template._id}
                      onClick={() =>
                        importAutomaticTemplate(template._id, template.condition, template.subject, template.content)
                      }
                    />
                  )}
                </div>
              </TemplateSegment>
            );
          }
          return null;
        })}
      </Modal.Content>
    </StyledModal>
  );
};

export default AnnouncementTemplates;
