import React from "react";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import Emoji from "./Emoji";

function EmojiChooser({ onComplete, existing, onRemoveExisting, useNative }) {
  const addEmoji = (emoji, e) => {
    e.stopPropagation();
    onComplete(useNative ? emoji.native : emoji.id);
  };

  return (
    <div onClick={(e) => e.stopPropagation()} data-testid="simplydo-emoji-chooser">
      {existing?.length > 0 && (
        <div style={{ marginBottom: 5 }}>
          <p
            style={{
              marginRight: 5,
              paddingTop: 4,
              marginBottom: 0,
              paddingBottom: 0,
            }}
          >
            Existing labels (click to remove){" "}
          </p>
          {existing.map((em) => (
            <span
              key={em}
              style={{
                marginRight: 3,
                marginBottom: 3,
                cursor: onRemoveExisting ? "pointer" : "default",
                display: "inline-block",
              }}
            >
              <Emoji
                key={em}
                emoji={{ id: em }}
                size={20}
                onClick={(emoji) => onRemoveExisting && onRemoveExisting(emoji.id)}
              />
            </span>
          ))}
        </div>
      )}
      <Picker onClick={addEmoji} set="apple" title="Pick your emoji…" darkMode={false} emoji="point_up" />
    </div>
  );
}

export default EmojiChooser;
