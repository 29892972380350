import React, { useState, useCallback } from "react";
import { Grid, Segment, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import actions from "actions";
import api from "api";
import SetupBranding from "./SetupBranding";

const ProvideEmail = ({ user, updateEmailAddress }) => {
  const [saving, setSaving] = useState(false);
  const [email, setEmail] = useState("");

  const addEmail = useCallback(() => {
    setSaving(true);
    api.auth.provideEmail(
      { email },
      () => {
        updateEmailAddress(email);
        setSaving(false);
        toast.success("Added email address");
      },
      () => {
        setSaving(false);
        toast.error("Failed to add email. Please contact support.");
      },
    );
  }, [email, updateEmailAddress]);

  if (!user) return null;
  return (
    <div>
      <SetupBranding />
      <Grid stackable centered>
        <Grid.Column computer={5} tablet={8}>
          <Segment style={{ marginTop: 50 }}>
            <h3>Please provide an email address</h3>
            <p>To continue, please enter an email address to receive updates.</p>
            <Form>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label="Email address"
                  placeholder="BrianMcBrain@simplydo.io"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Button onClick={addEmail} loading={saving} primary content="Save profile" />
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  updateEmailAddress: (email) => dispatch(actions.user.updateEmailAddress(email)),
});

const ProvideEmailContainer = connect(mapStateToProps, mapDispatchToProps)(ProvideEmail);

export default ProvideEmailContainer;
