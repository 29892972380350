import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Loader, Modal, Input, Table, Button, Label, Icon, Dropdown, Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Other } from "simplydo/interfaces";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import api from "api";
import util from "utils/utils";
import styled from "styled-components";
import ConfigurableTable from "components/lib/ConfigurableTable";
import moment from "moment";
import { ImageWithFallback } from "components/lib/ImageWithFallback";

const AssessmentsDashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  > .dashboard-title {
    display: block;
    margin: 0;
    font-weight: 600;
  }
  > table {
    min-width: 100%;
  }
  .download-report {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 10px 0;
  }
`;

const AssessmentsInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 5px;
  /* border: 1px solid #e9ebee; */
  cursor: pointer;
  &:hover {
    background-color: #f5f6f8;
  }
  > span {
    margin: 0;
    display: block;
    font-size: 0.9em;
  }
`;

const CompletedAssessmentsInfo = styled.div<{ $submittedAssessmentProgress?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > span {
    display: block;
    margin: 0;
    color: black;
    ${({ $submittedAssessmentProgress }) => $submittedAssessmentProgress === 100 && "color: green;"}
    ${({ $submittedAssessmentProgress }) => $submittedAssessmentProgress === 0 && "color: red;"}
  }
  > .number {
    font-size: 1.3em;
    font-weight: 600;
  }
`;

const UserAssessmentsTableContainer = styled.div`
  overflow-x: auto;
  width: 100%;
  > table {
    min-width: 100%;
  }
  .idea-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    > img {
      width: 25px;
      height: 25px;
      object-fit: cover;
      border-radius: 5px;
      margin-right: 5px;
    }
    > a {
      display: block;
      color: black;
      text-decoration-line: underline;
    }
  }
`;

type IDashboardAssessmentIdea = Other.IIdea;

type IDashboardAssessment = {
  assessor: string;
  ownerAssessor: Other.IUser;
  ideas: IDashboardAssessmentIdea[];
  totalSubmittedAssessments: number;
  hasSubmissionsWithConflict: boolean;
  totalStartedAssessments: number;
  totalAssessments: number;
  submittedAssessmentProgress: number;
  startedAssessmentProgress: number;
  assessorIdeaIds: string[];
};

const AssessorIdeas = ({
  user,
  assessorId,
  isOpen,
  onClose,
  challenge,
  unsubmitAssessment,
  unsubmittingAssessment,
}: {
  user: Other.IUserMe;
  assessorId: string;
  isOpen: boolean;
  onClose: () => void;
  challenge: Other.IChallenge;
  unsubmitAssessment: (ideaId, assessorId) => void;
  unsubmittingAssessment: string;
}) => {
  const [ideas, setIdeas] = useState<IDashboardAssessmentIdea[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();

  const getAssessor = useCallback(() => {
    setLoading(true);
    api.challenges.getAssessor(
      challenge._id,
      assessorId,
      ({ ideas: newIdeas }) => {
        setIdeas(newIdeas);
        setLoading(false);
      },
      () => {
        setLoading(false);
      },
    );
  }, [assessorId, challenge._id]);

  useEffect(() => {
    if (isOpen) getAssessor();
  }, [isOpen, getAssessor]);

  const ideaTemplate = challenge.ideaTemplateData;

  if (!ideaTemplate) return null;
  const hasConflictOfInterest = ideas.some(
    (idea) => idea.assessments?.length && idea.assessments[0].conflictOfInterest?.hasConflictOfInterest,
  );

  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={isOpen}
      onClose={onClose}
      size="fullscreen"
    >
      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <UserAssessmentsTableContainer>
          <Table celled style={{ overflowX: "scroll" }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t("common:capitalise", { key: "generic.idea" })}</Table.HeaderCell>
                {hasConflictOfInterest ? <Table.HeaderCell>Conflict of Interest</Table.HeaderCell> : null}
                {ideaTemplate.body.map((section) =>
                  section.type === "assessment" ? (
                    <Table.HeaderCell key={section.id}>Section: {section.name}</Table.HeaderCell>
                  ) : null,
                )}
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {ideas.map((idea) => {
                const { _id, name, assessments = [] } = idea;
                const userAssessment = assessments[0];
                const absoluteScore = userAssessment?.assessment
                  ? Object.values(userAssessment.assessment)
                      .map((value) => value.score ?? 0)
                      .reduce((a, b) => a + b, 0)
                  : 0;
                const absoluteTotalScore = ideaTemplate.body
                  .flatMap((section) =>
                    section.type === "assessment"
                      ? section.fields.map((field) => field?.options?.availableScore ?? 0)
                      : [],
                  )
                  .reduce((a, b) => a + b, 0);

                return (
                  <Table.Row key={idea._id}>
                    <Table.Cell singleLine style={{ maxWidth: 250, textOverflow: "ellipsis", overflow: "hidden" }}>
                      <div className="idea-name">
                        <ImageWithFallback
                          src={util.ideaCoverImage(idea)}
                          fallbackSrc={util.ideaCoverImage()}
                          alt={`${t("common:capitalise", { key: "generic.idea" })} cover`}
                        />
                        <Link to={`/ideas/${_id}`} style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                          {name}
                        </Link>
                      </div>
                    </Table.Cell>
                    {hasConflictOfInterest ? (
                      <Table.Cell singleLine>
                        {userAssessment?.conflictOfInterest?.hasConflictOfInterest ? (
                          <div>
                            <div style={{ marginBottom: 5 }}>
                              <Icon color="red" name="warning circle" />
                              <span>
                                <b>Conflict of interest declared</b>
                              </span>
                            </div>
                            {userAssessment.conflictOfInterest.notes ? (
                              <div>
                                <span>{userAssessment.conflictOfInterest.notes}</span>
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          "N/A"
                        )}
                      </Table.Cell>
                    ) : null}
                    {ideaTemplate.body.map((section) => {
                      if (section.type !== "assessment") return null;
                      if (userAssessment?.conflictOfInterest?.hasConflictOfInterest) {
                        return (
                          <Table.Cell key={section.id}>
                            <i>N/A</i>
                          </Table.Cell>
                        );
                      }
                      const hasScoreInEveryField = section.fields.every(
                        (field) => typeof userAssessment?.assessment?.[field.id]?.score === "number",
                      );
                      if (!hasScoreInEveryField) {
                        if (userAssessment?.isSubmitted) {
                          return (
                            <Table.Cell key={section.id}>
                              <i>
                                This section was not part of the assessment at the time when this assessment was
                                submitted. It is not considered in the final score.
                              </i>
                            </Table.Cell>
                          );
                        }
                        return (
                          <Table.Cell key={section.id}>
                            <i>Not yet fully assessed</i>
                          </Table.Cell>
                        );
                      }
                      const sectionAvailableScore = section.fields
                        .map((field) => field?.options?.availableScore ?? 0)
                        .reduce((a, b) => a + b, 0);
                      const userSectionAssessmentScore = section.fields.reduce((acc, field) => {
                        const userFieldAssessment = userAssessment?.assessment?.[field.id];
                        return acc + (userFieldAssessment?.score || 0);
                      }, 0);
                      return (
                        <Table.Cell key={section.id} style={{ minWidth: 200 }}>
                          <span>
                            <b>
                              Score: {userSectionAssessmentScore || 0}/{sectionAvailableScore}
                            </b>
                          </span>
                        </Table.Cell>
                      );
                    })}
                    {userAssessment ? (
                      <Table.Cell collapsing>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                          {!userAssessment?.conflictOfInterest?.hasConflictOfInterest && (
                            <Label size="small" color="violet">
                              {absoluteScore} / {absoluteTotalScore} - {Math.round(userAssessment.score || 0)}%
                            </Label>
                          )}
                          {userAssessment.startedAt || userAssessment.isSubmitted ? (
                            <>
                              {userAssessment.isSubmitted ? (
                                <Label size="small" color="green">
                                  Submitted
                                </Label>
                              ) : (
                                <Label size="small" color="orange">
                                  Not yet submitted
                                </Label>
                              )}
                            </>
                          ) : (
                            <Label size="small" color="red">
                              Not yet started
                            </Label>
                          )}
                          {userAssessment.isSubmitted && util.canManageChallenge(user, challenge) ? (
                            <Button
                              size="tiny"
                              content="Unsubmit"
                              onClick={() => unsubmitAssessment(_id, assessorId)}
                              loading={unsubmittingAssessment === `${_id}-${assessorId}`}
                              style={{ height: 24, padding: "0 20px" }}
                            />
                          ) : null}
                        </div>
                      </Table.Cell>
                    ) : null}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </UserAssessmentsTableContainer>
      )}
    </Modal>
  );
};

const AssessorTable = ({
  assessors,
  setOpenAssessor,
  onSort,
  sort,
  unassignAssessorFromAllIdeas,
}: {
  assessors: IDashboardAssessment[];
  challenge: Other.IChallenge;
  setOpenAssessor: (assessorId: string) => void;
  onSort: (key: string) => void;
  sort: { key: string; direction: "asc" | "desc" };
  unassignAssessorFromAllIdeas: (assessorId: string) => void;
}) => {
  const { t } = useTranslation();
  return (
    <ConfigurableTable
      tableKey="assessmentsDashboard"
      data={assessors}
      keyExtractor={(item) => item.assessor}
      restrictOverflow
      onSort={onSort}
      sort={{ key: sort.key as keyof IDashboardAssessment, direction: sort.direction }}
      columns={[
        {
          key: "fullName",
          name: "Assessor",
          sortable: true,
          render: ({ item }) => (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Link to={`/users/${item.assessor}`}>
                  <ImageWithFallback
                    style={{ objectFit: "cover" }}
                    avatar
                    src={util.avatarUrl(item?.ownerAssessor)}
                    fallbackSrc={util.avatarUrl()}
                  />
                </Link>
                <Link style={{ marginLeft: 5 }} to={`/users/${item.assessor}`}>
                  {item.ownerAssessor.profile.fullName}
                </Link>
              </div>
            </>
          ),
        },
        {
          key: "totalAssessments",
          name: "Total Assessments",
          sortable: true,
          render: ({ item }) => (
            <>
              <AssessmentsInfo onClick={() => setOpenAssessor(item.assessor)}>
                <span>{item.totalAssessments}</span>
              </AssessmentsInfo>
            </>
          ),
        },
        {
          key: "notStartedAssessments",
          name: "Not Started",
          render: ({ item }) => (
            <>
              <AssessmentsInfo onClick={() => setOpenAssessor(item.assessor)}>
                <span>{item.totalAssessments - item.totalStartedAssessments - item.totalSubmittedAssessments}</span>
              </AssessmentsInfo>
            </>
          ),
        },
        {
          key: "inProgressAssessment",
          name: "In Progress",
          render: ({ item }) => (
            <>
              <AssessmentsInfo onClick={() => setOpenAssessor(item.assessor)}>
                <span>{item.totalStartedAssessments}</span>
              </AssessmentsInfo>
            </>
          ),
        },
        {
          key: "submittedAssessments",
          name: "Submitted",
          render: ({ item }) => (
            <>
              <AssessmentsInfo onClick={() => setOpenAssessor(item.assessor)}>
                <span>
                  {item.totalSubmittedAssessments} submitted{" "}
                  {item.hasSubmissionsWithConflict ? (
                    <Popup
                      trigger={<Icon size="large" color="red" name="warning sign" />}
                      content={"This assessor has reported a conflict of interest on one or more ideas."}
                    />
                  ) : null}
                </span>
              </AssessmentsInfo>
            </>
          ),
        },
        {
          key: "assessmentsCompleted",
          name: "Progress",
          sortable: true,
          render: ({ item }) => (
            <CompletedAssessmentsInfo $submittedAssessmentProgress={item.submittedAssessmentProgress}>
              <span className="number">{item.submittedAssessmentProgress}%</span>
              <span>Submitted</span>
            </CompletedAssessmentsInfo>
          ),
        },
        {
          key: "openAssessments",
          name: "",
          settingName: "Actions",
          center: true,
          collapsing: true,
          render: ({ item }) => (
            <>
              <Button
                icon="eye"
                content={t("assessments.dashboard.view-assessments")}
                onClick={() => setOpenAssessor(item.assessor)}
                size="small"
              />
              <Dropdown direction="left" trigger={<Button icon="settings" size="small" />} icon={null}>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => unassignAssessorFromAllIdeas(item.assessor)}>
                    <Icon name="remove user" />
                    Unassign this assessor from all ideas
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
          ),
        },
      ]}
    />
  );
};

type IAssessmentsDashboard = {
  challenge: Other.IChallenge;
  getAssignedAssessments: () => void;
  myAssignedAssessments: Other.IIdeaAssignment[];
  setMyAssignedAssessments: (assessments: Other.IIdeaAssignment[]) => void;
  demoingTour?: string;
};

const AssessmentsDashboard = ({ challenge }: IAssessmentsDashboard) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [assessors, setAssessors] = useState<IDashboardAssessment[]>([]);
  const [pendingInvitations, setPendingInvitations] = useState<Other.IInvitation[]>([]);
  const [totals, setTotals] = useState<{ assessors: number; ideas: number }>({ assessors: 0, ideas: 0 });
  const [sort, setSort] = useState<"assessmentsCompleted" | "fullName" | "totalAssessments" | "">(
    "assessmentsCompleted",
  );
  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("desc");
  const [openAssessor, setOpenAssessor] = useState<string>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  // Format: {idea_id}-{user_id}
  const [unsubmittingAssessment, setUnsubmittingAssessment] = useState<string>("");
  const [downloadingAssessmentReport, setDownloadingAssessmentReport] = useState<boolean>(false);
  const [downloadedAssessmentReport, setDownloadedAssessmentReport] = useState<string>("");

  const challengeId = challenge._id;
  const user = useSelector((state: { user: Other.IUserMe | null }) => state?.user);
  const { t } = useTranslation();

  const getAssessmentDashboard = useCallback(
    (shouldSetLoading) => {
      setLoading(shouldSetLoading);
      api.challenges.getAssessmentDashboard(
        challengeId,
        searchValue,
        ({ assessors: newAssessors, totals: newTotals, pendingInvitations }) => {
          setAssessors(newAssessors);
          setTotals(newTotals);
          setPendingInvitations(pendingInvitations);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
          toast.error(err.message);
        },
      );
    },
    [challengeId, searchValue],
  );

  const unsubmitAssessment = useCallback(
    (ideaId, assessorId) => {
      setUnsubmittingAssessment(`${ideaId}-${assessorId}`);
      api.ideas.unsubmitAssessment(
        ideaId,
        assessorId,
        () => {
          toast.success("Unsubmitted assessment");
          setUnsubmittingAssessment("");
          getAssessmentDashboard(false);
        },
        () => {
          toast.error("Unsubmitted failed");
          setUnsubmittingAssessment("");
        },
      );
    },
    [getAssessmentDashboard],
  );

  const generateAssessmentReport = useCallback(
    (withNotes: boolean) => {
      toast.success("Your report will download shortly.");
      setDownloadingAssessmentReport(true);
      api.reports.challengeAssessmentReport(
        challengeId,
        { includeNotes: withNotes },
        (c) => {
          setDownloadedAssessmentReport(c.link);
          window.location = c.link;
          setDownloadingAssessmentReport(false);
        },
        () => {
          toast.error("Error unable to generate report");
          setDownloadingAssessmentReport(false);
        },
      );
    },
    [challengeId],
  );

  useEffect(() => {
    getAssessmentDashboard(true);
  }, [getAssessmentDashboard]);

  const sortedAssessors = useMemo(() => {
    if (!sort) return assessors;
    return [...assessors].sort((a, b) => {
      if (sort === "assessmentsCompleted") {
        if (sortDirection === "asc") return a.submittedAssessmentProgress - b.submittedAssessmentProgress;
        return b.submittedAssessmentProgress - a.submittedAssessmentProgress;
      }
      if (sort === "fullName") {
        if (sortDirection === "asc")
          return (a.ownerAssessor?.profile.fullName ?? "") > (b.ownerAssessor?.profile.fullName ?? "") ? -1 : 1;
        return (a.ownerAssessor?.profile.fullName ?? "") < (b.ownerAssessor?.profile.fullName ?? "") ? -1 : 1;
      }
      if (sort === "totalAssessments") {
        if (sortDirection === "asc") return a.totalAssessments - b.totalAssessments;
        return b.totalAssessments - a.totalAssessments;
      }
      return a.submittedAssessmentProgress - b.submittedAssessmentProgress;
    });
  }, [assessors, sort, sortDirection]);

  const unassignAssessorFromAllIdeas = useCallback(
    (assessorId) => {
      const usingAssessor = assessors.find((a) => a.assessor === assessorId);
      util
        .confirm(
          `Unassign assessor from all ${t("generic.ideas")}`,
          `Are you sure you want to unassign this assessor from all ${t("generic.ideas")}? All their existing assessments will be deleted. This action cannot be undone.`,
        )
        .then(() => {
          api.challenges.unassignAssessors(
            challengeId,
            usingAssessor.assessorIdeaIds,
            [assessorId],
            () => {
              setAssessors((prevAssessors) => prevAssessors.filter((a) => a.assessor !== assessorId));
            },
            (err) => {
              toast.error(err.message);
            },
          );
        })
        .catch(() => {});
    },
    [assessors, challengeId, t],
  );

  const onSort = useCallback(
    (sortKey) => {
      if (sortKey !== sort) {
        setSort(sortKey);
        setSortDirection("desc");
      } else if (sortDirection === "desc") {
        setSortDirection("asc");
      } else {
        setSort("");
      }
    },
    [sort, sortDirection],
  );

  const resendInvitation = useCallback((invitationId) => {
    api.invitations.resend(
      invitationId,
      () => {
        toast.success("Invitation re-sent");
      },
      () => {
        toast.error("Failed to re-send invitation");
      },
    );
  }, []);

  return (
    <AssessmentsDashboardContainer>
      <Input
        style={{ maxWidth: "300px", marginBottom: 10 }}
        onChange={(e, { value }) => setSearchValue(value)}
        placeholder={`Search ${util.pluralise(totals.ideas, t("generic.idea"), t("generic.ideas"))}/${util.pluralise(totals.assessors, "assessor", "assessors")}...`}
        value={searchValue}
        icon="search"
      />
      <AssessorIdeas
        user={user}
        assessorId={openAssessor}
        challenge={challenge}
        isOpen={!!openAssessor}
        onClose={() => setOpenAssessor("")}
        unsubmitAssessment={unsubmitAssessment}
        unsubmittingAssessment={unsubmittingAssessment}
      />
      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <>
          <AssessorTable
            assessors={sortedAssessors}
            challenge={challenge}
            setOpenAssessor={setOpenAssessor}
            sort={{ key: sort, direction: sortDirection }}
            onSort={onSort}
            unassignAssessorFromAllIdeas={unassignAssessorFromAllIdeas}
          />
        </>
      )}
      {pendingInvitations.length > 0 && (
        <>
          <h4 style={{ marginBottom: 0 }}>Pending invitations</h4>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell colSpan={1}>Email</Table.HeaderCell>
                <Table.HeaderCell colSpan={1}>Date of last contact</Table.HeaderCell>
                <Table.HeaderCell colSpan={2}></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {pendingInvitations.map((invitation) => (
                <Table.Row key={invitation._id}>
                  <Table.Cell collapsing>{invitation.invitee}</Table.Cell>
                  <Table.Cell>
                    {moment(invitation?.lastContactedAt || invitation?.createdAt).format("DD/MM/YYYY")}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Dropdown direction="left" trigger={<Button icon="settings cog" />} icon={null}>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            navigator.clipboard.writeText(invitation.link);
                            toast.success("Link copied");
                          }}
                        >
                          <>
                            <Icon name="copy" />
                            Copy invitation link
                          </>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            resendInvitation(invitation._id);
                          }}
                        >
                          <>
                            <Icon name="mail outline" />
                            Re-send invitation
                          </>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </>
      )}
      {util.canViewEntireChallenge(user, challenge) ? (
        <div className="download-report">
          {downloadedAssessmentReport ? (
            <Button
              secondary
              icon="cloud download"
              content="Download assessment report"
              as="a"
              href={downloadedAssessmentReport}
              style={{ margin: 0 }}
            />
          ) : (
            <Dropdown
              trigger={
                <Button
                  secondary
                  icon="file excel"
                  content="Generate assessment report"
                  loading={downloadingAssessmentReport}
                  style={{ margin: 0 }}
                />
              }
              icon={null}
            >
              <Dropdown.Menu>
                <Dropdown.Header>Generate assessment report</Dropdown.Header>
                <Dropdown.Item onClick={() => generateAssessmentReport(true)}>
                  ... <b>with</b> assessor notes
                </Dropdown.Item>
                <Dropdown.Item onClick={() => generateAssessmentReport(false)}>
                  ... <b>without</b> assessor notes
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      ) : null}
    </AssessmentsDashboardContainer>
  );
};

export default AssessmentsDashboard;
