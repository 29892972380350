import React from "react";
import util from "utils/utils";
import { Trans } from "react-i18next";

export default function DiscussionReactedContent({ ownerGroup = { name: "Undefined" }, events }) {
  return (
    <Trans i18nKey="notifications.groupDiscussionReacted">
      <b>{{ name: util.getEventUsers(events) }}</b>
      reacted to your post in the group discussion for
      <b>{{ group: ownerGroup.name }}</b>
    </Trans>
  );
}
