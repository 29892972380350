import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Label, Modal, Table, Button, Divider, Icon, Statistic, Popup, Input } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import toast from "react-hot-toast";
import moment from "moment";
import api from "api";

import SelectableDropdown from "components/lib/SelectableDropdown";
import { UserChip } from "components/lib/Chips";
import { StyledModal } from "./StyledModal";
import RichTextViewer from "components/lib/Editors/RichTextViewer";

const AnnouncementLogs = ({ forType, forId, standardConditions, automaticConditions, t }) => {
  const [page, setPage] = useState(1);
  const [showing, setShowing] = useState(10);
  const [logState, setLogState] = useState({
    logs: [],
    total: 0,
    nextPageAvailable: false,
    prevPageAvailable: false,
  });
  const [logFilter, setLogFilter] = useState({
    searchTerm: "",
    announcementType: "",
  });
  const [viewingLog, setViewingLog] = useState(null);
  const { logs, nextPageAvailable, prevPageAvailable, total } = logState;
  const sentWhenText = useMemo(
    () => automaticConditions.find((condition) => condition.value === viewingLog?.sentWhen)?.text,
    [automaticConditions, viewingLog],
  );

  const fetchLogs = useCallback(() => {
    api.announcements.getLogs(
      forType,
      forId,
      { page, ...logFilter },
      (data) => {
        setLogState(data);
      },
      (err) => {
        toast.error(err.message);
      },
    );
  }, [forType, forId, page, logFilter]);

  useEffect(() => fetchLogs(), [fetchLogs]);

  const automaticOptions = [
    { key: "joinGroup", value: "joinGroup", text: "Someone joins the group" },
    { key: "createIdea", value: "createIdea", text: `User creates ${t("generic.ideaWithArticle")}` },
    {
      key: "createChallenge",
      value: "createChallenge",
      text: `User creates ${t("generic.challengeWithArticle")}`,
    },
    { key: "register", value: "register", text: "New user signs-up" },
    { key: "referredto", value: "referredto", text: "User is referred into this organisation" },
    { key: "referredFrom", value: "referredFrom", text: "User is referred to another organisation" },
    { key: "submitIdea", value: "submitIdea", text: `User submits ${t("generic.ideaWithArticle")}` },
  ];

  return (
    <div>
      <StyledModal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={viewingLog}
        onClose={() => setViewingLog(null)}
      >
        <Modal.Header>{t("announcements.form.title")}</Modal.Header>
        <Modal.Content>
          {viewingLog && (
            <Modal.Description>
              <h4>{t("announcements.message.subject")}</h4>
              <p>{viewingLog.subject}</p>
              <h4>{t("announcements.message.sentAt")}</h4>
              <p>{moment(viewingLog.createdAt).format("HH:mm on DD/MM/YY")}</p>
              <Divider />
              <h4>{t("announcements.message.message")}</h4>
              <RichTextViewer content={viewingLog.body} />
              <Divider />
              {viewingLog.events?.processedCount ? (
                <>
                  <h4>Events</h4>
                  <Statistic.Group widths="four" size="mini">
                    <Statistic>
                      <Statistic.Value>
                        <Icon name="check" color="green" />
                      </Statistic.Value>
                      <Statistic.Label>Sent</Statistic.Label>
                    </Statistic>
                    <Statistic>
                      <Statistic.Value>{viewingLog.events.deliveredCount || 0}</Statistic.Value>
                      <Statistic.Label>Delivered</Statistic.Label>
                    </Statistic>
                    <Statistic>
                      <Statistic.Value>{viewingLog.events.bounceCount || 0}</Statistic.Value>
                      <Statistic.Label>Bounced</Statistic.Label>
                    </Statistic>
                    <Statistic>
                      <Statistic.Value>{viewingLog.events.openCount || 0}</Statistic.Value>
                      <Statistic.Label>Opened</Statistic.Label>
                    </Statistic>
                  </Statistic.Group>
                  <Divider />
                </>
              ) : null}
              <h4>{t("announcements.message.triggers")}</h4>
              {viewingLog.announcementType === "automatic" ? (
                <Label color="pink" content="Auto" style={{ marginRight: 5 }} />
              ) : null}
              {sentWhenText ? <Label content={`Send when: ${t(sentWhenText)}`} /> : null}
              {viewingLog.sentConditions
                ? viewingLog.sentConditions.map((c) => (
                    <Label
                      key={c.type}
                      size="small"
                      style={{ marginRight: 5, marginBottom: 5 }}
                      content={t(standardConditions.find((condition) => condition.value === c.type).text)}
                    />
                  ))
                : null}
              <div style={{ marginTop: 10 }} />
              {viewingLog.to ? (
                <div>
                  <h4>To</h4>
                  <p>{viewingLog.to}</p>
                </div>
              ) : null}
              {viewingLog.bccUsers && viewingLog.bccUsers.length > 0 ? (
                <div>
                  <h4>BCC</h4>
                  {viewingLog.bccUsers.length > 10 ? (
                    <>
                      {viewingLog.bccUsers.slice(0, showing).map((b) => (
                        <UserChip user={b} style={{ marginRight: 5, marginBottom: 5 }} key={b._id} />
                      ))}
                      {showing < viewingLog.bccUsers.length && (
                        <span>{`+${viewingLog.bccUsers.length - showing} others`}</span>
                      )}
                      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        {showing > 10 && (
                          <span
                            style={{
                              textDecorationLine: "underline",
                              cursor: "pointer",
                              marginRight: 7,
                              fontSize: 12,
                            }}
                            onClick={() => setShowing(showing - 50)}
                          >
                            <em>Show less</em>
                          </span>
                        )}
                        {showing < viewingLog.bccUsers.length && (
                          <span
                            style={{ textDecorationLine: "underline", cursor: "pointer", fontSize: 12 }}
                            onClick={() => setShowing(showing + 50)}
                          >
                            <em>Show more</em>
                          </span>
                        )}
                      </div>
                    </>
                  ) : (
                    viewingLog.bccUsers.map((b) => (
                      <UserChip user={b} style={{ marginRight: 5, marginBottom: 5 }} key={b._id} />
                    ))
                  )}
                </div>
              ) : null}
            </Modal.Description>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button content={t("generic.close")} onClick={() => setViewingLog(null)} />
        </Modal.Actions>
      </StyledModal>

      <h4>{t("announcements.log.title")}</h4>
      <SelectableDropdown
        single
        value={logFilter.announcementType}
        placeholder="Filter by announcement type"
        onChange={(selection) => setLogFilter((prevState) => ({ ...prevState, announcementType: selection }))}
        fluid={false}
        options={
          logState?.automaticLogTypes?.length > 0
            ? [
                ...[
                  { key: "standard", value: "standard", text: "Manual announcements" },
                  {
                    key: "automatic",
                    value: "automatic",
                    text: "Automatic announcements",
                    isDivider: true,
                  },
                ],
                ...automaticOptions.filter((o) => logState?.automaticLogTypes?.includes(o.value)),
              ]
            : [{ key: "standard", value: "standard", text: "Manual announcements" }]
        }
      />

      <Input
        style={{ marginLeft: 10 }}
        placeholder="Filter by user name or email..."
        icon="search"
        value={logFilter.searchTerm}
        onChange={(e) => setLogFilter((prevState) => ({ ...prevState, searchTerm: e.target.value }))}
      />
      <div style={{ width: "100%", overflowX: "auto", marginTop: 15 }}>
        <Table compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("announcements.log.table.sent")}</Table.HeaderCell>
              <Table.HeaderCell>{t("announcements.log.table.subject")}</Table.HeaderCell>
              <Table.HeaderCell collapsing>
                Sent when{" "}
                <Popup
                  content={`This field only applies to automatic announcements such as '${t("generic.idea")} created'.`}
                  trigger={<Icon name="question circle" />}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>{t("announcements.log.table.recipients")}</Table.HeaderCell>
              <Table.HeaderCell collapsing>
                Sent{" "}
                <Popup
                  content="We are not able to track send counts for all of your announcements. Any announcement without send data will show a checkmark icon to indicate a successful delivery instead."
                  trigger={<Icon name="question circle" />}
                />
              </Table.HeaderCell>
              <Table.HeaderCell collapsing>Delivered</Table.HeaderCell>
              <Table.HeaderCell collapsing>
                Bounced{" "}
                <Popup
                  content="Bounced emails were rejected by the recipient's mail server. This is commonly caused by a misspelled or nonexistent email address."
                  trigger={<Icon name="question circle" />}
                />
              </Table.HeaderCell>
              <Table.HeaderCell collapsing>Opened</Table.HeaderCell>

              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {logs.map((l) => (
              <Table.Row key={l._id}>
                <Table.Cell collapsing>{moment(l.createdAt).format("HH:mm DD/MM/YY")}</Table.Cell>
                <Table.Cell>{l.subject}</Table.Cell>
                <Table.Cell>{automaticOptions?.find((o) => o.value === l?.sentWhen)?.text}</Table.Cell>
                <Table.Cell collapsing>
                  {l.to} {l.bcc && l.bcc.length > 0 && <span>{l.bcc.length} in BCC</span>}
                </Table.Cell>
                {l.events?.processedCount ? (
                  <>
                    <Table.Cell collapsing>
                      <Icon name="check" color="green" />
                    </Table.Cell>
                    <Table.Cell collapsing>{l.events?.deliveredCount || 0}</Table.Cell>
                    <Table.Cell collapsing>{l.events?.bounceCount || 0}</Table.Cell>
                    <Table.Cell collapsing>{l.events?.openCount || 0}</Table.Cell>
                  </>
                ) : (
                  <>
                    <Table.Cell collapsing></Table.Cell>
                    <Table.Cell collapsing></Table.Cell>
                    <Table.Cell collapsing></Table.Cell>
                    <Table.Cell collapsing></Table.Cell>
                  </>
                )}
                <Table.Cell collapsing>
                  <Button
                    size="mini"
                    icon="eye"
                    basic
                    content={t("generic.view", { entity: "" })}
                    onClick={() => setViewingLog(l)}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="9">
                {t("announcements.log.number", { amount: logs.length, total })}
                <Button.Group basic floated="right" size="tiny">
                  {prevPageAvailable ? (
                    <Button
                      content={t("generic.previous")}
                      icon="arrow left"
                      onClick={() => setPage((prev) => prev - 1)}
                    />
                  ) : null}
                  <Button disabled={true} content={`Page ${page}`} />
                  {nextPageAvailable ? (
                    <Button onClick={() => setPage((prev) => prev + 1)}>
                      {t("generic.next")} <Icon name="arrow right" />
                    </Button>
                  ) : null}
                </Button.Group>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    </div>
  );
};

export default withTranslation()(AnnouncementLogs);
