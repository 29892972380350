import React, { useState } from "react";
import { Button, Label, Modal } from "semantic-ui-react";
import styled from "styled-components";
import useTheme from "theme/useTheme";

type Screen = {
  title: string;
  content: any;
};

type Action = {
  label: string;
  onClick: () => void;
  color?: string;
  icon?: string;
  primary?: boolean;
};

type ModalCarouselProps = {
  title?: string;
  open: boolean;
  screens: Screen[];
  actions: Action[];
  onClose: () => void;
};

const ProgressLabels = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  > .ui.label {
    margin: 0 0.5rem;
  }
`;

const FlexActions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-between;
`;

type ProgressLabelProps = {
  isActive: boolean;
  colour: string;
  onClick: () => void;
};

const ProgressLabel = ({ isActive, colour, onClick }: ProgressLabelProps) => (
  <Label
    empty
    circular
    onClick={onClick}
    size="large"
    color={!isActive ? "grey" : null}
    style={{ backgroundColor: isActive && colour, cursor: "pointer" }}
  />
);

const ModalCarousel = ({ title, open, onClose, screens, actions }: ModalCarouselProps) => {
  const theme = useTheme();
  const [activePage, setActivePage] = useState(0);

  const nextPageAvailable = activePage < screens.length - 1;
  const prevPageAvailable = activePage !== 0;

  if (!screens.length) return null;
  return (
    <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={open} onClose={onClose}>
      {title ? <Modal.Header>{title}</Modal.Header> : null}
      <Modal.Content>
        <div>
          <h1>{screens[activePage].title}</h1>
          {screens[activePage].content}
        </div>
      </Modal.Content>
      <FlexActions>
        {screens.length > 1 ? (
          <div style={{ display: "flex", flex: 1, gap: 25 }}>
            <Button
              disabled={!prevPageAvailable}
              labelPosition="left"
              icon="chevron left"
              content="Prev"
              onClick={() => setActivePage((prevPage) => prevPage - 1)}
            />
            <ProgressLabels>
              {screens.map((screen, index) => (
                <ProgressLabel
                  isActive={activePage === index}
                  onClick={() => setActivePage(index)}
                  colour={theme?.primaryColour}
                />
              ))}
            </ProgressLabels>
            <Button
              disabled={!nextPageAvailable}
              labelPosition="right"
              icon="chevron right"
              content="Next"
              primary
              onClick={() => setActivePage((prevPage) => prevPage + 1)}
            />
          </div>
        ) : (
          <div></div>
        )}

        {actions.map((action) => (
          <Button
            key={action.label}
            primary={action.primary}
            content={action.label}
            icon={action.icon}
            onClick={action.onClick}
          />
        ))}
      </FlexActions>
    </Modal>
  );
};

export default ModalCarousel;
