import React from "react";
import handleViewport from "react-in-viewport";
import styled from "styled-components";
import { Segment, Message, Icon, Label } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import util from "utils/utils";

const StyledSegment = styled(Segment)`
  ${({ $sectionInclude }) =>
    $sectionInclude
      ? `
    border: none !important;
    color: white;
    background-color: ${util.adjustColour($sectionInclude.colour, -0.08)} !important;
  `
      : ""}
  ${({ $isAssessment }) =>
    $isAssessment
      ? `
    border: none !important;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${util.adjustColour("#6435c9", -0.08)} !important;
    h3 {
      margin: 0;
      padding: 0;
    }
  `
      : ""}
  ${({ $isPreview }) =>
    $isPreview
      ? `
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  `
      : ""}
`;

function SectionHeader(props) {
  const { section, sectionInclude, isEditing, t, isPreview, assessment } = props;
  if (sectionInclude) {
    return (
      <StyledSegment attached="top" $sectionInclude={sectionInclude} $isPreview={isPreview}>
        <h3>{sectionInclude.title}</h3>
        {isEditing && sectionInclude.description && (
          <p style={{ display: "inline-flex" }}>{t(`ideas.6Cs.sections.${sectionInclude.key}.description`)}</p>
        )}
      </StyledSegment>
    );
  }

  return (
    <StyledSegment attached="top" $isPreview={isPreview} $isAssessment={section.type === "assessment"}>
      <h3>{section.name}</h3>
      {section.type === "assessment" ? (
        <Label
          active
          color={
            section.fields.filter(
              (field) =>
                assessment?.assessment?.[field.id]?.score !== undefined &&
                assessment.assessment?.[field.id]?.score !== "",
            ).length === section.fields.length
              ? "green"
              : undefined
          }
        >
          Assessed{" "}
          {
            section.fields.filter(
              (field) =>
                assessment?.assessment?.[field.id]?.score !== undefined &&
                assessment.assessment?.[field.id]?.score !== "",
            ).length
          }{" "}
          / {section.fields.length}
        </Label>
      ) : null}
      {section.type === "edi" && (
        <Message success>
          <Message.Header>
            <Icon name="lock" /> This section is about equality, diversity, and inclusion
          </Message.Header>
          <Message.Content>
            Your information here is protected and is only available to select {t("generic.challenge")} administrators.
          </Message.Content>
        </Message>
      )}
    </StyledSegment>
  );
}

export default withTranslation()(handleViewport(SectionHeader, { threshold: 1 }));
