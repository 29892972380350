import React, { useEffect } from "react";
import { Button } from "semantic-ui-react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import actions from "actions";
import util from "utils/utils";
import { SearchParams } from "simplydo/core";

import DownloadAppBanner from "components/home/DownloadAppBanner";
import { MultiLineParagraph } from "components/lib/UI";
import Login from "./Login";
import RegisterFlow from "./RegisterFlow";
import LoginNewOrg from "./LoginNewOrg";
import ForgottenPassword from "./ForgottenPassword";
import ResetPassword from "./ResetPassword";
import Enrol from "./Enrol";

const StyledEntry = styled.div`
  flex: 1 0 auto;
  display: ${({ theme }) => (!theme.sizes.isComputer ? "block" : "flex")};
  width: 100%;
  .sidebar {
    display: flex;
    flex-direction: column;
    .sidebar-content {
      flex: 1 0 auto;
      background-color: ${({ theme }) => theme.secondaryColour};
      background-image: linear-gradient(
        to bottom right,
        ${({ theme }) => theme.secondaryColour},
        ${({ theme }) => util.adjustColour(theme.secondaryColour, -0.2)}
      );
      color: white;
      width: ${({ theme }) => (!theme.sizes.isComputer ? "100%" : "300px")};
      padding: 10px;
    }
    img {
      max-width: 50%;
      display: block;
      margin-top: 30px;
    }
  }
  .main {
    flex: 1;
    padding: 30px ${({ theme }) => (!theme.sizes.isComputer ? "10px" : "10%")};
  }
`;

function Entry({ organisation, user, onSelectOrganisation, t }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      return;
    }

    const params = new SearchParams(window.location.search);
    const then = params.get("then") ?? "/";
    const code = params.get("code");
    if (then && (!code || (code && user?.organisation?.code === code))) {
      navigate(then);
    }
  }, [user, navigate]);

  return (
    <StyledEntry>
      <DownloadAppBanner />
      <div className="sidebar">
        <div className="sidebar-content">
          {organisation?.welcomeText ? (
            <MultiLineParagraph content={organisation.welcomeText} />
          ) : (
            <>
              <img src="https://cdn.simplydo.co.uk/images/logo_light.png" alt="Simply Do logo" />
              <h3>Welcome to {util.appName()}</h3>
              <p>
                Login to discover new {t("generic.challenges")} and to continue building your {t("generic.ideas")}.
              </p>
            </>
          )}
          {organisation && (
            <>
              <h5 style={{ display: "inline-block", marginTop: 20, marginBottom: 0 }}>
                {t("entry.register.org.signingUpWith", { organisation })}
              </h5>
              <Button
                fluid
                size="tiny"
                type="button"
                content={t("entry.register.org.changeThis")}
                onClick={() => onSelectOrganisation(null)}
                basic
                inverted
                style={{ margin: "10px 10px 10px 0px" }}
              />
            </>
          )}
        </div>
      </div>
      <div className="main">
        <Routes>
          <Route path="password/forgotten" element={<ForgottenPassword />} />
          <Route path="password/reset" element={<ResetPassword />} />
          <Route path="login/organisation" element={<LoginNewOrg />} />
          <Route path="enrol" element={<Enrol />} />
          <Route path="register" element={<RegisterFlow />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </div>
    </StyledEntry>
  );
}

const mapStateToProps = (state) => ({ user: state.user, organisation: state.organisations.urlOrganisation });
const mapDispatchToProps = (dispatch) => ({
  onSelectOrganisation: (org) => dispatch(actions.organisations.receiveUrlOrganisation(org)),
});
const EntryContainer = withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Entry));

export default EntryContainer;
