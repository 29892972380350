import { SearchParams } from "simplydo/core";
import { Other } from "simplydo/interfaces";

type IRole = Other.IRole;

export const roles = (api) => ({
  get(forType: string, forId: string, success, fail) {
    api.authenticatedRequest("GET", `/roles/${forType}/${forId}`, null, success, fail);
  },
  addPermissionsToRole(forType: string, forId: string, roleId: string, permissions: string[], success, fail) {
    api.authenticatedRequest(
      "POST",
      `/roles/${forType}/${forId}/${roleId}/permissions`,
      { permissions },
      success,
      fail,
    );
  },
  removePermissionsFromRole(forType: string, forId: string, roleId: string, permissions: string[], success, fail) {
    api.authenticatedRequest(
      "DELETE",
      `/roles/${forType}/${forId}/${roleId}/permissions`,
      { permissions },
      success,
      fail,
    );
  },
  getForUser(forType: string, forId: string, userId: string, success, fail) {
    api.authenticatedRequest("GET", `/roles/${forType}/${forId}/user/${userId}`, null, success, fail);
  },
  assignRole(forType: string, forId: string, roleId: string, userIds: string[], success, fail) {
    api.authenticatedRequest("POST", `/roles/${forType}/${forId}/${roleId}/assign`, { users: userIds }, success, fail);
  },
  unassignRole(forType: string, forId: string, roleId: string, userIds: string[], success, fail) {
    api.authenticatedRequest(
      "DELETE",
      `/roles/${forType}/${forId}/${roleId}/assign`,
      { users: userIds },
      success,
      fail,
    );
  },
  createRole(forType: string, forId: string, data: Partial<IRole>, success, fail) {
    api.authenticatedRequest("POST", `/roles/${forType}/${forId}`, data, success, fail);
  },
  deleteRole(forType: string, forId: string, roleId: string, success, fail) {
    api.authenticatedRequest("DELETE", `/roles/${forType}/${forId}/${roleId}`, null, success, fail);
  },
  updateRole(forType: string, forId: string, roleId: string, data: Partial<IRole>, success, fail) {
    api.authenticatedRequest("POST", `/roles/${forType}/${forId}/${roleId}`, data, success, fail);
  },
  addDefaultRole(forType: string, forId: string, roleId: string, success, fail) {
    api.authenticatedRequest("POST", `/roles/${forType}/${forId}/${roleId}/default`, null, success, fail);
  },
  removeDefaultRole(forType: string, forId: string, roleId: string, success, fail) {
    api.authenticatedRequest("DELETE", `/roles/${forType}/${forId}/${roleId}/default`, null, success, fail);
  },
  getPotentialRoleUsers(
    forType: string,
    forId: string,
    params: { query?: string; page?: number; limit?: number; currRoleId?: string },
    success,
    fail,
  ) {
    const queryParams = new SearchParams(params).toSafeString();
    api.authenticatedRequest("GET", `/roles/${forType}/${forId}/users${queryParams}`, null, success, fail);
  },
});
