import React, { useCallback, useState } from "react";
import { Icon, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import actions from "actions";
import styled from "styled-components";
import api from "api";
import util from "utils/utils";

const StyledFollowContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 10px 15px 10px 14px;
  border: 2px solid
    ${({ $followed, $dark }) => {
      if ($followed) {
        return "#d1d3d6";
      }
      if ($dark) {
        return "#848484";
      }
      return "rgba(240, 240, 240, 0.6)";
    }};
  background-color: ${({ $followed, $dark }) => {
    if ($followed) {
      return "#f1f3f4";
    }
    if ($dark) {
      return "#a5a5a5";
    }
    return "transparent";
  }};
  border-radius: 5px;
  i {
    font-size: 1em;
    height: 1.5em;
    margin: 0;
  }
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
  &:hover {
    ${({ $disabled }) =>
      !$disabled &&
      `
      border-color: ${({ $followed }) => ($followed ? "darkgray" : "#fff")};
    `}
  }
`;

const StyledFollowSpan = styled.span`
  display: block;
  margin-left: 5px;
  font-size: 12px;
  height: 19px;
  color: ${({ $followed, $dark }) => {
    if ($followed) {
      return "gray";
    }
    if ($dark) {
      return "#fff";
    }
    return "rgba(240, 240, 240, 1)";
  }} !important;

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      `
      color: ${({ $followed }) => ($followed ? "darkgray" : "#fff")};
    `}
  }
`;

const StyledFollowLinkContainer = styled.div`
  flex: 0.15 1 15%;
  font-size: larger;
  cursor: pointer;
`;

const StyledFollowLink = styled.div`
  color: ${({ theme }) => theme.primaryColour};
`;

const FollowChip = ({
  forType,
  forId,
  user,
  onClick,
  setChildHovered,
  asLink,
  linkFollowText,
  linkUnfollowText,
  linkUnfollowHoverText,
  dark,
  disabled,
  className,
  style,
  textStyle,
}) => {
  const [hovered, setHovered] = useState(false);
  const followed = util.userIsFollowingContext(user, forType, forId);

  const propagateHovered = useCallback(
    (isHovered) => {
      if (setChildHovered) setChildHovered(isHovered);
      setHovered(isHovered);
    },
    [setChildHovered, setHovered],
  );

  const sharedProps = {
    onMouseEnter: () => propagateHovered(true),
    onMouseLeave: () => propagateHovered(false),
    $dark: dark,
    $disabled: disabled,
    style,
    className,
  };

  const sharedTextProps = {
    $dark: dark,
    $disabled: disabled,
    style: textStyle,
  };

  if (asLink) {
    return followed ? (
      <StyledFollowLinkContainer onClick={() => onClick(false)} {...sharedProps}>
        <StyledFollowLink style={textStyle}>
          <Icon name="star" style={{ marginRight: 3 }} />{" "}
          {hovered ? linkUnfollowHoverText || "Unfollow" : linkUnfollowText || "Following"}
        </StyledFollowLink>
      </StyledFollowLinkContainer>
    ) : (
      <StyledFollowLinkContainer onClick={() => onClick(true)} {...sharedProps}>
        <StyledFollowLink style={textStyle}>
          <Icon name="star outline" style={{ marginRight: 3 }} />
          {linkFollowText || "Follow"}
        </StyledFollowLink>
      </StyledFollowLinkContainer>
    );
  }

  return followed ? (
    <StyledFollowContainer onClick={() => (disabled ? {} : onClick(false))} $followed {...sharedProps}>
      <Icon style={{ color: "gray" }} name="star" />{" "}
      <StyledFollowSpan $followed {...sharedTextProps}>
        {hovered && !disabled ? "Unfollow" : "Following"}
      </StyledFollowSpan>
    </StyledFollowContainer>
  ) : (
    <StyledFollowContainer onClick={() => (disabled ? {} : onClick(true))} {...sharedProps}>
      <Icon style={{ color: "white" }} name="star outline" />{" "}
      <StyledFollowSpan {...sharedTextProps}>Follow</StyledFollowSpan>
    </StyledFollowContainer>
  );
};

const mapDispatchToProps = (dispatch) => ({ updateUser: (user) => dispatch(actions.user.receiveUser(user)) });

const ChallengeFollowChip = ({
  challenge,
  user,
  updateUser,
  onFollow = () => {},
  onUnfollow = () => {},
  asLink,
  linkFollowText,
  linkUnfollowText,
  linkUnfollowHoverText,
  style,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const { t } = useTranslation();

  const followChallenge = useCallback(
    (status) => {
      const followObject = util.userIsFollowingContextObject(user, "challenge", challenge._id);
      if (status) {
        followObject.following = true;
        followObject.unfollowed = false;
      } else {
        followObject.following = false;
        followObject.unfollowed = true;
      }

      const oldFollowing = (user?.following || []).filter((f) => f._id !== challenge._id);
      const newFollowing = [followObject, ...oldFollowing];

      updateUser({ ...user, following: newFollowing });
      if (status) {
        onFollow();
      } else {
        onUnfollow();
      }

      api.challenges.follow(
        challenge._id,
        status,
        () => {},
        (err) => {
          if (status) {
            onUnfollow();
          } else {
            onFollow();
          }
          updateUser(user);
          toast.error(err.message);
        },
      );
    },
    [challenge, user, updateUser, onFollow, onUnfollow],
  );

  const isFollowing = util.userIsFollowingContext(user, "challenge", challenge?._id);
  const showUnfollowInfo = isFollowing && util.isChallengeAdmin(user, challenge);

  if (!user) return null;
  return (
    <Popup
      content={
        showUnfollowInfo
          ? `You cannot unfollow ${t("generic.challengeWithArticle")} you are currently managing`
          : `Follow ${t("generic.challengeWithArticle")} to receive updates on your homepage, and the ${t("generic.challenge")} will appear on your '${t("common:capitalise", { key: "generic.challenge" })}' page`
      }
      disabled={!showUnfollowInfo && isFollowing}
      trigger={
        <div>
          <FollowChip
            user={user}
            forType="challenge"
            forId={challenge._id}
            onClick={followChallenge}
            setChildHovered={setPopupOpen}
            asLink={asLink}
            linkFollowText={linkFollowText}
            linkUnfollowText={linkUnfollowText}
            linkUnfollowHoverText={linkUnfollowHoverText}
            style={style}
            disabled={showUnfollowInfo}
          />
        </div>
      }
      open={popupOpen}
    />
  );
};

const IdeaFollowChip = ({
  idea,
  user,
  updateUser,
  onFollow = () => {},
  onUnfollow = () => {},
  asLink,
  linkFollowText,
  linkUnfollowText,
  linkUnfollowHoverText,
  style,
  className,
  textStyle,
  dark,
}) => {
  const [popupOpen, setPopupOpen] = useState(false);
  const { t } = useTranslation();

  const followIdea = useCallback(
    (status) => {
      const followObject = util.userIsFollowingContextObject(user, "idea", idea._id);
      if (status) {
        followObject.following = true;
        followObject.unfollowed = false;
      } else {
        followObject.following = false;
        followObject.unfollowed = true;
      }

      const oldFollowing = (user?.following || []).filter((f) => f._id !== idea._id);
      const newFollowing = [followObject, ...oldFollowing];

      updateUser({ ...user, following: newFollowing });
      if (status) {
        onFollow();
      } else {
        onUnfollow();
      }
      api.ideas.follow(
        idea._id,
        status,
        () => {},
        (err) => {
          if (status) {
            onUnfollow();
          } else {
            onFollow();
          }
          updateUser(user);
          toast.error(err.message);
        },
      );
    },
    [idea, user, updateUser, onFollow, onUnfollow],
  );

  if (!user) return null;
  return (
    <Popup
      on="hover"
      trigger={
        <div>
          <FollowChip
            user={user}
            forType="idea"
            forId={idea._id}
            onClick={followIdea}
            setChildHovered={setPopupOpen}
            asLink={asLink}
            linkFollowText={linkFollowText}
            linkUnfollowText={linkUnfollowText}
            linkUnfollowHoverText={linkUnfollowHoverText}
            style={style}
            className={className}
            textStyle={textStyle}
            dark={dark}
          />
        </div>
      }
      open={popupOpen}
      content={`Follow ${t("generic.ideaWithArticle")} to receive notifications when somebody likes it or posts a comment. Creating or interacting with ${t("generic.ideaWithArticle")} automatically follows it for you.`}
    />
  );
};

const ChallengeFollowChipContainer = connect(
  (state) => ({ user: state.user }),
  mapDispatchToProps,
)(ChallengeFollowChip);

const IdeaFollowChipContainer = connect((state) => ({ user: state.user }), mapDispatchToProps)(IdeaFollowChip);

export { ChallengeFollowChipContainer as ChallengeFollowChip, IdeaFollowChipContainer as IdeaFollowChip };
