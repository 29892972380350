import React, { useState, useMemo } from "react";
import { Form, Modal, Label, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import util from "utils/utils";

import Tag, { TagContainer } from "components/lib/Tag";

export const TagView = ({ tags, hideView, noMargin, children, ...tagProps }) =>
  hideView ? (
    children
  ) : (
    <TagContainer $noMargin={noMargin}>
      {tags ? tags.map((t) => <Tag tag={t} key={t._id} {...tagProps} />) : null}
      {children}
    </TagContainer>
  );

const TagChooserContent = ({ tags, allTags, removeTag, addTag, showCount }) => {
  const tagIds = useMemo(() => tags.map((c) => c._id), [tags]);
  const { selectedTags = [], availableTags = [] } = allTags.reduce((p, c) => {
    p.selectedTags = p.selectedTags || [];
    p.availableTags = p.availableTags || [];

    if (tagIds.includes(c._id)) {
      p.selectedTags.push(c);
    } else {
      p.availableTags.push(c);
    }
    return p;
  }, {});
  const tagsRemaining = tags?.length ? 4 - tags.length : 4;
  return (
    <Form>
      {tags?.length > 0 && <h5>Selected tags</h5>}
      {tags?.length > 0 && (
        <Label size="tiny" color={tagsRemaining === 0 ? "orange" : undefined} basic>
          {util.pluralise(tagsRemaining, "tag", "tags")} remaining
        </Label>
      )}
      <TagView
        tags={selectedTags}
        showCount={showCount}
        noRedirect
        onClick={(t) => {
          removeTag(t);
        }}
        onRemove={(t) => {
          removeTag(t);
        }}
      />
      <h5>Tags</h5>
      <p style={{ marginBottom: 0, color: tagsRemaining === 0 && "grey" }}>Click a tag to select it.</p>
      <TagView
        tags={availableTags}
        noRedirect
        showCount={showCount}
        taggingDisabled={tagsRemaining === 0}
        onClick={(t) => {
          if (!tagIds.includes(t._id)) {
            addTag(t);
          }
        }}
      />
    </Form>
  );
};

const TagModalChooser = ({
  tags = [],
  availableTags = [],
  removeTag,
  addTag,
  user,
  trigger,
  noRedirect,
  canEdit = true,
  showCount = true,
  viewCanRemove,
  onStateChange,
  hideView = false,
  noMargin,
  isChallenge,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const triggerElement = useMemo(
    () =>
      trigger
        ? React.cloneElement(trigger, {
            onClick: () => {
              onStateChange && onStateChange(true);
              setModalOpen(true);
            },
          })
        : null,
    [trigger, onStateChange],
  );
  const allTags = availableTags || [];

  if (!canEdit) {
    return <TagView tags={tags} />;
  }

  if (!trigger) {
    return (
      <TagChooserContent
        tags={tags}
        allTags={allTags}
        removeTag={removeTag}
        addTag={addTag}
        user={user}
        showCount={showCount}
      />
    );
  }

  if (availableTags.length === 0) {
    return null;
  }
  return (
    <>
      <TagView
        tags={tags}
        noRedirect={noRedirect}
        onRemove={viewCanRemove ? removeTag : null}
        hideView={hideView}
        noMargin={noMargin}
      >
        {triggerElement}
      </TagView>

      <Modal
        open={modalOpen}
        closeOnDimmerClick
        onClose={() => {
          onStateChange && onStateChange(false);
          setModalOpen(false);
        }}
      >
        <Modal.Header>Tags</Modal.Header>
        <Modal.Content>
          <p>
            Please select up to <b>four</b> tags which best categorise your{" "}
            {isChallenge ? t("generic.challenge") : `${t("generic.idea")}`}.
          </p>
          <TagChooserContent
            tags={tags}
            allTags={allTags}
            removeTag={removeTag}
            addTag={addTag}
            user={user}
            showCount={showCount}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => {
              onStateChange && onStateChange(false);
              setModalOpen(false);
            }}
          >
            Done
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(TagModalChooser);
