const constants = {
  termsVersion: 2,
  avatars: ["1.png", "2.png", "3.png", "4.png", "5.png", "6.png", "7.png", "8.png"],
  defaultPrimaryColour: "#819df8",

  businessSectors: [
    "Agriculture and Farming",
    "Consumer Products and Retail",
    "Creative Industries",
    "Education, Recruitment and Training",
    "Environmental and Energy",
    "Financial and Professional Services",
    "Food and Hospitality",
    "I.C.T.",
    "Life Sciences",
    "Manufacturing, Advanced Materials and Engineering",
    "Property and Construction",
    "Sport, Leisure and Tourism",
    "Other",
  ],

  organisationCategories: [
    "Accountancy, banking and finance",
    "Business, consulting and management",
    "Charity and voluntary work",
    "Creative arts and design",
    "Energy and utilities",
    "Engineering and manufacturing",
    "Environment and agriculture",
    "Healthcare",
    "Hospitality and events management",
    "Information technology",
    "Law",
    "Law enforcement and security",
    "Leisure, sport and tourism",
    "Marketing, advertising and PR",
    "Media and internet",
    "Property and construction",
    "Public services and administration",
    "Recruitment and HR",
    "Retail",
    "Sales",
    "Science and pharmaceuticals",
    "Social care",
    "Teacher training and education",
    "Transport and logistics",
  ],

  supplierInnovationTags: [
    "Aerospace and Defence",
    "Agriculture, Forestry and Paper",
    "Automotive: Vehicle and Parts",
    "Banks and insurance",
    "Chemicals",
    "Construction and Materials",
    "Electronics and Electrical Equipment",
    "Food and Beverage",
    "Healthcare",
    "Industrial and General Engineering",
    "Industrial Goods and Services",
    "Industrial Metals",
    "Media",
    "Mining",
    "Oil and Gas",
    "Personal and Household Goods",
    "Pharmaceuticals and Biotechnology",
    "Retail",
    "Technology – Hardware & Software",
    "Telecommunications",
    "Transportation",
    "Travel, Leisure and Leisure Goods",
    "Utilities",
    "Other",
  ],

  employeeImprovementTags: [
    "Business Continuity",
    "CPD",
    "Charity and Voluntary",
    "Diversity and Inclusion",
    "Equipment",
    "Estates and Facilities",
    "External Communications",
    "Finance",
    "Health and Safety",
    "Internal Communications",
    "Leadership and Organisational Development",
    "User Experience",
    "Quality Improvement",
    "Recognition and Awards",
    "Sustainability",
  ],

  businessExperiences: [
    "Work experience",
    "Summer placements",
    "Year long placements",
    "Internships (paid)",
    "Internships (unpaid)",
  ],

  fileTypeIcons: {
    "archive outline": ["zip", "gz", "tar"],
    "word outline": ["doc", "docx"],
    "code outline": ["js", "python", "c", "java", "json", "xml", "html", "css", "md"],
    "image outline": ["jpg", "jpeg", "png", "gif", "bmp"],
    "pdf outline": ["pdf"],
    "video outline": ["mp4", "mkv", "wmv", "m4"],
    "powerpoint outline": ["ppt", "pptx"],
    "audio outline": ["wav", "mp3", "ogg"],
    "excel outline": ["xls", "xlsx"],
    "text outline": ["txt"],
  },

  permissions: {
    super: [
      {
        key: "super.viewDashboard",
        title: "View dashboard",
        text: "Enable the superadmin dashboard for this user. This shows/hides the 'Superadmin' link in the header.",
      },
      {
        key: "super.listOrgs",
        title: "List organisations",
        text: "Allow the user to view a list of existing organisations.",
      },
      { key: "super.createOrgs", title: "Create organisations", text: "Allow the user to create new organisations." },
      { key: "super.manageOrgs", title: "Manage organisations", text: "Allow editing existing organisations." },
      {
        key: "super.deleteOrgs",
        title: "Delete organisations",
        text: "Allow the user to delete existing organisations.",
      },
      {
        key: "super.switchOrg",
        title: "Organisation switching",
        text: "Allow the user to switch between existing organisations.",
      },
      {
        key: "super.listRecents",
        title: "View recently online",
        text: "Allow the user to view recently online users across all organisations.",
      },
      {
        key: "super.managePermissions",
        title: "Manage permissions",
        text: "Allow the user to manage super-level user permissions.",
      },
      {
        key: "super.ghostUser",
        title: "Enable user ghosting",
        text: "Allow the user to ghost as any user in the platform.",
      },
    ],
    org: [
      {
        key: "org.viewDashboard",
        title: "View admin dashboard",
        text: "Enable the admin organisation dashboard for this user. This shows/hides the 'Administration' link in the header.",
      },
      {
        key: "org.listUsers",
        title: "View user list",
        text: "Allow viewing the list of users registered with your organisation. Depends on org.viewDashboard.",
      },
      {
        key: "org.deleteUsers",
        title: "Delete users",
        text: "Allow deletion of registered users. Depends on org.viewDashboard and org.listUsers.",
      },
      {
        key: "org.uploadFiles",
        title: "Upload files",
        text: "Allow user to upload new files (and to delete their own files) in Uploads Area. Depends on org.viewDashboard.",
      },
      {
        key: "org.deleteFiles",
        title: "Delete files",
        text: "Allow user to delete all files in Uploads Area. Depends on org.viewDashboard and org.uploadFiles.",
      },
      {
        key: "org.createGroups",
        title: "Create groups",
        text: "Allow user to create new groups (and become their leader).",
      },
      {
        key: "org.manageGroups",
        title: "Manage groups",
        text: "Allow user to manage all groups as if they were the group leader.",
      },
      { key: "org.deleteGroups", title: "Delete groups", text: "Allow user to delete all groups." },
      {
        key: "org.createChallenges",
        title: "Create challenges",
        text: "Allow user to create and edit their own challenges (if restricted in organisation settings).",
      },
      {
        key: "org.manageChallenges",
        title: "Manage challenges",
        text: "Allow user to track and edit all challenges posted by users in your organisation. Depends on org.viewDashboard",
      },
      {
        key: "org.publishChallenges",
        title: "Publish challenges",
        text: "Allow user to publish and close challenges in your organisation",
      },
      {
        key: "org.deleteChallenges",
        title: "Delete challenges",
        text: "Allow the user to delete challenges posted by users in your organisation. Depends on org.viewDashboard and org.manageChallenges",
      },
      {
        key: "org.manageProjectBoards",
        title: "Manage project boards",
        text: "Allow the user to create and manage project boards in their organisation.",
      },
      {
        key: "org.createAnnouncements",
        title: "Create announcements",
        text: "Allow the user to create and manage their own announcements. Depends on org.viewDashboard.",
      },
      {
        key: "org.manageAnnouncements",
        title: "Manage announcements",
        text: "Allow the user to manage and delete all announcements. Depends on org.viewDashboard and org.createAnnouncements.",
      },
      {
        key: "org.changeSettings",
        title: "Change settings",
        text: "Allow the user to change core organisaion settings (e.g. branding, sign-on, and security). Depends on org.viewDashboard.",
      },
      {
        key: "org.managePermissions",
        title: "Manage user permissions",
        text: "Allow the user to manage organisation-level user permissions. Depends on org.viewDashboard and org.listUsers and org.manageUsers.",
      },
      {
        key: "org.supportUsers",
        title: "Support users",
        text: "Receive messages from non-administrators for help and support purposes.",
      },
      {
        key: "org.innovationIntelligence",
        title: "Innovation intelligence",
        text: "Allow the user to access the innovation intelligence area, in any group in their organisation.",
      },
      {
        key: "org.ipManagement",
        title: "IP Management",
        text: "Allow the user to sign IP statements for challenges in this organisation, on behalf of the organisation.",
      },
      {
        key: "org.viewEdi",
        title: "View EDI",
        text: `Allow the user to view data contained in EDI sections.`,
      },
    ],
    group: [
      {
        key: "group.leader",
        title: "Group Leader",
        text: "Administrate group, including changing settings and members.",
      },
    ],
  },
  legacyStampMapper: {
    green_apple: "green",
    full_moon: "yellow",
    large_orange_diamond: "orange",
    red_circle: "red",
    aquarius: "purple",
    large_blue_circle: "blue",
    ribbon: "pink",
    green: "green_apple",
    yellow: "full_moon",
    orange: "large_orange_diamond",
    red: "red_circle",
    purple: "aquarius",
    blue: "large_blue_circle",
    pink: "ribbon",
  },
  subscriptions: [
    {
      type: "general",
      title: "subscriptions.general.title",
      key: "directMessages",
      text: "subscriptions.directMessages.text",
    },
    {
      type: "general",
      title: "subscriptions.announcements.title",
      key: "announcements",
      text: "subscriptions.announcements.text",
    },
    {
      type: "general",
      title: "subscriptions.invitations.title",
      key: "invitations",
      text: "subscriptions.invitations.text",
    },
    {
      type: "general",
      title: "subscriptions.userInteraction.title",
      key: "userInteraction",
      text: "subscriptions.userInteraction.text",
    },
    {
      type: "general",
      title: "subscriptions.achievements.title",
      key: "achievements",
      text: "subscriptions.achievements.text",
    },
    {
      type: "ideas",
      title: "subscriptions.ideaUpdates.title",
      key: "ideaUpdates",
      text: "subscriptions.ideaUpdates.text",
    },
    {
      type: "ideas",
      title: "subscriptions.ideaManagement.title",
      key: "ideaManagement",
      text: "subscriptions.ideaManagement.text",
    },
    {
      type: "challenges",
      title: "subscriptions.challengeUpdate.title",
      key: "challengeUpdates",
      text: "subscriptions.challengeUpdates.text",
    },
    {
      type: "challenges",
      title: "subscriptions.challengeManagement.title",
      key: "challengeManagement",
      text: "subscriptions.challengeManagement.text",
    },
    {
      type: "challenges",
      title: "subscriptions.challengeDailyUpdates.title",
      key: "challengeDailyUpdates",
      text: "subscriptions.challengeDailyUpdates.text",
    },
    {
      type: "challenges",
      title: "subscriptions.challengeWeeklyUpdates.title",
      key: "challengeWeeklyUpdates",
      text: "subscriptions.challengeWeeklyUpdates.text",
    },
    {
      type: "groups",
      title: "subscriptions.groupDiscussions.title",
      key: "groupDiscussions",
      text: "subscriptions.groupDiscussions.text",
    },
    {
      type: "ideas",
      title: "subscriptions.followedIdeaWeeklyUpdates.title",
      key: "followedIdeaWeeklyUpdates",
      text: "subscriptions.followedIdeaWeeklyUpdates.text",
    },
  ],

  enabledFeatures: [
    {
      key: "challenges",
      title: "Create challenges",
      forType: "challenge",
    },
    {
      key: "createExternalChallenges",
      title: "Create external challenges",
      forType: "challenge",
    },
    {
      key: "receiveExternalChallenges",
      title: "Receive external challenges",
      forType: "challenge",
    },
    {
      key: "ipManagement",
      title: "Challenge IP management",
      forType: "challenge",
    },
    {
      key: "receiveReferrals",
      title: "Receive user referred from another organisation",
      forType: "general",
    },
    {
      key: "innovationIntelligence",
      title: "Global search and dashboards",
      forType: "innointel",
    },
    {
      key: "innovationIntelligenceLocalSearch",
      title: "Local Search",
      forType: "innointel",
      label: "Beta",
    },
    {
      key: "ideaImpacts",
      title: "Idea impacts",
      forType: "challenge",
    },
    {
      key: "globalCreditsafe",
      title: "Use SimplyDo's Creditsafe login",
      forType: "integrations",
    },
    {
      key: "simplyCrm",
      title: "Enable Simply CRM integration",
      forType: "integrations",
    },
    {
      key: "ideaBusinessProfiles",
      title: "Idea business profiles",
      forType: "challenge",
    },
    {
      key: "openAI",
      title: "Open AI features",
      forType: "integrations",
    },
    {
      key: "6cs",
      title: "6Cs challenges",
      forType: "challenge",
      showWarning: "This format type is largely deprecated and should not be used for new challenges.",
    },
    {
      key: "allowPublicChallenges",
      title: "Allow public challenges",
      forType: "general",
    },
    {
      key: "ideaSpreadsheetView",
      title: `Idea spreadsheet view`,
      forType: "general",
    },
    {
      key: "customPages",
      title: "Create richtext custom pages",
      forType: "general",
    },
    {
      key: "userGhosting",
      title: "User ghosting (Organisation-level user ghosting)",
      forType: "general",
      showWarning: "This should be used with caution, as it can be used to impersonate users.",
    },
    {
      key: "conditionalFields",
      title: "Conditional fields in idea formats",
      forType: "challenge",
      label: "Beta",
    },
    {
      key: "ideaMerging",
      title: "Idea merging",
      forType: "challenge",
      label: "Beta",
    },
    {
      key: "googleHelper",
      title: "Google Helper FAB",
      forType: "innointel",
      label: "Beta",
    },
    {
      key: "bypassSSOProtection",
      title: "Bypass SSO login protection",
      forType: "general",
      showWarning:
        "This should be used with caution, as it bypasses SSO login protection which enables users to take control of previously created accounts if they control the email address.",
    },
    {
      key: "dynamics365",
      forType: "integrations",
      label: "Beta",
      showWarning: "Do not enable for production use.",
      title: "Dynamics 365 integration",
    },
    {
      key: "challengePlanner",
      title: "Challenge planner",
      forType: "integrations",
      label: "Beta",
    },
  ] as const,
  superadminTags: [
    {
      key: "internalInnovation",
      title: "Employee led",
    },
    {
      key: "externalInnovation",
      title: "Supplier led",
    },
    {
      key: "police",
      title: "Police",
    },
    {
      key: "nhs",
      title: "NHS",
    },
    {
      key: "defence",
      title: "Defence",
    },
    {
      key: "government",
      title: "Government",
    },
    {
      key: "housing",
      title: "Housing",
    },
    {
      key: "academia",
      title: "Academia",
    },
    {
      key: "other",
      title: "Other",
    },
  ],

  emailRegex:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line no-useless-escape

  defaultSubscriptions: {
    announcements: ["email"],
    directMessages: ["email", "push"],
    invitations: ["email"],
    mentions: ["email", "push"],
    userInteraction: ["email", "push"],
    achievements: ["push"],
    challengeUpdates: ["email", "push"],
    challengeManagement: ["email", "push"],
    ideaUpdates: ["email", "push"],
    ideaManagement: ["email", "push"],
    challengeDailyUpdates: [],
    challengeWeeklyUpdates: ["email", "push"],
    groupDiscussions: ["push"],
  },

  productTours: [
    // Tour info:
    // - After adding a tour, ensure its enabled in the settings for organisation
    /*
    {
      key: 'example',
      value: 'example',
      title: 'Example title', // This is displayed to admins when enabling/disabling the tour
      description: 'Explain to users what a challenge is, and show where they can create an idea', // Same as above
      steps: [ // Any number of steps can be provided
        {
          target: '.challenge-tour-step-1', // CSS classname of the element to show the tooltip over
          content: 'This is the challenge header', // The content of the tooltip. This can be a string OR any react node
          disableBeacon: true, // 99% of the time you want to set disableBeacon: true - this ensures the tooltip automatically opens without user input,
          //
          // The following custom parameters are added to allow custom actions to take place.
          // If you provide a prop `customFunctions` to the ProductTour function, which is an object which keys as functionIds, then by specifing the functionId here they be called when that step is run _before_ any content is shown
          //
          customTourFunctionId: 'functionIdHere'
        },
      ],
    }
    */
    {
      key: "assessment",
      value: "assessment",
      title: "productTours.assessment.title",
      description: "productTours.assessment.description",
      steps: [
        {
          target: ".assessment-tour-step-1",
          content: "productTours.assessment.steps.1.content",
          disableBeacon: true,
          customTourFunctionId: "closeIdea",
        },
        {
          target: ".assessment-tour-step-2",
          content: "productTours.assessment.steps.2.content",
          disableBeacon: true,
        },
        {
          target: ".assessment-tour-step-5",
          content: "productTours.assessment.steps.3.content",
          disableBeacon: true,
        },
        {
          target: ".assessment-tour-step-3",
          content: "productTours.assessment.steps.4.content",
          disableBeacon: true,
        },
        {
          placement: "center",
          content: "productTours.assessment.steps.5.content",
          target: "body",
          disableBeacon: true,
          customTourFunctionId: "openIdea",
        },
        {
          target: ".assessment-tour-step-4",
          content: "productTours.assessment.steps.6.content",
          disableBeacon: true,
        },
        {
          target: ".assessment-tour-step-6",
          content: "productTours.assessment.steps.7.content",
          customTourFunctionId: "closeIdea",
          disableBeacon: true,
        },
      ],
    },
    {
      key: "assessmentHomepage",
      value: "assessmentHomepage",
      title: "productTours.assessmentHomepage.title",
      description: "productTours.assessmentHomepage.description",
      steps: [
        {
          placement: "center",
          target: "body",
          content: "productTours.assessmentHomepage.steps.1.content",
          disableBeacon: true,
        },
        {
          target: ".assessment-homepage-tour-step-1",
          content: "productTours.assessmentHomepage.steps.2.content",
          disableBeacon: true,
        },
        {
          target: ".assessment-homepage-tour-step-2",
          content: "productTours.assessmentHomepage.steps.3.content",
          disableBeacon: true,
        },
      ],
    },
    {
      key: "innovationIntelligenceOverview",
      value: "innovationIntelligenceOverview",
      title: "productTours.innovationIntelligenceOverview.title",
      description: "productTours.innovationIntelligenceOverview.description",
      steps: [
        {
          placement: "center",
          target: "body",
          content: "productTours.innovationIntelligenceOverview.steps.1.content",
        },
        {
          target: ".innovation-intelligence-overview-tour-step-1",
          content: "productTours.innovationIntelligenceOverview.steps.2.content",
          disableBeacon: true,
        },
        {
          target: ".innovation-intelligence-overview-tour-step-2",
          content: "productTours.innovationIntelligenceOverview.steps.3.content",
          disableBeacon: true,
        },
        {
          target: ".innovation-intelligence-overview-tour-step-3",
          content: "productTours.innovationIntelligenceOverview.steps.4.content",
          disableBeacon: true,
        },
      ],
    },
    {
      key: "innovationIntelligenceSearch",
      value: "innovationIntelligenceSearch",
      title: "productTours.innovationIntelligenceSearch.title",
      description: "productTours.innovationIntelligenceSearch.description",
      steps: [
        {
          placement: "center",
          target: "body",
          content: "productTours.innovationIntelligenceSearch.steps.1.content",
        },
        {
          target: ".innovation-intelligence-search-tour-step-1",
          content: "productTours.innovationIntelligenceSearch.steps.2.content",
          disableBeacon: true,
          customTourFunctionId: "exampleSearch",
        },
      ],
    },
    {
      key: "orgSwitcher",
      value: "orgSwitcher",
      title: "productTours.orgSwitcher.title",
      description: "productTours.orgSwitcher.description",
      steps: [
        {
          target: ".org-switcher-tour-step-1",
          content: "productTours.orgSwitcher.steps.1.content",
          disableBeacon: true,
        },
      ],
    },
    {
      key: "projectBoardOnIdeasPage",
      value: "projectBoardOnIdeasPage",
      title: "productTours.projectBoardOnIdeasPage.title",
      description: "productTours.projectBoardOnIdeasPage.description",
      steps: [
        {
          target: ".project-board-ideas-page-1",
          content: "productTours.projectBoardOnIdeasPage.steps.1.content",
          disableBeacon: true,
        },
      ],
    },
  ],

  accreditations: [
    { key: "cyberEssentialsPlus", text: "Cyber Essentials Plus" },
    { key: "cyberEssentials", text: "Cyber Essentials" },
    { key: "ISO27001", text: "ISO 27001" },
    { key: "ISO9001", text: "ISO 9001" },
    { key: "FENSA", text: "FENSA" },
    { key: "SSIP", text: "SSIP" },
    { key: "MCS", text: "MCS" },
    { key: "PAS2030", text: "PAS 2030" },
    { key: "livingWageEmployer", text: "Living Wage Employer" },
    { key: "other", text: "Other" },
  ],

  challengeDeadlineMomentFormat: "DD/MM/YYYY HH:mm",
};

export default constants;
