import React, { useCallback, useMemo } from "react";
import { Form, Segment, Message } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { useAppSelector } from "store";
import util from "utils/utils";
import api from "api";
import toast from "react-hot-toast";
import { datePlusDays } from "./Calendar";

const Settings = ({ challenge, calendar, setCalendar }) => {
  const user = useAppSelector((state) => state.user);
  const canManageChallenge = util.canManageChallenge(user, challenge);
  const challengeId = challenge._id;

  const from = useMemo(() => (calendar?.start ? new Date(calendar.start) : new Date()), [calendar]);
  const nextMonth = useMemo(() => new Date(from), [from]);
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  const to = useMemo(() => (calendar?.end ? new Date(calendar.end) : nextMonth), [calendar, nextMonth]);

  // Calculate min and max dates for the date pickers
  const toMinDate = datePlusDays(from, 1);
  const fromMaxDate = datePlusDays(to, -1);

  const setFrom = useCallback(
    (value: string) => {
      api.challenges.updateCalendar(
        challengeId,
        { start: value },
        () => {
          setCalendar((prev) => ({ ...prev, start: value }));
        },
        (error) => {
          toast.error(error.message);
        },
      );
    },
    [challengeId, setCalendar],
  );

  const setTo = useCallback(
    (value: string) => {
      api.challenges.updateCalendar(
        challengeId,
        { end: value },
        () => {
          setCalendar((prev) => ({ ...prev, end: value }));
        },
        (error) => {
          toast.error(error.message);
        },
      );
    },
    [challengeId, setCalendar],
  );
  const openDateIssue = useMemo(() => {
    return challenge?.openDate < calendar?.start || challenge?.openDate > calendar?.end;
  }, [challenge, calendar?.start, calendar?.end]);

  const closeDateIssue = useMemo(() => {
    return challenge?.deadline < calendar?.start || challenge?.deadline > calendar?.end;
  }, [challenge, calendar?.start, calendar?.end]);

  return (
    <Segment>
      <p>Manage and configure the planner.</p>
      {openDateIssue || closeDateIssue ? (
        <Message
          warning
          size="small"
          icon="warning sign"
          header="Important"
          content={`The challenge ${openDateIssue && closeDateIssue ? "open/close" : closeDateIssue ? "close" : "open"} date is currently outside of the planner date range.`}
          style={{ marginBottom: 20 }}
        />
      ) : null}
      {canManageChallenge ? (
        <Form>
          <Form.Group>
            <Form.Field>
              <label>The calendar will span from:</label>
              <DateInput
                value={from.toISOString().split("T")[0]}
                dateFormat="YYYY-MM-DD"
                onChange={(e, { value }) => setFrom(value)}
                maxDate={fromMaxDate.toISOString().split("T")[0]}
              />
            </Form.Field>

            <Form.Field>
              <label>to:</label>
              <DateInput
                value={to.toISOString().split("T")[0]}
                dateFormat="YYYY-MM-DD"
                onChange={(e, { value }) => setTo(value)}
                minDate={toMinDate.toISOString().split("T")[0]}
              />
            </Form.Field>
          </Form.Group>
        </Form>
      ) : null}
    </Segment>
  );
};

export default Settings;
