import React from "react";
import { Menu, Label, Image } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import util from "utils/utils";
import { EmptyBox } from "components/lib/UI";

const MessageTrayMessages = ({ messageThreads = [], selectMessageThread, user, t }) => {
  if (!messageThreads.length) {
    return (
      <Menu.Item>
        <EmptyBox>
          <h5>{t("messages.empty")}</h5>
        </EmptyBox>
      </Menu.Item>
    );
  }
  const numberOfMessagesToShow = window?.innerHeight ? (0.95 * window?.innerHeight - 100) / 85 : 9;
  return (
    <>
      {messageThreads.slice(0, numberOfMessagesToShow).map((thread) => {
        if (thread.lastMessageAt || thread.participants.length > 2) {
          return (
            thread.participantUsers.length > 0 && (
              <Menu.Item
                fitted
                link
                key={thread._id}
                onClick={() => selectMessageThread(thread)}
                style={{
                  borderBottom: "1px solid",
                  borderColor: "#E9EBEE",
                  padding: ".833em 1em",
                  borderRadius: 0,
                  marginTop: 0,
                  marginBottom: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 10,
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {thread?.participantUsers?.length === 2 && (
                      <Image avatar src={util.getChatAvatar(user, thread)} style={{ marginRight: 5 }} />
                    )}
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: 15,
                        display: "block",
                        margin: 0,
                      }}
                    >
                      {util.getChatName(user, thread)}
                    </span>
                  </div>
                  {thread.lastMessageSnippet && thread.lastMessageAt && (
                    <span>{moment(new Date(thread.lastMessageAt)).fromNow()}</span>
                  )}
                </div>
                {thread.seenBy && thread.seenBy.indexOf(user && user._id) === -1 && (
                  <Label size="tiny" color="teal">
                    New
                  </Label>
                )}
                {thread.lastMessageSnippet || thread.lastMessageAt ? (
                  <span>
                    {thread.lastMessageUser === (user && user._id) && "You: "}{" "}
                    {thread.lastMessageSnippet || moment(new Date(thread.lastMessageAt)).fromNow()}
                  </span>
                ) : null}
              </Menu.Item>
            )
          );
        }
        return null;
      })}
    </>
  );
};

export default withTranslation()(MessageTrayMessages);
