import actions from "actions";
import { SearchParams } from "simplydo/core";
import { store } from "store";

export const users = (api) => ({
  create(email, firstName, lastName, sendEnrolmentEmail, success, fail) {
    api.authenticatedRequest(
      "POST",
      "/users",
      {
        email,
        firstName,
        lastName,
        sendEnrolmentEmail,
      },
      success,
      fail,
    );
  },
  createMultiple(data, success, fail) {
    api.authenticatedRequest("POST", "/users", data, success, fail);
  },
  get(id, success, fail) {
    api.authenticatedRequest("GET", `/users/${id}`, null, success, fail);
  },
  delete(id, data, success, fail) {
    api.authenticatedRequest("DELETE", `/users/${id}`, data, success, fail);
  },
  update(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/users/${id}`, data, success, fail);
  },
  getNotificationPreferences(id, success, fail) {
    api.authenticatedRequest("GET", `/users/${id}/notifications`, null, success, fail);
  },
  updateNotificationPreferences(id, types, methods, success, fail) {
    api.authenticatedRequest("PUT", `/users/${id}/notifications`, { methods, types }, success, fail);
  },
  unsubscribeFromNotification(id, type, token, success, fail) {
    api.maybeAuthenticatedRequest("DELETE", `/users/${id}/notifications/${type}`, { token }, success, fail);
  },
  updateFeatureFlags(id, featureFlags, success, fail) {
    api.authenticatedRequest("PUT", `/users/${id}/features`, { featureFlags }, success, fail);
  },
  updateTablePreferences(id, key, preferences, success, fail) {
    api.authenticatedRequest("PUT", `/users/${id}/tables/${key}`, preferences, success, fail);
  },
  updateSpreadsheetPreferences(id, key, preferences, success, fail) {
    api.authenticatedRequest("PUT", `/users/${id}/spreadsheets/${key}`, preferences, success, fail);
  },
  updateLanguagePreferences(id, lang, success, fail) {
    api.authenticatedRequest("PUT", `/users/${id}/language/${lang}`, null, success, fail);
  },
  me(utm, success, fail) {
    api.authenticatedRequest("GET", `/users/me${utm ? `?utm-link=${utm}` : ""}`, null, success, fail);
  },
  ideas(id, challenge, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/users/${id}/ideas${challenge ? `?challenge=${challenge}` : ""}`,
      null,
      success,
      fail,
    );
  },
  challenges(id, data, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/users/${id}/challenges?query=${data.query}&page=${data.page || 1}&limit=${data.limit || 10}`,
      null,
      success,
      fail,
    );
  },
  feed(data, success, fail) {
    const { page, limit, include } = data;
    api.authenticatedRequest(
      "GET",
      `/users/feed${page ? `?page=${page}` : ""}${limit ? `&limit=${limit}` : ""}${include ? `&include=${include.join()}` : ""}`,
      null,
      success,
      fail,
    );
  },
  dashboard(success, fail) {
    api.authenticatedRequest("GET", "/users/dashboard", null, success, fail);
  },
  suggestions(success, fail) {
    api.authenticatedRequest("GET", "/users/suggestions", null, success, fail);
  },
  refer(id, code, data, success, fail) {
    api.authenticatedRequest("PUT", `/users/${id}/organisations/${code}/refer`, data, success, fail);
  },
  bulkRefer(code, data, success, fail) {
    api.authenticatedRequest("PUT", `/users/organisations/${code}/refer`, data, success, fail);
  },
  getChallenges(id, params, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/users/${id}/challenges?withProjectBoard=${!!params.withProjectBoard}&query=${params.query}&page=${params.page || 1}&limit=${params.limit || 10}`,
      null,
      success,
      fail,
    );
  },
  getAchievements(id, success, fail) {
    api.authenticatedRequest("GET", `/users/${id}/achievements`, null, success, fail);
  },
  getOrganisations(id, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/users/${id}/organisations`,
      null,
      (data) => success && success(data.organisations),
      fail,
    );
  },
  switchOrganisation(id, orgId, success, fail) {
    api.authenticatedRequest("PUT", `/users/${id}/organisations/${orgId}`, null, success, fail);
  },
  leaveOrganisation(id, orgId, success, fail) {
    api.authenticatedRequest("DELETE", `/users/${id}/organisations/${orgId}`, null, success, fail);
  },
  getRecentlyActive(page, term, success, fail) {
    api.authenticatedRequest("GET", `/users/recent?page=${page || 1}&term=${term || ""}`, null, success, fail);
  },
  getSessions(id, success, fail) {
    api.authenticatedRequest("GET", `/users/${id}/sessions`, null, success, fail);
  },
  removeService(id, service, success, fail) {
    api.authenticatedRequest("DELETE", `/users/${id}/services/${service}`, null, success, fail);
  },
  ghost(id, success, fail) {
    api.authenticatedRequest(
      "POST",
      `/users/${id}/ghost`,
      null,
      ({ token }) => {
        api.revertToken = api.token;
        api.auth.localLogin(token, true); // Login but don't store token

        store.dispatch(actions.user.ghost(id));
        success && success();
      },
      fail,
    );
  },
  unGhost(success, fail) {
    const ghostToken = api.token;
    api.token = api.revertToken;
    const state = store.getState();
    api.authenticatedRequest(
      "DELETE",
      `/users/${state.user.ghostingAs}/ghost`,
      { ghostToken },
      () => {
        api.revertToken = null;
        api.auth.localLogin(api.token, true);
        store.dispatch(actions.user.unGhost());
        success && success();
      },
      fail,
    );
  },
  getMicrosoftGroups(id, success, fail) {
    api.authenticatedRequest("GET", `/users/${id}/microsoft/groups`, null, success, fail);
  },
  getPowerbiWorkspaces(id, contextId = "userIntegration", success, fail) {
    api.authenticatedRequest("GET", `/users/${id}/powerbi/workspaces?contextId=${contextId}`, null, success, fail);
  },
  updateFlag(id, data, success, fail) {
    api.authenticatedRequest("PUT", `/users/${id}/flags`, data, success, fail);
  },
  heartbeat(id, success, fail) {
    api.authenticatedRequest("POST", `/users/${id}/heartbeat`, null, success, fail);
  },
  getFollowing(id, params, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/users/${id}/following?query=${params.query || ""}&showDeleted=${params.showDeleted ? "true" : "false"}&page=${params.page || 1}&showUnfollowed=${params.showUnfollowed ? "true" : "false"}`,
      null,
      success,
      fail,
    );
  },
  findByEmail(params, success, fail) {
    const queryParams = new SearchParams(params).toSafeString();
    api.authenticatedRequest("GET", `/users/find${queryParams}`, null, success, fail);
  },
  createProfile(data, success, fail) {
    api.authenticatedRequest("PUT", "/users/ideas/profiles", data, success, fail);
  },
  updateProfile(id, data, success, fail) {
    api.authenticatedRequest("POST", `/users/ideas/profiles/${id}`, data, success, fail);
  },
  getProfile(id, success, fail) {
    api.authenticatedRequest("GET", `/users/ideas/profiles/${id}`, null, success, fail);
  },
  deleteProfile(id, success, fail) {
    api.authenticatedRequest("DELETE", `/users/ideas/profiles/${id}`, null, success, fail);
  },
  getExistingProfiles(success, fail) {
    api.authenticatedRequest("GET", "/users/ideas/profiles", null, success, fail);
  },
  getIdeaBusinessProfileCompanies(params, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/users/ideas/profiles/companies?query=${params.query || ""}&page=${params.page || 1}&limit=${params.limit || 10}`,
      null,
      success,
      fail,
    );
  },
  getIdeaBusinessProfileAdvancedDetails(companyName, success, fail) {
    api.authenticatedRequest(
      "GET",
      `/users/ideas/profiles/companies/advanced?query=${companyName || ""}`,
      null,
      success,
      fail,
    );
  },
  generateIdeaBusinessProfileCreditReport(id, success, fail) {
    api.authenticatedRequest("POST", `/users/ideas/profiles/${id}/creditreport`, null, success, fail);
  },
  generateBusinessDescription(data: { companyName: string; websiteUrl: string }, success, fail) {
    api.authenticatedRequest("POST", `/users/ideas/profiles/generate_description`, data, success, fail);
  },
  getIntegrations(id, service, success, fail) {
    api.authenticatedRequest("GET", `/users/${id}/integrations/${service}`, null, success, fail);
  },
  addIntegration(id, service, success, fail) {
    api.authenticatedRequest("POST", `/users/${id}/integrations/${service}`, null, success, fail);
  },
  deleteIntegration(id, service, integrationId, success, fail) {
    api.authenticatedRequest("DELETE", `/users/${id}/integrations/${service}/${integrationId}`, null, success, fail);
  },
});
