import React, { useContext, useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import actions from "actions";
import { withTranslation } from "react-i18next";
import styled, { ThemeContext } from "styled-components";
import { Container, Dropdown, Grid, Icon, Button, Transition, Popup, Modal } from "semantic-ui-react";
import toast from "react-hot-toast";
import api from "api";
import util from "utils/utils";
import constants from "utils/constants";

import EmojiChooser from "components/lib/Emoji/EmojiChooser";
import Emoji from "components/lib/Emoji/Emoji";
import { Banner, PlaceholderItem, PlaceholderStack } from "components/lib/UI";
import { ImageChooser } from "components/lib/Choosers";
import UserChooser from "components/lib/Choosers/UserChooser";
import { UserChip, AnonymousChip, IdeaBusinessProfileChip } from "components/lib/Chips";
import { IdeaLikeHandler } from "components/lib/Ideas";
import PinIdeaHelper from "components/lib/Ideas/PinIdeaHelper";
import IdeaTags from "./Tags";
import IdeaPDFExport from "components/lib/PDFExport/IdeaPDFExport";

const OptionsMenu = styled(Dropdown.Menu)`
  width: 300px;
  min-width: 0 !important;
  .labels {
    display: flex;
    flex-wrap: wrap;
    .label {
      width: 50%;
    }
    .label:hover {
      background-color: #e9ebee95;
      cursor: pointer;
    }
  }
`;

const LabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 10px;
`;

const StyledLaneInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
  > i.icon {
  }
  > div {
    display: flex;
    flex-direction: column;
    > span {
      display: block;
      margin: 0;
    }
    .title-info {
      font-size: 0.8em;
    }
    .lane-name {
      font-weight: bold;
      font-size: 1.2em;
      color: white;
      text-decoration-line: underline;
    }
  }
`;

export const LaneInfo = ({ board, idea, style }) => {
  if (idea?._id && board?.laneObject?.name) {
    const currentUrl = window.location.href;
    if (currentUrl.indexOf(`/challenges/${idea.challenge}/board`) > -1) return null;
    if (board.laneObject?.laneCount === 0) return null;
    return (
      <StyledLaneInfo style={style}>
        <Icon name="compass" size="large" />
        <div>
          <span className="title-info">PROJECT LANE</span>
          <Link className="lane-name" to={`/challenges/${idea.challenge}/board?idea=${idea._id}`}>
            {board.laneObject.name}
          </Link>
        </div>
      </StyledLaneInfo>
    );
  }
  return null;
};

const IdeaHeader = ({
  idea = {},
  loading,
  isEditing,
  updateIdea,
  updateFilters,
  user,
  t,
  deleteIdea,
  onFollow,
  leaveIdea,
}) => {
  const theme = useContext(ThemeContext);
  const [labelChooserOpen, setLabelChooserOpen] = useState(false);
  const ideaId = idea?._id;
  const userId = user?._id;
  const ideaChallenge = idea?.challenge;
  const ideaTemplate = idea?.ideaTemplate;

  const navigate = useNavigate();

  const canPresent = useMemo(() => {
    if (ideaTemplate?.body) {
      return ideaTemplate.body.filter((s) => s.include).length === 6;
    }
    return false;
  }, [ideaTemplate]);

  const joinIdea = useCallback(() => {
    api.ideas.addCollaborator(
      ideaId,
      userId,
      ({ authors, collaborators }) => {
        updateIdea(authors, ["authors"], true);
        updateIdea(collaborators, ["collaborators"], true);
      },
      (err) => toast.error(err.message),
    );
  }, [ideaId, userId, updateIdea]);

  const findIdeasWithTag = useCallback(
    (value) => {
      updateFilters({ ideaFilter: value });
    },
    [updateFilters],
  );

  const voteForIdea = useCallback(
    (addVote) => {
      api.ideas.vote(
        ideaId,
        addVote,
        ({ addedVotesFor, removedVotesFor }) => {
          const newIdea = { votedFor: idea.votedFor, voteCount: idea.voteCount || 0 };

          if (!newIdea.voteCount) newIdea.voteCount = 0;
          if (addedVotesFor.indexOf(ideaId) > -1) {
            onFollow("challenge", ideaChallenge);
            newIdea.votedFor = true;
            newIdea.voteCount += 1;
          }
          if (removedVotesFor.indexOf(ideaId) > -1) {
            newIdea.votedFor = false;
            newIdea.voteCount -= 1;
          }
          updateIdea(newIdea.voteCount, ["voteCount"], true);
          updateIdea(newIdea.votedFor, ["votedFor"], true);
        },
        (err) => {
          toast.error(err.message);
        },
      );
    },
    [ideaId, updateIdea, ideaChallenge, idea.votedFor, idea.voteCount, onFollow],
  );

  const pinIdea = useCallback(() => {
    api.ideas.updateStatus(
      ideaId,
      { isPinned: !idea.isPinned },
      (ideaStatus) => {
        updateIdea(ideaStatus.isPinned, ["isPinned"], true);
      },
      (err) => toast.error(err.message),
    );
  }, [ideaId, idea.isPinned, updateIdea]);

  const labelIdea = useCallback(
    (label, isApplied) => {
      const stamps = idea.stamps || [];
      let existingIndex = stamps.indexOf(label);
      if (label in constants.legacyStampMapper) {
        existingIndex = stamps.indexOf(constants.legacyStampMapper[label]);
        if (existingIndex < 0) {
          existingIndex = stamps.indexOf(label);
        }
      }
      if (isApplied && existingIndex === -1) stamps.push(label);
      if (!isApplied && existingIndex > -1) stamps.splice(existingIndex, 1);
      api.ideas.updateStatus(ideaId, { stamps }, () => {
        updateIdea(stamps, ["stamps"], true);
      });
    },
    [ideaId, idea.stamps, updateIdea],
  );

  const addAssessors = useCallback(
    (assessors) => {
      const existingUsers = assessors.filter((existingAssessor) => !existingAssessor.isEmailInvitee);
      if (existingUsers.length === 0) {
        return;
      }
      api.challenges.assignAssessors(
        ideaChallenge,
        [ideaId],
        existingUsers.map((u) => u._id),
        ({ ideas }) => {
          const updatedIdea = ideas[0];
          updateIdea(updatedIdea.assessment, ["assessment"], true);
          toast.success("Assessors updated");
        },
        (err) => toast.error(err.message),
      );
    },
    [ideaChallenge, ideaId, updateIdea],
  );

  const createChallengeFromIdea = useCallback(() => {
    api.challenges.createFromIdea(
      { idea: ideaId },
      (data) => {
        navigate(`/challenges/${data.challenge._id}`);
        toast.success("Challenge created");
      },
      (err) => toast.error(err.message),
    );
  }, [ideaId, navigate]);

  const setCoverImage = useCallback(
    (filename, _name, url) => {
      updateIdea(url, ["coverImageUrl"], true);
      if (!url) {
        toast.success("Cover image removed.");
      }
      updateIdea(filename, ["coverImage"]);
      if (!url) {
        updateIdea("https://cdn.simplydo.co.uk/images/idea.png", ["coverImageUrl"], true);
      }
    },
    [updateIdea],
  );

  if (isEditing) return null;

  const board = idea.projectManagement?.boards?.filter((b) => b.forId === idea.challenge)[0];
  return (
    <Transition visible={!isEditing} animation="slide down" duration={400}>
      <Banner
        marginless
        image={util.mixinCssUrlFallback(util.ideaCoverImage(idea, null), util.ideaCoverImage())}
        style={{ zIndex: 200 }}
      >
        <div className="overlay" />
        <div className="overlay-body">
          <Modal
            mountNode={document.getElementById("semantic-modal-mount-node")}
            basic
            open={labelChooserOpen}
            onClose={() => setLabelChooserOpen(false)}
            closeIcon
          >
            <div
              style={{ display: "flex", justifyContent: "center" }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <EmojiChooser
                onComplete={(em) => labelIdea(em, true)}
                existing={idea.stamps}
                onRemoveExisting={(em) => labelIdea(em, false)}
              />
            </div>
          </Modal>
          <Container style={{ marginBottom: -20 }}>
            <Grid stackable>
              <Grid.Column computer={16}>
                {user && (
                  <IdeaTags
                    idea={idea}
                    findIdeasWithTag={findIdeasWithTag}
                    updateIdea={updateIdea}
                    isMobile={theme.sizes.isMobile}
                  />
                )}

                {loading ? (
                  <PlaceholderStack gap={10} style={{ marginBottom: 30, marginTop: 30 }}>
                    <PlaceholderItem height={30} />
                    <PlaceholderItem height={10} />
                  </PlaceholderStack>
                ) : (
                  <>
                    <h1 style={{ margin: "0 0 10px 0" }}>
                      {idea.name ||
                        `${t("common:capitalise", { key: "generic.ideas" })} ${idea.ownerChallenge?.name ? `for ${idea.ownerChallenge?.name}` : ""}`}
                    </h1>

                    <h5 style={{ fontSize: 14, marginTop: 0, marginBottom: board?.laneObject?.name ? 10 : 0 }}>
                      <span style={{ textTransform: "uppercase" }}>{t("generic.idea")}</span>
                      {idea.ownerChallenge && (
                        <span style={{ color: "white" }}>
                          {" "}
                          For{" "}
                          <Link style={{ color: "white" }} to={`/challenges/${idea.challenge}`}>
                            {idea.ownerChallenge?.name}
                          </Link>
                        </span>
                      )}
                    </h5>
                  </>
                )}
              </Grid.Column>
              <Grid.Column computer={8} textAlign="left" verticalAlign="bottom">
                {loading ? (
                  <PlaceholderItem height={15} style={{ width: "50%" }} />
                ) : (
                  <div style={{ color: "white" }}>
                    {"Created by "}
                    {(!idea.authors || idea.authors.length === 0) && <AnonymousChip />}
                    {idea.lockedOwnerIdeaBusinessProfile ? (
                      <IdeaBusinessProfileChip inverted ideaBusinessProfile={idea.lockedOwnerIdeaBusinessProfile} />
                    ) : null}
                    {(idea.authors || []).map((a) => (
                      <UserChip key={a._id} inverted style={{ margin: 5 }} user={a} />
                    ))}
                  </div>
                )}
              </Grid.Column>
              <Grid.Column computer={8} textAlign="right" verticalAlign="bottom">
                {user && (
                  <>
                    {!idea.isSubmitted &&
                    util.canEditIdea(user, idea) &&
                    (!idea.coverImage || idea.coverImage.indexOf("random-unsplash-") === 0) ? (
                      <ImageChooser
                        forType="idea"
                        forId={ideaId}
                        onComplete={setCoverImage}
                        trigger={
                          <Button size="small" content={t("ideas.header.coverImage")} primary icon="file image" />
                        }
                      />
                    ) : null}

                    {idea.ownerChallenge && idea.ownerChallenge.votingVisibility === "users" ? (
                      <IdeaLikeHandler
                        idea={idea}
                        challenge={idea.ownerChallenge}
                        onLikeIdea={(_ideaId, addVote) => voteForIdea(addVote)}
                        trigger={
                          <Button
                            color={idea.votedFor ? "green" : "grey"}
                            icon="thumbs up"
                            style={{ marginLeft: 5 }}
                            size="tiny"
                            label={
                              idea.ownerChallenge.voteCountVisibility === "users"
                                ? { basic: true, pointing: "left", content: idea.voteCount || 0 }
                                : null
                            }
                          />
                        }
                      />
                    ) : null}

                    {util.canManageChallenge(user, idea.ownerChallenge) ? (
                      <Popup
                        content={
                          <div>
                            <p>
                              <b>{idea.isPinned ? t("ideas.options.pin.unPin") : t("ideas.options.pin.challenge")}</b>
                            </p>

                            <PinIdeaHelper challenge={idea.ownerChallenge} />
                          </div>
                        }
                        trigger={
                          <Button
                            size="small"
                            primary={idea.isPinned}
                            basic={!idea.isPinned}
                            icon="thumbtack"
                            onClick={pinIdea}
                          />
                        }
                      />
                    ) : null}
                    {canPresent && !theme.sizes.isMobile && (
                      <Popup
                        content="Presentation"
                        trigger={<Button size="small" icon="play" as={Link} to={`/ideas/${idea._id}/present`} />}
                      />
                    )}

                    {!loading ? (
                      <IdeaPDFExport
                        trigger={<Button size="small" icon="file pdf" basic />}
                        ideas={[idea]}
                        challenge={idea.ownerChallenge}
                      />
                    ) : null}

                    {util.canManageChallenge(user, idea.ownerChallenge) && (
                      <Dropdown
                        icon={null}
                        direction="left"
                        trigger={<Button basic size="small" icon="chevron down" data-testid="idea-options-dropdown" />}
                      >
                        <OptionsMenu>
                          {idea.user !== user._id && (
                            <Dropdown.Item
                              icon={`user ${util.canEditIdea(user, idea) ? "times" : "plus"}`}
                              content={
                                util.canEditIdea(user, idea)
                                  ? `Leave ${t("generic.idea")}`
                                  : `Collaborate on this ${t("generic.idea")}`
                              }
                              onClick={util.canEditIdea(user, idea) ? leaveIdea : joinIdea}
                            />
                          )}
                          {idea.ownerChallenge?.ideaTemplateData?.assessmentEnabled && (
                            <UserChooser
                              trigger={<Dropdown.Item icon="user plus" content="Assign assessors" />}
                              enabledFeatures={{ search: true, invite: true }}
                              onComplete={(users) => {
                                const emailUsers = users.filter((u) => u.isEmailInvitee);
                                if (emailUsers.length) {
                                  api.invitations.createBulk(
                                    {
                                      invitees: emailUsers.map((u) => u._id),
                                      invitationType: "email",
                                      forType: "ideaAssessor",
                                      forId: idea.challenge,
                                      forIdeas: [idea._id],
                                    },
                                    () => {
                                      toast.success(`Invitation${emailUsers.length > 1 ? "s" : ""} sent`);
                                    },
                                  );
                                }
                                addAssessors(users);
                              }}
                              clearOnComplete
                              externalInvitesInAudience={idea?.ownerChallenge?.visibility?.organisations?.length > 0}
                              forId={idea?.ownerChallenge?._id}
                              audienceWarningText={
                                <span>
                                  The users below are currently outside of the {t("generic.challenge")} audience. Users
                                  not in the
                                  {t("generic.challenge")} audience will be unable to view the {t("generic.challenge")}{" "}
                                  and their assessments. To ensure assessors can access their assessments, add them to
                                  the{" "}
                                  <a href={`/challenges/${idea?.ownerChallenge?._id}/settings/audience`}>
                                    {t("generic.challenge")} audience
                                  </a>{" "}
                                  after making this assignment.
                                </span>
                              }
                              forType="assessor"
                              instructions={`Choose users to assign as assessors of the selected ${t("generic.ideas")}. Assessors will be able to find the ${t("generic.ideas")} on their 'Assessments' tab within this ${t("generic.challenge")}.`}
                              confirm="Assign these assessors"
                              searchFunction={(term, cb, fail) =>
                                api.search.challengeOwner(idea.challenge, term, ({ users }) => cb(users), fail)
                              }
                            />
                          )}
                          {idea.challengeCreation && (
                            <Dropdown.Item icon="target" onClick={() => createChallengeFromIdea()}>
                              Create {t("generic.challengeWithArticle")} from this {t("generic.idea")}
                            </Dropdown.Item>
                          )}

                          <Dropdown.Divider />

                          {labelIdea && (
                            <Dropdown.Item
                              icon="paint brush"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setLabelChooserOpen(true);
                              }}
                              content="Apply a label"
                            />
                          )}

                          <LabelsContainer>
                            {idea.stamps && idea.stamps.length
                              ? idea.stamps.map((stamp) => (
                                  <Popup
                                    key={stamp}
                                    on="hover"
                                    content="Remove label"
                                    trigger={
                                      <div
                                        className="emoji"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                        }}
                                      >
                                        <Emoji
                                          emoji={{ id: stamp }}
                                          size={20}
                                          onClick={(em) => labelIdea(em.id, false)}
                                        />
                                      </div>
                                    }
                                  />
                                ))
                              : null}
                          </LabelsContainer>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            icon="trash"
                            content={`Delete ${t("generic.idea")}`}
                            onClick={() => deleteIdea(true)}
                          />
                        </OptionsMenu>
                      </Dropdown>
                    )}
                  </>
                )}
              </Grid.Column>
            </Grid>
          </Container>
        </div>
      </Banner>
    </Transition>
  );
};

const mapStateToProps = (state) => ({ user: state.user, challenge: state.challenge });

const mapDispatchToProps = (dispatch) => ({
  updateFilters: (data) => dispatch(actions.challenges.updateIdeaFilters(data)),
  onFollow: (context, id) => dispatch(actions.user.onFollow(context, id)),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(IdeaHeader));
