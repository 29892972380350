import React, { useMemo } from "react";
import { Dropdown, Image, Icon, Button } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";
import styled from "styled-components";

const BusinessProfileDropDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .company-info {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
    span {
      display: block;
      margin: 0;
    }
    .description {
      opacity: 0.7;
    }
  }
`;

type IProfileSelector = {
  attachedBusinessProfile: Other.IIdeaBusinessProfile;
  loadingExistingProfiles: boolean;
  existingProfiles: Other.IIdeaBusinessProfile[];
  createNewCompany: () => void;
  attachBusinessProfile: (ideaBusinessProfile: string) => void;
};

const BusinessProfileDropdownItem = ({
  businessProfile,
  onClick,
  attachedBusinessProfile,
}: {
  businessProfile: Other.IIdeaBusinessProfile;
  onClick?: (ideaBusinessProfile: string) => void;
  attachedBusinessProfile?: Other.IIdeaBusinessProfile;
}) => {
  const { profile, _id } = businessProfile;
  const { name, imageUrl, address = {} } = profile;
  const { country, city } = address;
  return (
    <Dropdown.Item
      key={_id}
      value={_id}
      selected={attachedBusinessProfile?._id === _id}
      onClick={!(attachedBusinessProfile?._id === _id) ? () => onClick(_id) : () => {}}
      content={
        <BusinessProfileDropDownContainer>
          {imageUrl ? (
            <Image avatar src={imageUrl} style={{ maxHeight: "fit-content", objectFit: "cover" }} />
          ) : (
            <Icon name="building" size="large" />
          )}
          <div className="company-info">
            <span>{name}</span>
            {country || city ? (
              <span className="description">{`${city || ""}${city && country ? ", " : ""}${country}`}</span>
            ) : null}
          </div>
        </BusinessProfileDropDownContainer>
      }
    />
  );
};

const BusinessProfileSelector = ({
  attachedBusinessProfile,
  loadingExistingProfiles,
  existingProfiles,
  createNewCompany,
  attachBusinessProfile,
}: IProfileSelector) => {
  const dropdownOptions = useMemo(
    () =>
      [
        // attachedBusinessProfile || null,
        ...existingProfiles.filter((profile) => profile._id !== attachedBusinessProfile?._id),
      ].filter((dropdownItem) => !!dropdownItem),
    [attachedBusinessProfile, existingProfiles],
  );

  if (!existingProfiles.length) {
    return (
      <Button
        size="large"
        fluid
        icon="add"
        content="Create a new organisation profile"
        onClick={() => createNewCompany()}
      />
    );
  }
  return (
    <Dropdown
      selection
      options={dropdownOptions}
      fluid
      style={{ marginBottom: 5 }}
      placeholder="Select a organisation profile.."
      loading={loadingExistingProfiles}
      // @ts-ignore
      text={
        attachedBusinessProfile ? (
          <BusinessProfileDropdownItem businessProfile={attachedBusinessProfile} onClick={() => {}} />
        ) : null
      }
    >
      <Dropdown.Menu>
        {dropdownOptions.map((businessProfile: Other.IIdeaBusinessProfile) => (
          <BusinessProfileDropdownItem
            key={businessProfile._id}
            businessProfile={businessProfile}
            onClick={(ideaBusinessProfile) => attachBusinessProfile(ideaBusinessProfile)}
            attachedBusinessProfile={attachedBusinessProfile}
          />
        ))}
        <Dropdown.Item
          content={
            <BusinessProfileDropDownContainer>
              <Icon name="add" size="large" />
              <div className="company-info">
                <span>Create a new organisation profile</span>
              </div>
            </BusinessProfileDropDownContainer>
          }
          onClick={() => createNewCompany()}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default BusinessProfileSelector;
