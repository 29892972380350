import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Container, Message, Divider, Form, Input, Table, Button, Icon, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { FakeLink } from "components/lib/UI";
import toast from "react-hot-toast";
import util from "utils/utils";
import actions from "actions";
import api from "api";

const isValidPhoneNumber = (number) => import("libphonenumber-js").then((lib) => lib.isValidNumber(number));

const formatNumber = (number) => {
  let formattedNumber = number;
  if (number.indexOf("07") === 0) {
    formattedNumber = formattedNumber.replace("07", "+447");
  }
  const regex = /[^0-9]/g;
  const cleanNumber = formattedNumber.replace(regex, "");
  "".slice();
  if (formattedNumber.startsWith("+")) {
    return `+${cleanNumber.slice(0, 2)} ${cleanNumber.slice(2)}`;
  }
  return cleanNumber;
};

export function UserSettingsCommunication({ t, user, onEmailAddressChanged, onPhoneNumbersChanged }) {
  const [newEmail, setNewEmail] = useState("");
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [formattedNewNumber, setFormattedNewNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [verificationEntry, setVerificationEntry] = useState(null);
  const [verifyCode, setVerifyCode] = useState("");
  const [verificationLoading, setVerificationLoading] = useState(false);
  const [verificationError, setVerificationError] = useState(null);
  const [validNumber, setValidNumber] = useState(false);

  useEffect(() => {
    const newNumber = formatNumber(newPhoneNumber);
    setFormattedNewNumber(newNumber);
    isValidPhoneNumber(newNumber).then((valid) => {
      setValidNumber(valid);
    });
  }, [newPhoneNumber]);

  const phoneNumbers = useMemo(() => user.phoneNumbers || [], [user.phoneNumbers]);

  const addNumber = useCallback(() => {
    util
      .confirm(
        "Confirm phone number",
        `We are about to send a verification code to '${formattedNewNumber}'. Please make sure you entered it correctly.`,
      )
      .then(() => {
        api.auth.addPhoneNumber(formattedNewNumber, (newNumber) => {
          onPhoneNumbersChanged([...user.phoneNumbers, newNumber]);
          setNewPhoneNumber("");
          setVerificationEntry(newNumber);
        });
      });
  }, [formattedNewNumber, onPhoneNumbersChanged, user.phoneNumbers]);

  const verify = useCallback(() => {
    setVerificationLoading(true);
    api.auth.verifyPhoneNumber(
      verificationEntry.number,
      verifyCode,
      () => {
        setVerificationLoading(false);
        setVerificationEntry(null);
        setVerifyCode("");
        toast.success("Phone number verified!");

        const index = phoneNumbers.findIndex((pn) => pn.number === verificationEntry.number);
        phoneNumbers.splice(index, 1, {
          number: verificationEntry.number,
          verified: true,
        });
        onPhoneNumbersChanged([...phoneNumbers]);
      },
      (e) => {
        setVerificationLoading(false);
        toast.error("Verification failed.");
        setVerificationError(e.message);
      },
    );
  }, [verificationEntry, verifyCode, onPhoneNumbersChanged, phoneNumbers]);

  const save = useCallback(() => {
    if (!setNewEmail) {
      setError("Please enter an email address");
      return;
    }
    setError("");
    setLoading(true);
    api.auth.updateEmailAddress(
      newEmail,
      ({ email }) => {
        setLoading(false);
        setNewEmail("");
        onEmailAddressChanged(email);
        toast("Email address successfully updated");
      },
      (err) => {
        setLoading(false);
        setError(err.message);
      },
    );
  }, [newEmail, onEmailAddressChanged]);

  const sendNewCode = useCallback(() => {
    if (!verificationEntry) {
      return;
    }
    util
      .confirm(
        "Generate new code",
        `We are about to send a new verification code to '${verificationEntry.number}'. This will invalidate the previous code. Proceed?`,
      )
      .then(() => {
        api.auth.resendPhoneNumberVerification(
          verificationEntry.number,
          () => {
            toast.success("Sent new verification token.");
          },
          () => {
            toast.error("Failed to generate new verification token, please contact an administrator.");
          },
        );
      });
  }, [verificationEntry]);

  return (
    <Container>
      <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={!!verificationEntry}>
        <Modal.Header>
          <Icon name="lock" style={{ paddingRight: "5px" }} />
          Verify phone number
          <br />
        </Modal.Header>
        <Modal.Content>
          <p>
            To complete the verification process, please enter the six digit one-time code which was sent to "
            {verificationEntry?.number}".
          </p>
          <p>
            Please be aware that verification codes are only valid for one hour. If you missed yours or if it expired
            please{" "}
            <a style={{ cursor: "pointer" }} href="#a" onClick={sendNewCode}>
              click here
            </a>{" "}
            to receive a new code for this number.
          </p>
          {verificationError ? <Message error>{verificationError}</Message> : null}
          <div style={{ textAlign: "center" }}>
            <Input
              onKeyDown={(e) => {
                e.keyCode === 13 && verify();
              }}
              onChange={(e) => setVerifyCode(e.target.value)}
              value={verifyCode}
              placeholder="123456"
              size="large"
              autoFocus
            />
          </div>
          <div style={{ textAlign: "center", marginTop: 5 }}>
            <FakeLink withBorder onClick={() => sendNewCode()}>
              Resend verification code
            </FakeLink>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t("generic.cancel")}
            onClick={() => {
              setVerificationEntry(null);
              setVerifyCode("");
            }}
          />
          <Button
            primary
            content="Continue"
            onClick={() => verify()}
            loading={verificationLoading}
            disabled={verifyCode.length !== 6}
          />
        </Modal.Actions>
      </Modal>
      {!user.ssoUser && (
        <>
          <h3>Email address</h3>
          <h4>Use this page to change the email address associated with your {util.appName()} account.</h4>
          <p>
            We use your email address in order to identify you when you login, and to send you notifications about key
            updates to your {t("generic.challenges")} and {t("generic.ideas")}.
          </p>
          <Divider hidden />

          <h4>Change your registered email address by completing the form below</h4>
          {error && <Message warning>{error}</Message>}
          <Form>
            <Form.Field>
              <label htmlFor="current-email-input">Current email address</label>
              <Form.Input id="current-email-input" style={{ width: 300 }} value={user?.emails[0]?.address} readOnly />
            </Form.Field>
            <Form.Field>
              <label htmlFor="new-email-input">New email address</label>
              <Form.Input
                id="new-email-input"
                style={{ width: 300 }}
                value={newEmail}
                placeholder="jane@example.com"
                onChange={(e) => setNewEmail(e.target.value)}
              />
            </Form.Field>
            <Form.Button loading={loading} primary content="Change email address" onClick={save} />
          </Form>

          <Divider section />
        </>
      )}

      <h3>Phone numbers</h3>
      <p>Add a new number.</p>
      <Table fixed basic="very" padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Phone number</Table.HeaderCell>
            <Table.HeaderCell>Verification status</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {user?.phoneNumbers?.map((entry, i) => (
            <Table.Row key={entry.number}>
              <Table.Cell>{entry.number}</Table.Cell>
              <Table.Cell>
                {entry.verified ? (
                  <>
                    <Icon name="check circle" style={{ paddingRight: 5 }} />
                    Verified
                  </>
                ) : (
                  <>
                    <>
                      <Icon name="close" style={{ paddingRight: 5 }} />
                      Not verified
                    </>
                    <Button
                      style={{ marginLeft: 10 }}
                      compact
                      size="small"
                      basic
                      content={"Verify now"}
                      onClick={() => {
                        setVerificationEntry(entry);
                      }}
                    />
                  </>
                )}
              </Table.Cell>
              <Table.Cell textAlign="right">
                <Button
                  compact
                  size="small"
                  basic
                  color="red"
                  content={t("generic.remove")}
                  onClick={() => {
                    util
                      .confirm("Remove number", `Are you sure you want to remove '${formatNumber(entry.number)}'?`)
                      .then(() => {
                        api.auth.removePhoneNumber(entry.number, () => {
                          user.phoneNumbers.splice(i, 1);
                          onPhoneNumbersChanged([...user.phoneNumbers]);
                        });
                      });
                  }}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Form>
        <Form.Input
          type="text"
          placeholder="Enter your phone number"
          value={newPhoneNumber}
          onChange={(event) => {
            setNewPhoneNumber(event.target.value);
          }}
          error={
            newPhoneNumber === "" || validNumber
              ? null
              : {
                  content: "Please enter a valid phone number",
                  pointing: "above",
                }
          }
          action={{
            disabled: !validNumber,
            content: "Add number",
            icon: "angle double right",
            labelPosition: "right",
            onClick: () => addNumber(),
          }}
        />
      </Form>
    </Container>
  );
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  onEmailAddressChanged: (email) => dispatch(actions.user.updateEmailAddress(email)),
  onPhoneNumbersChanged: (phoneNumbers) => dispatch(actions.user.updatePhoneNumbers(phoneNumbers)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserSettingsCommunication));
