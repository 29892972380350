import { isElementDomNode, omitExtraAttributes } from "remirror";
import { LinkExtension } from "remirror/extensions";

const hostNameSplit = window.location.host.split(".");
const withoutSubdomain = hostNameSplit.slice(1).join(".") || window.location.host;
const localRegex = new RegExp(
  `((^| )(http://www.https://www.|http://|https://)(([a-z]{1,15}.)?${withoutSubdomain}(/| |$)))`,
  "gi",
);

// https://github.com/remirror/remirror/issues/836
export class CustomLinkExtension extends LinkExtension {
  onView(view) {
    this.view = view;
    this.updateOnPaste = [];
  }

  createMarkSpec(extra) {
    const AUTO_ATTRIBUTE = "data-link-auto";
    return {
      // We allow the atom attributes for @ mentions here
      attrs: {
        ...extra.defaults(),
        href: {},
        auto: { default: false },
      },
      inclusive: false,
      parseDOM: [
        {
          tag: "a[href]",
          getAttrs: (node) => {
            if (!isElementDomNode(node)) {
              return false;
            }

            const href = node.getAttribute("href");
            const auto = node.hasAttribute(AUTO_ATTRIBUTE) || this.options.autoLinkRegex.test(node.textContent ?? "");
            // The priority on extensions does not appear to be implemented correctly right now
            // To avoid links taking higher priority over mentions, we also include the mention attributes
            // If they have been provided with the link manually, we allow them to pass through sanitisation
            const defaultLinkAttrs = { ...extra.parse(node), href, auto };
            const mentionId = node.getAttribute("data-mention-atom-id");
            if (mentionId) {
              return false;
            }
            return defaultLinkAttrs;
          },
        },
      ],
      toDOM: (node) => {
        const { auto: _, ...rest } = omitExtraAttributes(node.attrs, extra);
        const auto = node.attrs.auto ? { [AUTO_ATTRIBUTE]: "" } : {};
        const rel = "noopener noreferrer nofollow";
        const attrs = {
          ...extra.dom(node),
          ...rest,
          rel,
          target: "_blank",
          ...auto,
        };
        if (node.attrs["data-mention-atom-id"]) {
          return false;
        }

        return ["a", attrs, 0];
      },
    };
  }

  // https://github.com/remirror/remirror/blob/next/packages/%40remirror/extension-link/src/link-extension.ts
  createPlugin() {
    return {
      props: {
        handleDOMEvents: {
          click: (view, evt) => {
            const { onExternalLink } = view.manager.settings;

            if (view.props.editable()) {
              evt.preventDefault();
              return true;
            }
            if (!onExternalLink) {
              return false;
            }

            const coordinates = view.posAtCoords({ left: evt.clientX, top: evt.clientY });
            if (!coordinates) {
              return false;
            }
            const node = view.docView.node.nodeAt(coordinates.pos);
            if (!node) {
              return false;
            }
            const storedMark = node.marks.find((m) => m.type.name === this.type.name);
            if (!storedMark) {
              return false;
            }

            const isLocal = storedMark.attrs.href.match(localRegex);
            if (isLocal) {
              return false;
            }

            evt.preventDefault();
            onExternalLink(storedMark.attrs.href);
            return true;
          },
        },
      },
      appendTransaction: (transactions, _oldState, state) => {
        const transactionsWithLinkMeta = transactions.filter((tr) => !!tr.getMeta(this.name));

        if (transactionsWithLinkMeta.length === 0) {
          return;
        }

        transactionsWithLinkMeta.forEach((tr) => {
          const trMeta = tr.getMeta(this.name);

          if (trMeta.command === "updateLink") {
            const { range, attrs } = trMeta;
            const { selection, doc } = state;
            const meta = {
              range,
              selection,
              doc,
              attrs,
            };

            const { from, to } = range ?? selection;
            this.options.onUpdateLink(doc.textBetween(from, to), meta);
          }
        });
      },
    };
  }
}
