import React from "react";
import { Menu, Image, Loader, Button, Icon } from "semantic-ui-react";
import { UserChip } from "components/lib/Chips";
import moment from "moment";
import util from "utils/utils";
import styled from "styled-components";

const RecipientContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  div {
    margin: 5px;
  }
`;

const Recipient = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 10px 25px;
  cursor: pointer;
`;

const MessageTraySearch = ({
  results,
  selectRecipients,
  searching,
  addRecipient,
  removeRecipient,
  recipients,
  createChat,
}) => {
  if (searching)
    return (
      <Menu.Item>
        <Loader />
      </Menu.Item>
    );
  if (!results) return <Menu.Item header>No users found</Menu.Item>;
  return (
    <>
      {results.slice(0, 9).map((u) => (
        <Menu.Item key={u._id} style={{ display: "flex" }} onClick={() => addRecipient(u)}>
          <Image avatar src={util.avatarUrl(u)} />
          <div
            style={{
              marginLeft: 10,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div>
              <b>{u.profile.fullName}</b>
              {util.getProfileJob(u) ? <span style={{ color: "gray" }}> {util.getProfileJob(u)}</span> : null}
            </div>
            {u.lastSeenAt ? (
              <span>
                Last seen
                {moment(u.lastSeenAt).fromNow()}
              </span>
            ) : null}
          </div>
        </Menu.Item>
      ))}
      {recipients.length ? (
        <>
          <RecipientContainer>
            {recipients.map((u) => (
              <Recipient key={u._id} onClick={() => removeRecipient(u)}>
                <UserChip vertical disableLink user={u} style={{ margin: 0 }} />
                <Icon name="remove" style={{ position: "absolute" }} />
              </Recipient>
            ))}
          </RecipientContainer>
          {recipients.length > 1 ? (
            <Button
              primary
              icon="send"
              rounded
              secondary
              fluid
              onClick={() => createChat(recipients.map((u) => u._id))}
            >
              Start group chat
            </Button>
          ) : (
            <Button primary icon="send" rounded fluid onClick={() => selectRecipients(recipients[0])}>
              Message user
            </Button>
          )}
        </>
      ) : null}
    </>
  );
};

export default MessageTraySearch;
