import React, { Component } from "react";
import styled from "styled-components";
import util from "utils/utils";

const StyledBanner = styled.div`
  background-image: ${({ $image, theme }) =>
    $image
      ? `url(${$image})`
      : `linear-gradient(to bottom right, ${theme.secondaryColour}, ${util.adjustColour(theme.secondaryColour, 0.5)})`};
  background-size: cover;
  background-position: center center;
  margin-top: ${({ theme }) => (theme.sizes.isMobile ? "-10px" : "-30px")};
  padding-top: ${({ theme }) => (theme.sizes.isMobile ? 10 : 50)}px;
  padding-bottom: ${({ theme }) => (theme.sizes.isMobile ? 0 : 30)}px;
  margin-bottom: ${({ theme, $marginless }) => {
    if ($marginless) {
      return 0;
    }
    if (theme.sizes.isMobile) {
      return 15;
    }
    return 30;
  }}px;
  position: relative;
  z-index: 10;
  .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 11;
  }
  .overlay-body {
    position: relative;
    z-index: 12;
  }
  .ui.button.basic {
    background: white !important;
    &:hover {
      background: white !important;
    }
  }
  h1 {
    color: #fff !important;
    font-size: 2.5em;
  }
  p {
    color: #e2e7f3;
    font-size: 1.5em;
  }
  h5,
  h2,
  h3 {
    color: #fff;
  }
`;

const StyledCompactBanner = styled.div`
  width: 100%;
  background-image: ${({ $image, theme }) =>
    $image
      ? `url(${$image})`
      : `linear-gradient(to bottom right, ${theme.secondaryColour}, ${util.adjustColour(theme.secondaryColour, 0.5)})`};
  background-size: cover;
  margin-bottom: 20px;
  background-position: center center;
  box-shadow: ${({ theme }) => theme.boxShadow};
  border-radius: 5px;
  padding: 5px;
  position: relative;
  z-index: 10;
  .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 11;
  }
  .overlay-body {
    position: relative;
    z-index: 12;
  }
  .ui.button.basic {
    background: white !important;
    &:hover {
      background: white !important;
    }
  }
  h1 {
    color: #fff !important;
    font-size: 2em;
  }
  p {
    color: #e2e7f3;
    font-size: 1em;
  }
  h5,
  h2,
  h3 {
    color: #fff;
  }
`;

class Banner extends Component {
  render() {
    const { image, style, marginless, children, compact, isMobile } = this.props;
    const BannerComponent = compact ? StyledCompactBanner : StyledBanner;
    return (
      <BannerComponent $image={image} $marginless={marginless} style={style} $isMobile={isMobile}>
        {children}
      </BannerComponent>
    );
  }
}

export default Banner;
