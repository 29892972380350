import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { Icon, Modal, Button, Header, Divider, Popup, Input, Message, Loader, Dimmer } from "semantic-ui-react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import api from "api";
import util from "utils/utils";
import useTheme from "theme/useTheme";

import { FakeLink, TooltipButton } from "components/lib/UI";
import TermsCheckbox from "components/lib/Entry/TermsCheckbox";
import IdeaTags from "components/ideas/Tags";

const StyledButton = styled(Button)`
  min-width: max-content;
`;

const IdeaSubmissionNextStep = ({ to, icon, text, onClick }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <div
      style={{
        flex: 1,
        marginBottom: 25,
        fontSize: "larger",
        cursor: "pointer",
      }}
    >
      <div style={{ color: theme.primaryColour }} onClick={to ? () => navigate(to) : onClick}>
        <Icon style={{ paddingRight: 3 }} name={icon} />
        {text}
      </div>
    </div>
  );
};

const SubmitIdeaButton = ({
  user,
  updateIdea,
  onSubmit = () => {},
  scrollToRequired,
  isSaving,
  style,
  size = "tiny",
  idea = { ideaTemplate: { body: [] } },
  unsaved,
  submitRef,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [accountCreated, setAccountCreated] = useState(false);
  const [accountLoading, setAccountLoading] = useState(false);
  const [accountExists, setAccountExists] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [anonymousSubmissionModalOpen, setAnonymousSubmissionModalOpen] = useState(false);
  let authToken = null;
  if (util.localStorageIsSupported()) {
    authToken = localStorage.getItem(`ideaAuthToken:${idea._id}`);
  }
  const [nameIdeaModelOpen, setNameIdeaModelOpen] = useState(false);
  const [loadingAIIdeaName, setLoadingAIIdeaName] = useState(false);
  const [createAccountSubmitModalOpen, setCreateAccountSubmitModalOpen] = useState(false);
  const { ownerChallenge, tags = [] } = idea;
  const ideaId = idea?._id;
  const theme = useTheme();

  const isSubmissible = idea?.submissibilityStatus?.isSubmissible;
  const incorrectFields = idea?.submissibilityStatus?.incorrectFields;

  const checkName = useCallback(
    () =>
      new Promise((resolve) => {
        if (
          !idea.isSubmitted &&
          (!idea?.name ||
            !idea?.name ||
            idea?.name ===
              `${t("common:capitalise", { key: "generic.idea" })} for ${idea.ownerChallenge && idea.ownerChallenge?.name}`)
        ) {
          if (nameIdeaModelOpen === false) {
            setNameIdeaModelOpen(true);
          } else {
            toast.error("You must enter a name");
          }
        } else {
          setNameIdeaModelOpen(false);
          if (idea.isSubmitted) {
            resolve({ nameChanged: false });
          } else {
            updateIdea(idea?.name, ["name"], true, () => resolve({ nameChanged: true }));
          }
        }
      }),
    [idea, nameIdeaModelOpen, updateIdea, t],
  );

  const getAIIdeaName = useCallback(() => {
    setLoadingAIIdeaName(true);
    api.ideas.createIdeaNameWithAI(
      ideaId,
      ({ success, name: aiName }) => {
        if (success) {
          updateIdea(aiName, ["name"]);
        }
        setLoadingAIIdeaName(false);
      },
      () => {
        setLoadingAIIdeaName(false);
      },
    );
  }, [ideaId, updateIdea]);

  useEffect(() => {
    if (nameIdeaModelOpen && util.organisationFeaturesEnabled(user, ["openAI"])) {
      getAIIdeaName();
    }
  }, [getAIIdeaName, nameIdeaModelOpen, user]);

  const checkFields = useCallback(() => {
    if (!isSubmissible && incorrectFields?.length) {
      scrollToRequired(incorrectFields[0].section);
      toast.error(`The field "${incorrectFields[0].title}" requires action before submission`);
    }
  }, [incorrectFields, isSubmissible, scrollToRequired]);

  const submitLogic = useCallback(
    (toSubmit, emailToRegister, bypassConfirmation = false) => {
      const func = () => {
        const upd = { isSubmitted: toSubmit, authToken };
        if (emailToRegister) {
          upd.registerUser = true;
          upd.email = emailToRegister;
          upd.agreedTermsVersion = termsAgreed;
        }
        setIsSubmitting(true);
        api.ideas.updateStatus(
          ideaId,
          upd,
          (newStatus) => {
            if (emailToRegister) {
              setAccountCreated(true);
              setEmailAddress("");
            }
            if (newStatus.isSubmitted) {
              if (
                !user &&
                idea.ownerChallenge?.visibility?.publicIdeaCreation &&
                idea.ownerChallenge?.visibility?.publicIdeaCreationRequirement === "accountCreation"
              ) {
                setCreateAccountSubmitModalOpen(true);
              } else {
                setSubmitModalOpen(true);
              }
              onSubmit(toSubmit);
              setModalOpen(false);
              setAnonymousSubmissionModalOpen(false);
              setIsSubmitting(false);
            } else {
              toast.success(t("ideas.submit.confirm.unsubmitSuccess"));
              onSubmit(toSubmit);
              setModalOpen(false);
              setAnonymousSubmissionModalOpen(false);
              setIsSubmitting(false);
            }
          },
          (err) => {
            if (err.status === 400) {
              updateIdea({ ...idea.ownerChallenge, stage: "closed" }, ["ownerChallenge"], true);
            }
            toast.error(err.message);
            setIsSubmitting(false);
          },
        );
      };
      if (!bypassConfirmation) {
        util
          .confirm(
            toSubmit ? t("ideas.submit.confirm.submit.title") : t("ideas.submit.confirm.withdraw.title"),
            toSubmit ? t("ideas.submit.confirm.submit.info") : t("ideas.submit.confirm.withdraw.info"),
          )
          .then(
            () => {
              func();
            },
            () => {},
          )
          .catch(() => {});
      } else func();
    },
    [t, user, ideaId, idea, onSubmit, termsAgreed, authToken, updateIdea],
  );

  const submitIdea = useCallback(
    (toSubmit, emailToRegister) => {
      setModalOpen(false);
      checkName()
        .then(({ nameChanged }) => submitLogic(toSubmit, emailToRegister, nameChanged))
        .catch(() => {});
    },
    [setModalOpen, checkName, submitLogic],
  );

  const handleSubmission = useCallback(() => {
    if (!user && ownerChallenge?.visibility?.publicIdeaCreationRequirement !== "accountCreation")
      setAnonymousSubmissionModalOpen(true);
    else if (
      !ownerChallenge ||
      !ownerChallenge.tagBeforeSubmission ||
      tags.length > 0 ||
      (ownerChallenge?.tagList ?? []).length === 0
    )
      submitIdea(true);
    else setModalOpen(true);
  }, [ownerChallenge, submitIdea, user, tags]);

  const checkForAccount = useCallback(() => {
    setAccountLoading(false);
    setAccountCreated("");
    api.auth.verifyAccount(
      ownerChallenge.organisation,
      emailAddress,
      null,
      ({ exists }) => {
        if (exists) {
          setAccountExists(true);
        } else {
          submitIdea(true, emailAddress);
        }
      },
      () => {
        setAccountLoading(false);
      },
    );
  }, [emailAddress, ownerChallenge, submitIdea]);

  const handleClick = useCallback(() => {
    const onComplete = isSubmissible ? () => handleSubmission() : () => checkFields();
    onComplete();
  }, [checkFields, handleSubmission, isSubmissible]);

  const defaultName = `${t("common:capitalise", { key: "generic.idea" })} for ${idea.ownerChallenge?.name}`;
  const isDefaultName = idea && idea.name === defaultName;
  return (
    <>
      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        style={{ backgroundColor: "#fff" }}
      >
        <Modal.Header>Ready to submit your {t("generic.idea")}?</Modal.Header>
        <Modal.Content>
          <p>{t("ideas.statusBar.tag.info")}</p>
          <IdeaTags idea={idea} updateIdea={updateIdea} isSubmission={true} />
        </Modal.Content>
        <Modal.Actions>
          <Button content={t("generic.cancel")} onClick={() => setModalOpen(false)} />
          <Button content={t("generic.submit")} primary icon="paper plane" onClick={() => submitIdea(true)} />
        </Modal.Actions>
      </Modal>

      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={anonymousSubmissionModalOpen}
        onClose={() => setAnonymousSubmissionModalOpen(false)}
      >
        <Header as="h2">Ready to submit your {t("generic.idea")}?</Header>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: 20,
          }}
        >
          <>
            {idea.ownerChallenge?.visibility?.publicIdeaCreationRequirement === "requireEmail" ? (
              <h3>Enter your email address to submit your {t("generic.idea")}</h3>
            ) : (
              <h3>We recommend leaving your email address before you submit</h3>
            )}
            <p>
              Whilst submitted the {t("generic.idea")} will no longer be editable. If you provide your email address
              below we'll set you up with a {util.appName()} account. This means you can come back later to stay updated
              about the
              {t("generic.challenge")} and to withdraw your submission should you wish to make amends.
            </p>
            {!accountCreated && !accountExists && (
              <Input
                placeholder="jane@example.com"
                type="email"
                value={emailAddress}
                onChange={(e) => {
                  setEmailAddress(e.target.value);
                  setAccountLoading(false);
                }}
                style={{ marginBottom: 20, width: 300 }}
              />
            )}
            {accountExists && (
              <>
                <Message info>
                  <Message.Header>Account exists</Message.Header>
                  <p>
                    An account already exists using this email. Click the button below to login and assign this{" "}
                    {t("generic.idea")} to your account.
                  </p>
                </Message>
                <Button
                  content="Login"
                  as={Link}
                  secondary
                  to={`/login?then=/ideas/${idea._id}`}
                  style={{ width: 300, marginBottom: 20 }}
                />
              </>
            )}
            {emailAddress && !accountExists ? (
              <>
                <TermsCheckbox
                  agreed={termsAgreed === 2}
                  onChange={(checked) => setTermsAgreed(checked ? 2 : false)}
                  style={{
                    marginBottom: 20,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                />
                <Button
                  disabled={!termsAgreed}
                  content={`Create account & Submit ${t("generic.idea")}`}
                  onClick={() => checkForAccount()}
                  style={{ width: 300 }}
                  loading={accountLoading}
                  primary
                />
              </>
            ) : (
              <>
                {idea.ownerChallenge?.visibility?.publicIdeaCreationRequirement === "noAction" && (
                  <Button
                    content={`Submit ${t("generic.idea")}`}
                    onClick={() => submitIdea(true)}
                    style={{ width: 300 }}
                    primary
                  />
                )}
              </>
            )}
            <span />
          </>
          <FakeLink
            onClick={() => {
              setAnonymousSubmissionModalOpen(false);
              setEmailAddress("");
              setAccountExists("");
            }}
            style={{ marginTop: 10, color: "#000" }}
            withBorder
          >
            Continue editing
          </FakeLink>
        </div>
      </Modal>

      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={submitModalOpen}
        onClose={() => setSubmitModalOpen(false)}
      >
        <div
          style={{
            textAlign: "center",
            marginTop: 7,
            marginBottom: 10,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <div style={{ marginTop: 40, marginBottom: 20 }}>
            <Icon name="checkmark" size="huge" color="green" />
          </div>
          <Header as="h2" style={{ marginBottom: 20 }}>
            <small>
              Your {t("generic.idea")} was successfully submitted to {idea?.ownerChallenge?.name}
            </small>
          </Header>
          {idea?.ownerChallenge && idea.ownerChallenge.ideaVisibility === "users" ? null : (
            <>
              <Divider horizontal>
                <Header as="h4">{t("challenges.new.done.info")}</Header>
              </Divider>
              <Modal.Content>
                {accountCreated && (
                  <Message success style={{ marginLeft: 40, marginRight: 40 }}>
                    <h3>We've set-up an account for you</h3>
                    <p>
                      Follow the link in the account setup email we sent you to resume editing your {t("generic.idea")}.
                    </p>
                  </Message>
                )}
                <div style={{ marginTop: 30, marginBottom: 30, display: theme.sizes.isMobile ? null : "flex" }}>
                  {user?._id ? (
                    <IdeaSubmissionNextStep
                      theme={theme}
                      history={history}
                      to={"/challenges/discover"}
                      icon="eye"
                      text={`Discover ${t("generic.challenges")}`}
                    />
                  ) : null}
                  {idea.ownerChallenge && (
                    <IdeaSubmissionNextStep
                      theme={theme}
                      history={history}
                      to={`/challenges/${idea.ownerChallenge?._id}`}
                      icon="target"
                      text={`View the ${t("generic.challenge")}`}
                    />
                  )}
                  <Popup
                    size="tiny"
                    on="click"
                    clickable
                    position="top center"
                    style={{ minWidth: "max-content" }}
                    trigger={
                      <IdeaSubmissionNextStep theme={theme} icon="share" text={`Share your ${t("generic.idea")}`} />
                    }
                    content={
                      <div
                        style={{
                          zIndex: 10000,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                        }}
                      >
                        <Button
                          href={`https://x.com/intent/tweet?text=${util.host(user)}/ideas/${idea._id}`}
                          target="_blank"
                          color="black"
                          style={{ marginRight: 7, width: 35 }}
                          icon={() => "𝕏"}
                        />
                        <Button
                          href={`https://www.facebook.com/sharer/sharer.php?u=${util.host(user)}/ideas/${idea._id}`}
                          target="_blank"
                          color="facebook"
                          style={{ marginRight: 7 }}
                          icon="facebook"
                        />
                        <Input
                          action={
                            <Button
                              content="Copy"
                              color="grey"
                              onClick={() =>
                                window.navigator.clipboard.writeText(`${util.host(user)}/ideas/${idea._id}`)
                              }
                            />
                          }
                          defaultValue={`${util.host(user)}/ideas/${idea._id}`}
                        />
                      </div>
                    }
                  />
                </div>
              </Modal.Content>
            </>
          )}
        </div>
        <Modal.Actions>
          {idea?.ownerChallenge && idea.ownerChallenge.ideaVisibility === "users" ? (
            <Button
              primary
              content={`View ${t("generic.ideas")} created by others`}
              onClick={() => {
                setSubmitModalOpen(false);
                navigate(`/challenges/${idea.ownerChallenge._id}/ideas`);
              }}
            />
          ) : (
            <Button content={`Return to my ${t("generic.idea")}`} onClick={() => setSubmitModalOpen(false)} />
          )}
        </Modal.Actions>
      </Modal>

      <Modal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={createAccountSubmitModalOpen}
        onClose={() => setCreateAccountSubmitModalOpen(false)}
      >
        <Modal.Header>Thanks for your submission</Modal.Header>
        <Modal.Content>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: 20,
              paddingTop: 5,
            }}
          >
            <h3 style={{ marginBottom: 15 }}>
              Your {t("generic.idea")} was successfully submitted to {idea?.ownerChallenge?.name}.
            </h3>
            <p>
              Login or register for an account with {util.appName()} to track the progress of your {t("generic.idea")}.
              You will also then be able to engage with other {t("generic.challenges")} and view other available
              submitted {t("generic.ideas")} on {util.appName()}.
            </p>
            <Button
              content="Login or register"
              primary
              onClick={() => {
                setCreateAccountSubmitModalOpen(false);
                navigate(`/login?then=/challenges/${ownerChallenge._id}/ideas`);
              }}
            />
          </div>
        </Modal.Content>
      </Modal>

      <Modal mountNode={document.getElementById("semantic-modal-mount-node")} open={nameIdeaModelOpen}>
        <Modal.Header>Ready to submit your {t("generic.idea")}?</Modal.Header>
        <Modal.Content>
          {loadingAIIdeaName ? (
            <div style={{ padding: "20px 0" }}>
              <Dimmer active inverted>
                <Loader active content={`Generating ${t("generic.idea")} name...`} inverted />
              </Dimmer>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 20,
                paddingTop: 5,
              }}
            >
              <h3>Please name your {t("generic.idea")} before submission</h3>
              <p>
                Naming your {t("generic.idea")} will help to identify it within the {t("generic.challenge")}. We suggest
                choosing a name based on the topic of the {t("generic.idea")}.
              </p>
              <Input
                placeholder={`Name your ${t("generic.idea")}...`}
                value={isDefaultName ? "" : idea?.name}
                onChange={(e) => updateIdea(e.target.value || defaultName, ["name"])}
                style={{ minWidth: theme.sizes.isMobile ? "80vw" : 400 }}
              />
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button content="Cancel" onClick={() => setNameIdeaModelOpen(false)} />
          <TooltipButton
            content="Submit"
            disabled={!idea?.name || isDefaultName}
            icon="checkmark"
            onClick={() => submitIdea(true)}
            primary
            wrapperStyle={{ marginLeft: ".75em" }}
            tooltip={
              !idea?.name || isDefaultName
                ? `You are required to give your ${t("generic.idea")} a name before submission`
                : undefined
            }
          />
        </Modal.Actions>
      </Modal>
      {util.canEditIdea(user, idea) || util.canManageChallenge(user, idea.ownerChallenge) ? (
        idea.isSubmitted ? (
          <StyledButton
            ref={submitRef}
            size={size}
            style={style}
            content={
              util.canEditIdea(user, idea)
                ? theme.sizes.isMobile
                  ? `Unsubmit ${t("generic.idea")}`
                  : t("ideas.editing")
                : t("generic.unsubmit")
            }
            icon="edit"
            onClick={() => submitIdea(false)}
            primary
            loading={isSubmitting}
            className="submit-idea-button"
          />
        ) : (
          <Popup
            disabled={
              (isSubmissible || incorrectFields?.length === 0) &&
              (ownerChallenge?.stage === "draft" || ownerChallenge?.stage === "published")
            }
            on="hover"
            position="bottom left"
            content={
              ownerChallenge?.stage === "closed" ? (
                `This ${t("generic.challenge")} is now closed to submissions.`
              ) : (
                <p>
                  <b>Fields that need action:</b>
                  <ul>
                    {incorrectFields?.map((f) => (
                      <li key={f.id}>{f.title}</li>
                    ))}
                  </ul>
                </p>
              )
            }
            trigger={
              <StyledButton
                ref={submitRef}
                disabled={
                  isSaving || unsaved || ownerChallenge?.stage === "closed" || (!ownerChallenge && !idea?.isSubmitted)
                }
                style={style}
                size={size}
                content={isSaving ? t("generic.saving") : t("generic.submit")}
                icon={isSaving ? "spinner" : "paper plane"}
                onClick={handleClick}
                primary
                loading={isSubmitting}
                className="submit-idea-button"
              />
            }
          />
        )
      ) : null}
    </>
  );
};

export default SubmitIdeaButton;
