import React from "react";
import util from "utils/utils";
import { Trans } from "react-i18next";

export default function CommentReacted({ events, ownerIdea = { name: "Undefined" } }) {
  return (
    <Trans i18nKey="notifications.ideaCommentReacted">
      <b>{{ name: util.getEventUsers(events) }}</b> reacted to your comment on the idea
      <b>{{ idea: ownerIdea.name }}</b>
    </Trans>
  );
}
