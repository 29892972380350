import React from "react";
import util from "utils/utils";
import { Trans, useTranslation } from "react-i18next";
import FormattedComment from "components/lib/FormattedComment";

export default function IdeaCommentedContent({ events, ownerIdea = { name: "Undefined" } }) {
  const { t } = useTranslation();
  const textSuffix = events[0]?.isFollower ? `${t("generic.ideaWithArticle")} you follow` : `your ${t("generic.idea")}`;
  return (
    <Trans i18nKey="notifications.ideaCommented">
      <b>{{ name: util.getEventUsers(events) }}</b> commented on {{ suffix: textSuffix }}
      <b>{{ idea: ownerIdea.name }}</b>: <FormattedComment noLink>"{{ comment: events[0]?.comment }}"</FormattedComment>
    </Trans>
  );
}
