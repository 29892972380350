import React, { useState, useMemo } from "react";
import { Modal, Button, Divider, Icon, Table, Popup } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import RichTextViewer from "components/lib/Editors/RichTextViewer";
import moment from "moment";
import util from "utils/utils";
import { StyledModal } from "../StyledModal";
import StandardAnnouncementModal from "./StandardAnnouncementModal";

const StandardAnnouncements = ({
  forType,
  forId,
  t,
  standardConditions,
  scheduledAnnouncements,
  setScheduledAnnouncements,
  deliveredScheduledAnnouncements,
  setPage,
  prevPageAvailable,
  nextPageAvailable,
  deleteAnnouncement,
}) => {
  const [editingAnnouncement, setEditingAnnouncement] = useState({});
  const [viewingDeliveredAnnouncement, setViewingDeliveredAnnouncement] = useState({});

  // We set this if we have any data from Sendgrid about automatic announcements. This comes in the form of an object "events"
  // With possible event counts: openCount, deliveredCount, bounceCount
  const scheduledAnnouncementsHaveEventData = useMemo(
    () => !!deliveredScheduledAnnouncements.find((a) => !!a.events),
    [deliveredScheduledAnnouncements],
  );
  const allScheduledAnnouncementsHaveEventData = useMemo(
    () => deliveredScheduledAnnouncements.every((a) => !!a.events),
    [deliveredScheduledAnnouncements],
  );

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end", margin: "10px 0" }}>
        <Button
          secondary
          icon="paper plane"
          content={t("announcements.manual.new.title")}
          size="small"
          onClick={() => setEditingAnnouncement({ _id: null })}
        />
      </div>
      <StandardAnnouncementModal
        forType={forType}
        forId={forId}
        deleteAnnouncement={deleteAnnouncement}
        standardConditions={standardConditions}
        scheduledAnnouncements={scheduledAnnouncements}
        setScheduledAnnouncements={setScheduledAnnouncements}
        deliveredScheduledAnnouncement={deliveredScheduledAnnouncements}
        editingAnnouncement={editingAnnouncement}
        setEditingAnnouncement={setEditingAnnouncement}
      />
      {scheduledAnnouncements?.length ? (
        <div>
          <h5 style={{ clear: "both" }}>Announcements</h5>
          <Table unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Send date</Table.HeaderCell>
                <Table.HeaderCell>Time</Table.HeaderCell>
                <Table.HeaderCell>Subject</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {scheduledAnnouncements.map((a) => (
                <Table.Row key={a._id}>
                  <Table.Cell collapsing>{moment(a.sendAt).format("YYYY-MM-DD")}</Table.Cell>
                  <Table.Cell collapsing>{moment(a.sendAt).format("HH:mm")}</Table.Cell>
                  <Table.Cell>{a.subject}</Table.Cell>
                  <Table.Cell collapsing>
                    {a.sendFailed ? (
                      <span style={{ color: "red" }}>
                        <Icon name="warning sign" /> Failed: {a.failMessage}
                      </span>
                    ) : (
                      <span style={{ color: "green" }}>
                        <Icon name="check circle" /> Ready
                      </span>
                    )}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    <Button basic size="tiny" icon="edit" content="Edit" onClick={() => setEditingAnnouncement(a)} />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      ) : null}

      {deliveredScheduledAnnouncements?.length ? (
        <div>
          <Divider hidden />
          <h5 style={{ display: "contents" }}>Delivered announcements</h5>
          <Button.Group size="mini" style={{ marginLeft: 10, float: "right", marginBottom: 5 }}>
            {prevPageAvailable ? (
              <Button
                size="tiny"
                icon="arrow left"
                content="Previous page"
                onClick={() => setPage((prev) => prev - 1)}
              />
            ) : null}
            {nextPageAvailable ? (
              <Button size="mini" onClick={() => setPage((prev) => prev + 1)}>
                Next page <Icon name="arrow right" />
              </Button>
            ) : null}
          </Button.Group>
          <Table unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Send date</Table.HeaderCell>
                <Table.HeaderCell>Time</Table.HeaderCell>
                <Table.HeaderCell>Subject</Table.HeaderCell>
                {scheduledAnnouncementsHaveEventData ? (
                  [
                    <Table.HeaderCell collapsing>
                      Sent{" "}
                      {!allScheduledAnnouncementsHaveEventData ? (
                        <Popup
                          content="We were not able to track send counts for all of your announcements. Any announcement without send data will show a checkmark icon to indicate a successful delivery instead."
                          trigger={<Icon name="question circle" />}
                        />
                      ) : null}
                    </Table.HeaderCell>,
                    <Table.HeaderCell collapsing>Delivered</Table.HeaderCell>,
                    <Table.HeaderCell collapsing>
                      Bounced{" "}
                      <Popup
                        content="Bounced emails were rejected by the recipient's mail server. This is commonly caused by a misspelled or nonexistent email address."
                        trigger={<Icon name="question circle" />}
                      />
                    </Table.HeaderCell>,
                    <Table.HeaderCell collapsing>Opened</Table.HeaderCell>,
                  ]
                ) : (
                  <Table.HeaderCell>Status</Table.HeaderCell>
                )}
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {deliveredScheduledAnnouncements.map((a) => (
                <Table.Row key={a._id}>
                  <Table.Cell collapsing>{moment(a.sentAt).format("YYYY-MM-DD")}</Table.Cell>
                  <Table.Cell collapsing>{moment(a.sentAt).format("HH:mm")}</Table.Cell>
                  <Table.Cell>{a.subject}</Table.Cell>
                  {scheduledAnnouncementsHaveEventData ? (
                    <>
                      {!a?.events ? (
                        <>
                          <Table.Cell collapsing>
                            <Icon name="check circle" color="green" />
                          </Table.Cell>
                          <Table.Cell collapsing />
                          <Table.Cell collapsing />
                          <Table.Cell collapsing />
                        </>
                      ) : (
                        [
                          <Table.Cell collapsing>{a.events?.processedCount || a.countSent || 0}</Table.Cell>,
                          <Table.Cell collapsing>{a.events?.deliveredCount || 0}</Table.Cell>,
                          <Table.Cell collapsing>{a.events?.bounceCount || 0}</Table.Cell>,
                          <Table.Cell collapsing>{a.events?.openCount || 0}</Table.Cell>,
                        ]
                      )}
                    </>
                  ) : (
                    <Table.Cell collapsing>Sent</Table.Cell>
                  )}
                  <Table.Cell collapsing>
                    <Button
                      basic
                      size="tiny"
                      icon="eye"
                      content="View"
                      onClick={() => setViewingDeliveredAnnouncement(a)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      ) : null}

      {/* View delivered announcements modal */}
      <StyledModal
        mountNode={document.getElementById("semantic-modal-mount-node")}
        open={!util.isEmpty(viewingDeliveredAnnouncement)}
        onClose={() => setViewingDeliveredAnnouncement({})}
      >
        <Modal.Header>{t("announcements.form.title")}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <h4>{t("announcements.message.subject")}</h4>
            <p>{viewingDeliveredAnnouncement.subject}</p>
            <h4>{t("announcements.message.sentAt")}</h4>
            <p>{moment(viewingDeliveredAnnouncement.sentAt).format("HH:mm on DD/MM/YY")}</p>
            <Divider />
            <h4>{t("announcements.message.message")}</h4>
            <RichTextViewer content={viewingDeliveredAnnouncement.body} />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button content={t("generic.close")} onClick={() => setViewingDeliveredAnnouncement({})} />
        </Modal.Actions>
      </StyledModal>
    </>
  );
};

export default withTranslation()(StandardAnnouncements);
