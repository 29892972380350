import React from "react";
import { Link } from "react-router-dom";
import util from "utils/utils";
import ProductTour from "components/lib/ProductTour";
import RowComponent from "components/lib/RowComponent";

const AssessmentAssignments = ({
  challenges,
}: {
  challenges: {
    _id: string;
    name: string;
    bannerImageUrl: string;
    incompleteAssessmentCount: number;
  }[];
}) => {
  if (!challenges.length) return null;
  return (
    <>
      <ProductTour tour="assessmentHomepage" isReady />
      <RowComponent className="assessment-homepage-tour-step-1">
        <RowComponent.Fill>
          <RowComponent.Title>Your assessment assignments</RowComponent.Title>
        </RowComponent.Fill>
        {challenges.map((challenge, index) => (
          <RowComponent.Cell
            index={index}
            key={challenge._id}
            as={Link}
            to={`/challenges/${challenge._id}/assessments/assigned`}
            className={index === 0 ? "assessment-homepage-tour-step-2" : null}
          >
            <div
              style={{
                width: 50,
                height: 40,
                borderRadius: 3,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundImage: `url(${util.mixinCssUrlFallback(util.getImageUrl(challenge, ["coverImage", "bannerImage"], ["200x200"]), util.challengeImage())})`,
              }}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: 5,
                marginRight: 5,
                position: "relative",
                top: -1,
              }}
            >
              <RowComponent.Title style={{ color: "#5d5d5d" }} block>
                {challenge.name}
              </RowComponent.Title>
              <RowComponent.StatusArea>
                <RowComponent.StatusLabel color="orange" />
                {util.pluralise(challenge.incompleteAssessmentCount, "assessment", "assessments")} to complete
              </RowComponent.StatusArea>
            </div>
          </RowComponent.Cell>
        ))}
      </RowComponent>
    </>
  );
};

export default AssessmentAssignments;
