import styled from "styled-components";

const StyledPortraitSearchItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  &:hover {
    background-color: #babcbe;
  }
  ${({ $active }) => $active && "background-color: #babcbe;"}
`;

const StyledLandscapeSearchItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  &:hover {
    background-color: #babcbe;
  }
  ${({ $active }) => $active && "background-color: #babcbe;"}
`;

export { StyledPortraitSearchItem, StyledLandscapeSearchItem };
