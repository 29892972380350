import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

const WelcomeMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 2rem 0 -1rem 0;
  img {
    width: 300px;
    height: 100px;
    align-self: center;
    object-fit: contain;
  }
  h3 {
    display: block;
    margin: 10px 0;
    font-size: 2.3em;
    font-family: "Inter";
  }
  p {
    display: block;
    margin: 0;
    font-size: 1.3em;
    font-family: "Inter";
    text-align: center;
  }
`;

const SetupBranding = ({ user }) => {
  if (!user) return null;
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <WelcomeMessageContainer>
        <img alt="" src={user?.organisation?.darkLogoUrl} />
        <h3>Welcome to {user?.organisation?.name}!</h3>
        <p>We just need to know a few more details about you</p>
      </WelcomeMessageContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const SetupBrandingContainer = connect(mapStateToProps)(SetupBranding);

export default SetupBrandingContainer;
