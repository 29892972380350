import React, { useCallback, useState, useEffect, useMemo } from "react";
import api from "api";
import toast from "react-hot-toast";
import { withTranslation } from "react-i18next";
import { Button, Dropdown, Popup, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import util from "utils/utils";
import actions from "actions";
import moment from "moment";
import { UserChip } from "components/lib/Chips";

import { IdeaTeamStartChat, startIdeaChat, handleIdeaChat as funcHandleIdeaChat } from "components/lib/Ideas";
import UserChooser from "components/lib/Choosers/UserChooser";
import RequestJoinModal from "components/lib/Ideas/RequestJoinModal";

export const RequestIdeaTeam = ({ idea, updateIdea }) => {
  const [joinModalOpen, setJoinModalOpen] = useState(false);
  return (
    <>
      <RequestJoinModal
        idea={idea}
        open={joinModalOpen}
        onClose={() => setJoinModalOpen(false)}
        onConfirm={() => {
          updateIdea({ requestedToJoin: true }, ["requestedToJoin"], true);
        }}
      />
      <Button
        size="tiny"
        disabled={idea.requestedToJoin}
        compact
        content={idea.requestedToJoin ? "Awaiting approval to join" : "Request to join team"}
        icon="paper plane"
        fluid
        style={{ marginTop: 10 }}
        onClick={() => setJoinModalOpen(true)}
      />
    </>
  );
};

const IdeaTeam = ({ user, idea, leaveIdea, canEdit, t, updateIdea, onMessageThreadSelected }) => {
  const [invitations, setInvitations] = useState([]);
  const [_isUpdating, setIsUpdating] = useState(false);

  const [chatSearching, setChatSearching] = useState(false);
  const [chatThread, setChatThread] = useState(null);
  const ideaId = idea?._id;

  const getInvitations = useCallback(() => {
    api.invitations.getForType("idea", ideaId, ({ invitations: newInvitations }) => {
      setInvitations(newInvitations);
    });
  }, [ideaId]);

  useEffect(() => getInvitations(), [getInvitations]);

  const makeOwner = useCallback(
    (teammate) => {
      setIsUpdating(true);
      util
        .confirm(t("ideas.teammates.owner.title"), t("ideas.teammates.owner.info", { teammate }))
        .then(() => {
          api.ideas.updateOwner(
            idea._id,
            teammate._id,
            ({ collaborators }) => {
              updateIdea(teammate._id, ["user"], true);
              updateIdea(collaborators, ["collaborators"], false);
              setIsUpdating(false);
            },
            (err) => {
              toast.error(err.message);
              setIsUpdating(false);
            },
          );
        })
        .catch(() => {
          setIsUpdating(false);
        });
    },
    [idea, updateIdea, t],
  );

  const searchForCollaborators = useCallback(
    (term, cb, fail) => {
      api.search.ideaCollaborators(
        idea._id,
        term,
        ({ users }) => {
          cb(users);
        },
        fail,
      );
    },
    [idea._id],
  );

  const handleIdeaChat = useCallback(() => {
    funcHandleIdeaChat(chatThread, idea.name, "idea", idea._id, chatSearching, idea, user._id, onMessageThreadSelected);
  }, [chatThread, idea, chatSearching, user._id, onMessageThreadSelected]);

  const getIdeaChat = useCallback(() => {
    startIdeaChat("idea", idea._id, setChatSearching, setChatThread, handleIdeaChat, false);
  }, [idea._id, handleIdeaChat]);

  const addTeammateMulti = useCallback(
    (teammateIds) => {
      api.ideas.addCollaboratorMulti(
        idea._id,
        teammateIds,
        ({ authors = [], collaborators = [] }) => {
          updateIdea(authors, ["authors"], true);
          updateIdea(collaborators, ["collaborators"], true);
          if (collaborators.length === 1) getIdeaChat();
        },
        (err) => toast.error(err.message),
      );
    },
    [idea._id, updateIdea, getIdeaChat],
  );

  const removeTeammate = useCallback(
    (teammate) => {
      util
        .confirm(t("ideas.teammates.remove.title"), t("ideas.teammates.remove.info", { teammate }))
        .then(() => {
          api.ideas.removeCollaborator(
            idea._id,
            teammate._id,
            ({ authors, collaborators }) => {
              updateIdea(authors, ["authors"], true);
              updateIdea(collaborators, ["collaborators"], true);
            },
            (err) => toast.error(err.message),
          );
        })
        .catch(() => {});
    },
    [idea._id, t, updateIdea],
  );

  const removeInvite = useCallback(
    (invitation) => {
      util
        .confirm(t("ideas.invitations.remove.title"), t("ideas.invitations.remove.info"))
        .then(() => {
          api.invitations.remove(
            invitation._id,
            () => {
              setInvitations((prevInvitations) => prevInvitations.filter((i) => i._id !== invitation._id));
            },
            (err) => toast.error(err.message),
          );
        })
        .catch(() => {});
    },
    [t],
  );

  const teammateActionButton = useCallback(
    (teammate) => {
      const { collaborators = [] } = idea;
      const teammateIsCollaborator = collaborators.indexOf(teammate._id) > -1;
      const teammateIsOwner = idea.user === teammate._id;
      const currentUserIsOwner = idea.user === user._id;
      const teammateIsCurrentUser = user._id === teammate._id;
      if (!canEdit && !teammateIsCurrentUser) return null;
      if (canEdit && teammateIsCurrentUser && teammateIsOwner) return null;
      if (canEdit && !teammateIsCurrentUser && !currentUserIsOwner) return null;
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            paddingRight: 10,
          }}
        >
          <Dropdown icon="dropdown">
            <Dropdown.Menu direction="left">
              {canEdit && !teammateIsOwner && currentUserIsOwner ? (
                <>
                  <Dropdown.Item
                    icon="chess king"
                    content={t("ideas.teammates.add.owner")}
                    onClick={() => makeOwner(teammate)}
                  />
                  <Dropdown.Item
                    icon="ban"
                    style={{ color: "rgba(200, 10, 30, 0.87)" }}
                    content={t("ideas.teammates.remove.removeNow")}
                    onClick={() => removeTeammate(teammate)}
                  />
                </>
              ) : null}
              {teammateIsCurrentUser && !teammateIsOwner && teammateIsCollaborator ? (
                <Dropdown.Item
                  icon="ban"
                  style={{ color: "rgba(200, 10, 30, 0.87)" }}
                  content={t("ideas.options.leave")}
                  onClick={() => leaveIdea()}
                />
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    },
    [idea, user, t, leaveIdea, canEdit, makeOwner, removeTeammate],
  );

  const invitationActionButton = useCallback(
    (invitation) => {
      if (!canEdit) return null;
      return (
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
          <Dropdown icon="dropdown">
            <Dropdown.Menu direction="left">
              {canEdit ? (
                <Dropdown.Item
                  icon="ban"
                  style={{ color: "rgba(200, 10, 30, 0.87)" }}
                  content={t("ideas.invitations.remove.removeNow")}
                  onClick={() => removeInvite(invitation)}
                />
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      );
    },
    [t, canEdit, removeInvite],
  );

  const { authors = [] } = idea;
  const invited = useMemo(
    () =>
      (invitations || []).map((i) => ({
        ...i,
        profile: {
          fullName: i.inviteeUser?.profile?.fullName || i.invitee,
          jobTitle: `Invited ${moment(i.createdAt).format("DD/MM/YY, hh:mm A")}`,
        },
      })),
    [invitations],
  );
  if (!user) return;
  return (
    <div data-testid="idea-team">
      {idea?.user || idea?.collaborators?.length ? (
        <Popup
          on="hover"
          content={t("ideas.teammates.para")}
          trigger={<h5 style={{ color: "#333", marginBottom: 10 }}>{t("ideas.teammates.title")}</h5>}
        />
      ) : null}

      {authors.map((u) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 8,
            alignItems: "center",
            maxWidth: "100%",
          }}
          key={u._id}
        >
          <UserChip user={u} compact />
          {idea.user === u._id && (
            <Popup
              trigger={<Icon circular inverted size="small" name="lightbulb" color="violet" />}
              content={`${t("common:capitalise", { key: "generic.idea" })} owner`}
              on="hover"
            />
          )}
          {teammateActionButton(u)}
        </div>
      ))}
      {invited.length > 0 && user._id === idea.user ? (
        <>
          <h5 style={{ color: "#333", marginBottom: 10 }}>Pending invitations</h5>
          {invited.map((u) => (
            <div key={u._id} style={{ display: "flex", justifyContent: "space-between", marginBottom: 8 }}>
              <UserChip user={u} compact />
              {invitationActionButton(u)}
            </div>
          ))}
        </>
      ) : null}

      {util.canJoinIdeaChat(user, idea) ? (
        <IdeaTeamStartChat idea={idea} style={{ marginTop: 10 }} fluid size="tiny" compact basic />
      ) : null}
      {canEdit && user._id === idea.user ? (
        <UserChooser
          trigger={
            <Button
              size="tiny"
              compact
              content={t("ideas.teammates.add.addNow")}
              icon="user add"
              fluid
              style={{ marginTop: util.canJoinIdeaChat(user, idea) ? 5 : 10 }}
            />
          }
          enabledFeatures={{ search: !idea?.ownerChallenge?.preventCollaboration, invite: true, persistentToken: true }}
          persistentTokenTitle={t("ideas.teammates.link.title")}
          persistentTokenDescription={t("ideas.teammates.link.description")}
          persistentTokenWarning={t("ideas.teammates.link.warning")}
          searchFunction={searchForCollaborators}
          confirm="Add these users to the team"
          clearOnComplete
          forType="idea"
          forId={idea._id}
          onComplete={(users) => {
            const emailUsers = users.filter((u) => u.isEmailInvitee);
            if (emailUsers.length) {
              api.invitations.createBulk(
                {
                  invitees: emailUsers.map((u) => u._id),
                  invitationType: "email",
                  forType: "idea",
                  forId: idea._id,
                },
                ({ invitations: newInvitations }) => {
                  setInvitations((prevInvitations) => [...prevInvitations, ...newInvitations]);
                  toast.success(`Invitation${emailUsers.length > 1 ? "s" : ""} sent`);
                },
              );
            }
            const nonEmailUsers = users.filter((u) => !u.isEmailInvitee);
            if (nonEmailUsers.length) addTeammateMulti(nonEmailUsers.map((u) => u._id));
            if (users.find((u) => u.isEmailInvitee === undefined)) toast.success("Collaborators updated");
          }}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => ({
  onMessageThreadSelected: (thread, isPhone) => dispatch(actions.messages.selectThread(thread, isPhone)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IdeaTeam));
