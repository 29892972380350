import React from "react";
import util from "utils/utils";
import BaseChip, { ChipConfiguration } from "./BaseChip";

const ProfileChip = ({
  profile,
  ...props
}: ChipConfiguration & {
  profile: {
    _id: string;
    name: string;
    overview?: string;
  };
}) => {
  return (
    <BaseChip
      {...props}
      linkTo={`/profiles/${profile._id}`}
      title={profile.name}
      description={profile.overview}
      imageUrl={util.profileLogo(profile)}
    />
  );
};

export default ProfileChip;
