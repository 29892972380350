import React, { useCallback, useState } from "react";
import { Modal, Button, Input, Popup, Message } from "semantic-ui-react";
import { connect } from "react-redux";
import toast from "react-hot-toast";
import { withTranslation } from "react-i18next";
import { UserChip } from "components/lib/Chips";
import actions from "actions";
import styled from "styled-components";
import api from "api";
import util from "utils/utils";

const ModalContainer = styled.div`
  display: flex;
  ${({ $marginedTop }) => $marginedTop && "margin-top: 12.5px;"}
  &:not(:last-child) {
    padding-bottom: 12.5px;
    border-bottom: 0.5px solid lightgray;
  }
`;

const ParticipantContainer = styled.div`
  display: flex;
  flex-direction: column;
  div {
    margin: 5px 0;
  }
  > div:not(:last-child) {
    border-bottom: 0.5px solid lightgray;
  }
  > div:hover {
    background-color: #f9f9f9;
  }
`;

const ActionArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-right: 25px;
  }
  h4 {
    margin: 5px 0px;
  }
`;

const ParticipantActions = styled.div`
  display: flex;
  align-items: center;
`;

const Participant = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const ChatSettings = ({ thread, onMessageThreadSelected, user, modalOpen, closeModal, t, setMinimised }) => {
  const [searchState, setSearchState] = useState({
    results: [],
    searching: false,
    searchValue: "",
  });
  const { results } = searchState;
  const { participantUsers = [], participants = [], participantsMuted = [] } = thread || {};
  const threadId = thread?._id;
  const isMuted = participantsMuted.indexOf(user._id) > -1;

  const removeParticipant = useCallback(
    (participantId) => {
      const filteredParticipants = participants.filter((p) => p !== participantId);
      api.messages.updateThread(
        threadId,
        { participants: filteredParticipants },
        (newThread) => {
          onMessageThreadSelected(newThread);
        },
        (err) => toast.error(err.message),
      );
    },
    [participants, threadId, onMessageThreadSelected],
  );

  const addParticipant = useCallback(
    (newId) => {
      const newParticipants = [...participants, newId];
      api.messages.updateThread(
        threadId,
        { participants: newParticipants },
        (newThread) => {
          onMessageThreadSelected(newThread);
        },
        (err) => toast.error(err.message),
      );
    },
    [participants, threadId, onMessageThreadSelected],
  );

  const searchMessageRecipient = useCallback(
    (e) => {
      setSearchState((prevState) => ({ ...prevState, searching: true, searchValue: e?.target?.value }));
      api.search.messageRecipient(
        e?.target?.value || "",
        ({ users }) => {
          setSearchState((prevState) => ({ ...prevState, searching: false, results: users }));
        },
        (err) => {
          toast.error(err.message);
          setSearchState((prevState) => ({ ...prevState, searching: false }));
        },
      );
    },
    [setSearchState],
  );

  const changeChatOwner = useCallback(
    (newOwnerId) => {
      api.messages.updateThread(
        threadId,
        { user: newOwnerId },
        (newThread) => {
          onMessageThreadSelected(newThread);
        },
        (err) => toast.error(err.message),
      );
    },
    [threadId, onMessageThreadSelected],
  );

  const deleteThread = useCallback(
    () =>
      util
        .confirm("Delete thread", "Delete this thread and all associated messages. This action cannot be reversed.")
        .then(() => {
          api.messages.deleteThread(
            threadId,
            () => {
              setMinimised(true);
              onMessageThreadSelected(null);
            },
            (err) => toast.error(err.message),
          );
        })
        .catch(() => {}),
    [threadId, onMessageThreadSelected, setMinimised],
  );

  const handleSearchChange = useCallback(
    (e) => {
      e.persist();
      searchMessageRecipient(e);
    },
    [searchMessageRecipient],
  );

  const muteThread = useCallback(() => {
    api.messages.muteThread(
      threadId,
      !isMuted,
      ({ participantsMuted: newParticipantsMuted }) => {
        onMessageThreadSelected({ ...thread, participantsMuted: newParticipantsMuted });
      },
      (err) => toast.error(err.message),
    );
  }, [threadId, isMuted, onMessageThreadSelected, thread]);

  const canEdit = thread.user === user._id && !(thread.forType && thread.forId);

  return (
    <Modal
      mountNode={document.getElementById("semantic-modal-mount-node")}
      open={modalOpen}
      onClose={closeModal}
      style={{ minHeight: "50vh", maxHeight: "90vh" }}
    >
      <Modal.Content>
        <h3 style={{ marginTop: 5, marginBottom: 5 }}>Thread settings</h3>
        <ModalContainer>
          <ActionArea>
            <h4>Participants</h4>
            <ParticipantContainer>
              {participantUsers.map((u, index) => (
                <Participant key={index}>
                  <UserChip user={u} noLink />
                  <ParticipantActions>
                    {canEdit && user._id !== u._id ? (
                      <Popup
                        content="Make owner of chat"
                        on="hover"
                        trigger={
                          <Button
                            color="yellow"
                            compact
                            icon="chess queen"
                            onClick={() => changeChatOwner(u._id)}
                            size="tiny"
                          />
                        }
                      />
                    ) : (
                      <div />
                    )}
                    {!canEdit || participants.length < 3 ? (
                      <div />
                    ) : (
                      <Popup
                        content="Remove from chat"
                        on="hover"
                        trigger={<Button compact icon="trash" onClick={() => removeParticipant(u._id)} size="tiny" />}
                      />
                    )}
                  </ParticipantActions>
                </Participant>
              ))}
            </ParticipantContainer>
          </ActionArea>
          {canEdit ? (
            <ActionArea>
              <h4>Add participant</h4>
              <ParticipantContainer>
                <Input
                  size="small"
                  fluid
                  onChange={(e) => handleSearchChange(e)}
                  placeholder={t("messages.findRecipient")}
                />
                {results.slice(0, 5).map((u) => (
                  <Participant key={u._id}>
                    <UserChip user={u} noLink />
                    {[thread.user, ...participants].indexOf(u._id) > -1 ? (
                      <div />
                    ) : (
                      <Button compact icon="add" onClick={() => addParticipant(u._id)} size="tiny" />
                    )}
                  </Participant>
                ))}
              </ParticipantContainer>
            </ActionArea>
          ) : null}
        </ModalContainer>
        <ModalContainer>
          <ActionArea style={{ paddingTop: 10 }}>
            <h4>Mute chat</h4>
            <span>You will no longer receive push notifications or emails when messages are sent to this chat.</span>
            <Button
              color={isMuted ? "blue" : ""}
              content={isMuted ? "Un-mute chat" : "Mute chat"}
              onClick={() => muteThread()}
              style={{ width: "fit-content", marginTop: 10 }}
            />
          </ActionArea>
        </ModalContainer>
        {canEdit ? (
          <ModalContainer $marginedTop>
            <ActionArea>
              <h4>Delete chat</h4>
              This action is permanent and cannot be undone
              <Button
                style={{ maxWidth: "fit-content", marginTop: 10 }}
                icon="trash"
                content="Delete chat"
                size="small"
                basic
                onClick={() => deleteThread()}
              />
            </ActionArea>
          </ModalContainer>
        ) : null}
        {thread?.forType === "idea" ? (
          <Message info>
            The participants of this chat are tied to the {t("generic.idea")} team. If the {t("generic.idea")} is
            deleted, you will continue to be able to use the chat, along with edit the participants.
          </Message>
        ) : null}
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = (dispatch) => ({
  onMessageThreadSelected: (thread) => dispatch(actions.messages.selectThread(thread)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChatSettings));
