import { useEffect, useState } from "react";
import Color from "color";
import { useSelector } from "react-redux";

export const deviceSizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
};

const sat = 60;
const lum = 80;

export const colorMap = {
  red: `hsl(0deg ${sat}% ${lum}%)`,
  orange: `hsl(29deg ${sat}% ${lum}%)`,
  yellow: `hsl(43deg ${sat}% ${lum}%)`,
  green: `hsl(96deg ${sat}% ${lum}%)`,
  blue: `hsl(217deg ${sat}% ${lum}%)`,
  grey: "#e0e1e2",
  msteams: "#6264a7",
};

// https://github.com/Qix-/color/blob/e188999dee229c902102ec37e398ff4d868616e5/index.js#L298
export const isDark = (color, threshold = 145) => {
  const rgb = color.rgb().color;
  const yiq = (rgb[0] * 2126 + rgb[1] * 7152 + rgb[2] * 722) / 10000;
  return yiq < threshold;
};

const useTheme = () => {
  const { innerWidth } = window;
  const [width, setInnerWidth] = useState(innerWidth);
  const [theme, setTheme] = useState({
    sizes: {
      width: 0,
      isMobile: width < deviceSizes.tablet,
      isTablet: width >= deviceSizes.tablet && width < deviceSizes.laptop,
      isComputer: width >= deviceSizes.laptop,
      isLargeComputer: width >= deviceSizes.laptopL,
    },
    primaryColour: "#14435B",
    secondaryColour: "#1b678e",
    accent: "",
    secondaryAccent: "",
    shouldBeWhiteOnSecondary: false,
    shouldBeWhiteOnAccent: false,
    shouldBeWhiteOnSecondaryAccent: false,
  });

  const organisation = useSelector((state) => {
    const urlOrganisation = state.organisations?.urlOrganisation;
    return state?.user?.organisation || urlOrganisation || {};
  });
  const { primaryColour = "#14435B", secondaryColour = "#1b678e" } = organisation;

  useEffect(() => {
    const updateDimensions = () => {
      const { innerWidth: currentInnerWidth } = window;
      setInnerWidth(currentInnerWidth);
    };

    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  useEffect(() => {
    const isMobile = width < deviceSizes.tablet;
    const isTablet = width >= deviceSizes.tablet && width < deviceSizes.laptop;
    const isComputer = width >= deviceSizes.laptop;
    const isLargeComputer = width >= deviceSizes.laptopL;
    const sizes = {
      isMobile,
      isTablet,
      isComputer,
      isLargeComputer,
      width,
      minStyle: Object.entries(deviceSizes).reduce((curr, [key, val]) => ({ ...curr, [key]: `min-width: ${val}px;` })),
      maxStyle: Object.entries(deviceSizes).reduce((curr, [key, val]) => ({ ...curr, [key]: `max-width: ${val}px;` })),
    };

    const accent = Color(primaryColour).lighten(0.2);
    const secondaryAccent = Color(secondaryColour).lighten(0.6).fade(0.8);

    setTheme({
      primaryColour,
      secondaryColour: secondaryColour || primaryColour,
      accent: accent.hex(),
      secondaryAccent: secondaryColour ? secondaryAccent.hex() : accent.hex(),
      shouldBeWhiteOnSecondary: isDark(Color(secondaryColour)),
      shouldBeWhiteOnPrimary: isDark(Color(primaryColour)),
      shouldBeWhiteOnAccent: isDark(accent),
      shouldBeWhiteOnSecondaryAccent: isDark(secondaryAccent),
      boxShadow: "0.5px 0.5px 2.5px 1px #C0C0C0",
      sizes,
      colorMap,
    });
  }, [primaryColour, secondaryColour, width]);

  return theme;
};

export default useTheme;
