import React, { useMemo } from "react";
import { Grid, Table, Divider, Label, Icon, Segment } from "semantic-ui-react";
import { Other } from "simplydo/interfaces";

import constants from "utils/constants";

import {
  businessStatusOptions,
  businessSizeOptions,
  businessTypeOptions,
} from "components/ideas/Components/IdeaBusinessProfile/BusinessProfileEditor";

type OverviewProps = {
  businessProfile: Other.IIdeaBusinessProfile;
};

export const Overview = ({ businessProfile }: OverviewProps) => {
  const { profile } = businessProfile;
  const { address } = profile;
  const { country, city, streetAddress, postcode } = address;
  const size = useMemo(
    () => businessSizeOptions.find((option) => option.value === profile?.size)?.text,
    [profile?.size],
  );
  const type = useMemo(
    () => businessTypeOptions.find((option) => option.value === profile?.type)?.text,
    [profile?.type],
  );
  const status = useMemo(
    () => businessStatusOptions.find((option) => option.value === profile?.status)?.text,
    [profile?.status],
  );
  const fullAddress = useMemo(
    () => [streetAddress, city, country, postcode].filter((addressSegment) => !!addressSegment),
    [country, city, streetAddress, postcode],
  );

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          {profile?.imageUrl && (
            <Grid.Column width={5}>
              <img style={{ minWidth: "100%" }} src={profile?.imageUrl} alt={profile?.name} />
            </Grid.Column>
          )}
          <Grid.Column width={11}>
            <h4>About</h4>
            {profile?.websiteUrl && (
              <p style={{ marginBottom: 7 }}>
                <Icon name="world" />
                {profile?.websiteUrl}
              </p>
            )}

            {fullAddress && (
              <p style={{ marginBottom: 7 }}>
                <Icon name="map pin" />
                {fullAddress.join(", ")}
              </p>
            )}

            {profile?.phoneNumber && (
              <p style={{ marginBottom: 7 }}>
                <Icon name="phone" />
                {profile?.phoneNumber}
              </p>
            )}

            {size && (
              <p style={{ marginBottom: 7 }}>
                <Icon name="users" />
                {size}
              </p>
            )}

            {status && (
              <p style={{ marginBottom: 7 }}>
                <Icon name="building" />
                {status}
              </p>
            )}

            {type && (
              <p style={{ marginBottom: 7 }}>
                <Icon name="industry" />
                {type}
              </p>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider hidden />
      {!profile?.creditsafeRegNoSkipped && profile?.creditsafeRegNo && (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 5, fontWeight: "bold" }}>
          <p style={{ marginBottom: 0, marginRight: 5 }}>Company registration number</p>
          <Label size="small" content={profile?.creditsafeRegNo} />
        </div>
      )}
      {!profile?.creditsafeRegNoSkipped && profile?.vatNo && (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 5, fontWeight: "bold" }}>
          <p style={{ marginBottom: 0, marginRight: 5 }}>VAT number</p>
          <Label size="small" content={profile?.vatNo} />
        </div>
      )}
      {profile?.accreditations && profile?.accreditations?.length > 0 && (
        <div style={{ display: "flex", alignItems: "center", marginBottom: 5, fontWeight: "bold" }}>
          <p style={{ marginBottom: 0, marginRight: 5 }}>Accreditations</p>
          {profile?.accreditations.map((accreditation) => (
            <Label
              size-="small"
              key={accreditation}
              content={constants.accreditations.find((acc) => acc.key === accreditation)?.text || accreditation}
            />
          ))}
        </div>
      )}
      <Divider hidden />
      <p>{profile?.description}</p>
      <Divider hidden />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="2">Primary contact details</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell collapsing>Full name</Table.Cell>
            <Table.Cell>{profile?.primaryContact?.fullName}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>Email</Table.Cell>
            <Table.Cell>{profile?.primaryContact?.email}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>Phone number</Table.Cell>
            <Table.Cell>{profile?.primaryContact?.phoneNumber}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell collapsing>Job title</Table.Cell>
            <Table.Cell>{profile?.primaryContact?.jobTitle}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Segment>
  );
};
